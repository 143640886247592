import React, {Fragment} from "react";
import {Xwg} from "../../models/xwg";
import {Link} from "@tanstack/react-router";
import {Tooltip} from "@chakra-ui/react";
import {useTranslation} from "../../utils/helpers";
import {ROUTES} from "../../constants";

export const CwgCategories = ({xwg}: {xwg: Xwg}) => {
    const {t} = useTranslation("cwg_index");

    return <>
        {xwg.categories?.map((tag, index) => {
            return <Fragment key={index}>
                {index > 0 && ", "}
                <Tooltip label={t(
                    "Show xWGs that are cataloged under {{category}}: {{tag}}.",
                    {
                        category: tag.category.name,
                        tag: tag.name
                    }
                )}>
                    <Link
                        to={ROUTES.BROWSE_ADVANCED_WITH_FILTER}
                        params={{
                            filter: {
                                tags: [{category: tag.category.id, name: tag.id}]
                            }
                        }}
                    >
                        {tag.name}
                    </Link>
                </Tooltip>
            </Fragment>;
        })}
        {", "}
        <Tooltip label={t("Show xWGs that are cataloged under the same set of categories as this one.")}>
            <Link
                style={{textDecoration: "none"}}
                to={ROUTES.BROWSE_ADVANCED_WITH_FILTER}
                params={{
                    filter: {
                        tags: xwg.categories?.map(t => ({category: t.category.id, name: t.id}))
                    }
                }}
            >
                ✹
            </Link>
        </Tooltip>
    </>;
}
