import {chakra, Flex, Box, Spacer, FlexProps, ButtonGroup} from "@chakra-ui/react";

export type ContentHeaderProps = {
    title: string;
    toolbarTitle?: string;
    toolbarButtons?: React.ReactNode[];
    toolbar?: React.ReactNode;
}

export function ContentHeader({title, toolbar, toolbarTitle, toolbarButtons, ...props}: FlexProps & ContentHeaderProps) {
    return <Flex alignItems={"center"} mb={"3"} flexWrap={"wrap"} {...props}>
        <Box><h2 style={{margin: "0"}}>{title}</h2></Box>
        <Spacer />
        <Box>
            {toolbar}
            {(toolbarTitle || toolbarButtons) && (
                <Flex gap={"1"} alignItems={"center"}>
                    {toolbarTitle && <chakra.label fontWeight={"bold"} mr={2}>{toolbarTitle}</chakra.label>}
                    <ButtonGroup isAttached={true}>{toolbarButtons}</ButtonGroup>
                </Flex>
            )}
        </Box>
    </Flex>
}
