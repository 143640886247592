import {FieldPath, UseFormSetError} from "react-hook-form";
import {DiagnosticError, Diagnostics, Loc} from "../models/error";
import {getFixedT} from "./getFixedT";

type PrefixOptions = {
    prefix?: Loc;
    stripPrefix?: Loc;
    suffix?: Loc;
    stripSuffix?: Loc;
}

type CustomErrors = {
    customErrors?: Record<string, string | ((diag: DiagnosticError) => string)>;
}

function arrayEquals(array1: any[], array2: any[]) {
    // if the other array is a falsy value, return
    if (!array1 || !array2) {
        return false;
    }

    // if the argument is the same array, we can be sure the contents are same as well
    if (array1 === array2) {
        return true;
    }

    // compare lengths - can save a lot of time
    if (array1.length != array2.length) {
        return false;
    }

    for (let i = 0, l = array1.length; i < l; i++) {
        // Check if we have nested arrays
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            // recurse into the nested arrays
            if (!arrayEquals(array1[i], array2[i])) {
                return false;
            }
        } else if (array1[i] !== array2[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}

export function localizeMessageByType(
    diag: DiagnosticError,
    customErrors: Record<string, string | ((diag: DiagnosticError) => string)> = {}
) {
    if (customErrors[diag.type]) {
        const msg = customErrors[diag.type];
        if (typeof msg === "string") {
            return msg;
        } else {
            return msg(diag);
        }
    }

    const t = getFixedT("forms");
    switch (diag.type) {
        case "entity_already_exists":
            return t("Entity already exists.");

        case "forbidden":
            return t("You are not allowed to perform this action.");

        case "greater_than":
            return t("Value must be greater than {{value}}.", {value: diag.ctx.gt});

        case "greater_than_equal":
            return t("Value must be greater than or equal to {{value}}.", {value: diag.ctx.ge});

        case "int_from_float":
        case "int_parsing":
            return t("Value must be an integer.");

        case "float_parsing":
        case "float_type":
        case "finite_number":
            return t("Value must be a number.");

        case "less_than":
            return t("Value must be less than {{value}}.", {value: diag.ctx.lt});

        case "less_than_equal":
            return t("Value must be less than or equal to {{value}}.", {value: diag.ctx.le});

        case "list_type":
        case "iterable_type":
        case "set_type":
        case "tuple_type":
            return t("Value must be a list.");

        case "enum":
        case "literal_error":
            return t("Value must be one of {{expected}}.", {values: diag.ctx.expected});

        case "mapping_type":
            return t("Value must be an object.");

        case "missing":
            return t("Value is required.");

        case "multiple_of":
            return t("Value must be a multiple of {{value}}.", {value: diag.ctx.multiple_of});

        case "string_pattern_mismatch":
        case "value_error":
        case "invalid_value":
        case "logic_error":
            return t("Value is invalid.");

        case "string_too_long":
        case "too_long":
        case "url_too_long":
            return t("Value is too long.");

        case "string_too_short":
        case "too_short":
            return t("Value is too short.");

        case "string_type":
        case "string_unicode":
            return t("Value must be a string.");

        case "url_parsing":
        case "url_scheme":
        case "url_syntax_violation":
        case "url_type":
            return t("Value must be a valid URL.");

        case "date_from_datetime_parsing":
            return t("Value must be a valid date.");

        default:
            return diag.msg;
    }
}

export function diagToForm<T extends object = object>(
    e: Diagnostics,
    setError: UseFormSetError<T>,
    options: PrefixOptions & CustomErrors = {}
) {
    for (const diag of e.detail) {
        let {loc} = diag;
        const {type} = diag;

        if (options.stripPrefix) {
            if (!arrayEquals(loc.slice(0, options.stripPrefix.length), options.stripPrefix)) {
                //console.log(`Skipping ${loc} because it does not have prefix ${options.stripPrefix}`);
                continue;
            }
            loc = loc.slice(options.stripPrefix.length);
        }

        if (options.prefix) {
            loc = [...options.prefix, ...loc];
        }

        if (options.stripSuffix) {
            if (!arrayEquals(loc.slice(loc.length - options.stripSuffix.length), options.stripSuffix)) {
                //console.log(`Skipping ${loc} because it does not have suffix ${options.stripSuffix}`);
                continue;
            }

            loc = loc.slice(0, loc.length - options.stripSuffix.length);
        }

        if (options.suffix) {
            loc = [...loc, ...options.suffix];
        }

        if (!loc.length) {
            loc = ["root"];
        }

        //console.log(`Setting error '${loc.join('.')}' as ${msg} (${type})`);

        console.log(diag);

        setError(
            loc.join(".") as FieldPath<T>,
            {
                type: type,
                message: localizeMessageByType(diag, options.customErrors),
            }
        );
    }
}
