import {createFileRoute, Outlet} from '@tanstack/react-router'
import {PageHeader} from "../../components/pageHeader";
import {PageContainer} from "../../components/pageContainer";
import {PageFooter} from "../../components/pageFooter";
import * as React from "react";
import {useEffect, useState} from "react";
import {getAPI, useAPI} from "../../api/api";
import {useCurrentUser} from "../../models/user";

export const Route = createFileRoute('/_site')({
    component: () => {
        const ldr = Route.useLoaderData() ?? {unreadCount: 0};
        const [count, setCount] = useState<number>(ldr.unreadCount ?? 0);

        const api = useAPI({flash: false});
        const cu = useCurrentUser();

        useEffect(() => {
            if (!cu) {
                return;
            }

            const interval = setInterval(async () => {
                const resp = await api.get<number>("/api/v2/messages/unread-count");
                setCount(resp.data);
            }, 10000);

            return () => {
                clearInterval(interval);
            }
        }, [setCount, cu]);

        return <>
            <PageHeader unreadCount={count} />
            <PageContainer>
                <Outlet />
            </PageContainer>
            <PageFooter />
        </>
    },
    loader: async ({context}) => {
        const api = getAPI(context);
        const cu = !!context.currentUser.currentUser;
        const unreadCount = cu ? (await api.get<number>("/api/v2/messages/unread-count")).data : 0;

        return {
            unreadCount: unreadCount
        };
    }
});
