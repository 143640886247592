import { createFileRoute } from '@tanstack/react-router'
import {getAPI} from "../../../../api/api";
import {XwgWithId} from "../../../../models/xwg";
import {TokenScope} from "../../../../models/tokenScope";
import {CwgAdditionalImages} from "../../../../components/cwg/cwgAdditionalImages";
import {ErrorComponent} from "../../../../components/error";

export const Route = createFileRoute('/_site/cwg/index/$xwgId/images')({
    params: {
        parse: (params: Record<string, string>) => (
            {
                xwgId: parseInt(params.xwgId)
            }
        ),
        stringify: (params: {xwgId: number}) => (
            {
                xwgId: params.xwgId.toString()
            }
        )
    },
    loader: async ({context, params}) => {
        const api = getAPI(context);
        const resp = await api.get<XwgWithId>(
            `/api/v2/xwg/${params.xwgId}`,
            {
                params: {
                    attribute: [
                        "id", "name", "version", "images", "publish_status", "tags",
                        "image.id", "image.user", "image.name", "image.url", "image.created", "image.type"
                    ],
                    imageType: [
                        ...context.currentUser.currentUser?.has(TokenScope.CWG_IMAGES) ? ["primary", "other"] : [],
                        "private"
                    ],
                }
            }
        );

        return {
            xwg: resp.data
        };
    },
    component: () => {
        const {xwg} = Route.useLoaderData({
            select: ({xwg}) => ({
                xwg: (xwg instanceof XwgWithId) ? xwg : new XwgWithId(xwg)
            })
        });

        return <CwgAdditionalImages xwg={xwg} />
    },
    errorComponent: ErrorComponent,
});
