import {createFileRoute, Link, Outlet} from '@tanstack/react-router'

import React from "react";
import {useTranslation} from "../../../../../utils/helpers";

import {zeroLevelBeforeLoad} from "../../../../../utils/collectionListing";
import {PublicProfile} from "../../../../../components/users/publicProfile";
import {loadUser} from "../route";
import {Button} from "@chakra-ui/react";
import {zeroLevelRouteLoader} from "../../../../../utils/collectionListing/zero";
import {useMatchData} from "../../../../../utils/findInMatchesData";
import {ROUTES} from "../../../../../constants";

export const Route = createFileRoute('/_site/users/_public_profile/notwant/$username')({
    component: CwgBrowseHeader,
    beforeLoad: async ({context, params}) => {
        return {
            ...(await zeroLevelBeforeLoad(context, {
                not_wanted: [params.username]
            })),
            isAdvanced: false
        }
    },
    loader: async ({context, params}) => ({
        ...await loadUser({context, params}),
        ...await zeroLevelRouteLoader({context})
    }),
});

function CwgBrowseHeader() {
    const {t} = useTranslation("profile");
    const {t: tb} = useTranslation("browse");
    const {user, tagCategories} = Route.useLoaderData();
    const isViewAll = useMatchData<boolean>("isViewAll") ?? false;

    return <PublicProfile
        title={t("{{user}} - doesn't want", {user: user?.name})}
        selected={ROUTES.USER_NOT_WANT}
        toolbarTitle={tb("Primary sorting:")}
        toolbarButtons={[
            ...tagCategories.map(category =>
                <Button
                    size={"sm"}
                    key={category.id}
                    as={Link}
                    from={ROUTES.USER_NOT_WANT}
                    to={"./$primarySorting"}
                    params={{
                        primarySorting: `by-${category.id}`
                    }}
                >
                    {category.name}
                </Button>
            ),
            <Button
                size={"sm"}
                as={Link}
                from={ROUTES.USER_NOT_WANT}
                to={"./all"}
                isActive={isViewAll}
                key={"all"}
            >
                {tb("All")}
            </Button>
        ]}
        user={user}
    >
        <Outlet />
    </PublicProfile>
}
