import {createFileRoute, Link, useRouter} from '@tanstack/react-router'
import {useTranslation} from "../../../../utils/helpers";
import {getAPI, useAPI} from "../../../../api/api";
import {ListResponse} from "../../../../models/response";
import {News} from "../../../../models/news";
import {
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    ButtonGroup,
    Table,
    Tbody,
    Td,
    Tfoot,
    Th,
    Thead,
    Tr, useToast
} from "@chakra-ui/react";
import React from "react";
import Pagination from "../../../../components/generic/pagination";
import {ProfileLink} from "../../../../components/users/profileLink";
import {EditButton, RemoveButton} from "../../../../components/generic/buttons";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../components/generic/breadcrumb";
import {Route as AdminIndexRoute} from "../index";
import {FormattedDateTime} from "../../../../components/generic/date";
import {Route as CreateRoute} from "./create";
import {Route as ModifyRoute} from "./modify.$id";

export const Route = createFileRoute('/_site/admin/news/')({
    component: () => {
        const {t} = useTranslation("news");
        const nav = Route.useNavigate();
        const search = Route.useSearch();
        const {news, count} = Route.useLoaderData();
        const api = useAPI();
        const router = useRouter();
        const toast = useToast();

        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("News administration")}</h2>

                <Button as={Link} to={CreateRoute.fullPath}>
                    {t("Add news")}
                </Button>

                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>{t("Administration", {ns: "admin"})}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink as={Link} to={Route.fullPath}>{t("News")}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>

            <Table>
                <Thead>
                    <Tr>
                        <Th>{t("Date")}</Th>
                        <Th>{t("Title")}</Th>
                        <Th>{t("Author")}</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {news.map((item) => <Tr key={item.id}>
                        <Td className={"td-shrink"}><FormattedDateTime date={item.date} /></Td>
                        <Td>{item.title}</Td>
                        <Td className={"td-shrink"}><ProfileLink user={item.author} /></Td>
                        <Td className={"td-shrink"}>
                            <ButtonGroup size={"sm"} isAttached>
                                <EditButton onClick={async () => (await nav({
                                    to: ModifyRoute.fullPath,
                                    params: {
                                        id: item.id
                                    }
                                }))} />
                                <RemoveButton
                                    confirm={t("Do you really want to remove this news?")}
                                    onClick={async () => {
                                        await api.delete(`/api/v2/news/${item.id}`);
                                        toast({
                                            title: t("News has been successfully removed."),
                                            status: "success",
                                            isClosable: true
                                        });

                                        await router.invalidate();
                                    }}
                                />
                            </ButtonGroup>
                        </Td>
                    </Tr>)}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Td colSpan={4}>
                            <Pagination
                                count={count}
                                offset={search.offset}
                                limit={search.limit}
                                onOffsetChange={async (newOffset) => {
                                    await nav({
                                        search: (prev) => {
                                            return {
                                                ...prev,
                                                offset: newOffset
                                            }
                                        }
                                    });
                                }}
                            />
                        </Td>
                    </Tr>
                </Tfoot>
            </Table>
        </>
    },
    validateSearch: (search: Record<string, string>) => {
        return {
            offset: parseInt(search.offset) || 0,
            limit: parseInt(search.limit) || 10
        }
    },
    loaderDeps: ({search}) => ({offset: search.offset, limit: search.limit}),
    loader: async ({context, deps}) => {
        const api = getAPI(context);
        const resp = await api.get<ListResponse<News>>("/api/v2/news", {
            params: {
                offset: deps.offset,
                limit: deps.limit,
                attributes: ["id", "date", "title", "author"]
            }
        });

        return {
            news: resp.data.items,
            count: resp.data.count
        }
    }
});
