import {chakra, Flex, FlexProps} from "@chakra-ui/react";
import {useTranslation} from "../../utils/helpers";
import MdiArrowLeftBold from "~icons/mdi/arrow-left-bold";
import React from "react";

export function SearchHint(props: FlexProps) {
    const {t: ts} = useTranslation("search");

    return <Flex
        {...props}
        alignItems={"center"}
        mt={"4em"}
    >
        <chakra.span
            color={"#c9b88a"}
            ml={"-1em"}
        >
            <MdiArrowLeftBold width={"6em"} height={"6em"}/>
        </chakra.span>
        <span>
            {ts("Fill in the search query in the left side of the screen. You must enter at least three letters from the xWG name.")}
        </span>
    </Flex>
}
