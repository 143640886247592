import { createFileRoute } from '@tanstack/react-router'
import { CollectionViewComponent } from './$primarySorting/$primaryCategoryName/$secondaryCategoryName'
import { thirdLevelIndexRoute } from '../../../../../utils/collectionListing/'
import {XwgFilter} from "../../../../../models/xwgSearchQuery";
import _ from "lodash";

export const Route = createFileRoute(
    '/_site/users/_public_profile/collection/$username/all',
)({
    ...thirdLevelIndexRoute({
        validateSearch: (search: Record<string, unknown>) => {
            return {
                filter: (search.filter ?? {}) as XwgFilter
            }
        },
        beforeLoad: async ({context: {filter, ...restOfContext}, search}) => {
            filter = _.mergeWith({}, filter, search.filter, (objValue, srcValue) => {
                if (Array.isArray(objValue) && Array.isArray(srcValue)) {
                    return [...objValue, ...srcValue];
                }
            });

            return {
                ...restOfContext,
                filter
            }
        },
        component: CollectionViewComponent,
        loader: async () => ({
            isViewAll: true,
        }),
    }),
})
