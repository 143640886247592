import {ErrorMessage, FormButtons} from "../generic/form";
import {FormControl, FormLabel, Input} from "@chakra-ui/react";
import {Button} from "../generic/buttons";
import React from "react";
import {useTranslation} from "../../utils/helpers";
import {useForm} from "react-hook-form";
import {useCurrentUser} from "../../models/user";

export type ChangePasswordFormData = {
    currentPassword: string;
    newPassword: string;
    repeatNewPassword: string;
};

export type ChangePasswordFormMethods = ReturnType<typeof useForm<ChangePasswordFormData>>

export type ChangePasswordFormProps = {
    requireOldPassword?: boolean;
    onSubmit?: (
        data: ChangePasswordFormData,
        methods: ChangePasswordFormMethods
    ) => void
};

export function ChangePasswordForm({requireOldPassword = true, onSubmit}: ChangePasswordFormProps) {
    const {t} = useTranslation("settings");
    const {t: tf} = useTranslation("forms");

    const methods = useForm<ChangePasswordFormData>();
    const {
        register,
        handleSubmit,
        watch,
        formState: {
            isSubmitting,
            errors
        }
    } = methods;

    const cu = useCurrentUser();

    return <form onSubmit={handleSubmit((data: ChangePasswordFormData) => {
        onSubmit?.(data, methods);
    })}>
        <ErrorMessage errors={errors} name={"root"}/>

        {requireOldPassword &&
            <FormControl>
                <FormLabel>{t("Old password")}</FormLabel>
                <Input
                    type="password"
                    disabled={isSubmitting}
                    {...register("currentPassword", {
                        required: tf("Required."),
                    })}
                    autoComplete={"current-password"}
                />
                <ErrorMessage errors={errors} name={"currentPassword"}/>
            </FormControl>
        }
        <FormControl>
            <FormLabel>{t("New password")}</FormLabel>
            <Input
                type="password"
                disabled={isSubmitting}
                {...register("newPassword", {
                    required: tf("Required."),
                    validate: (val: string) => {
                        if (val.length < 8) {
                            return t("Password must be at least {{minLength}} characters long.", {minLength: 8});
                        }
                    }
                })}
                autoComplete={"new-password"}
            />
            <p className={"description"}>{t("Password must be at least {{minLength}} characters long.", {minLength: 8})}</p>
            <ErrorMessage errors={errors} name={"newPassword"}/>
        </FormControl>
        <FormControl>
            <FormLabel>{t("New password again")}</FormLabel>
            <Input
                type="password"
                disabled={isSubmitting}
                {...register("repeatNewPassword", {
                    required: tf("Required."),
                    validate: (value) => value === watch("newPassword") || t("New passwords does not match.")
                })}
                autoComplete={"new-password"}
            />
            <ErrorMessage errors={errors} name={"repeatNewPassword"}/>
        </FormControl>
        <FormButtons>
            <input type={"hidden"} name={"username"} value={cu?.name} autoComplete={"username"} />
            <Button
                type="submit"
                disabled={isSubmitting}
            >
                {t("Change")}
            </Button>
        </FormButtons>
    </form>
}
