import { columnsAnatomy } from "../../components/generic/columns";
import {
    createMultiStyleConfigHelpers, cssVar
} from "@chakra-ui/react";
import {scrollbarStyle} from "./scrollbar";

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(columnsAnatomy.keys);
const $dividerBorderWidth = cssVar("dividerBorderWidth");

const baseStyle = definePartsStyle({
    container: {
        display: "flex",
        flexDirection: {
            base: "column",
            lg: "row",
        },
    },

    column: {
        flex: "1 1 50%",

        borderLeftStyle: {
            base: "none",
            lg: "solid"
        },
        borderRightStyle: {
            base: "none",
            lg: "solid"
        },
        borderTopStyle: {
            base: "solid",
            lg: "none"
        },
        borderBottomStyle: {
            base: "solid",
            lg: "none"
        },

        ml: {
            base: 0,
            lg: `calc(-1 * (${$dividerBorderWidth.reference} / 2))`,
        },

        mr: {
            base: 0,
            lg: `calc(-1 * (${$dividerBorderWidth.reference} / 2))`,
        },

        mt: {
            base: `calc(-1 * (${$dividerBorderWidth.reference} / 2))`,
            lg: 0,
        },

        mb: {
            base: `calc(-1 * (${$dividerBorderWidth.reference} / 2))`,
            lg: 0
        },

        borderWidth: $dividerBorderWidth.reference,
        borderColor: "divider.500",
        borderRadius: "xl",

        pl: {
            base: 0,
            lg: 4
        },
        pr: {
            base: 0,
            lg: 4
        },
        pt: {
            base: 4,
            lg: 0
        },
        pb: {
            base: 4,
            lg: 0
        },

        "&:first-of-type": {
            borderLeft: "none",
            borderLeftRadius: {
                lg: 0,
                base: "xl",
            },
            ml: 0,
            mt: 0,
            pl: 0
        },
        "&:last-of-type": {
            borderRight: "none",
            borderRightRadius: {
                lg: 0,
                base: "xl",
            },
            mr: 0,
            mb: 0,
            pr: 0
        },
    }
});

const fixedVariant = definePartsStyle({
    container: {
        maxH: "calc(95vh)",
        overflow: "hidden",
    },
    column: {
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        ...scrollbarStyle,
    }
});

export const columnsTheme = defineMultiStyleConfig({
    baseStyle,
    variants: {
        fixed: fixedVariant
    }
});
