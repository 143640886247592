import {createFileRoute} from '@tanstack/react-router'
import {getAPI} from "../../../../api/api";
import {searchXwg} from "../../../../api/searchXwg";
import {SearchFilter, XwgWithId} from "../../../../models/xwg";
import {XwgFilter} from "../../../../models/xwgSearchQuery";
import _ from "lodash";
import {SearchPagination} from "../../../../models";
import React from "react";
import {CwgSearchResult} from "../../../../components/cwg/cwgSearchResult";

export const Route = createFileRoute('/_site/cwg/search/$query')({
    component: () => {
        const {searchResult} = Route.useLoaderData({select: ({searchResult}) => {
            if (!searchResult) {
                return {};
            }

            const {items, count} = searchResult;
            return {
                searchResult: {
                    items: items.map(xwg => (!(xwg instanceof XwgWithId)) ? new XwgWithId(xwg) : xwg),
                    count
                }
            }
        }});
        const {offset, limit} = Route.useSearch();
        const nav = Route.useNavigate();

        return <CwgSearchResult
            search={searchResult}
            offset={offset}
            limit={limit}
            onOffsetChange={async (offset) => {
                await nav({
                    search: (search) => {
                        return {
                            ...search,
                            offset: offset
                        }
                    }
                })
            }}
        />
    },
    params: {
        parse: (params: Record<string, string>) => {
            return {
                query: params.query
            }
        },
        stringify: (params) => {
            return {
                query: params.query
            }
        }
    },
    validateSearch: (search: Record<string, unknown>): SearchPagination & SearchFilter => {
        return {
            offset: parseInt(search.offset?.toString() ?? "0"),
            limit: parseInt(search.limit?.toString() ?? "100"),
            filter: (search.filter ?? {}) as XwgFilter,
        }
    },
    loaderDeps: ({search}) => search,
    loader: async ({context, params: {query}, deps: {offset, limit, filter}}) => {
        const api = getAPI(context);

        const fullFilter = _.assign({}, filter, {names: [query]});

        const searchResult = await searchXwg({
            api,
            filter: fullFilter,
            offset: offset,
            limit: limit
        });

        return {
            searchResult
        }
    },
    gcTime: 0,
    pendingMs: 600_000
});
