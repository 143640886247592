import React, {forwardRef} from "react";
import {ErrorMessage as HookErrorMessage} from "@hookform/error-message";
import {Controller as RHKController, ControllerProps, FieldPath, FieldValues, UseFormStateReturn, ControllerFieldState, ControllerRenderProps} from "react-hook-form";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    ButtonGroup,
    ButtonGroupProps,
    chakra,
    HTMLChakraProps,
    Switch as ChakraSwitch,
    SwitchProps
} from "@chakra-ui/react";
import {cx} from "@emotion/css";

const FieldSet = function({legend, children, ...props}: HTMLChakraProps<"fieldset"> & {legend?: string}) {
    return <chakra.fieldset
        {...props}
        border={"none"}
        padding={"0 0 1em"}
        {... legend ? {
            borderTop: "solid",
            borderTopWidth: "var(--dividerBorderWidth)",
            borderColor: "divider.500",
            pt: ".5em"
        } : {}}
    >
        {legend && <chakra.legend
            fontWeight={"bold"}
            fontSize={"1.1em"}
            paddingLeft={".5em"}
            paddingRight={".5em"}
            marginLeft={"1em"}
        >
            {legend}
        </chakra.legend>}
        {children}
    </chakra.fieldset>;
}

const ErrorMessage = ({className, errors, name, asAlert = false, ...props}: {errors: any, name: string, asAlert?: boolean} & HTMLChakraProps<"span">) => {
    return <HookErrorMessage
        errors={errors}
        name={name}
        render={(args) => {
            const msg = args.message && <chakra.span
                {...props}
                className={cx("ErrorMessage", className)}
                color={asAlert ? undefined : "textDanger"}
                display={"inline-block"}
                mt={".5em"}
                mb={".2em"}
            >
                {args.message}
            </chakra.span>;

            if (asAlert) {
                return <Alert status={"error"}>
                    <AlertIcon />
                    <AlertDescription>{msg}</AlertDescription>
                </Alert>
            }
            return msg;
        }}
    />;
};

const FormButtons = ({children, ...props}: Omit<ButtonGroupProps, "isAttached" | "display" | "justifyContent">) => <ButtonGroup
    isAttached
    display={"flex"}
    justifyContent={"center"}
    mt={4}
    {...props}
>
    {children}
</ButtonGroup>;

const Switch = forwardRef(({onChange, value, ...props}: Omit<SwitchProps, "value" | "isChecked" | "onChange"> & {
    onChange: (value: boolean) => void,
    value: boolean,
}, forwardedRef) => {
    return <ChakraSwitch
        ref={forwardedRef}
        isChecked={value}
        onChange={(e) => onChange(e.currentTarget.checked)}
        {...props}
    />;
});

export type TransformProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    toInputValue?: (value: TFieldValues[TName]) => any,
    fromInputValue?: (value: any) => TFieldValues[TName],
}

function Controller<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    {toInputValue, fromInputValue, ...props}: (
        TransformProps<TFieldValues, TName> &
        Omit<ControllerProps<TFieldValues, TName>, "render"> &
        {
            render: (props: {
                field: Omit<ControllerRenderProps<TFieldValues, TName>, "value" | "onChange"> & {
                    value: TransformProps<TFieldValues, TName>["toInputValue"] extends undefined ? ControllerRenderProps<TFieldValues, TName>["value"] : ReturnType<Exclude<TransformProps<TFieldValues, TName>["toInputValue"], undefined>>,
                    onChange: TransformProps<TFieldValues, TName>["fromInputValue"] extends undefined ? ControllerRenderProps<TFieldValues, TName>["onChange"] : (v: ReturnType<Exclude<TransformProps<TFieldValues, TName>["fromInputValue"], undefined>>) => void,
                },
                fieldState: ControllerFieldState;
                formState: UseFormStateReturn<TFieldValues>;
            }) => React.ReactElement
        }
    )
) {
    const {render, ...rest} = props;
    return <RHKController<TFieldValues, TName>
        {...rest}
        render={({field: {onChange, onBlur, value, ...fieldProps}, ...restOfRender}) => {
            const finalValue = toInputValue ? toInputValue(value) : value;
            return render({
                ...restOfRender,
                field: {
                    onChange: (value) => {
                        const finalValue = fromInputValue ? fromInputValue(value) : value;
                        onChange(finalValue);
                    },
                    onBlur,
                    value: finalValue,
                    ...fieldProps
                }
            });
        }}
    />;
}

export {
    FieldSet,
    FormButtons,
    ErrorMessage,
    Controller,
    Switch
};
