import { createFileRoute } from '@tanstack/react-router'
import {thirdLevelIndexRoute} from "../../../../../../../utils/collectionListing";
import {CollectionViewComponent} from "../$secondaryCategoryName";

export const Route = createFileRoute(
  '/_site/cwg-collection/browse/$primarySorting/$primaryCategoryName/all/',
)({
    ...thirdLevelIndexRoute({
        validateSearch: (search: Record<string, unknown>) => {
            const views = ["catalogue", "images", "table"];

            return {
                mode: (views.find((v) => v === search.mode) ?? views[0])
            }
        },
        component: CollectionViewComponent,
        additionalDisplayOptions: ({search: {mode}}) => {
            switch (mode) {
                case "images":
                case "table":
                    return {
                        "attributes": ["collection_info"]
                    }

                default:
                    return {};
            }
        }
    })
});
