import {Controller, FieldErrors, FormProvider, useForm, UseFormSetError, useWatch} from "react-hook-form";
import {XwgEntry} from "../../models/xwg";
import React, {HTMLProps, useEffect, useMemo, useState} from "react";
import {ErrorMessage, FormButtons} from "../generic/form";
import {useTranslation} from "../../utils/helpers";
import {useCurrentUser} from "../../models/user";
import {TokenScope} from "../../models/tokenScope";
import {LightSelect} from "../generic/select";
import CwgImageUpload from "./imageUpload";
import {Button} from "../generic/buttons";
import {ExtendedTagInCategory, SimpleTag, TagCategory} from "../../models/tag";
import {
    FormControl,
    FormHelperText,
    FormLabel,
    Input, NumberDecrementStepper, NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper
} from "@chakra-ui/react";
import {Trans} from "react-i18next";

type CwgFormProps = {
    xwg?: XwgEntry,
    disabled?: boolean,
    onSubmit: (data: XwgEntry, setError: UseFormSetError<XwgEntry>) => void,
    onSearch?: (name: string | undefined) => void,
    showImages?: boolean,
    tagCategories: TagCategory[],
    errors?: FieldErrors<XwgEntry>,
} & Omit<HTMLProps<HTMLFormElement>, "onSubmit">;

const CwgForm = (
    {
        xwg,
        disabled: initialDisabled,
        onSubmit,
        onSearch,
        showImages = true,
        tagCategories,
        ...props
    }: CwgFormProps
) => {
    const {t} = useTranslation("cwg_ops");
    const {t: tIndex} = useTranslation("cwg_index");

    const [disabled, setDisabled] = useState<boolean>(initialDisabled ?? false);
    const currentUser = useCurrentUser();

    useEffect(() => {
        if (initialDisabled !== undefined) {
            setDisabled(initialDisabled);
        }
    }, [initialDisabled]);

    const methods = useForm<XwgEntry>({
        criteriaMode: "all",
        disabled: disabled ?? false,
        defaultValues: xwg ?? {
            version: "1"
        },
    });
    const {
        control,
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setError,
        reset,
    } = methods;

    const watchName = watch("name");
    useEffect(() => {
        const searchTrigger = window.setTimeout(() => {
            if (onSearch) {
                onSearch(watchName);
            }
        }, 500);

        return () => {
            window.clearTimeout(searchTrigger);
        }
    }, [watchName]);

    // Sort tags in xWG in the same order as there are categories, to properly populate form.
    useEffect(() => {
        if (xwg) {
            const currentTags = xwg.tags as SimpleTag[];
            xwg.tags = [
                ...tagCategories.map(category => currentTags.find(tag => tag.category === category.id)) as SimpleTag[],
            ];
            reset(xwg);
        }
    }, [xwg, tagCategories]);

    const categories = useWatch({
        control: control,
        name: tagCategories.map((_, idx) => `tags.${idx}`) as `tags.${number}`[],
    });

    const [overlay, setOverlay] = useState<boolean>(true);

    useEffect(() => {
        const setTags: ExtendedTagInCategory[] = categories.filter(t => typeof(t) === "object").map((tag: SimpleTag) => {
            return tagCategories.find(category => category.id === tag.category)?.tags.find(t => t.id === tag.name);
        }).filter(t => t !== undefined) ?? [];

        setOverlay((
            setTags?.some(t => t.overlay) ||
            setTags?.every(t => t.overlay === null)
        ) ?? false);
    }, [categories]);

    const memoizedImageUpload = useMemo(() => <CwgImageUpload overlay={overlay} />, [errors, overlay]);

    const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | undefined>(undefined);
    useEffect(() => {
        setMenuPortalTarget(document.body);
    }, []);

    return (
        <form onSubmit={handleSubmit((data) => {
            if (!currentUser || !currentUser.has(TokenScope.ADMIN_CATALOGUE)) {
                delete data.cat_no;
            }

            onSubmit(data, setError);
        })} {...props}>
            <FormProvider {...methods}>
                <main>
                    <ErrorMessage errors={errors} name={"root"} asAlert />

                    <FormControl>
                        <FormLabel>{tIndex("Name")}:</FormLabel>
                        <Input
                            {...register("name", {
                                required: t("Field is required.")
                            })}
                            autoFocus
                        />
                        <ErrorMessage errors={errors} name={"name"} />
                        <FormHelperText>
                            <Trans ns={"cwg_ops"}>Enter what is written <strong>AROUND</strong> xWG.</Trans>
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel>{tIndex("Version")}:</FormLabel>
                        <Controller
                            name={"version"}
                            render={({field}) => <NumberInput w={"6em"} {...field} min={1}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>}
                            rules={{
                                min: {
                                    value: 1,
                                    message: t("Version must be positive number.")
                                },
                                required: t("Field is required."),
                            }}
                        />
                        <ErrorMessage errors={errors} name={"version"} />
                    </FormControl>

                    {currentUser && currentUser.has(TokenScope.ADMIN_CATALOGUE) && <FormControl>
                        <FormLabel>{tIndex("Catalogue number")}:</FormLabel>
                        <Input
                            {...register("cat_no")}
                        />
                        <ErrorMessage errors={errors} name={"cat_no"} />
                    </FormControl>}

                    <FormControl>
                        <FormLabel>{tIndex("Note")}:</FormLabel>
                        <Input
                            {...register("note")}
                        />
                        <ErrorMessage errors={errors} name={"note"} />
                        <FormHelperText>{t("Write by words, what image is burned on the xWG.")}</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel>{tIndex("Link")}:</FormLabel>
                        <Input
                            {...register("link")}
                        />
                        <ErrorMessage errors={errors} name={"link"} />
                        <FormHelperText>{t("Provide a link to the given user's profile or geocache/event listing page.")}</FormHelperText>
                    </FormControl>

                    {tagCategories.map((category, index) => {
                        return <FormControl key={category.id}>
                            <FormLabel>{category.name}:</FormLabel>
                            <Controller
                                control={control}
                                name={`tags.${index}`}
                                rules={{
                                    required: t("Field is required."),
                                }}
                                render={({field: {onChange, value, name}}) => {
                                    return <LightSelect
                                        instanceId={`category-${category.id}`}
                                        name={name}
                                        onChange={(value) => {
                                            onChange({
                                                category: category.id,
                                                name: value
                                            });
                                        }}
                                        value={(value as SimpleTag)?.name}
                                        options={category.tags.map(tag => ({value: tag.id, label: tag.name}))}
                                        placeholder={category.name}
                                        menuPortalTarget={menuPortalTarget}
                                    />
                                }}
                            />
                            <ErrorMessage errors={errors} name={`tags.${index}`}/>
                        </FormControl>;
                    })}

                    {showImages &&
                        <FormControl>
                            <FormLabel>{t("Photo")}:</FormLabel>
                            <FormHelperText>{t("Please, provide photo of xWG that you want to enter. Try to create perpendicular photo with good lighting conditions (daylight or light which does not create shadow on the xWG), as the photo is then used for displaying on the website.")}</FormHelperText>
                            <FormHelperText>{t("If xWG has nonstandard backside, please, upload the backside too.")}</FormHelperText>
                            {memoizedImageUpload}
                        </FormControl>
                    }
                </main>

                <FormButtons>
                    <Button type={"submit"}>
                        {!xwg ? t("Insert") : t("Modify")}
                    </Button>
                </FormButtons>
            </FormProvider>
        </form>
    );
}

export default CwgForm;
