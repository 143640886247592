import React, {forwardRef} from "react";

import EditIcon from "~icons/emojione-v1/lower-left-pencil";
import TrashIcon from "~icons/fxemoji/wastebasket";
import {useTranslation} from "../../utils/helpers";
import {useAlert} from "./alert";
import {Button as ChakraButton, ButtonProps as ChakraButtonProps, ComponentWithAs, Tooltip} from "@chakra-ui/react";

export type ButtonProps = {
    icon?: React.ComponentType | string;
    confirm?: string;
    title?: string;
} & Omit<ChakraButtonProps, "title">;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function({
        icon, children, onClick, confirm, title, ...props
}: ButtonProps, forwardedRef){
    const {showConfirm} = useAlert();
    const {t} = useTranslation("cwg_ops");

    (props as {onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown}).onClick = async (e) => {
        if (confirm) {
            if (await showConfirm(title ?? t("Are you sure?"), confirm) === "yes" && onClick) {
                onClick?.(e);
            }
        } else {
            if (onClick) {
                onClick?.(e);
            }
        }
    };

    if (icon && !props.dangerouslySetInnerHTML) {
        const Icon = (typeof icon !== "string") ? icon : null;
        return <Tooltip label={children}>
            <ChakraButton
                {...props}
                ref={forwardedRef}
            >
                {Icon
                    ? <Icon />
                    : <img src={icon as string} style={{width: "1.2em"}} />
                }
            </ChakraButton>
        </Tooltip>
    } else {
        if (props.dangerouslySetInnerHTML) {
            return <ChakraButton
                {...props}
                ref={forwardedRef}
            />;
        } else {
            return <ChakraButton
                {...props}
                ref={forwardedRef}
            >
                {children}
            </ChakraButton>;
        }
    }
}) as ComponentWithAs<"button", ButtonProps>;

const EditButton = ((props: Omit<ButtonProps, "children" | "icon">) => {
    const {t} = useTranslation("cwg_ops");
    return <Button
        variant={"secondary"}
        icon={EditIcon}
        {...props}
    >
        {t("Modify")}
    </Button>
}) as ComponentWithAs<"button", Omit<ButtonProps, "children" | "icon">>;

const RemoveButton = (({...props}: Omit<ButtonProps, "children" | "icon" | "title">) => {
    const {t} = useTranslation("cwg_ops");
    return <Button
        variant={"secondary"}
        icon={TrashIcon}
        {...props}
    >
        {t("Remove")}
    </Button>
}) as ComponentWithAs<"button", Omit<ButtonProps, "children" | "icon" | "title">>;

export {
    Button,
    EditButton,
    RemoveButton
};
