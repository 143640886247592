import {Alert, AlertIcon, Box, BoxProps, chakra, HStack, ListItem, UnorderedList} from "@chakra-ui/react";
import {Button} from "../generic/buttons";
import ScrollDownIcon from "~icons/mdi/chevron-down";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ExistingMessage, MessageFormat, MessageThreadDetail} from "../../models/messages";
import {useTranslation} from "../../utils/helpers";
import {FormattedDateTime} from "../generic/date";
import {User} from "../../models/user";
import {ProfileLink} from "../users/profileLink";
import {useAPI} from "../../api/api";
import Markdown from "react-markdown";
import remarkGemoji from "remark-gemoji";

function UserMessageHeader({user, ...props}: {user: User} & BoxProps) {
    return <chakra.h4 {...props}><ProfileLink user={user} /></chakra.h4>
}

function SystemMessageHeader(props: BoxProps) {
    const {t} = useTranslation("messages");
    return <chakra.h4 {...props}>{t("System message")}</chakra.h4>;
}

function SenderByType({message, ...props}: {message: ExistingMessage} & BoxProps) {
    if (message.user) {
        return <UserMessageHeader user={message.user} {...props} />
    } else {
        return <SystemMessageHeader {...props} />
    }
}

function MessageHeader({message}: {message: ExistingMessage}) {
    return <HStack justifyContent={"space-between"} mb={2} mx={-1} px={1} pb={1}>
        <SenderByType message={message} m={0} />
        <FormattedDateTime date={message.date} />
    </HStack>
}

function MessageBody({message}: {message: ExistingMessage}) {
    if (message.format === MessageFormat.HTML) {
        return <p dangerouslySetInnerHTML={{__html: message.message}}/>
    } else {
        return <Markdown remarkPlugins={[remarkGemoji]}>{message.message}</Markdown>
    }
}

export function MessagesView({thread, ...props}: { thread: MessageThreadDetail} & BoxProps) {
    const {t} = useTranslation("messages");
    const [messages, setMessages] = useState<ExistingMessage[]>(thread.messages);
    const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(thread.messages.length < thread.count);
    const [scrollLock, setScrollLock] = useState<number | null>(null);

    const messageList = useRef<HTMLUListElement>(null);
    const scrollToBottomToggle = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        setHasMoreMessages(thread.messages.length < thread.count);
        setMessages(thread.messages);
        setScrollLock(null);
    }, [thread, setMessages, setHasMoreMessages, setScrollLock]);

    useEffect(() => {
        if (messageList.current) {
            messageList.current.scrollTo({
                top: messageList.current.scrollHeight
            });
        }
    }, [messageList.current, thread]);

    const performScroll = useCallback(() => {
        if (messageList.current) {
            if (!scrollLock) {
                messageList.current.scrollTo({
                    top: messageList.current.scrollHeight,
                    behavior: "smooth"
                });
            } else {
                messageList.current.scrollTo({
                    top: messageList.current.scrollHeight - scrollLock
                });
            }
        }
    }, [messageList.current, scrollLock]);

    useEffect(() => {
        performScroll();
    }, [messageList.current, scrollLock]);

    const scrollToBottom = useCallback(() => {
        if (scrollLock === null) {
            performScroll();
        } else {
            setScrollLock(null);
        }
    }, [scrollLock, setScrollLock, performScroll]);

    // Show / hide the scrollToBottom button based on scroll position.
    useEffect(() => {
        if (!messageList.current) {
            if (scrollToBottomToggle.current) {
                scrollToBottomToggle.current.style.display = "none";
            }
            return;
        }

        const handler = () => {
            if (messageList.current) {
                if (messageList.current.scrollTop + messageList.current.clientHeight >= messageList.current.scrollHeight - 10) {
                    if (scrollToBottomToggle.current) {
                        scrollToBottomToggle.current.style.display = "none";
                    }
                } else {
                    if (scrollToBottomToggle.current) {
                        scrollToBottomToggle.current.style.display = "block";
                    }
                }
            }
        };

        handler();
        messageList.current.addEventListener("scroll", handler);

        return () => {
            messageList.current?.removeEventListener("scroll", handler);
        }
    }, [messageList.current, scrollToBottomToggle.current]);

    const api = useAPI();

    const loadOlderMessages = useCallback(async () => {
        const additionalThread = await api.get<MessageThreadDetail>("/api/v2/messages/{threadId}/", {
            pathParams: {
                threadId: thread.id
            },
            params: {
                offset: messages.length
            }
        });

        // Ensure that the topmost message will stay in view after loading more messages.
        if (messageList.current) {
            setScrollLock(messageList.current?.scrollHeight - messageList.current?.scrollTop);
        }

        setHasMoreMessages(messages.length + additionalThread.data.messages.length < additionalThread.data.count);
        setMessages(messages.concat(additionalThread.data.messages));
    }, [
        messages,
        api,
        thread,
        setMessages,
        setHasMoreMessages,
        setScrollLock,
        messageList.current
    ]);

    const renderedMessages = useMemo(() => <>{messages.toReversed().map((message) => (
        <ListItem key={message.id} p={2}>
            <MessageHeader message={message} />
            {message.subject ? <h4>{message.subject}</h4> : null}
            <MessageBody message={message} />
        </ListItem>
    ))}</>, [messages]);

    return <Box display={"flex"} flexDirection={"column"} alignItems={"stretch"} flexGrow={"1"} position={"relative"} overflow={"hidden"} {...props}>
        <UnorderedList variant={"messages"} overflow={"auto"} ref={messageList} height={"100%"}>
            {hasMoreMessages && <ListItem textAlign={"center"}>
                <Button onClick={loadOlderMessages} variant={"ghost"}>{t("Load older messages")}</Button>
            </ListItem>}
            {messages.length === 0 && <ListItem>
                <Alert status={"info"}>
                    <AlertIcon />
                    {t("No messages in this thread.")}
                </Alert>
            </ListItem>}
            {renderedMessages}
        </UnorderedList>
        <Button
            variant={"ghost"}
            onClick={scrollToBottom}
            ref={scrollToBottomToggle}
            position={"absolute"}
            right={4}
            bottom={4}
            icon={ScrollDownIcon}
            zIndex={2}
            fontSize={"3em"}
            p={1}
        >
            {t("Scroll to bottom")}
        </Button>
    </Box>
}
