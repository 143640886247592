import {User} from "./user";
import {XwgWithId} from "./xwg";

export enum MessageFormat {
    HTML = "html",
    MARKDOWN = "markdown"
}

export type Message = {
    subject: string | null;
    message: string;
    format: MessageFormat;
};

export type ExistingMessage = {
    id: number;
    user?: User;
    date: string;
    read: boolean;
} & Message;

export type MessageThread = {
    id: string;
    unread: number;
    last_message: ExistingMessage;
    send_allowed: boolean;
    user?: User;
    xwg?: XwgWithId;
};

export type MessageThreadWithUser = MessageThread & { users: User[] };
export type MessageThreadWithXwg = MessageThread & { xwg: XwgWithId };

export type MessageThreadDetail = MessageThread & {
    messages: ExistingMessage[];
    count: number;
}
