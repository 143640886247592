import {createFileRoute, useNavigate} from '@tanstack/react-router'
import React from "react";
import {AdvancedSearchForm} from "../../../../../components/cwg/advancedSearchForm";
import {Route as LayoutRoute} from "./route";

export const Route = createFileRoute('/_site/cwg/browse/advanced/')({
    component: AdvancedBrowse,
})

function AdvancedBrowse() {
    const {tagCategories, userTags} = LayoutRoute.useLoaderData();
    const nav = useNavigate();

    return (
        <AdvancedSearchForm
            tagCategories={tagCategories}
            userTags={userTags}
            onFilter={async (filter) => {
                await nav({
                    to: "./$filter",
                    params: {
                        filter: filter
                    },
                });
            }} />
    );
}
