import React from "react";
import {APIApplication, ExistingAPIApplication} from "../../models/api";
import {useFieldArray, useForm, UseFormSetError} from "react-hook-form";
import {FormControl, FormLabel, Input, InputGroup, InputRightElement, Textarea} from "@chakra-ui/react";
import {useTranslation} from "../../utils/helpers";
import {Button, RemoveButton} from "../generic/buttons";
import {ErrorMessage, FormButtons} from "../generic/form";
import AddIcon from "~icons/mdi/plus-circle";

export type ApplicationFormProps = {
    onSubmit: (application: APIApplication, setError: UseFormSetError<APIApplication>) => void;
    application?: APIApplication;
}

export function ApplicationForm({
    onSubmit,
    application,
}: ApplicationFormProps) {
    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<APIApplication>({
        defaultValues: application,
    });
    const {t} = useTranslation("api");
    const { fields, append, remove } = useFieldArray({
        control,
        // @ts-ignore
        name: "redirect_uri",
    });

    return <form onSubmit={handleSubmit((data) => onSubmit(data, setError))}>
        <FormControl>
            <FormLabel>{t("Name")}</FormLabel>
            <Input type={"text"} {...register("name")} />
            <ErrorMessage errors={errors} name={"name"} />
        </FormControl>
        <FormControl>
            <FormLabel>{t("Description")}</FormLabel>
            <Textarea {...register("description")} />
            <ErrorMessage errors={errors} name={"description"} />
        </FormControl>
        <FormControl>
            <FormLabel>{t("Redirect URI")}</FormLabel>
            {fields.map((field, index) => <InputGroup mb={2} key={field.id}>
                <Input {...register(`redirect_uri.${index}`)} />
                <InputRightElement>
                    <RemoveButton variant={"ghost"} onClick={() => remove(index)} mr={2} />
                </InputRightElement>
                <ErrorMessage errors={errors} name={`redirect_uri.${index}`} />
            </InputGroup>)}
            <Button variant={"secondary"} onClick={() => append("")} icon={AddIcon}>{t("Add row")}</Button>
        </FormControl>
        <FormButtons>
            <Button type={"submit"}>{t((application as ExistingAPIApplication)?.id ? "Save" : "Register")}</Button>
        </FormButtons>
    </form>
}
