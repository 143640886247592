import {keyframes} from "@emotion/react";
import React from "react";
import {Box, BoxProps} from "@chakra-ui/react";
import {cx} from "@emotion/css";

const loader1 = keyframes`
0%    { clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% ); }
12.5% { clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% ); }
25%   { clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% ); }
50%   { clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% ); }
62.5% { clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% ); }
75%   { clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% ); }
100%  { clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% ); }
`;

const loader2 = keyframes`
0%     { transform:scaleY(1)  rotate(0deg); }
49.99% { transform:scaleY(1)  rotate(135deg); }
50%    { transform:scaleY(-1) rotate(0deg); }
100%   { transform:scaleY(-1) rotate(-135deg); }
`;

export const Loader = ({className, ...props}: BoxProps) => <Box
    height={"100%"}
    aspectRatio={"1/1"}
    borderRadius={"50%"}
    border={"solid .25em"}
    borderColor={"divider.500"}
    animation={`${loader1} 0.8s infinite linear alternate, ${loader2} 1.6s infinite linear`}
    className={cx(className, "Loader")}
    {...props}
/>

export default Loader;
