import React from "react";
import {useTranslation} from "../utils/helpers";

import {Box, BoxProps, chakra, Text} from "@chakra-ui/react";
import {Link, useRouter} from "@tanstack/react-router";

export const PageFooter = function(props: BoxProps) {
    const {t} = useTranslation("layout");
    const {options: { context: {applicationData}}} = useRouter();

    return <chakra.footer
        {...props}
        w={"100%"}
        maxW={"var(--chakra-breakpoints-lg)"}
        ml={"auto"}
        mr={"auto"}
        mt={2}
        mb={8}
        px={4}
        py={2}
        display={"flex"}
        gap={"2em"}
        fontSize={"sm"}
        fontWeight={600}
    >
        <Box flex={"1 1 60%"}>
            <p dangerouslySetInnerHTML={{__html: t("CWG Collection is website for catalogization of your <abbr title=\"(Czech) Wood Geocoin\">xWG</abbr> collection.")}}></p>
            <p>{t("You can note various information about your collection (year, number of pieces, comment) and also any other information in the form of tags.")}</p>
            <p>{t("You can offer xWG for exchange and easily have overview of your offered pieces such as offers from other users.")}</p>
            <p>{t("Because of available API, it is possible to access the collection from another applications.")}</p>
        </Box>
        <Box flex={"1 1 40%"}>
            <p>
                CWG Collection &copy; Niximor 2011-{new Date().getFullYear()}<br/>
                <abbr title="Czech Wood Geocoin">CWG</abbr> &copy; <a href="http://www.cwg-sigitem.cz/" target="_blank">JPAgeo
                and Poník &amp; team</a> 2005-{new Date().getFullYear()}<br/>
            </p>

            <ul style={{"listStyleType": "none"}}>
                <li><Link to={"/report"}>{t("Report issue", {ns: "problems"})}</Link></li>
                <li><Link to="/cwg-main/api">{t("API")}</Link></li>
            </ul>

            <Text as={"p"} opacity={.4} mt={1}>
                {t("UI Version {{version}}", {version: applicationData.version})}
            </Text>
            {applicationData.apiVersion && <Text as={"p"} opacity={.4}>{t("API Version {{version}}", {version: applicationData.apiVersion})}</Text>}
        </Box>
    </chakra.footer>;
}
