import React, {useState} from "react";
import {createFileRoute, Link, useNavigate} from '@tanstack/react-router'
import {combineTitle} from "../../../utils/combineTitle";
import {getFixedT} from "../../../utils/getFixedT";
import {getAPI, useAPI} from "../../../api/api";
import {ExistingAPIApplication} from "../../../models/api";
import {useTranslation} from "../../../utils/helpers";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    ButtonGroup,
    Flex,
    Spacer,
    useToast,
    Button as ChakraButton, Code,
    Table, Thead, Tbody, Tr, Th, Td
} from "@chakra-ui/react";
import {Button, EditButton, RemoveButton} from "../../../components/generic/buttons";
import KeyIcon from "~icons/flat-color-icons/lock";
import {useAlert} from "../../../components/generic/alert";
import {Route as ApplicationEditRoute} from "./$clientId";
import {Route as RegisterApplicationRoute} from "./register";
import {CopyToClipboard} from "../../../components/generic/copyToClipboard";

export const Route = createFileRoute('/_site/applications/')({
    meta: ({matches, match}) => {
        const t = getFixedT("layout", match.context.request?.i18n);
        return combineTitle(matches, t("Applications"))
    },
    component: ApplicationsComponent,
    loader: async ({context}) => {
        const api = getAPI(context);
        const applications = await api.get<ExistingAPIApplication[]>("/api/v2/oauth/application/");
        return {
            applications: applications.data
        }
    }
});


function ApplicationsComponent() {
    const {t} = useTranslation("api");
    const {applications: initialApplications} = Route.useLoaderData() as {applications: ExistingAPIApplication[]};
    const {showConfirm} = useAlert();
    const nav = useNavigate();
    const api = useAPI();
    const toast = useToast();
    const [applications, setApplications] = useState<ExistingAPIApplication[]>(initialApplications);

    return <>
        <Flex alignItems={"center"} mb={4}>
            <h2 style={{margin: "0"}}>{t("Applications")}</h2>
            <Spacer />
            <ChakraButton as={Link} to={RegisterApplicationRoute.fullPath}>{t("Register application")}</ChakraButton>
        </Flex>
        {!applications.length && <Alert status={"info"}>
            <AlertIcon />
            <Box>
                <AlertTitle>{t("There are no applications")}</AlertTitle>
                <AlertDescription>{t("You have not registered any applications yet. To be able to access API, you need to have registered application which you will use to obtain access token.")}</AlertDescription>
            </Box>
        </Alert>}
        {applications.length > 0 && <Table>
            <Thead>
                <Tr>
                    <Th className={"td-shrink"}>{t("Client ID")}</Th>
                    <Th>{t("Name")}</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            <Tbody>
                {applications.map(application => <Tr key={application.id}>
                    <Td className={"td-shrink"}>{application.id}</Td>
                    <Td>{application.name}</Td>
                    <Td className={"text-right"}>
                        <ButtonGroup size={"sm"} isAttached>
                            <EditButton onClick={() => {
                                nav({
                                    to: ApplicationEditRoute.fullPath,
                                    params: {
                                        clientId: application.id
                                    }
                                })
                            }} />
                            <Button
                                icon={KeyIcon}
                                variant={"secondary"}
                                onClick={async () => {
                                    if (await showConfirm(
                                        t("Reset secret key?"),
                                        t("Are you sure you want to reset secret key for this application? This will invalidate all existing access tokens and the application won't be able to obtain new tokens until it is updated to use the new key."),
                                    ) === "yes") {
                                        const resp = await api.post(`/api/v2/oauth/application/${encodeURIComponent(application.id)}/reset-secret`);
                                        toast({
                                            title: t("Secret key has been reset"),
                                            description: <>
                                                <p>{t("The secret key for this application has been reset.")}</p>
                                                <p style={{fontWeight: "bold"}}>
                                                    {t("New client secret is:")}{" "}
                                                    <Code colorScheme={"green"}>
                                                        {resp.data.client_secret}
                                                        <CopyToClipboard
                                                            variant={"ghost"}
                                                            content={resp.data.client_secret}
                                                        />
                                                    </Code>
                                                </p>
                                                <p>{t("Store this code securely. You won't be able to recover it.")}</p>
                                            </>,
                                            status: "success",
                                            isClosable: true,
                                            duration: null
                                        })
                                    }
                                }}
                            >{t("Reset secret key")}</Button>
                            <RemoveButton
                                title={t("Delete application?")}
                                confirm={t("Are you sure you want to delete this application? This will invalidate all existing access tokens and the application won't be able to obtain new tokens until it is re-registered.")}
                                onClick={async () => {
                                    await api.delete(`/api/v2/oauth/application/${encodeURIComponent(application.id)}`);
                                    toast({
                                        title: t("Application has been deleted"),
                                        status: "success"
                                    });

                                    const resp = await api.get<ExistingAPIApplication[]>("/api/v2/oauth/application/");
                                    setApplications(resp.data);
                                }}
                            />
                        </ButtonGroup>
                    </Td>
                </Tr>)}
            </Tbody>
        </Table>}
    </>;
}
