import {CollectionMatch} from "./updateCollection";
import {XwgExtendedCollection} from "../models/xwg";
import {WithAPI} from "./api";

export type RemoveCollectionProps = {
    xwgId: number;
    match: CollectionMatch;
} & WithAPI;

export async function removeCollection({xwgId, api, match}: RemoveCollectionProps): Promise<XwgExtendedCollection[]> {
    await api.delete(`/api/v2/xwg/${xwgId}/collection/${match.year}/${match.date}`);
    const newCollection = await api.get<XwgExtendedCollection[]>(`/api/v2/xwg/${xwgId}/collection`);
    return newCollection.data || [];
}
