import React, {useCallback, useState} from "react";
import Loader from "../generic/loader";
import {XwgStatus, XwgWithId} from "../../models/xwg";

import collectLarge from "../../assets/static/status/btn/collect_large.png";
import offersLarge from "../../assets/static/status/btn/offers_large.png";
import wantsLarge from "../../assets/static/status/btn/wants_large.png";
import notWantLarge from "../../assets/static/status/btn/not_want_large.png";
import {cwgOps} from "../../api/cwgOps";
import {useAPI} from "../../api/api";
import {useTranslation} from "../../utils/helpers";
import {cx} from "@emotion/css";
import {BoxProps, Container, Tooltip, useToast} from "@chakra-ui/react";
import {Button} from "../generic/buttons";
import {useCurrentUser} from "../../models/user";

type StateShifterProps = {
    xwg: XwgWithId;
    status: XwgStatus;
    onStatusChange?: (newStatus: XwgStatus) => void;
    size?: string;
    variant?: string;
};

export function StateShifter({
    xwg,
    status,
    onStatusChange,
    size = "sm",
    variant = "ghost",
    ...props
}: StateShifterProps & BoxProps){
    const [collectionSaving, setCollectionSaving] = useState(false);
    const [offersSaving, setOffersSaving] = useState(false);
    const [wantsSaving, setWantsSaving] = useState(false);
    const [notWantSaving, setNotWantSaving] = useState(false);

    const api = useAPI();
    const cu = useCurrentUser();

    const {t} = useTranslation("cwg_index");
    const toast = useToast();

    const onToggleCollection = useCallback(async () => {
        if (collectionSaving) {
            return;
        }

        if (!xwg.id) {
            return;
        }

        setCollectionSaving(true);

        try {
            const result = await cwgOps.toggleCollection({api, xwg});

            if (result.collected) {
                toast({
                    title: t("xWG has been placed into your collection."),
                    status: "success",
                    isClosable: true,
                });
            } else {
                toast({
                    title: t("xWG has been removed from your collection."),
                    status: "success",
                    isClosable: true,
                });
            }

            onStatusChange?.(result);
        } finally {
            setCollectionSaving(false);
        }


    }, [xwg, api]);

    const onToggleOffers = useCallback(async () => {
        if (offersSaving) {
            return;
        }

        setOffersSaving(true);
        try {
            const result = await cwgOps.toggleOffers({api, xwg});

            if (result.offered) {
                toast({
                    title: t("Your offer has been updated."),
                    status: "success",
                    isClosable: true,
                });
            } else {
                toast({
                    title: t("Your offer has been removed."),
                    status: "success",
                    isClosable: true,
                });
            }

            onStatusChange?.(result);
        } finally {
            setOffersSaving(false);
        }
    }, [xwg, api]);

    const onToggleWants = useCallback(async () => {
        if (wantsSaving) {
            return;
        }

        setWantsSaving(true);
        try {
            const result = await cwgOps.toggleWants({api, xwg})

            if (result.wanted) {
                toast({
                    title: t("You were placed to the list of users who wants this xWG."),
                    status: "success",
                    isClosable: true,
                });
            } else {
                toast({
                    title: t("You were removed from list of users who wants this xWG."),
                    status: "success",
                    isClosable: true,
                });
            }

            onStatusChange?.(result);
        } finally {
            setWantsSaving(false);
        }
    }, [xwg, api]);

    const onToggleNotWant = useCallback(async () => {
        if (notWantSaving) {
            return;
        }

        setNotWantSaving(true);
        try {
            const result = await cwgOps.toggleNotWant({api, xwg})

            if (result.not_wanted) {
                toast({
                    title: t("You were placed to the list of users who don't want this xWG."),
                    status: "success",
                    isClosable: true,
                });
            } else {
                toast({
                    title: t("You were removed from list of users who don't want this xWG."),
                    status: "success",
                    isClosable: true,
                });
            }

            onStatusChange?.(result);
        } finally {
            setNotWantSaving(false);
        }
    }, [xwg, api]);

    if (!cu) {
        return null;
    }

    return <Container
        {...props}
        display={"flex"}
        variant={"state-shifter"}
    >
        <Tooltip label={t("Collect")}>
            <Button
                variant={variant}
                size={size}
                type="button"
                onClick={onToggleCollection}
            >
                {!collectionSaving
                    ? <img src={collectLarge} className={cx(!status.collected && "inactive")} alt={t("Collect")} />
                    : <Loader />
                }
            </Button>
        </Tooltip>
        <Tooltip label={t("Offer")}>
            <Button
                variant={variant}
                size={size}
                type="button"
                onClick={onToggleOffers}
            >
                {!offersSaving
                    ? <img src={offersLarge} className={cx(!status.offered && "inactive")} alt={t("Offer")} />
                    : <Loader />
                }
            </Button>
        </Tooltip>
        <Tooltip label={t("Want")}>
            <Button
                variant={variant}
                size={size}
                type="button"
                onClick={onToggleWants}
            >
                {!wantsSaving
                    ? <img src={wantsLarge} className={cx(!status.wanted && "inactive")} alt={t("Want")} />
                    : <Loader />
                }
            </Button>
        </Tooltip>
        <Tooltip label={t("Don't want")}>
            <Button
                variant={variant}
                size={size}
                type="button"
                onClick={onToggleNotWant}
            >
                {!notWantSaving
                    ? <img src={notWantLarge} className={cx(!status.not_wanted && "inactive")} alt={t("Don't want")} />
                    : <Loader />
                }
            </Button>
        </Tooltip>
    </Container>
}

