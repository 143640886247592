import {TagCategory} from "../../models/tag";
import {useTranslation} from "../../utils/helpers";
import React, {useCallback, useRef} from "react";
import {Controller, ErrorMessage, FieldSet, FormButtons, Switch} from "../generic/form";
import {Button} from "../generic/buttons";
import Select from "../generic/select";
import {FormControl, FormLabel, Grid, HStack, IconButton, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {XwgFilter} from "../../models/xwgSearchQuery";
import {useForm} from "react-hook-form";
import {
    negativeTransformers,
    positiveTransformers,
    prepareFilter,
    sanitizeFilter,
    XwgAdvancedFilterFormData
} from "./advancedSearchForm";
import {useParams} from "@tanstack/react-router";
import SearchIcon from "~icons/mdi/search";


type CwgSearchFormProps = {
    tagCategories: TagCategory[];
    onSearch?: (search: XwgFilter) => void;
    filter?: XwgFilter;
}

const CwgSearchForm = ({onSearch, tagCategories, filter = {}}: CwgSearchFormProps) => {
    const {t} = useTranslation("browse");

    const params = useParams({strict: false});

    const {handleSubmit, register, control, formState: {errors}} = useForm<XwgAdvancedFilterFormData>({
        defaultValues: prepareFilter({...filter, names: params.query ? [params.query] : undefined})
    });

    const doSearch = useCallback((data: XwgAdvancedFilterFormData) => {
        onSearch?.(sanitizeFilter(data));
    }, [onSearch]);

    const formRef = useRef<HTMLFormElement>(null);
    //const timeoutRef = useRef<number | null>(null);

    /*const submitOnChange = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = window.setTimeout(() => {
            formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }, 500);
    }, [timeoutRef, formRef.current]);*/

    return (
        <form onSubmit={handleSubmit(doSearch)} ref={formRef} /*onChange={submitOnChange}*/>
            <FieldSet legend={t("Search query")}>
                <InputGroup>
                    <Input {...register("name.0", {required: t("You must search for something.", {ns: "search"})})} autoFocus />
                    <InputRightElement>
                        <IconButton type={"submit"} variant={"inside-input"} icon={<SearchIcon />} aria-label={t("Search")} />
                    </InputRightElement>
                </InputGroup>
                <ErrorMessage name={"name.0"} errors={errors} />
            </FieldSet>
            <FieldSet legend={t("Advanced search")}>
                <HStack alignItems={"flex-start"} justifyContent={"stretch"}>
                    {tagCategories.map(category => (
                        <FormControl key={category.id}>
                            <FormLabel>{category.name}:</FormLabel>
                            <Controller
                                control={control}
                                name={`categories.${category.id}`}
                                render={({field}) => <Select
                                    instanceId={`category-${category.id}`}
                                    isMulti={true}
                                    isClearable={false}
                                    placeholder={category.name}
                                    options={category.tags.map(tag => ({value: tag.id, label: tag.name}))}
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange(value);
                                    }}
                                />}
                            />
                        </FormControl>
                    ))}
                </HStack>

                <HStack>
                    <Grid templateColumns={"1fr 1fr"} rowGap={"2"} columnGap={8}>
                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"collectedByMe"}
                                {...positiveTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I have collected")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"collectedByMe"}
                                {...negativeTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I haven't collected")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"offeredByMe"}
                                {...positiveTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I offer")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"offeredByMe"}
                                {...negativeTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I don't offer")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"wantedByMe"}
                                {...positiveTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I want")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"wantedByMe"}
                                {...negativeTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I don't have on my want list")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"notWantedByMe"}
                                {...positiveTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I don't want")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"notWantedByMe"}
                                {...negativeTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I don't have on my not wanted list")}
                        </FormLabel>
                    </Grid>
                </HStack>
            </FieldSet>
            <FormButtons>
                <Button type={"submit"}>{t("Filter")}</Button>
            </FormButtons>
        </form>
    );
}

export {
    CwgSearchForm
};

export type {
    CwgSearchFormProps
};
