import {createFileRoute} from '@tanstack/react-router'
import {thirdLevelLayoutRoute} from "../../../../../../../utils/collectionListing";
import {useTranslation} from "../../../../../../../utils/helpers";

export function ViewAllTab() {
    const {t} = useTranslation("browse");
    return <>{t("All")}</>;
}

export const Route = createFileRoute('/_site/cwg-collection/browse/$primarySorting/$primaryCategoryName/$secondaryCategoryName')({
    ...thirdLevelLayoutRoute({
        additionalTabs: [
            {
                label: <ViewAllTab />,
                to: "../all/"
            }
        ],
        tagRoute: () => Route.fullPath
    })
});
