import axios, {AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse, CanceledError} from "axios";
import {isServer} from "../utils";
import {RequestData} from "../models/requestData";
import {AccessTokenResponse, TUserStore} from "../models/currentUser";
import React from "react";
import {RouterContext} from "../models/routerContext";
import {Code, useToast} from "@chakra-ui/react";
import {useRouter} from "@tanstack/react-router";
import {jwtDecode} from "jwt-decode";
import {getFixedT} from "../utils/getFixedT";
import {CopyToClipboard} from "../components/generic/copyToClipboard";
import pino, {Logger} from "pino";
import {ApiError, errorFactory, WithGRID} from "../utils/errors";
import {Languages} from "../i18n";
import {i18n} from "i18next";
import {useTranslation} from "../utils/helpers";
import {useMatchData} from "../utils/findInMatchesData";

const logger = pino();

export type WithAPI = {
    api: Axios;
}

export function createLogger(req: RequestData): Logger {
    return req.log;
}

export function useLogger(): Logger {
    const {state: {matches}} = useRouter();
    const request = (matches.find(match => (match.loaderData as RouterContext)?.request)?.loaderData as RouterContext)?.request;

    if (!request) {
        return pino();
    }

    if (!request || !request.log || typeof(request.log?.info) !== "function") {
        request.log = pino({
            mixin: () => ({
                id: request.grid
            })
        });
    }

    return request.log;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export interface Type<T, A extends any[] = any[]> extends Function {
    new (...args: A): T;
}

export type Axios = /*AxiosInstance |*/ AxiosMiddleware;

export type AxiosAdditionalRequestConfig = {
    pathParams?: Record<string, string | number | boolean>;
};

export class AxiosMiddleware<AdditionalProps extends AxiosAdditionalRequestConfig = AxiosAdditionalRequestConfig> {
    private instance: Axios | AxiosInstance;

    constructor(instance: Axios | AxiosInstance) {
        this.instance = instance;
    }

    toString(): string {
        return `${this.constructor.name}<${this.instance.toString()}>`;
    }

    async request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D> & AdditionalProps): Promise<R> {
        if (config.pathParams && config.url) {
            for (const [key, value] of Object.entries(config.pathParams)) {
                config.url = config.url.replace(`{${key}}`, encodeURIComponent(value as string));
            }
        }

        return await this.instance.request<T, R, D>(config);
    }

    async get<T = any, D = any, R = AxiosResponse<T>, >(url: string, config?: AxiosRequestConfig<D> & AdditionalProps): Promise<R> {
        return await this.request<T, R, D>(Object.assign({
            method: "GET",
            url
        }, config));
    }

    async delete<T = any, D = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig<D> & AdditionalProps): Promise<R> {
        return await this.request<T, R, D>(Object.assign({
            method: "DELETE",
            url
        }, config));
    }

    async head<T = any, D = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig<D> & AdditionalProps): Promise<R> {
        return await this.request<T, R, D>(Object.assign({
            method: "HEAD",
            url
        }, config));
    }

    async options<T = any, D = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig<D> & AdditionalProps): Promise<R> {
        return await this.request<T, R, D>(Object.assign({
            method: "OPTIONS",
            url
        }, config));
    }

    async post<T = any, D = any, R = AxiosResponse<T>>(url: string, data?: D, config?: Omit<AxiosRequestConfig<D>, "data"> & AdditionalProps): Promise<R> {
        return await this.request<T, R, D>(Object.assign({
            method: "POST",
            url,
            data
        }, config));
    }

    async put<T = any, D = any, R = AxiosResponse<T>>(url: string, data?: D, config?: Omit<AxiosRequestConfig<D>, "data"> & AdditionalProps): Promise<R> {
        return await this.request<T, R, D>(Object.assign({
            method: "PUT",
            url,
            data
        }, config));
    }

    async patch<T = any, D = any, R = AxiosResponse<T>>(url: string, data?: D, config?: Omit<AxiosRequestConfig<D>, "data"> & AdditionalProps): Promise<R> {
        return await this.request<T, R, D>(Object.assign({
            method: "PATCH",
            url,
            data
        }, config));
    }
}

export class ErrorHandlerMiddleware extends AxiosMiddleware {
    remoteApp: string;

    constructor(instance: Axios, remoteApp: string) {
        super(instance);
        this.remoteApp = remoteApp;
    }

    async request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
        try {
            return await super.request<T, R, D>(config);
        } catch (error) {
            throw errorFactory(error as object, config.headers?.["X-Grid"], this.remoteApp);
        }
    }
}

export class LoggerMiddleware extends AxiosMiddleware {
    logger: ReturnType<typeof useLogger>;
    serviceName: string;

    constructor(instance: Axios, logger: ReturnType<typeof useLogger>, serviceName: string) {
        super(instance);
        this.logger = logger;
        this.serviceName = serviceName
    }

    async request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
        const params = config.params && !(config.params instanceof URLSearchParams) ? new URLSearchParams(config.params) : config.params;
        this.logger.info(`Calling ${this.serviceName}: ${config.method?.toUpperCase()} ${config.url}${params ? "?" + params.toString() : ""}...`);
        if (config.data) {
            this.logger.debug(config.data);
        }

        try {
            const resp = await super.request(config);
            this.logger.info(`${this.serviceName} response: ${config.method?.toUpperCase()} ${config.url}: ${resp.status} ${resp.statusText}`);
            return resp as R;
        } catch (error) {
            if (error instanceof ApiError) {
                this.logger.error(`Request to ${this.serviceName} failed: ${error.toString()}`);
            }
            throw error;
        }
    }
}

export class FlashMiddleware extends AxiosMiddleware {
    message?: string;
    toast: ReturnType<typeof useToast>;

    constructor(instance: Axios, toast: ReturnType<typeof useToast>, message?: string) {
        super(instance);
        this.toast = toast;
        this.message = message;
    }

    async request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
        const t = getFixedT("default");

        const flashId = this.toast({
            duration: null,
            title: this.message ?? t("Loading data..."),
            status: "loading"
        });

        try {
            return await super.request(config);
        } catch (e) {
            if (!((e as {cause: unknown}).cause instanceof CanceledError) && !(e instanceof CanceledError)) {
                this.toast({
                    status: "error",
                    title: t("An error occurred while loading data."),
                    description: (e as WithGRID).grid ? <>
                        {t("Request ID:")}{" "}
                        <Code colorScheme={"red"}>
                            {(e as WithGRID).grid}
                            <CopyToClipboard content={(e as WithGRID).grid} variant={"ghost"}/>
                        </Code>
                    </> : null,
                    isClosable: true
                });
            }
            throw e;
        } finally {
            this.toast.close(flashId);
        }
    }
}

class ExtractServerVersionMiddleware extends AxiosMiddleware {
    onVersionDetected: (version: string) => void;

    constructor(instance: Axios, onVersionDetected: (version: string) => void) {
        super(instance);
        this.onVersionDetected = onVersionDetected;
    }

    async request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
        const response = await super.request(config);

        let serverVersion = response.headers["x-server-version"];
        if (serverVersion && serverVersion.includes(" ")) {
            serverVersion = serverVersion.split(" ")[1];
            if (serverVersion) {
                this.onVersionDetected(serverVersion);
            }
        }

        return response as R;
    }
}

export class GridMiddleware extends AxiosMiddleware {
    grid: string | undefined;

    constructor(instance: Axios, grid: string) {
        super(instance);
        this.grid = grid;
    }

    async request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
        if (!config.headers) {
            config.headers = new AxiosHeaders();
        }

        if (!config.headers["X-Grid"]) {
            config.headers["X-Grid"] = this.grid;
        }

        return await super.request(config);
    }
}

export class LanguageInjector extends AxiosMiddleware {
    i18n: i18n;

    constructor(instance: Axios, i18n: i18n) {
        super(instance);
        this.i18n = i18n;
    }

    async request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
        if (!config.headers) {
            config.headers = new AxiosHeaders();
        }

        if (!config.headers["Accept-Language"]) {
            config.headers["Accept-Language"] = Languages[(this.i18n.language ?? "en") as keyof typeof Languages].be;
        }

        return await super.request(config);
    }
}


export type MiddlewareInitializer<T extends AxiosMiddleware = AxiosMiddleware> = [Type<T>, ...ConstructorParameters<Type<T>>[]];
export type Middlewares<T extends any[]> = {
    [P in keyof T]: T[P] extends T[number] ? MiddlewareInitializer<T[P]> : never;
}

export type CreateAPIOptions = {
    url?: string;
}

export function createAPI<M extends any[] = []>(req: RequestData, currentUser?: TUserStore, middlewares: Middlewares<M> = [] as Middlewares<M>, options?: CreateAPIOptions): Axios {
    const url = new URL(options?.url ?? req.url);
    const instance = axios.create({
        baseURL: url.origin,
        paramsSerializer: (params) => {
            const urlSearchParams = new URLSearchParams();
            for (const [key, value] of Object.entries(params)) {
                if (value === undefined) {
                    continue;
                }

                if (Array.isArray(value)) {
                    for (const item of value) {
                        if (item === undefined) {
                            continue;
                        }

                        urlSearchParams.append(key, item);
                    }
                } else {
                    urlSearchParams.append(key, value);
                }
            }

            return urlSearchParams.toString();
        },
    });

    // Inject authorization token into request headers.
    instance.interceptors.request.use(async (config) => {
        // If authorization has already been provided, do not overwrite it.
        if (currentUser && currentUser.token && !config.headers["Authorization"]) {
            // This condition prevents infinite loop when refreshing token.
            if (config.url !== "/api/v2/oauth/token") {
                const jwt = jwtDecode(currentUser.token.access_token);

                // If the access token expires within 60 seconds, refresh it.
                if (jwt && jwt.exp) {
                    const expiration = (jwt.exp - (Date.now() / 1000));
                    if (expiration < 60) {
                        logger.debug(`Refreshing access token because expiration is within 60 seconds (expires in ${expiration} seconds).`);
                        // Refresh token
                        const jwtResponse = await instance.post<AccessTokenResponse>("/api/v2/oauth/token", {
                            "grant_type": "refresh_token",
                            "refresh_token": currentUser.token.refresh_token ?? req.cookies.refresh_token,
                        }, {
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                            },
                            validateStatus: (status) => status === 200 || status === 401,
                        });

                        if (jwtResponse.status === 401) {
                            // User's login has expired. Redirect to index page instead of doing request.
                            // TODO
                        }

                        currentUser.token = jwtResponse.data;
                    }
                }
            }

            config.headers["Authorization"] = `Bearer ${currentUser.token.access_token}`;
        }
        return config;
    });

    // Inject host into request headers (need for sending emails pointing to correct domain).
    if (isServer) {
        instance.interceptors.request.use((config) => {
            const reqUrl = new URL(req.url);

            let host = reqUrl.host;
            if (!host.includes(":")) {
                host = `${host}:${reqUrl.port}`;
            }

            req.log.info({
                "X-Forwarded-Host": host,
                "X-Forwarded-Proto": reqUrl.protocol.replace(":", "")
            }, "Injecting X-Proxy headers");

            config.headers["X-Forwarded-Host"] = host;
            config.headers["X-Forwarded-Proto"] = reqUrl.protocol.replace(":", "");
            return config;
        });
    }

    let i: Axios | AxiosInstance = instance;
    for (const [Middleware, ...args] of middlewares) {
        i = new Middleware(i, ...args);
    }

    return i as AxiosMiddleware;
}

export type APIOptions<M extends any[] = []> = {
    logger: boolean;
    flash: boolean | string;
    middlewares: Middlewares<M>;
}

export function useAPI<M extends any[] = []>(options: Partial<APIOptions<M>> = {}): Axios {
    const opts: APIOptions<M> = Object.assign({
        logger: isServer,
        flash: !isServer,
        middlewares: [],
    }, options);

    const logger = useLogger();
    const toast = useToast();

    const router = useRouter();
    const ctx = router.options.context as RouterContext;

    const {i18n} = useTranslation();
    const grid = useMatchData<string>("grid");

    const middlewares = [
        [GridMiddleware, grid ?? ctx.request.grid],
        [ErrorHandlerMiddleware, "cwg-rest"],
        [ExtractServerVersionMiddleware, (version: string) => { ctx.applicationData.apiVersion = version }],
        [LanguageInjector, i18n],
        ...opts.middlewares,
        ...(opts.flash ? [[FlashMiddleware, toast, typeof options.flash === "string" ? options.flash : undefined]] : []),
        ...(opts.logger ? [[LoggerMiddleware, logger, "cwg-rest"]] : []),
    ];

    return createAPI(
        ctx.request,
        ctx.currentUser,
        // @ts-ignore
        middlewares,
        {

        }
    );
}

export function getAPI<M extends any[] = []>(context: RouterContext & {grid?: string}, options: Partial<APIOptions<M>> = {}): Axios {
    const opts: APIOptions<M> = Object.assign({
        logger: isServer,
        flash: false,
        middlewares: [],
    }, options);

    const middlewares = [
        [GridMiddleware, context.grid ?? context.request.grid],
        [ErrorHandlerMiddleware, "cwg-rest"],
        [ExtractServerVersionMiddleware, (version: string) => {context.applicationData.apiVersion = version}],
        [LanguageInjector, context.request.i18n],
        ...opts.middlewares,
        ...(opts.logger ? [[LoggerMiddleware, createLogger(context.request), "cwg-rest"]] : []),
    ];

    return createAPI(
        context.request,
        context.currentUser,
        // @ts-ignore
        middlewares,
    );
}
