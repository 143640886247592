import {createFileRoute, redirect} from '@tanstack/react-router'
import React from "react";
import LoginForm from "../../components/users/loginForm";
import {Route as RootRoute} from "../__root";
import {Route as LoginAuthorizeRoute} from "./authorize";
import {RISON} from "rison2";
import {UserError} from "../../utils/errors";

export type OAuthParams = {
    client_id: string;
    response_type: string;
    redirect_uri?: string;
    state?: string;
    scope?: string;
}

export const Route = createFileRoute('/login/')({
    beforeLoad: ({context, search}) => {
        if (context.currentUser.currentUser && !search.force) {
            throw redirect({
                to: LoginAuthorizeRoute.fullPath,
                search: search
            });
        }
    },
    validateSearch: (raw: Record<string, unknown>): OAuthParams & {force: boolean} => {
        if (!raw.client_id) {
            throw new UserError("client_id is required");
        }

        if (typeof(raw.response_type) !== "string") {
            raw.response_type = "code";
        }

        if (typeof(raw.redirect_uri) !== "string" && raw.redirect_uri !== undefined) {
            throw new UserError("redirect_uri is required");
        }

        if (typeof(raw.state) !== "string" && raw.state !== undefined) {
            throw new UserError("state must be a string");
        }

        if (typeof(raw.scope) !== "string" && raw.scope !== undefined) {
            throw new UserError("scope must be a string");
        }

        return {
            client_id: raw.client_id,
            response_type: raw.response_type as string,
            redirect_uri: raw.redirect_uri,
            state: raw.state,
            scope: raw.scope,
            force: !!raw.force
        }
    },
    component: () => {
        const {request} = RootRoute.useLoaderData();
        const search = Route.useSearch();

        const url = new URL("/oauth2/login", request.url);

        return <LoginForm
            client_id={request.clientId}
            redirect_uri={url.toString()}
            state={btoa(RISON.stringify({
                ...search,
                next: new URL("/login/authorize", request.url).toString(),
            }))}
        />
    }
});
