import {UserEmail} from "../../models/user";
import {
    Alert,
    AlertIcon,
    Badge,
    Button,
    Checkbox,
    Input,
    Table,
    Tbody,
    Td,
    Tfoot,
    Th,
    Thead,
    Tooltip,
    Tr,
    useToast
} from "@chakra-ui/react";
import {useTranslation} from "../../utils/helpers";
import {RemoveButton} from "../generic/buttons";
import {LightSelect} from "../generic/select";
import {Languages} from "../../i18n";
import AddIcon from "~icons/mdi/plus-circle";
import {Controller, useForm} from "react-hook-form";
import {useAPI} from "../../api/api";
import {ErrorMessage} from "../generic/form";
import {useEffect, useState} from "react";
import {ListResponse} from "../../models/response";

export function EmailSettings({emails: defaultEmails}: {emails: UserEmail[]}) {
    const {t, i18n} = useTranslation("settings");
    const {t: tLang} = useTranslation("languages");
    const {t: tOps} = useTranslation("cwg_ops");
    const api = useAPI();

    const [emails, setEmails] = useState<UserEmail[]>(defaultEmails);

    useEffect(() => {
        setEmails(defaultEmails);
    }, [defaultEmails]);

    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: {
            errors
        }
    } = useForm<UserEmail>({
        defaultValues: {
            email: "",
            language: (Languages[i18n.language as keyof typeof Languages] ?? Languages["en"]).be,
            receive_privmsg: true,
            receive_join: true,
        }
    });

    const toast = useToast();

    return <form onSubmit={handleSubmit(async (email) => {
        await api.post("/api/v2/users/me/emails/", [email]);
        setEmails((await api.get<ListResponse<UserEmail>>("/api/v2/users/me/emails/")).data.items);
        toast({
            title: t("New email has been added. Check your inbox for verification email."),
            status: "success"
        });
        reset();
    })}>
        <Table>
            <Thead>
                <Tr>
                    <Th>{t("Email")}</Th>
                    <Th textAlign={"center"}>{t("State")}</Th>
                    <Th textAlign={"center"}>{t("Receive private messages")}</Th>
                    <Th textAlign={"center"}>{t("Receive join notifications")}</Th>
                    <Th textAlign={"center"}>{t("Language for communication")}</Th>
                    <Th textAlign={"center"}></Th>
                </Tr>
            </Thead>
            <Tbody>
                {emails?.map(email => <Tr key={email.email}>
                    <Td>{email.email}</Td>
                    <Td textAlign={"center"}>{email.primary
                        ? <Badge colorScheme={"green"}>{t("Primary")}</Badge>
                        : !email.verified
                            ? <>
                                <Badge colorScheme={"red"}>{t("Not verified")}</Badge>
                                <Button
                                    variant={"secondary"}
                                    size={"xs"}
                                    mt={2}
                                    onClick={async () => {
                                        await api.post("/api/v2/users/me/emails?resend=true", [{
                                            email: email.email
                                        }]);
                                        toast({
                                            title: t("Verification email has been sent."),
                                            status: "success"
                                        });
                                    }}
                                >
                                    {t("Resend verification email")}
                                </Button>
                            </>
                            : <Tooltip label={t("Set this email as primary")}>
                                <Badge
                                    colorScheme={"gray"}
                                    onClick={async () => {
                                        await api.post("/api/v2/users/me/emails", [{
                                            email: email.email,
                                            primary: true
                                        }]);
                                        setEmails((await api.get<ListResponse<UserEmail>>("/api/v2/users/me/emails/")).data.items);
                                        toast({
                                            title: t("Email preferences were updated."),
                                            status: "success"
                                        });
                                    }}
                                    cursor={"pointer"}
                                >{t("Secondary")}</Badge>
                            </Tooltip>
                    }</Td>
                    <Td textAlign={"center"}>
                        <Tooltip label={t("Click to change preferences")}>
                            {email.receive_privmsg
                            ? <Badge
                                    colorScheme={"green"}
                                    cursor={"pointer"}
                                    onClick={async () => {
                                        await api.post("/api/v2/users/me/emails", [{
                                            email: email.email,
                                            receive_privmsg: false
                                        }]);
                                        setEmails((await api.get<ListResponse<UserEmail>>("/api/v2/users/me/emails/")).data.items);
                                        toast({
                                            title: t("Email preferences were updated."),
                                            status: "success"
                                        });
                                    }}
                                >
                                    {t("Yes")}
                            </Badge>
                            : <Badge
                                colorScheme={"red"}
                                cursor={"pointer"}
                                onClick={async () => {
                                    await api.post("/api/v2/users/me/emails", [{
                                        email: email.email,
                                        receive_privmsg: true
                                    }]);
                                    setEmails((await api.get<ListResponse<UserEmail>>("/api/v2/users/me/emails/")).data.items);
                                    toast({
                                        title: t("Email preferences were updated."),
                                        status: "success"
                                    });
                                }}
                            >
                                    {t("No")}
                            </Badge>}
                        </Tooltip>
                    </Td>
                    <Td textAlign={"center"}>
                        <Tooltip label={t("Click to change preferences")}>
                            {email.receive_join
                            ? <Badge
                                    colorScheme={"green"}
                                    cursor={"pointer"}
                                    onClick={async () => {
                                        await api.post("/api/v2/users/me/emails", [{
                                            email: email.email,
                                            receive_join: false
                                        }]);
                                        setEmails((await api.get<ListResponse<UserEmail>>("/api/v2/users/me/emails/")).data.items);
                                        toast({
                                            title: t("Email preferences were updated."),
                                            status: "success"
                                        });
                                    }}
                                >
                                    {t("Yes")}
                            </Badge>
                            : <Badge
                                    colorScheme={"red"}
                                    cursor={"pointer"}
                                    onClick={async () => {
                                        await api.post("/api/v2/users/me/emails", [{
                                            email: email.email,
                                            receive_join: true
                                        }]);
                                        setEmails((await api.get<ListResponse<UserEmail>>("/api/v2/users/me/emails/")).data.items);
                                        toast({
                                            title: t("Email preferences were updated."),
                                            status: "success"
                                        });
                                    }}
                                >
                                    {t("No")}
                            </Badge>}
                        </Tooltip>
                    </Td>
                    <Td>
                        <LightSelect
                            value={email.language}
                            options={Object.entries(Languages).map(([code, lang]) => ({
                                value: lang.be,
                                label: tLang(code)
                            }))}
                            onChange={async (value) => {
                                await api.post("/api/v2/users/me/emails", [{
                                    email: email.email,
                                    language: value
                                }]);
                                setEmails((await api.get<ListResponse<UserEmail>>("/api/v2/users/me/emails/")).data.items);
                                toast({
                                    title: t("Email preferences were updated."),
                                    status: "success"
                                });
                            }}
                        />
                    </Td>
                    <Td>
                        {!email.primary ? <RemoveButton
                            type={"button"}
                            confirm={t("Do you really want to remove this email?")}
                            onClick={async () => {
                                await api.delete(`/api/v2/users/me/emails/`, {params: {
                                    email: email.email
                                }});
                                setEmails((await api.get<ListResponse<UserEmail>>("/api/v2/users/me/emails/")).data.items);
                                toast({
                                    title: t("Email has been removed."),
                                    status: "success"
                                });
                            }}
                        /> : null}
                    </Td>
                </Tr>)}
                <Tr>
                    <Td colSpan={2}>
                        <Input
                            placeholder={t("Add new email")}
                            {...register("email", {
                                required: tOps("Field is required."),
                                validate: (value) => {
                                    if (emails.find(email => email.email === value)) {
                                        return t("Email already exists.");
                                    }
                                }
                            })}
                        />
                        <ErrorMessage errors={errors} name={"email"} />
                    </Td>
                    <Td textAlign={"center"}>
                        <Checkbox {...register("receive_privmsg")} />
                    </Td>
                    <Td textAlign={"center"}>
                        <Checkbox {...register("receive_join")} />
                    </Td>
                    <Td>
                        <Controller
                            control={control}
                            name={"language"}
                            render={({field: {onChange, value, name}}) => {
                                return <LightSelect
                                    name={name}
                                    value={value}
                                    instanceId={name}
                                    options={Object.entries(Languages).map(([code, lang]) => ({
                                        value: lang.be,
                                        label: tLang(code)
                                    }))}
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                />
                            }}
                        />
                    </Td>
                    <Td>
                        <Button type={"submit"} variant="secondary">
                            <AddIcon />
                        </Button>
                    </Td>
                </Tr>
            </Tbody>
            <Tfoot>
                <Tr>
                    <Td colSpan={6}>
                        <Alert status={"info"}>
                            <AlertIcon />
                            {t("Primary email is used for password recovery and emergency communication.")}
                        </Alert>
                    </Td>
                </Tr>
            </Tfoot>
        </Table>
    </form>;
}
