import {createFileRoute, Link, useMatches, useNavigate} from '@tanstack/react-router'
import {Route as LayoutRoute} from "./route";
import {ListResponse} from "../../../../../models/response";
import {Image, XwgWithId} from "../../../../../models/xwg";
import React, {useEffect, useMemo, useState} from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    BreadcrumbItem,
    BreadcrumbLink,
    ButtonGroup,
    Center,
    Checkbox,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tab,
    Table,
    TabList,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import {useTranslation} from "../../../../../utils/helpers";
import {XwgFilter} from "../../../../../models/xwgSearchQuery";
import {Route as XwgIndexRoute} from "../../../cwg/index.$xwgId/index";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../../components/generic/breadcrumb";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import {FormattedDate} from "../../../../../components/generic/date";
import {Button, EditButton} from "../../../../../components/generic/buttons";
import FlatColorIconsImageFile from '~icons/flat-color-icons/image-file';
import {ProfileLink} from "../../../../../components/users/profileLink";
import {User} from "../../../../../models/user";

import ChevronLeftIcon from "~icons/mdi/chevron-left";
import ChevronRightIcon from "~icons/mdi/chevron-right";
import EmojioneV1Eye from '~icons/emojione-v1/eye';
import holdIcon from "../../../../../assets/static/icons/hold.svg";
import archiveIcon from "../../../../../assets/static/icons/archive.svg";
import publishIcon from "../../../../../assets/static/icons/publish.svg";

import Loader from "../../../../../components/generic/loader";
import {useAPI} from "../../../../../api/api";

import {Route as HoldsRoute} from "./holds";
import {Route as AllRoute} from "./all";
import {Dd, Dl, Dt} from "../../../../../components/generic/dl";
import {Trans} from "react-i18next";
import {getImageBadge} from "../../../../../components/cwg/cwgAdditionalImages";
import {HTTP, ROUTES} from "../../../../../constants";
import {CwgCategories} from "../../../../../components/cwg/categories";
import {TFunction} from "i18next";

export const Route = createFileRoute('/_site/admin/approving/_queue/')({
    component: () => {
        const {t} = useTranslation("review");
        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("Review queue")}</h2>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={ROUTES.ADMIN}>{t("Administration", {ns: "admin"})}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath}>{t("Review queue")}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>
            <ReviewQueueNavigation />
        </>
    },
    beforeLoad: ({context}) => {
        (context.query.filter as XwgFilter).publish_status = ["new"];
        return {
            query: context.query
        }
    }
});

type ReviewQueueBatch = {
    items: {[key: string]: any};
}

export function ReviewQueueNavigation() {
    const {t} = useTranslation("review");
    const matches = useMatches();

    const tabs = [
        {label: t("New"), route: Route.fullPath},
        {label: t("My holds"), route: HoldsRoute.fullPath},
        {label: t("All"), route: AllRoute.fullPath}
    ];

    const activeIndex = tabs.findIndex(tab => tab.route == matches[matches.length - 1].fullPath);

    const {items, count} = LayoutRoute.useLoaderData({
        select: ({items, count}) => ({
            items: items.map(item => item instanceof XwgWithId ? item : new XwgWithId(item)),
            count: count
        })
    }) as ListResponse<XwgWithId>;

    const rq = useMemo(() => <ReviewQueue items={items} count={count} />, [JSON.stringify(items.map(i => i.id))]);
    const navigate = useNavigate();

    return <>
        <Tabs index={activeIndex}>
            <TabList>
                {tabs.map((tab, idx) => <Tab key={idx} onClick={async () => await navigate({to: tab.route})}>{tab.label}</Tab>)}
            </TabList>
            <TabPanels>
                <Box p={4}>
                    {rq}
                </Box>
            </TabPanels>
        </Tabs>
    </>;
}

function getImageFilter(image: Image) {
    if (image.type === "primary") {
        if (image.custom === false) {
            return "grayscale(100%)";
        } else {
            return "hue-rotate(50deg)";
        }
    }

    if (image.type === "other") {
        return "hue-rotate(160deg)";
    }

    return undefined;
}

function getImageLabel(image: Image, t: TFunction) {
    if (image.type === "primary") {
        if (image.custom === false) {
            return t("This xWG has default primary image.", {ns: "review"});
        }
        return t("This xWG has custom primary image.", {ns: "review"});
    }

    if (image.type === "other") {
        return t("Secondary image", {ns: "review"});
    }

    return t("Private image {{name}} by {{user}}", {
        ns: "review",
        name: image.name,
        user: image.user?.name ?? "unknown user"
    })
}

type ReviewQueueRowProps = {
    xwg: XwgWithId;
    isProcessed?: boolean
}

const ReviewQueueRow = function ReviewQueueRow({
    xwg,
    isProcessed = false,
}: ReviewQueueRowProps) {
    const ctx = useFormContext<ReviewQueueBatch>();
    const {t: ti} = useTranslation("cwg_index");
    const toast = useToast();
    const api = useAPI();
    const [processed, setProcessed] = useState<boolean>(isProcessed);

    const {isOpen, onOpen, onClose} = useDisclosure();
    const [showLoader, setShowLoader] = useState(true);
    const [modalImageIndex, setModalImageIndex] = useState<number>(0);

    useEffect(() => {
        setProcessed(processed || isProcessed);
    }, [isProcessed]);

    const currentModalImage = useMemo(() => {
        return xwg.images?.[modalImageIndex];
    }, [modalImageIndex, xwg]);

    console.log("render");

    return useMemo(() => <>
        <Tr key={xwg.id} opacity={processed ? .5 : undefined}>
            <Td className={"td-shrink"}>
                <Checkbox {...ctx.register(`items.${xwg.id}`)} />
            </Td>
            <Td>
                <Text as={"span"} fontWeight={600}>
                    <Link to={XwgIndexRoute.fullPath} params={{xwgId: xwg.id}}>
                        {xwg.name}
                    </Link>{" "}
                </Text>
                <Text as={"span"} fontSize="xs">{ti("(version {{version}})", {version: xwg.version, ns: "cwg_index"})}</Text>

                {xwg.note && <Text>{ti("Note:")} {xwg.note}</Text>}

                <HStack
                    mt={1}
                    justifyContent={"space-between"}
                >
                    <ButtonGroup isAttached size={"xs"} variant={"secondary"}>
                        {xwg.images?.map((image, idx) =>
                            <Tooltip label={getImageLabel(image, ti)} key={idx}>
                                <Button
                                    icon={FlatColorIconsImageFile}
                                    filter={getImageFilter(image)}
                                    onClick={() => {
                                        setModalImageIndex(idx);
                                        setShowLoader(true);
                                        onOpen();
                                    }}
                                />
                            </Tooltip>
                        )}
                    </ButtonGroup>

                    <Text fontSize={"2xs"}>{ti("ID {{id}}", {ns: "review", id: xwg.id})}</Text>
                </HStack>
            </Td>
            <Td>
                <ProfileLink user={xwg.created_by as User} />
                <Text><FormattedDate date={xwg.created} /></Text>
            </Td>
            <Td>
                <CwgCategories xwg={xwg} />
            </Td>
            <Td className={"td-shrink"}>
                {xwg.hold && <Text><Trans ns={"review"}>On hold by <ProfileLink user={xwg.hold} /></Trans></Text>}

                <ButtonGroup size={"md"} variant={"secondary"}>
                    <Button
                        as={Link}
                        to={ROUTES.XWG_INDEX}
                        params={{xwgId: xwg.id}}
                        icon={EmojioneV1Eye}
                    >
                        {ti("View", {ns: "review"})}
                    </Button>

                    <EditButton
                        as={Link}
                        to={ROUTES.XWG_MODIFY}
                        params={{xwgId: xwg.id}}
                    />

                    <Button
                        icon={publishIcon}
                        onClick={async () => {
                            const resp = await api.patch(`/api/v2/xwg/${xwg.id}`, {
                                publish_status: "published"
                            }, {
                                validateStatus: status => status === HTTP.OK || status === HTTP.CONFLICT
                            });

                            if (resp.status === HTTP.CONFLICT) {
                                toast({
                                    title: ti(
                                        "xWG {{name}} version {{version}} already exists in given categories.",
                                        {
                                            ns: "review",
                                            name: xwg.name,
                                            version: xwg.version
                                        }
                                    ),
                                    status: "error",
                                    isClosable: true
                                });
                            } else {
                                toast({
                                    title: ti(
                                        "xWG {{name}} version {{version}} has been published.",
                                        {
                                            ns: "review",
                                            name: xwg.name,
                                            version: xwg.version
                                        }
                                    ),
                                    status: "success",
                                    isClosable: true
                                });

                                setProcessed(true);
                            }
                        }}
                    >
                        {ti("Publish", {ns: "review"})}
                    </Button>

                    <Button
                        icon={archiveIcon}
                        onClick={async () => {
                            await api.patch(`/api/v2/xwg/${xwg.id}`, {
                                publish_status: "archived"
                            });

                            toast({
                                title: ti(
                                    "xWG {{name}} version {{version}} has been archived.",
                                    {
                                        ns: "review",
                                        name: xwg.name,
                                        version: xwg.version
                                    }
                                ),
                                status: "success",
                                isClosable: true
                            });

                            setProcessed(true);
                        }}
                    >
                        {ti("Archive", {ns: "review"})}
                    </Button>

                    <Button
                        icon={holdIcon}
                        onClick={async () => {
                            await api.patch(`/api/v2/xwg/${xwg.id}`, {
                                publish_status: "hold"
                            });

                            toast({
                                title: ti(
                                    "xWG {{name}} version {{version}} has been added to your holds.",
                                    {
                                        ns: "review",
                                        name: xwg.name,
                                        version: xwg.version
                                    }
                                ),
                                status: "success",
                                isClosable: true
                            });

                            setProcessed(true);
                        }}
                    >
                        {ti("Hold", {ns: "review"})}
                    </Button>
                </ButtonGroup>
            </Td>
        </Tr>
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}
            closeOnEsc={true}
            closeOnOverlayClick={true}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{ti("Images for {{name}} (version {{version}})", {ns: "review", name: xwg.name, version: xwg.version})}</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Center>
                        <img
                            src={currentModalImage?.url}
                            onLoad={() => setShowLoader(false)}
                            alt={currentModalImage?.name}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "90vh",
                                display: showLoader ? "none" : ""
                            }}
                        />
                        {showLoader && <Loader w={"8em"} m={8} borderWidth={"1em"} />}
                    </Center>
                    {currentModalImage && <Dl mt={4}>
                        <Dt>{ti("Image type:", {ns: "cwg_ops"})}</Dt>
                        <Dd>{currentModalImage && getImageBadge(currentModalImage, ti)}</Dd>
                        {["private", "other"].includes(currentModalImage.type) && <>
                            {currentModalImage?.name && <>
                                <Dt>{ti("Name:", {ns: "cwg_ops"})}</Dt>
                                <Dd>{currentModalImage.name}</Dd>
                            </>}

                            <Dt>{ti("Uploaded by:", {ns: "cwg_ops"})}</Dt>
                            <Dd><Trans ns={"cwg_ops"}><ProfileLink user={currentModalImage.user} /> at <FormattedDate date={currentModalImage.created} /></Trans></Dd>
                        </>}
                    </Dl>}
                </ModalBody>

                <ModalFooter justifyContent={"space-between"} gap={4}>
                    <Button
                        isDisabled={modalImageIndex <= 0}
                        onClick={() => {
                            setModalImageIndex(modalImageIndex - 1);
                            setShowLoader(true);
                        }}
                        icon={ChevronLeftIcon}
                    />

                    <ButtonGroup size={"xs"} variant={"secondary"} isAttached>
                        {xwg.images?.map((image, idx) => <Button
                            key={idx}
                            filter={getImageFilter(image)}
                            onClick={() => {
                                setModalImageIndex(idx);
                                setShowLoader(true);
                            }}
                            isActive={modalImageIndex === idx}
                            icon={FlatColorIconsImageFile}
                        />)}
                    </ButtonGroup>

                    <Button
                        isDisabled={modalImageIndex >= (xwg.images?.length ?? 0) - 1}
                        onClick={() => {
                            setModalImageIndex(modalImageIndex + 1);
                            setShowLoader(true);
                        }}
                        icon={ChevronRightIcon}
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>, [xwg.id, processed, isOpen, modalImageIndex, showLoader]);
};

export function ReviewQueue({items, count}: ListResponse<XwgWithId>) {
    const {t} = useTranslation("review");
    const methods = useForm<ReviewQueueBatch>();
    const api = useAPI();
    const toast = useToast();
    const [processed, setProcessed] = useState<{[key: string]: boolean}>({});

    return <FormProvider {...methods}>
        <Table>
            <Thead>
                <Tr>
                    <Th className={"td-shrink"}></Th>
                    <Th>{t("xWG", {ns: "cwg_index"})}</Th>
                    <Th>{t("Created by", {ns: "cwg_index"})}</Th>
                    <Th>{t("Category", {ns: "cwg_index"})}</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            <Tbody>
                {items.map(xwg => <ReviewQueueRow key={xwg.id} xwg={xwg} isProcessed={processed[xwg.id] ?? false} />)}
                {count === 0 && <Tr>
                    <Td colSpan={5}>
                        <Alert status={"success"}>
                            <AlertIcon />
                            <Box>
                                <AlertTitle>{t("Queue is empty")}</AlertTitle>
                                <AlertDescription>{t("Hooray! There are no items in the queue.")}</AlertDescription>
                            </Box>
                        </Alert>
                    </Td>
                </Tr>}
            </Tbody>
            <Tfoot>
                <Tr>
                    <Td colSpan={5}>
                        <HStack justifyContent={"space-between"} gap={8}>
                            {count > 0 ? <HStack gap={8}>
                                <Text fontWeight={"bold"}>{t("Process selected:")}</Text>
                                <ButtonGroup variant={"secondary"} size={"sm"}>
                                    <Button
                                        icon={publishIcon}
                                        onClick={async () => {
                                            const items = methods.getValues("items");
                                            const ids = Object.keys(items).filter(id => items[id]);

                                            await api.patch(`/api/v2/xwg`, ids.map(id => ({
                                                id,
                                                publish_status: "published"
                                            })));
                                            setProcessed((state) => ({
                                                ...state,
                                                ...Object.fromEntries(ids.map(id => [id, true]))
                                            }));
                                            toast({
                                                title: t("Selected items have been published."),
                                                status: "success",
                                                isClosable: true
                                            });
                                        }}
                                    >
                                        {t("Publish")}
                                    </Button>
                                    <Button
                                        icon={archiveIcon}
                                        onClick={async () => {
                                            const items = methods.getValues("items");
                                            const ids = Object.keys(items).filter(id => items[id]);

                                            await api.patch(`/api/v2/xwg`, ids.map(id => ({
                                                id,
                                                publish_status: "archive"
                                            })));
                                            setProcessed((state) => ({
                                                ...state,
                                                ...Object.fromEntries(ids.map(id => [id, true]))
                                            }));
                                            toast({
                                                title: t("Selected items have been archived."),
                                                status: "success",
                                                isClosable: true
                                            });
                                        }}
                                    >
                                        {t("Archive")}
                                    </Button>
                                    <Button
                                        icon={holdIcon}
                                        onClick={async () => {
                                            const items = methods.getValues("items");
                                            const ids = Object.keys(items).filter(id => items[id]);

                                            await api.patch(`/api/v2/xwg`, ids.map(id => ({
                                                id,
                                                publish_status: "hold"
                                            })));
                                            setProcessed((state) => ({
                                                ...state,
                                                ...Object.fromEntries(ids.map(id => [id, true]))
                                            }));
                                            toast({
                                                title: t("Selected items have been put on your hold list."),
                                                status: "success",
                                                isClosable: true
                                            });
                                        }}
                                    >
                                        {t("Hold")}
                                    </Button>
                                </ButtonGroup>
                            </HStack> : <Text></Text>}
                            <Text>{t("{{count}} items in queue.", {count})}</Text>
                        </HStack>
                    </Td>
                </Tr>
            </Tfoot>
        </Table>
    </FormProvider>;
}
