import {useTranslation} from "../../utils/helpers";
import {Controller, useForm} from "react-hook-form";
import {useAPI} from "../../api/api";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {BoxProps, chakra, FormControl, FormLabel, Input, InputGroup, InputRightAddon} from "@chakra-ui/react";
import {Button} from "../generic/buttons";
import SendIcon from "~icons/mdi/send";
import {ListResponse} from "../../models/response";
import {User} from "../../models/user";
import {ErrorMessage} from "../generic/form";
import {MessageFormat} from "../../models/messages";
import {
    BoldItalicUnderlineToggles,
    CreateLink,
    diffSourcePlugin,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    ListsToggle,
    markdownShortcutPlugin,
    MDXEditor,
    MDXEditorMethods,
    quotePlugin,
    Separator,
    StrikeThroughSupSubToggles,
    toolbarPlugin
} from "@mdxeditor/editor";

export type ThreadId = {
    threadId: string;
};

export type UserId = {
    userId: number;
};

export type XwgId = {
    xwgId: number;
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type MessageRecipient = (ThreadId | UserId | XwgId | {});

export type MessageData = {
    recipient?: string;
    message: string;
    subject?: string;
};

export type MessageFormProps = MessageRecipient & {
    showSubject?: boolean;
    onMessageSent?: (messageData: Omit<MessageData, "recipient"> & {threadId: string}) => void;
};

export function SendMessageForm({showSubject = true, onMessageSent, threadId, userId, xwgId, ...props}: MessageFormProps & BoxProps) {
    const {t} = useTranslation("messages");
    const {control, register, handleSubmit, formState: {isDirty, isSubmitting, isValid, errors, isSubmitSuccessful}, reset, setError} = useForm<MessageData>({
        defaultValues: {
            message: "",
            subject: "",
        }
    });
    const api = useAPI();

    const sendMessage = useCallback(async (data: MessageData) => {
        const {recipient, ...rest} = data;

        // Need to resolve recipient
        if (recipient) {
            const resp = await api.get<ListResponse<User>>("/api/v2/users/", {
                params: {
                    name: recipient,
                    attribute: ["id"]
                }
            });

            if (resp.data.count > 0) {
                userId = resp.data.items[0].id;
            } else {
                setError("recipient", {
                    type: "manual",
                    message: t("Recipient does not exists.")
                });
                return;
            }
        }

        const resp = await api.post<{id: string}>("/api/v2/messages/{threadId}", {
            subject: rest.subject,
            message: rest.message,
            format: MessageFormat.MARKDOWN
        }, {
            pathParams: {
                threadId: threadId ?? ""
            },
            params: {
                userId: userId,
                xwgId: xwgId
            }
        });

        if (onMessageSent) {
            onMessageSent({
                threadId: resp.data.id,
                ...rest
            });
        }
    }, [
        api,
        threadId,
        userId,
        xwgId
    ]);

    const editorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
            editorRef.current?.setMarkdown("");
        }
    }, [reset, isSubmitSuccessful, editorRef.current]);

    const hasTarget = !!(threadId || userId || xwgId);

    const {t: te} = useTranslation("editor");

    const bodyInputAndSubmit = useMemo(() => <InputGroup>
        <Controller
            control={control}
            name={"message"}
            defaultValue={""}
            render={({field}) => {
                return <MDXEditor
                    ref={editorRef}
                    markdown={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    translation={(key, defaultValue, interpolations) => te(key, {nsSeparator: ".", defaultValue: defaultValue, ...interpolations})}
                    plugins={[
                        linkPlugin(),
                        quotePlugin(),
                        markdownShortcutPlugin(),
                        linkDialogPlugin(),
                        diffSourcePlugin(),
                        listsPlugin(),
                        toolbarPlugin({
                            toolbarContents: () => <>
                                <BoldItalicUnderlineToggles options={['Bold', 'Italic']} />
                                <StrikeThroughSupSubToggles />
                                <Separator />
                                <CreateLink />
                                <ListsToggle />
                            </>
                        })
                    ]}
                />;
            }}
        />
        <InputRightAddon p={1}>
            <Button
                type={"submit"}
                icon={SendIcon}
                variant={"ghost"}
                isDisabled={!isDirty || isSubmitting || !isValid}
            >
                {t("Send")}
            </Button>
        </InputRightAddon>
    </InputGroup>, [control, isDirty, isSubmitting, isValid]);

    return <chakra.form onSubmit={handleSubmit(sendMessage)} {...props}>
        {!hasTarget && <FormControl>
            <FormLabel>{t("Recipient")}</FormLabel>
            <Input {...register("recipient")} isDisabled={isSubmitting}/>
            <ErrorMessage name="recipient" errors={errors}/>
        </FormControl>}

        {showSubject
            ? <>
                <FormControl>
                    <FormLabel>{t("Subject")}</FormLabel>
                    <Input {...register("subject")} isDisabled={isSubmitting}/>
                </FormControl>
                <FormControl>
                    <FormLabel>{t("Text")}</FormLabel>
                    {bodyInputAndSubmit}
                </FormControl>
            </>
            : bodyInputAndSubmit
        }
    </chakra.form>
}
