import {createFileRoute, Link} from '@tanstack/react-router'
import {useTranslation} from "../../../../utils/helpers";
import {Route as AdminIndexRoute} from "../index";
import {Route as GroupsIndexRoute} from "./index";
import React, {useCallback} from "react";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../components/generic/breadcrumb";
import {
    BreadcrumbItem,
    BreadcrumbLink,
    FormControl,
    FormLabel,
    Input,
    Radio,
    RadioGroup,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast
} from "@chakra-ui/react";
import {Controller, useForm} from "react-hook-form";
import {Group, GroupScopes} from "../../../../models/user";
import {useAPI} from "../../../../api/api";
import {TokenScope} from "../../../../models/tokenScope";
import {formToScopes, ScopeRadioValue, splitScopes} from "../users/$userId.privileges";
import {FormButtons} from "../../../../components/generic/form";
import {Button} from "../../../../components/generic/buttons";

type GroupCreate = {
    name: string;
    scopes: {
        [scope: string]: ScopeRadioValue;
    }
}

export const Route = createFileRoute('/_site/admin/groups/create')({
    component: () => {
        const {t} = useTranslation("admin_users");
        const {register, handleSubmit, control} = useForm<GroupCreate>({
            defaultValues: {
                scopes: Object.fromEntries(Object.values(TokenScope).map(scope => [scope, "-1"]))
            }
        });
        const api = useAPI();
        const toast = useToast();
        const navigate = Route.useNavigate();

        const createGroup = useCallback(async (data: GroupCreate) => {
            await api.post<any, [Group & GroupScopes]>("/api/v2/groups", [{
                name: data.name,
                scopes: splitScopes(formToScopes(data.scopes))
            }]);
            toast({
                title: t("Group has been created."),
                status: "success",
                isClosable: true
            });

            await navigate({
                to: GroupsIndexRoute.fullPath
            });
        }, [api]);

        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("Create group")}</h2>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>
                            {t("Administration", {ns: "admin"})}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={GroupsIndexRoute.fullPath}>
                            {t("Groups")}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath}>
                            {t("Create group")}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>

            <form onSubmit={handleSubmit(createGroup)}>
                <FormControl>
                    <FormLabel>{t("Name")}</FormLabel>
                    <Input
                        {...register("name", {required: true})}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>{t("Privileges")}</FormLabel>

                    <Table>
                        <Thead>
                            <Tr>
                                <Th>{t("Privilege")}</Th>
                                <Th textAlign={"center"}>{t("Unset")}</Th>
                                <Th textAlign={"center"}>{t("Yes")}</Th>
                                <Th textAlign={"center"}>{t("No")}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {Object.values(TokenScope).map((scope, idx) =>
                                <Controller
                                    control={control}
                                    name={`scopes.${scope}`}
                                    render={({field}) => (
                                        <RadioGroup
                                            key={idx}
                                            as={Tr}
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                        >
                                            <Th>{scope}</Th>
                                            <Td textAlign={"center"}>
                                                <Radio
                                                    value={"-1"}
                                                    colorScheme={"gray"}
                                                    display={"block"}
                                                />
                                            </Td>
                                            <Td textAlign={"center"}>
                                                <Radio
                                                    value={"1"}
                                                    colorScheme={"green"}
                                                    display={"block"}
                                                />
                                            </Td>
                                            <Td textAlign={"center"}>
                                                <Radio
                                                    value={"0"}
                                                    colorScheme={"red"}
                                                    display={"block"}
                                                />
                                            </Td>
                                        </RadioGroup>
                                    )}
                                />
                            )}
                        </Tbody>
                    </Table>
                </FormControl>

                <FormButtons>
                    <Button type={"submit"}>{t("Create")}</Button>
                </FormButtons>
            </form>
        </>
    }
})
