import {CurrentUser} from "../models/user";
import {Axios} from "./api";
import {AccessTokenResponse} from "../models/currentUser";

export class Unauthorized {
    constructor() {
        Object.setPrototypeOf(this, Unauthorized.prototype);
    }

    toString(): string {
        return "Unauthorized";
    }
}

export async function createShortLivedAccessToken({api, currentUser, password}: {api: Axios, currentUser: CurrentUser | null, password: string}): Promise<string> {
    if (!currentUser) {
        throw new Unauthorized();
    }

    const token = await api.post<AccessTokenResponse>(
        "/short-lived-access-token", {
            "username": currentUser.name,
            "password": password
        },
        {
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            "validateStatus": (status) => status === 200 || status === 401
        }
    )

    if (token.status === 401) {
        throw new Unauthorized();
    }

    return token.data.access_token;
}
