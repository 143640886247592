import React from "react";
import {chakra, HTMLChakraProps, omitThemingProps, ThemingProps, useStyleConfig} from "@chakra-ui/react";

export interface AnnouncementProps extends HTMLChakraProps<"div">, ThemingProps<"Announcement"> {}

export const Announcement = (props: AnnouncementProps) => {
    const style = useStyleConfig("Announcement", props);

    const {
        children,
        ...rest
    } = omitThemingProps(props);

    return (
        <chakra.div
            {...rest}
            __css={{...style}}
        >
            {children}
        </chakra.div>
    );
};

Announcement.displayName = "Announcement";
