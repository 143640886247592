import {XwgExtendedCollection} from "../../models/xwg";
import React, {useCallback, useState} from "react";
import {useAPI} from "../../api/api";
import {CollectionData, updateCollection} from "../../api/updateCollection";
import {CollectionForm} from "./collectionForm";
import {useAlert} from "../generic/alert";
import {ValidationError} from "../../utils/errors";
import {useTranslation} from "../../utils/helpers";
import {diagToForm} from "../../utils/diagToForm";
import {useToast} from "@chakra-ui/react";
import {UseFormReset, UseFormSetError} from "react-hook-form";

type CollectionFormProps = {
    xwgId: number;
    onUpdateCollection?: (collection: XwgExtendedCollection[]) => void;
    existingCollection?: XwgExtendedCollection[];
}

export const CreateCollectionForm = ({xwgId, onUpdateCollection, existingCollection}: CollectionFormProps) => {
    const [disabled, setDisabled] = useState(false);
    const {showConfirm} = useAlert();

    const api = useAPI({flash: "Saving..."});
    const {t} = useTranslation("cwg_index");
    const toast = useToast();

    const submitCallback = useCallback(async (data: CollectionData, setError: UseFormSetError<CollectionData>, reset: UseFormReset<CollectionData>) => {
        setDisabled(true);

        const needOverwrite = existingCollection && existingCollection.some(collection => collection.year == data.year && collection.date == data.date);
        if (needOverwrite) {
            const result = await showConfirm(
                t("Overwrite collection?"),
                t("Collection entry with matching year and date already exists. Continuing will overwrite that entry. Do you wish to continue?")
            );

            if (result !== "yes") {
                setDisabled(false);
                return;
            }

        }

        try {
            const newCollection = ((await updateCollection({
                api,
                data,
                xwgId: xwgId
            })) ?? []);

            toast({
                title: t(needOverwrite ? "Collection entry has been overwritten." : "Collection entry has been created."),
                status: "success"
            });

            if (onUpdateCollection) {
                onUpdateCollection(newCollection);
            }

            reset();
        } catch (e) {
            if (e instanceof ValidationError) {
                diagToForm(e.diagnostics, setError, {stripPrefix: ["body"]});
                diagToForm(e.diagnostics, setError, {stripPrefix: ["path"]});
            } else {
                console.error(e);
            }
        } finally {
            setDisabled(false);
        }
    }, [xwgId, existingCollection]);

    return <CollectionForm
        onSubmit={submitCallback}
        disabled={disabled}
    />;
}
