import React, {PropsWithChildren} from "react";
import {HeaderWithBreadcrumb} from "../generic/breadcrumb";
import {chakra} from "@chakra-ui/react";
import {PublishStatus, XwgWithId} from "../../models/xwg";
import {PublicationBadge} from "./publicationBadge";
import {Trans} from "react-i18next";

type CwgHeaderProps = {
    xwg: XwgWithId;
    showVersion?: boolean;
    publishStatus?: PublishStatus;
} & PropsWithChildren;

export function CwgHeader({xwg, showVersion, children, publishStatus}: CwgHeaderProps) {
    const resolvedPublishStatus = (publishStatus ?? xwg.publish_status) as PublishStatus;

    return <HeaderWithBreadcrumb>
        <chakra.h2 flexGrow={1} m={0}>
            <Trans
                ns={"cwg_index"}
                i18nKey={showVersion ? "{{name}} (version {{version}})" : "{{name}}"}
                values={{name: xwg.name, version: xwg.version}}
            />
            <PublicationBadge publishStatus={resolvedPublishStatus} />
        </chakra.h2>
        {children}
    </HeaderWithBreadcrumb>;
}
