import {XwgStatus} from "../../models/xwg";
import React from "react";

import collectedImg from "../../assets/static/status/collected.png";
import notWantImg from "../../assets/static/status/not_want.png";
import offersImg from "../../assets/static/status/offers.png";
import unofficialImg from "../../assets/static/status/unofficial.png";
import wantsImg from "../../assets/static/status/wants.png";
import {useTranslation} from "../../utils/helpers";
import {Box, BoxProps, Tooltip} from "@chakra-ui/react";


const CwgOverlay = ({status, unofficial, ...props}: {status?: XwgStatus, unofficial?: boolean} & BoxProps) => {
    const {t} = useTranslation("search");

    return <Box
        display={"flex"}
        position={"absolute"}
        right={0}
        top={0}
        {...props}
    >
        {status?.collected && <Tooltip label={t("You have at least one piece of this xWG in your collection.")}><img src={collectedImg} alt={t("Collected")} /></Tooltip>}
        {status?.offered && <Tooltip label={t("You offer this xWG.")}><img src={offersImg} alt={"Offer"} /></Tooltip>}
        {status?.wanted && <Tooltip label={t("You want this xWG.")}><img src={wantsImg} alt={"Want"} /></Tooltip>}
        {status?.not_wanted && <Tooltip label={t("You don't want this xWG.")}><img src={notWantImg} alt={"Don't want"} /></Tooltip>}
        {unofficial && <Tooltip label={t("xWG is not part of official catalogue")}><img src={unofficialImg} alt={t("Unofficial")} /></Tooltip>}
    </Box>
}

export default CwgOverlay;
