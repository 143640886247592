import {createFileRoute, Outlet} from '@tanstack/react-router'
import React from "react";
import {Box, Container, VStack} from "@chakra-ui/react";
import {Logo} from "../../components/logo";

export const Route = createFileRoute('/login')({
    component: () => <VStack mt={8}>
        <Box w={"30em"} maxW={"100%"}>
            <Logo />
        </Box>
        <Container bg={"shade"} borderRadius={"xl"} p={4}>
            <Outlet/>
        </Container>
    </VStack>
});
