import {XwgExtendedCollection} from "../models/xwg";
import {WithAPI} from "./api";

export type CollectionMatch = {
    year: number;
    date: string;
}

export type CollectionData = {
    year: number;
    pieces: number;
    date: string;
    comment?: string;
}

export type CollectionProps = {
    xwgId: number;
    match?: CollectionMatch;
    data: CollectionData;
} & WithAPI;

export async function updateCollection({xwgId, api, match, data}: CollectionProps): Promise<XwgExtendedCollection[]> {
    await api.put(`/api/v2/xwg/${xwgId}/collection/${match?.year ?? data.year}/${match?.date ?? data.date}`, data);
    const newCollection = await api.get<XwgExtendedCollection[]>(`/api/v2/xwg/${xwgId}/collection`);
    return newCollection.data ?? [];
}
