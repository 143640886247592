export const Languages = {
    en: {
        label: "English",
        be: "en_US"
    },
    cs: {
        label: "Česky",
        be: "cs_CZ"
    },
    pl: {
        label: "Polski",
        be: "pl_PL"
    },
    sk: {
        label: "Slovensky",
        be: "sk_SK"
    },
};

export const i18n = {
    supportedLngs: Object.keys(Languages),
    fallbackLng: "en",
    defaultNS: "default",
    ns: ["account", "admin", "api", "browse", "cookies", "cwg_history", "cwg_index", "cwg_ops", "default", "export", "forms", "import", "languages", "layout", "messages", "news", "oauth2", "overview", "problems", "profile", "review", "search", "settings", "tags", "admin_users"],
    interpolation: {
        escapeValue: false
    },
    debug: false
}
