import {createFileRoute} from '@tanstack/react-router'
import {thirdLevelIndexRoute} from "../../../../../../../utils/collectionListing";
import {findInMatchesData} from "../../../../../../../utils/findInMatchesData";
import {ExtendedTagInCategory} from "../../../../../../../models/tag";
import {getFixedT} from "../../../../../../../utils/getFixedT";
import {combineTitle} from "../../../../../../../utils/combineTitle";

export const Route = createFileRoute('/_site/cwg/browse/$primarySorting/$primaryCategoryName/$secondaryCategoryName/')({
    meta: ({matches, match}) => {
        const primaryCategoryTag = findInMatchesData<ExtendedTagInCategory>(matches, "primaryCategoryTag");
        const secondaryCategoryTag = findInMatchesData<ExtendedTagInCategory>(matches, "secondaryCategoryTag");

        const t = getFixedT("browse", match.context.request?.i18n);

        if (!primaryCategoryTag || !secondaryCategoryTag) {
            return [];
        }

        return combineTitle(matches, t(
            "{{primaryCategory}} {{secondaryCategory}} catalogue", {
                primaryCategory: primaryCategoryTag.name,
                secondaryCategory: secondaryCategoryTag.name
            }));
    },
    ...thirdLevelIndexRoute()
});
