import {createFileRoute} from '@tanstack/react-router'
import React from "react";
import Account from "../../../components/account/account";
import {loaderDeps, loadXwgs, validateSearch} from "../../../utils/collectionListing/third";
import {XwgFilter} from "../../../models/xwgSearchQuery";
import {CwgCatalogue, CwgCatalogueItems} from "../../../components/cwg/cwgCatalogueView";
import {useTranslation} from "../../../utils/helpers";
import {Alert, AlertDescription, AlertIcon} from "@chakra-ui/react";
import {TableWithImagesView} from "../../../components/cwg/tableWithImagesView";
import {OverviewTableView} from "../../../components/cwg/overviewTableView";

type SearchMode = {
    mode: "catalogue" | "images" | "table"
}

export const Route = createFileRoute('/_site/cwg-collection/duplicates')({
    component: () => {
        const {t} = useTranslation("browse");
        const {mode} = Route.useSearch();

        const views = [
            {
                name: t("Catalogue list"),
                view: CwgCatalogueItems,
                id: "catalogue"
            },
            {
                name: t("Table with images"),
                view: TableWithImagesView,
                id: "images",
            },
            {
                name: t("Overview table"),
                view: OverviewTableView,
                id: "table"
            }
        ];
        const view = views.find((v) => v.id === mode);

        const navigate = Route.useNavigate();

        return <Account title={t("Duplicates")}>
            <Alert>
                <AlertIcon />
                <AlertDescription>
                    {t("This list contains all xWGs which you have more than once in your collection. That means that you might be interrested in offering the xWGs for exchange.")}
                </AlertDescription>
            </Alert>
            <CwgCatalogue
                additionalViews={views}
                onViewChange={async (newView) => {
                    await navigate({
                        search: (prev) => ({
                            ...prev,
                            mode: newView.id as SearchMode["mode"]
                        })
                    })
                }}
                view={view}
            />
        </Account>
    },
    validateSearch: validateSearch<SearchMode>((search: Record<string, unknown>) => {
        const views = ["catalogue", "images", "table"];

        return {
            mode: (views.find((v) => v === search.mode) ?? views[0]) as SearchMode["mode"]
        }
    }),
    beforeLoad: async () => {
        return {
            filter: {
                min_collected: 2
            } as XwgFilter
        }
    },
    loaderDeps: loaderDeps<SearchMode>,
    loader: loadXwgs<SearchMode>({
        additionalDisplayOptions: ({search: {mode}}) => {
            switch (mode) {
                case "images":
                case "table":
                    return {
                        "attributes": ["collection_info"]
                    }

                default:
                    return {};
            }
        }
    })
})
