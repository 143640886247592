import {CollapsibleTabList, TabSpec} from "../generic/tabs";
import React from "react";
import {useTranslation} from "../../utils/helpers";
import {Outlet, useMatch} from "@tanstack/react-router";
import {TabPanels, Tabs} from "@chakra-ui/react";
import {useMatchData} from "../../utils/findInMatchesData";
import {ExtendedTagInCategory, TagCategory} from "../../models/tag";

export function CwgCatalogueSecondaryTabs({additionalTabs, tagRoute}: { additionalTabs?: TabSpec[], tagRoute?: () => string }) {
    const {t} = useTranslation("browse");

    const match = useMatch({strict: false});

    const secondaryCategory = useMatchData<TagCategory>("secondaryCategory");
    const secondaryCategoryTag = useMatchData<ExtendedTagInCategory>("secondaryCategoryTag");

    return <>
        <Tabs variant={"secondary"} index={secondaryCategory?.tags.findIndex(tag => tag.id == secondaryCategoryTag?.id)}>
            <CollapsibleTabList
                tabs={[
                    ...secondaryCategory?.tags.map(tag => ({
                        to: (tagRoute?.() ?? match.fullPath) as TabSpec["to"],
                        params: Object.assign({}, match.params, {secondaryCategoryName: tag.id}),
                        label: tag.name,
                        tooltip: t("{{count}} items", {count: tag.count})
                    })) ?? [],
                    ...additionalTabs ?? []
                ]}
            />
            <TabPanels>
                <Outlet />
            </TabPanels>
        </Tabs>
    </>
}
