import {createFileRoute} from '@tanstack/react-router'
import {useTranslation} from "../../utils/helpers";
import {useCurrentUser} from "../../models/user";
import {useFlash} from "../../components/generic/flash";
import {useAPI} from "../../api/api";
import React, {useCallback} from "react";
import {createShortLivedAccessToken, Unauthorized} from "../../api/shortLivedAccessToken";
import Account from "../../components/account/account";
import {
    ChangePasswordForm,
    ChangePasswordFormData,
    ChangePasswordFormMethods
} from "../../components/users/changePasswordForm";
import {getFixedT} from "../../utils/getFixedT";
import {combineTitle} from "../../utils/combineTitle";

export const Route = createFileRoute('/_site/change-password')({
    meta: ({matches, match}) => {
        const t = getFixedT("settings", match.context.request?.i18n);
        return combineTitle(matches, t("Change password"));
    },
    component: ChangePassword,
});



function ChangePassword() {
    const {t} = useTranslation("settings");

    const currentUser = useCurrentUser();
    const flash = useFlash();
    const api = useAPI();

    const changePassword = useCallback(async (data: ChangePasswordFormData, {reset, setError}: ChangePasswordFormMethods) => {
        try {
            const token = await createShortLivedAccessToken({
                api,
                currentUser,
                password: data.currentPassword
            });

            const res = await api.patch("/api/v2/users/me", {
                "password": data.newPassword
            }, {
                "headers": {
                    "Authorization": "Bearer " + token
                }
            });

            if (res.status === 200) {
                flash.success({
                    description: t("Password has been successfully changed."),
                });
                reset();
            }
        } catch (e) {
            if (e instanceof Unauthorized) {
                setError("currentPassword", {
                    "type": "incorrect",
                    "message": t("Invalid old password.")
                });
            }
        }
    }, [currentUser]);

    return <Account title={t("Change password")}>
        <ChangePasswordForm onSubmit={changePassword} />
    </Account>
}
