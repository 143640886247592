import {tableAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";
import {} from "@chakra-ui/theme-tools";

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(tableAnatomy.keys);

const variantSimple = definePartsStyle((props) => ({
    th: {
        color: "text",
        borderColor: "divider.600",
        px: 2,
        py: 1.5
    },
    td: {
        borderColor: "divider.600",
        px: 2,
        py: 1.5
    },
    thead: {
        "th, td": {
            bgGradient: "linear(to bottom, divider.300 0%, divider.500 100%)",
            color: "white",
            border: "none",
            textTransform: "none",
            letterSpacing: "normal",
            fontSize: props.size ?? "md",
            textShadow: ".1em .1em .1em rgba(0, 0, 0, 0.5)",
            "&:first-of-type": {
                borderTopLeftRadius: "md",
            },
            "&:last-of-type": {
                borderTopRightRadius: "md",
            }
        }
    },
    tbody: {
        tr: {
            _hover: {
                "th, td": {
                    backgroundColor: "shade",
                }
            }
        }
    },
    tfoot: {
        td: {
            borderBottom: "none"
        }
    }
}));

export const tableTheme = defineMultiStyleConfig({
    variants: {
        simple: variantSimple
    },
    defaultProps: {
        size: "md"
    }
});
