import React from "react";
import {createFileRoute, Link, useNavigate} from '@tanstack/react-router'
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../components/generic/breadcrumb";
import {useTranslation} from "../../../utils/helpers";
import {BreadcrumbItem, BreadcrumbLink, Code, useToast} from "@chakra-ui/react";
import {Route as ApplicationsRoute} from "./index";
import {ApplicationForm} from "../../../components/api/applicationForm";
import {useAPI} from "../../../api/api";
import {diagToForm} from "../../../utils/diagToForm";
import {CreatedAPIApplication} from "../../../models/api";
import {CopyToClipboard} from "../../../components/generic/copyToClipboard";
import {ValidationError} from "../../../utils/errors";

export const Route = createFileRoute('/_site/applications/register')({
  component: RegisterApplicationPage
})

function RegisterApplicationPage() {
    const {t} = useTranslation("api");
    const api = useAPI();
    const toast = useToast();
    const navigate = useNavigate();

    return <>
        <HeaderWithBreadcrumb>
            <h2>{t("Register application")}</h2>

            <Breadcrumb>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={ApplicationsRoute.fullPath}>{t("Applications")}</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink as={Link} to={Route.fullPath}>{t("Register application")}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </HeaderWithBreadcrumb>

        <ApplicationForm
            onSubmit={async (app, setErrors) => {
                try {
                    const resp = await api.post<CreatedAPIApplication>("/api/v2/oauth/application", app);
                    const clientSecret = resp.data.client_secret;

                    toast({
                        title: t("Application registered"),
                        description: <>
                            <p>{t("Application has been successfully registered.")}</p>
                            <p style={{fontWeight: "bold"}}>
                                {t("Client secret is:")}{" "}
                                <Code colorScheme={"green"}>
                                    {clientSecret}
                                    <CopyToClipboard
                                        variant={"ghost"}
                                        content={clientSecret}
                                    />
                                </Code>
                            </p>
                            <p>{t("Store this code securely. You won't be able to recover it.")}</p>
                        </>,
                        status: "success",
                        isClosable: true,
                        duration: null
                    });

                    await navigate({
                        to: ApplicationsRoute.fullPath,
                    });
                } catch (e) {
                    if (e instanceof ValidationError) {
                        diagToForm(e.diagnostics, setErrors, {stripPrefix: ["body"]});
                    } else {
                        throw e;
                    }
                }
            }}
        />
  </>;
}
