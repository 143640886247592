import {XwgSearchQuery} from "../models/xwgSearchQuery";
import {XwgQueryResponse, XwgWithId} from "../models/xwg";
import {WithAPI} from "./api";

type ListProps = {
    query: XwgSearchQuery,
} & WithAPI;

export async function listXwg({query, api}: ListProps): Promise<XwgQueryResponse> {
    if (!query.display) {
        query.display = {};
    }

    if (!query.filter) {
        query.filter = {};
    }

    if (query.display.attributes) {
        if (!query.display.attributes.includes("publish_status")) {
            query.display.attributes.push("publish_status");
        }

        if (!query.display.attributes.includes("id")) {
            query.display.attributes.push("id");
        }
    }

    if (query.filter.publish_status === undefined) {
        query.filter.publish_status = ["published"];
    }

    const response = await api.post<XwgQueryResponse>("/api/v2/xwg/query", query);
    return {
        items: response.data.items.map(xwg => new XwgWithId(xwg)),
        count: response.data.count,
        grid: response.headers["x-grid"]
    };
}
