import {createFileRoute, useMatch} from '@tanstack/react-router'
import {ErrorComponent} from "../../../../../../../../components/error";
import {CwgCatalogueSecondaryTabs} from "../../../../../../../../components/cwg/cwgCatalogueSecondaryTabs";
import {
    ViewAllTab
} from "../../../../../../cwg-collection/browse/$primarySorting/$primaryCategoryName/$secondaryCategoryName/route";
import { Route as RouteViewAll } from "./index";
import { Route as TagRoute } from "../$secondaryCategoryName/index";
import React from "react";

export const Route = createFileRoute(
  '/_site/users/_public_profile/notwant/$username/$primarySorting/$primaryCategoryName/all',
)({
    errorComponent: ErrorComponent,
    component: () => {
        const match = useMatch({strict: false});

        return <CwgCatalogueSecondaryTabs
            additionalTabs={[
                {
                    label: <ViewAllTab />,
                    to: RouteViewAll.fullPath,
                    params: Object.assign({}, match.params),
                }
            ]}
            tagRoute={() => TagRoute.fullPath}
        />
    },
})
