import {createFileRoute, Link} from '@tanstack/react-router'
import {useTranslation} from "../../../utils/helpers";
import React from "react";
import Account from "../../../components/account/account";
import {getAPI, useAPI} from "../../../api/api";
import {UserTag} from "../../../models/tag";
import {
    Alert,
    AlertIcon, ButtonGroup,
    Checkbox,
    Divider,
    HStack,
    Tag,
    TagCloseButton,
    TagLabel,
    Tooltip,
    useToast
} from "@chakra-ui/react";
import {useAlert} from "../../../components/generic/alert";
import {AddTagForm} from "../../../components/cwg/addTagForm";
import {Button} from "../../../components/generic/buttons";
import {Controller, useForm, useWatch} from "react-hook-form";
import {ROUTES} from "../../../constants";
import {getFixedT} from "../../../utils/getFixedT";
import {combineTitle} from "../../../utils/combineTitle";
import {RouterContext} from "../../../models/routerContext";

export const Route = createFileRoute('/_site/cwg-collection/tags')({
    meta: ({matches, match}) => {
        const t = getFixedT("tags", match.context.request?.i18n);
        return combineTitle(matches, t("Tags"));
    },
    component: UserTagsManager,
    loader: async ({context}: {context: RouterContext}) => {
        const api = getAPI(context);
        const tags = await api.get<UserTag[]>("/api/v2/tags/user/me");

        return {
            tags: tags.data
        }
    }
});

type BulkTagsForm = {
    tags: Record<string, boolean>;
};

function UserTagsManager() {
    const {t} = useTranslation("tags");
    const {tags: initialTags} = Route.useLoaderData();

    const [tags, setTags] = React.useState<UserTag[]>(initialTags);

    const {t: to} = useTranslation("cwg_ops");
    const {showConfirm} = useAlert();
    const api = useAPI();
    const toast = useToast();

    const [bulkMode, setBulkMode] = React.useState(false);

    const methods = useForm<BulkTagsForm>({defaultValues: {
        tags: {}
    }});

    const selectedTags = useWatch({
        control: methods.control,
        name: "tags"
    });

    return (
        <Account title={t("Tags")}>
            <Alert status={"info"}>
                <AlertIcon />
                {t("Here you can find list of all your tags that you assigned to some xWGs. By clicking the tag, you can filter xWGs labeled with this tag.")}
            </Alert>

            <HStack wrap={"wrap"} spacing={"6"} mt={"4"}>
                {tags.map((tag) => (
                    <Tag key={tag.id} variant={"tag"}>
                        <TagLabel><Link to={ROUTES.BROWSE_ADVANCED_WITH_FILTER} params={{filter: {tags:[tag.id]}}}>{tag.name} ({tag.count})</Link></TagLabel>
                        {bulkMode
                            ? <Controller
                                control={methods.control}
                                name={`tags.${tag.id}`}
                                render={({field}) =>
                                    <Checkbox
                                        ml={2}
                                        isChecked={!!field.value}
                                        onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                                        onChange={(e) => {
                                            field.onChange(!field.value);
                                            e.stopPropagation();
                                        }}
                                        ref={field.ref}
                                        onBlur={field.onBlur}
                                        name={field.name}
                                    />} />
                            : <Tooltip label={t("Remove tag")}>
                                <TagCloseButton onClick={async (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    if (tag.count === 0 || await showConfirm(to("Are you sure?"), t("Removing tag will clear it from all xWGs. Do you want to continue?")) === "yes") {
                                        await api.delete(`/api/v2/tags/user/me/${encodeURIComponent(tag.id)}`);
                                        const tags = await api.get<UserTag[]>("/api/v2/tags/user/me");
                                        setTags(tags.data);

                                        toast({
                                            title: t("Tag {{tag}} has been removed.", {tag: tag.name}),
                                            status: "success",
                                        });
                                    }
                                }} />
                            </Tooltip>
                        }
                    </Tag>
                ))}
            </HStack>

            <Divider mt={"6"} />

            <HStack
                justifyContent={"space-between"}
                flexWrap={"wrap"}
            >
                <AddTagForm
                    size={"md"}
                    style={{
                        maxWidth: "15em"
                    }}
                    onCreate={async (name) => {
                        await api.post<UserTag[]>("/api/v2/tags/user/me", [name]);

                        const tags = await api.get<UserTag[]>("/api/v2/tags/user/me");
                        setTags(tags.data);

                        toast({
                            title: t("Tag {{tag}} has been created.", {tag: name}),
                            status: "success",
                        });
                    }}
                />

                {bulkMode && <ButtonGroup isAttached variant={"secondary"} size={"sm"}>
                    <Button
                        onClick={() => {
                            const selection: BulkTagsForm["tags"] = {};
                            tags.forEach((tag) => {
                                selection[tag.id] = true;
                            });

                            methods.setValue("tags", selection);
                        }}
                    >
                        {t("Select all")}
                    </Button>
                    <Button
                        onClick={() => {
                            const selection: BulkTagsForm["tags"] = {};
                            tags.forEach((tag) => {
                                selection[tag.id] = tag.count === 0;
                            });

                            methods.setValue("tags", selection);
                        }}
                    >
                        {t("Select unused")}
                    </Button>
                    <Button
                        onClick={() => {
                            methods.setValue("tags", Object.fromEntries(tags.map((tag) => [tag.id, false])));
                        }}
                    >
                        {t("Clear selection")}
                    </Button>
                </ButtonGroup>}

                <HStack>
                    {bulkMode
                        ? <>
                            <Button onClick={() => setBulkMode(false)}>{t("Cancel", {ns: "forms"})}</Button>
                            <Button colorScheme={"red"} isDisabled={Object.values(selectedTags).every((value) => !value)} onClick={async () => {
                                if (await showConfirm(to("Are you sure?"), t("Remove selected tags?")) === "yes") {
                                    await api.delete(`/api/v2/tags/user/me/`, {
                                        params: {
                                            "tag": Object.keys(selectedTags).filter((key) => selectedTags[key])
                                        }
                                    });

                                    const tags = await api.get<UserTag[]>("/api/v2/tags/user/me");
                                    setTags(tags.data);

                                    toast({
                                        title: t("Selected tags has been removed."),
                                        status: "success",
                                    });
                                    methods.reset({"tags": {}});
                                }
                            }}>{t("Remove selected")}</Button>
                        </>
                        : <Button colorScheme={"red"} onClick={() => setBulkMode(true)}>{t("Bulk remove tags")}</Button>
                    }
                </HStack>
            </HStack>
        </Account>
    );
}
