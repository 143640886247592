import {createFileRoute} from '@tanstack/react-router'
import {useTranslation} from "../../../../utils/helpers";
import {TagStatistics} from "../../../../models/user";
import {PublicProfile} from "../../../../components/users/publicProfile";
import {Columns} from "../../../../components/generic/columns";
import {Dl, Dt, Dd} from "../../../../components/generic/dl";
import {FormattedNumber} from "../../../../components/generic/number";
import React, {Fragment} from "react";
import {FormattedDate} from "../../../../components/generic/date";
import {Table, Tr, Th, Tbody, Td} from "@chakra-ui/react";

import {loadUser} from "./route";
import {UserSearchForm} from "../../../../components/users/searchForm";
import {ROUTES} from "../../../../constants";

export const Route = createFileRoute('/_site/users/_public_profile/index/$username')({
    component: UserProfile,
    beforeLoad: async ({context: {attributes, ...restOfContext}}) => {
        attributes.push("id", "name", "private", "profile", "registration_date", "last_login", "total", "tag_statistics");
        return {
            attributes,
            ...restOfContext
        };
    },
    loader: loadUser,
});

function UserProfile() {
    const {t} = useTranslation("profile");
    const {t: to} = useTranslation("overview");

    const { user } = Route.useLoaderData();

    if (!user) {
        return null;
    }

    const user_tag_categories: {
        [key: string]: {
            category: { id: string, name: string },
            tags: TagStatistics[]
        }
    } = {};

    user.tag_statistics?.forEach(stat => {
        if (!user_tag_categories[stat.category.category.id]) {
            user_tag_categories[stat.category.category.id] = {
                category: stat.category.category,
                tags: []
            };
        }

        user_tag_categories[stat.category.category.id].tags.push(stat);
    });

    const navigate = Route.useNavigate();

    return <PublicProfile
        selected={Route.fullPath}
        title={t("{{user}} - profile", {user: user.name})}
        user={user}
        toolbar={<UserSearchForm
            inline
            onSearch={async (search) => {
                await navigate({
                    to: ROUTES.USER_SEARCH_WITH_QUERY,
                    params: {
                        username: search.username
                    }
                })
            }}
        />}
    >
        <Columns>
            <div>
                <h3>{t("Statistics")}</h3>
                <Dl variant={"border"}>
                    <Dt>{to("Total number of pieces:")}</Dt>
                    <Dd>
                        <FormattedNumber
                            value={user.total?.pieces}
                            approximate={user.total?.approximate}
                        />
                    </Dd>
                    <Dt>{to("Number of different xWGs:")}</Dt>
                    <Dd>
                        <FormattedNumber value={user.total?.unique} />
                    </Dd>
                </Dl>

                {user.total?.pieces > 0
                    ? <>
                        <h4>{t("Statistics splitted by categories")}</h4>

                        <Table>
                            <Tbody>
                                {Object.values(user_tag_categories).map((stat) => <Fragment key={stat.category.id}>
                                    <Tr>
                                        <Th>{stat.category.name}</Th>
                                        <Th className={"text-right"}>{t("Pieces")}</Th>
                                        <Th className={"text-right"}>{t("Unique xWGs")}</Th>
                                    </Tr>
                                    {stat.tags.map((tag) =>
                                        <Tr key={tag.category.id}>
                                            <Td style={{paddingLeft: "1.5em"}}>{tag.category.name}</Td>
                                            <Td className={"text-right"}><FormattedNumber value={tag.pieces} /></Td>
                                            <Td className={"text-right"}><FormattedNumber value={tag.unique} /></Td>
                                        </Tr>
                                    )}
                                </Fragment>)}
                            </Tbody>
                        </Table>
                    </>
                    : null
                }

                <h3>{t("User")}</h3>
                <Dl variant={"border"}>
                    <Dt>{t("Registration date:")}</Dt>
                    <Dd><FormattedDate date={user.registration_date} /></Dd>

                    <Dt>{t("Last login:")}</Dt>
                    <Dd><FormattedDate date={user.last_login} /></Dd>
                </Dl>
            </div>
            <div dangerouslySetInnerHTML={{__html: user.profile}} />
        </Columns>
    </PublicProfile>
}
