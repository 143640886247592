import {XwgStatus, XwgWithId} from "../models/xwg";
import {WithAPI} from "./api";

type ToggleOpProps = {
    xwg: XwgWithId;
} & WithAPI;

export const cwgOps = {
    toggleCollection: async ({xwg, api}: ToggleOpProps): Promise<XwgStatus> => {
        return (await api.post<XwgStatus>(`/api/v2/xwg/${xwg.id}/collection/toggle`)).data;
    },

    toggleOffers: async ({xwg, api}: ToggleOpProps) => {
        return (await api.post<XwgStatus>(`/api/v2/xwg/${xwg.id}/offer/toggle`)).data;
    },

    toggleWants: async ({xwg, api}: ToggleOpProps) => {
        return (await api.post<XwgStatus>(`/api/v2/xwg/${xwg.id}/want/toggle`)).data;
    },

    toggleNotWant: async ({xwg, api}: ToggleOpProps) => {
        return (await api.post<XwgStatus>(`/api/v2/xwg/${xwg.id}/not-want/toggle`)).data;
    },
}
