import {rgba} from "polished";
import React from "react";
import {NotFoundError} from "../utils/notFoundError";
import {Forbidden as ForbiddenError} from "../utils/errors";
import {useTranslation} from "../utils/helpers";
import {useCurrentUser} from "../models/user";
import {Trans} from "react-i18next";
import theme from "../style/cwg";
import {css, Global, useTheme} from "@emotion/react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    BoxProps,
    Button,
    Center,
    chakra,
    ChakraProvider,
    Container,
    cssVar,
    Flex,
    ListItem,
    Spacer,
    Tooltip,
    UnorderedList
} from "@chakra-ui/react";
import IconParkOutlineErrorComputer from '~icons/icon-park-outline/error-computer';
import IconCopy from "~icons/lucide/clipboard-copy";
import {Link, useNavigate} from "@tanstack/react-router";
import {ApiError, errorFactory, UserError} from "../utils/errors";
import {TanStackRouterDevtools} from "@tanstack/router-devtools";
import {ROUTES} from "../constants";
import {useMatchData} from "../utils/findInMatchesData";
import {ApplicationData} from "../models/routerContext";

export function Error(props: BoxProps) {
    return <Box
        {...props}
        bg={rgba("#F4D7D1", .5)}
        color={"#A12206"}
        p={2}
        border={"solid 1px #A12206"}
        borderRadius={1}
    />;
};

export function RequestError({error}: {error: ApiError | string}) {
    const theme = useTheme();

    return <Error>
        <Flex>
            <Box>
                <h3 style={{color: "inherit"}}>Error: {(typeof error === "object") ? error.statusText : error.toString()}</h3>
                {typeof error === "object" && <>
                    <p>
                        <b>Request identifier:</b>{" "}
                        <span
                            style={{
                                display: "inline-flex",
                                gap: ".5em",
                                alignItems: "center",
                                border: `solid 1px ${theme.colors.divider}`,
                                padding: ".2em .5em",
                                borderRadius: ".2em",
                                background: "rgba(0,0,0,.08)"
                            }}
                        >
                            <code style={{fontWeight: "bold"}}>
                                {error.grid}
                            </code>
                            <Tooltip label={"Copy to clipboard"}>
                                <Button
                                    variant={"ghost"}
                                    size={"sm"}
                                    onClick={() => {
                                        if (navigator.clipboard) {
                                            navigator.clipboard.writeText(error.grid);
                                        }
                                    }}
                                >
                                    <IconCopy/>
                                </Button>
                            </Tooltip>
                        </span>
                    </p>

                    {typeof error.payload === "object" && <>
                        <chakra.h4 color="inherit">Error payload:</chakra.h4>
                        <chakra.pre overflow={"auto"}>{JSON.stringify(error.payload, null, 2)}</chakra.pre>
                    </>}
                </>}
            </Box>
            <Spacer/>
            <Box p={3}>
                <IconParkOutlineErrorComputer height={"3em"} width={"3em"}/>
            </Box>
        </Flex>
    </Error>;
}

type WithStack = {
    stack: string;
}

type WithCause = {
    cause?: unknown;
}

function CausedBy({error}: {
  error: WithCause
}) {
    const cause = error.cause;

    if (!cause) {
        return null;
    }

    return <Box mt={4}>
        <chakra.h3 color={"inherit"}>Caused by:</chakra.h3>
        <chakra.pre overflowX={"auto"}>{JSON.stringify(cause, undefined, 2)}</chakra.pre>
        {(cause as WithStack).stack && <>
            <chakra.h4 color={"inherit"} mt={4}>Stack trace:</chakra.h4>
            <chakra.pre overflowX={"auto"}>{(cause as WithStack).stack}</chakra.pre>
        </>}

        <CausedBy error={cause as Error} />
    </Box>;
}

export function ErrorComponent({error: errorObj, reset}: {error: Error | ApiError | object, reset?: () => void}) {
    const error = errorFactory(errorObj);

    if (error instanceof ApiError) {
        return <RequestError error={error} />;
    }

    if (error instanceof UserError) {
        return <Error>
            <chakra.h3 m={0}>{error.message}</chakra.h3>
        </Error>;
    }

    if (error instanceof NotFoundError) {
        return <NotFound />;
    }

    if (error instanceof ForbiddenError) {
        return <Forbidden />;
    }

    return <Error>
        <Flex>
            <Box>
                <chakra.h3 color={"inherit"}>Application error</chakra.h3>
                {error instanceof ApiError
                    ? <>
                        <p style={{fontWeight: "bold"}}>Something went wrong.</p>
                        <p style={{marginTop: ".5em"}}>Request identifier: <code>{error.grid}</code> <IconCopy /></p>
                    </>
                    : <p style={{fontWeight: "bold"}}>Whoa, you hit an error in frontend component.</p>
                }
        </Box>
        <Spacer/>
        <Box p={3}>
                <IconParkOutlineErrorComputer height={"3em"} width={"3em"} onClick={() => {
                    reset?.();
                }} />
            </Box>
        </Flex>
        <Box mt={3} fontSize={"2xs"}>
            {(error as WithStack).stack && <>
                <chakra.h4 color={"inherit"}>Stack trace:</chakra.h4>
                <chakra.pre overflowX={"auto"}>{(error as WithStack).stack}</chakra.pre>
            </>}
            <CausedBy error={error} />
        </Box>
    </Error>;
}

export function NotFound() {
    const {t} = useTranslation("layout")
    const cu = useCurrentUser();

    return <>
        <h2>{t("Error 404: Page was not found")}</h2>
        <p>{t("Page, you are trying to open, does not exists.")}</p>
        <p>{t("What are you looking for?")}</p>
        <UnorderedList mt={2}>
            <ListItem>
                <strong>{t("Specific xWG")}</strong>{" - "}
                <Trans
                    defaults={"Link to this xWG is probably outdated and the xWG has been removed or did not existed. To find " +
                    "correct xWG, use <0>{{search}}</0>."}
                    values={{"search": t("Search")}}
                    components={[<Link to={ROUTES.XWG_SEARCH}>{t("Search")}</Link>]}
                    ns={"layout"}
                />
            </ListItem>
            <ListItem>
                <strong>{t("User profile")}</strong>{" - "}
                <Trans
                    defaults={"The user you are looking for is probably gone from the system. It is possible, that he didn't " +
                    "have account here, or that he renamed himself. You can try to find use using <0>{{search}}</0>, which is " +
                    "accessible using the Search page or on the right top side of each user's profile."}
                    values={{"search": t("user search")}}
                    components={[<Link to={"/users/search"}>{t("user search")}</Link>]}
                />
            </ListItem>
            <ListItem>
                <strong>{t("Something else")}</strong>{" - "}
                {t("Link to this page is probably no longer valid. It is possible, that the structure of pages has " +
                    "changed or author of the link did a typo. In that case, try to select one of options from the " +
                    "top menu.")}
            </ListItem>
        </UnorderedList>

        {!cu && <p><Trans
            defaults={"It is also possible, that the page is available only after you log in. To log in, click <0>{{login}}</0>."}
            values={{"login": t("here")}}
            components={[<Link to={"/"}>{t("here")}</Link>]}
        /></p>}
    </>;
}

export function Forbidden() {
    const {t} = useTranslation("layout")

    return <Alert status={"error"}>
        <AlertIcon />
        <Box>
            <AlertTitle>{t("Error 403: Forbidden")}</AlertTitle>
            <AlertDescription>{t("You are not allowed to access this page.")}</AlertDescription>
        </Box>
    </Alert>;
}

export function RootErrorComponent({error: errorObj, reset}: {error: Error | ApiError, reset?: () => void}) {
    const colorsShade = cssVar("chakra-colors-shade");
    const {t} = useTranslation("layout")
    const navigate = useNavigate();
    const applicationData = useMatchData<ApplicationData>("applicationData");

    return <>
        <ChakraProvider theme={theme}>
            <Global styles={css`
                html, body, #root {
                    height: 100%;
                }
                
                body, #root {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            `} />
            <Container
                maxW={"container.lg"}
                bg={colorsShade.reference}
                p={"3"}
                mt={8}
                mb={8}
                borderRadius={"xl"}
            >
                <ErrorComponent error={errorObj} />

                <Center mt={"3"}>
                    <Button
                        variant={"ghost"}
                        onClick={async () => {
                            reset?.();
                            await navigate({
                                to: "/",
                                replace: true
                            });
                        }}
                    >
                        {t("Home")}
                    </Button>
                    <Button
                        variant={"ghost"}
                        onClick={async () => {
                            reset?.();
                            await navigate({
                                to: ".",
                                replace: true,
                            });
                        }}
                    >
                        {t("Try again")}
                    </Button>
                </Center>
            </Container>
            {applicationData?.environment?.toLowerCase() !== "production" && <TanStackRouterDevtools position="bottom-right" />}
        </ChakraProvider>
    </>;
}

export function RootNotFound() {
    return <>
        <Container
            maxW={"container.lg"}
            bg={"shade"}
            p={3}
            mt={8}
            mb={8}
            borderRadius={"xl"}
        >
            <NotFound />
        </Container>
    </>
}

export default Error;
