import {UserSearch} from "../../models/user";
import {useTranslation} from "../../utils/helpers";
import {useForm} from "react-hook-form";
import React, {useId} from "react";
import {FormControl, FormLabel, Input, chakra} from "@chakra-ui/react";
import {FormButtons} from "../generic/form";
import {Button} from "../generic/buttons";

export function UserSearchForm({onSearch, inline}: {onSearch?: (search: UserSearch) => void, inline?: boolean}) {
    const {t} = useTranslation("search");
    const {register, handleSubmit} = useForm<UserSearch>();

    const userNameId = useId();

    return (
        <chakra.form
            onSubmit={handleSubmit(onSearch ?? (() => {}))}
            display={inline ? "flex" : "block"}
            alignItems={inline ? "center" : undefined}
            gap={inline ? 3 : undefined}
        >
            <FormControl
                display={inline ? "flex" : undefined}
                m={inline ? 0 : undefined}
                alignItems={inline ? "center" : undefined}
                gap={inline ? 3 : undefined}
            >
                <FormLabel htmlFor={userNameId} whiteSpace={inline ? "nowrap" : undefined} m={inline ? 0 : undefined}>{t("Search user")}:</FormLabel>
                <Input id={userNameId} type="text" {...register("username")} size={inline ? "sm" : undefined} />
            </FormControl>
            <FormButtons
                m={0}
            >
                <Button type={"submit"}>{t("Search")}</Button>
            </FormButtons>
        </chakra.form>
    );
}
