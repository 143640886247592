import {Breadcrumb as ChakraBreadcrumb, BreadcrumbProps} from "@chakra-ui/react";
import ChevronRightIcon from "~icons/entypo/chevron-small-right";
import styled from "@emotion/styled";

export function Breadcrumb(props: Omit<BreadcrumbProps, "separator">) {
    return <ChakraBreadcrumb separator={<ChevronRightIcon />} {...props} />
}

export const HeaderWithBreadcrumb = styled.header`
    & > h2 {
        margin-bottom: 0;
    }
    
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    .chakra-breadcrumb {
        flex-basis: 100%;
    }
    
    margin-bottom: 1em;
`;
