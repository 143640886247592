import {createFileRoute, Link} from '@tanstack/react-router'
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../components/generic/breadcrumb";
import React from "react";
import {BreadcrumbItem, BreadcrumbLink, useToast} from "@chakra-ui/react";
import {useTranslation} from "../../../../utils/helpers";
import {NewsForm} from "../../../../components/news/form";
import {useAPI} from "../../../../api/api";
import {CreateNews, News} from "../../../../models/news";
import {Route as AdminIndexRoute} from "../index";
import {Route as NewsIndexRoute} from "./index";

export const Route = createFileRoute('/_site/admin/news/create')({
    component: () => {
        const {t} = useTranslation("news");
        const api = useAPI();
        const nav = Route.useNavigate();
        const toast = useToast();

        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("Create news")}</h2>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>{t("Administration", {ns: "admin"})}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={NewsIndexRoute.fullPath}>{t("News")}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath}>{t("Create")}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>

            <NewsForm onSubmit={async (data: CreateNews) => {
                const resp = await api.post<News[]>("/api/v2/news", [data]);

                toast({
                    title: t("News has been successfully created."),
                    status: "success",
                    isClosable: true
                });

                await nav({
                    to: "../modify/$id",
                    params: {
                        id: resp.data[0].id
                    }
                });
            }} />
        </>
    }
})
