import {useTranslation as useTranslationOrig, UseTranslationOptions, UseTranslationResponse} from "react-i18next";
import {type KeyPrefix, type Namespace, TFunction} from "i18next";

export const isServer: boolean = !(typeof window !== 'undefined')

export function useTranslation<Ns extends Namespace, KPrefix extends KeyPrefix<Ns>>(
  ns?: Ns,
  options?: UseTranslationOptions<KPrefix>,
): UseTranslationResponse<Ns, KPrefix> {
    const {
        t: tFunc,
        ...other
    } = useTranslationOrig(ns, options);

    return {
        t: ((key: string | string[], options?: UseTranslationOptions<KPrefix>) => {
            return tFunc(
                // @ts-ignore
                key,
                Object.assign({nsSeparator: ""}, options)
            );
        }) as TFunction<Ns, KPrefix>,
        ...other
    }
}
