import {createFileRoute, Link, redirect} from '@tanstack/react-router'
import {useCurrentUser} from "../../models/user";
import React from "react";
import {OAuthParams, Route as LoginRoute} from "./index";
import {Button, chakra} from "@chakra-ui/react";
import {FormButtons} from "../../components/generic/form";
import {Application} from "../../api/apiApplicationDetail";
import {getAPI} from "../../api/api";
import {Todo} from "../../components/todo";
import {UserError} from "../../utils/errors";
import {NotFoundError} from "../../utils/notFoundError";
import {Trans, useTranslation} from "react-i18next";

export const Route = createFileRoute('/login/authorize')({
    beforeLoad: async ({context, search}) => {
        if (!search.client_id) {
            throw new UserError("Missing required 'client_id' argument.");
        }

        if (!context.currentUser.currentUser) {
            throw redirect({
                to: LoginRoute.fullPath,
                search: search
            });
        }
    },
    loaderDeps: ({search}) => search,
    loader: async ({context, deps: search}) => {
        const api = getAPI(context);
        try {
            const application = (await api.get<Application>(`/api/v2/oauth/application/{client_id}`, {
                pathParams: {
                    client_id: search.client_id
                }
            })).data;

            return {
                application
            }
        } catch (e) {
            if (e instanceof NotFoundError) {
                throw new UserError("Invalid 'client_id' argument.");
            }

            throw e;
        }
    },
    validateSearch: (raw: Record<string, string>): OAuthParams => {
        return {
            response_type: raw.response_type,
            client_id: raw.client_id,
            redirect_uri: raw.redirect_uri,
            state: raw.state,
            scope: raw.scope,
        }
    },
    component: () => {
        const cu = useCurrentUser();
        const {application} = Route.useLoaderData();
        const search = Route.useSearch();
        const {t} = useTranslation("account")

        const redirect_uri = new URL(search.redirect_uri ?? application.redirect_uri[0]);

        if (!cu) {
            throw new Error("User is not authorized, still, component is rendered. This is a bug.");
        }

        const username = cu.name;

        return <>
            <h2>{t("Authorize {{application}}?", {application: application.name})}</h2>

            <p>
                <Trans
                    ns={"account"}
                >
                    You are logged in as: <strong>{{username}}</strong>.
                </Trans>
            </p>

            <p>
                <Trans
                    ns={"account"}
                    values={{application: application.name}}
                >
                    Do you want to authorize <strong>{{application}}</strong> to access your data on CWG Collection?
                </Trans>
            </p>

            {application.description && <>
                <chakra.h4 mt={4}>{t("About application")}</chakra.h4>
                <p>{application.description}</p>
            </>}

            <form action={"/oauth2/authorize"} method={"post"}>
                <input type={"hidden"} name={"client_id"} value={application.id} />
                {search.state && <input type={"hidden"} name={"state"} value={search.state} />}
                <input type={"hidden"} name={"response_type"} value={search.response_type} />
                <input type={"hidden"} name={"redirect_uri"} value={redirect_uri.toString()} />

                <Todo>Need to solve how to exchange access_code / refresh_code from website, for authorization_code for different application.</Todo>

                <FormButtons gap={4}>
                    <Button type={"submit"}>{t("Authorize")}</Button>
                    <Button type={"button"} onClick={async () => {
                        redirect_uri.searchParams.set("error", "access_denied");
                        throw redirect({
                            href: redirect_uri.toString(),
                        });
                    }}>{t("Cancel")}</Button>
                </FormButtons>

                <FormButtons>
                    <Button
                        as={Link}
                        to={LoginRoute.fullPath}
                        search={{...search, force: true}}
                    >
                        {t("Sign in as a different user")}
                    </Button>
                </FormButtons>
            </form>
        </>
    }
})
