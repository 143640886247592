import {createFileRoute, Link} from '@tanstack/react-router'
import {
    Box,
    BreadcrumbItem,
    BreadcrumbLink,
    Card,
    Heading,
    LinkBox,
    LinkOverlay,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {useCurrentUser} from "../../../models/user";
import React, {SVGProps} from "react";

import UserIcon from "~icons/fa-solid/user";
import GroupIcon from "~icons/fa/group";
import NewsIcon from "~icons/system-uicons/newspaper";
import ApprovingIcon from "~icons/streamline/justice-hammer-solid"
import CategoriesIcon from '~icons/fa-regular/folder';

import {Todo} from "../../../components/todo";
import {TokenScope} from "../../../models/tokenScope";

import {Route as NewsIndexRoute} from "./news/index";
import {useTranslation} from "../../../utils/helpers";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../components/generic/breadcrumb";

export const Route = createFileRoute('/_site/admin/')({
    component: AdminIndex
});

function AdminLink({to, label, icon: Icon}: {to: string, label: React.ReactNode, icon: React.ComponentType<SVGProps<SVGSVGElement>>}) {
    return <WrapItem flex={"1 1"}>
        <LinkBox as={Card} p={4} textAlign={"center"} variant={"filled"} flex={"1"} alignSelf={"stretch"}>
            <Box mx={"auto"} my={4}>
                <Icon style={{width: "4em", height: "4em"}} />
            </Box>

            <Heading size={"md"} m={0}>
                <LinkOverlay as={Link} to={to} style={{textDecoration: "none"}}>{label}</LinkOverlay>
            </Heading>
        </LinkBox>
    </WrapItem>
}

function AdminIndex() {
    const user = useCurrentUser();
    const {t} = useTranslation("admin");

    if (!user) {
        return null
    }

    return <>
        <HeaderWithBreadcrumb>
            <h2>{t("Administration")}</h2>
            <Breadcrumb>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={Route.fullPath}>{t("Administration")}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </HeaderWithBreadcrumb>

        <Wrap spacing={4}>
            {user.has(TokenScope.ADMIN_USERS) && <AdminLink to={"/admin/users"} label={<>{t("Users", {ns: "admin_users"})}</>} icon={UserIcon} />}
            {user.has(TokenScope.ADMIN_GROUPS) && <AdminLink to={"/admin/groups"} label={<>{t("Groups", {ns: "admin_users"})}</>} icon={GroupIcon} />}
            {user.has(TokenScope.ADMIN_NEWS) && <AdminLink to={NewsIndexRoute.fullPath} label={t("News", {ns: "news"})} icon={NewsIcon} />}
            {user.has(TokenScope.ADMIN_PUBLISH) && <AdminLink to={"/admin/approving"} label={t("Approving", {ns: "review"})} icon={ApprovingIcon} />}
            {user.has(TokenScope.ADMIN_TAGS) && <AdminLink to={"/admin/categories"} label={<>{"Categories"}<Todo /></>} icon={CategoriesIcon} />}
        </Wrap>
    </>
}
