import {XwgStatus, XwgWithId} from "../../models/xwg";
import React from "react";
import {
    autoUpdate,
    flip,
    FloatingFocusManager,
    FloatingPortal,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole
} from "@floating-ui/react";
import {CwgRow} from "./row";
import {StateShifter} from "./stateShifter";
import {Box, BoxProps, chakra} from "@chakra-ui/react";

export type CwgPopupProps = {
    isOpen: boolean;
    onClose: () => void;
    onStatusChange?: (newStatus: XwgStatus) => void;
    xwg?: XwgWithId;
    status?: XwgStatus;
}

export function Popup({
    isOpen, onClose, onStatusChange, xwg, status, ...props
}: CwgPopupProps & BoxProps) {
    const { refs, floatingStyles, context } = useFloating({
        placement: "right",
        open: isOpen,
        onOpenChange: onClose,
        middleware: [
            offset(({rects}) => {
                return {
                    mainAxis: -rects.reference.width - 1,
                    crossAxis: rects.floating.height / 2 - 1,
                };
            }),
            shift(),
            flip(),
        ],
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const { getFloatingProps } = useInteractions([
        click, dismiss, role
    ]);

    if (!xwg || !status) {
        return null;
    }

    return <>
        <div ref={refs.setReference}></div>
        {isOpen && <FloatingPortal>
            <FloatingFocusManager context={context} modal={true}>
                <Box
                    border={"solid 1px"}
                    borderColor={"divider.500"}
                    borderRadius={"lg"}
                    boxShadow={"lg"}
                    bgColor={"popup"}
                    display={"flex"}
                    flexDirection={"column"}
                    cursor={"initial"}
                    p={2}
                    outline={"none"}
                    maxW={"90vw"}
                    zIndex={3}

                    {...props}
                    {...getFloatingProps()}
                    ref={refs.setFloating}
                    style={floatingStyles}
                    onClick={(e) => { e.stopPropagation(); }}
                >
                    <main>
                        <CwgRow xwg={xwg} onCloseRequest={onClose} />
                    </main>
                    <chakra.footer alignSelf={"center"}>
                        {xwg && xwg.status && <StateShifter
                            size={"md"}
                            xwg={xwg}
                            status={status}
                            onStatusChange={onStatusChange}
                        />}
                    </chakra.footer>
                </Box>
            </FloatingFocusManager>
        </FloatingPortal>}
    </>
}

export default Popup;
