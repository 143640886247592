import {Trans} from "react-i18next";
import React, {Fragment} from "react";
import textbg from "../assets/static/textbg.jpg";
import {chakra, HTMLChakraProps, useTheme} from "@chakra-ui/react";
import {useTranslation} from "../utils/helpers";

export function Logo(props: HTMLChakraProps<"svg">) {
    const theme = useTheme();
    const {t} = useTranslation("layout");

    return <chakra.svg
        xmlns={"http://www.w3.org/2000/svg"}
        w={"100%"}
        flexBasis={{base: "20em", lg: "60%"}}
        height={{base: "3em", lg: "7em"}}
        {...props}
    >
        <filter id={"shadow"}>
            <feOffset
                dx={1}
                dy={1}
            />
            <feGaussianBlur
                stdDeviation={1}
                result={"offset-blur"}
            />
            <feComposite
                operator={"out"}
                in={"SourceGraphic"}
                in2={"offset-blur"}
                result={"inverse"}
            />
            <feFlood
                floodColor={"black"}
                floodOpacity={.95}
                result={"color"}
            />
            <feComposite
                operator={"in"}
                in={"color"}
                in2={"inverse"}
                result={"shadow"}
            />
            <feComposite
                operator={"over"}
                in={"shadow"}
                in2={"SourceGraphic"}
            />
        </filter>
        <style>{`
                    .h1 {
                        font: 900 2.5em 'Lato', sans-serif;
                        color: ${theme.colors.text};
                        transform: skewX(-2deg) scale(1, .9);

                        tspan:first-child {
                            font-size: 2em;
                        }
                    }

                    .subtitle {
                        font: 700 .45em 'Lato', sans-serif;
                    }

                    @media (max-width: ${theme.breakpoints.lg}) {
                        .h1 {
                            font-size: 2em;
                            transform: skewX(-2deg) scale(1, .9) translate(0, -.4em);

                            tspan:first-child {
                                font-size: 1.5em;
                            }
                        }

                        .subtitle { display: none }
                    }
                `}</style>
        <clipPath id={"text"}>
            <text textAnchor={"end"} x={"100%"} y={"1.8em"} className={"h1"}>
                {t("CWG Collection").split(" ").map((word, idx) =>
                    <tspan key={idx} dx={idx > 0 ? "1em" : "-.5em"}>{word}</tspan>
                )}
                <tspan
                    className="subtitle"
                    x={"100%"}
                    dy={"1.5em"}
                    textAnchor={"end"}
                >
                    <Trans
                        defaults={"Website for <0>xWG</0> collection catalogization."}
                        components={[<Fragment/>]}
                        ns={"layout"}
                    />
                </tspan>
            </text>
        </clipPath>
        <defs>
            <pattern id={"textbg"} patternUnits={"userSpaceOnUse"} width={"200"} height={"200"}>
                <image href={textbg} dx={"50%"}/>
            </pattern>
        </defs>
        <g filter={"url(#shadow)"} width={"100%"} height={"100%"}>
            <rect clipPath={"url(#text)"} width={"100%"} height={"100%"} fill={"url(#textbg)"}/>
        </g>
    </chakra.svg>
}
