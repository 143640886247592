import React, {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {Link, NavigateOptions, useLocation} from "@tanstack/react-router";
import IconMdiDotsHorizontal from "~icons/mdi/dots-horizontal";
import {
    Box,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tab as ChakraTab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    TabsProps,
    Tooltip
} from "@chakra-ui/react";

type TabSpec = {
    label: string | ReactNode;
    tooltip?: string;
} & NavigateOptions;

export function CollapsibleTabList({tabs}: {tabs: TabSpec[]}) {
    const tabList = useRef<HTMLElement>(null);
    const [overflowAfter, setOverflowAfter] = useState<number | null>(null);

    useEffect(() => {
        setOverflowAfter(null);
    }, [tabs]);

    useEffect(() => {
        if (!tabList.current || overflowAfter !== null) {
            return;
        }

        const tabItems = Array.from(tabList.current.children) as HTMLElement[];
        const maxWidth = tabList.current.clientWidth;

        const allTabsWidth = tabItems.reduce((acc, item) => acc + item.clientWidth, 0);

        if (allTabsWidth > maxWidth) {
            let accumulatedTabWidth = 0;

            for (let i = 0; i < tabItems.length; i++) {
                const item = tabItems[i];

                if (item) {
                    if (item.offsetWidth + accumulatedTabWidth >= maxWidth) {
                        setOverflowAfter(i - 1);
                        break;
                    } else {
                        accumulatedTabWidth += item.offsetWidth;
                    }
                }
            }
        }
    }, [tabList.current, tabs, overflowAfter]);

    return useMemo(() => {
        return <TabList ref={tabList} overflow={"hidden"}>
            {(overflowAfter === null ? tabs : tabs.slice(0, overflowAfter)).map(({tooltip, label, ...nav}, index) =>
                tooltip
                    ? <Tooltip label={tooltip} key={index}><ChakraTab as={Link} {...nav}>{label}</ChakraTab></Tooltip>
                    : <ChakraTab as={Link} {...nav} key={index}>{label}</ChakraTab>
            )}
            {overflowAfter !== null &&
                <ChakraTab as={Box}>
                    <Menu isLazy>
                        <MenuButton>
                            <IconMdiDotsHorizontal />
                        </MenuButton>
                        <MenuList>
                            {tabs.slice(overflowAfter).map(({tooltip, label, ...nav}, index) =>
                                <MenuItem key={index} as={Link} {...nav} command={tooltip}>{label}</MenuItem>
                            )}
                        </MenuList>
                    </Menu>
                </ChakraTab>
            }
        </TabList>
    }, [tabs, overflowAfter]);
}

type NavigableTabSpec = {
    label: string;
    href: string;
    element: ReactNode;
}

type NavigableTabsProps = {
    tabs: NavigableTabSpec[];
} & Omit<TabsProps, "index" | "onChange" | "children">;

function NavigableTabs({tabs, ...props}: NavigableTabsProps) {
    const { hash } = useLocation();
    const [selected, setSelected] = useState<number>(0);

    useEffect(() => {
        if (hash) {
            setSelected(tabs.findIndex((tab) => tab.href === hash));
        }
    }, [hash]);

    return <Tabs
        {...props}
        index={selected}
        onChange={(index) => {
            window.location.hash = tabs[index].href;
        }}
    >
        <TabList>
            {tabs.map((tab, index) =>
                <ChakraTab key={index}>
                    {tab.label}
                </ChakraTab>
            )}
        </TabList>
        <TabPanels>
            {tabs.map((tab, index) => (
                <TabPanel key={index}>
                    {tab.element}
                </TabPanel>
            ))}
        </TabPanels>
    </Tabs>
}

export {
    NavigableTabs,
    type TabSpec,
    type NavigableTabSpec,
    type NavigableTabsProps,
}
