import {createFileRoute, useMatch} from '@tanstack/react-router'
import React from "react";
import {ViewAllTab, Route as TagRoute} from "../$secondaryCategoryName/route";
import {Route as RouteViewAll} from "./index";
import {ErrorComponent} from "../../../../../../../components/error";
import {CwgCatalogueSecondaryTabs} from "../../../../../../../components/cwg/cwgCatalogueSecondaryTabs";

export const Route = createFileRoute(
  '/_site/cwg-collection/browse/$primarySorting/$primaryCategoryName/all',
)({
    errorComponent: ErrorComponent,
    component: () => {
        const match = useMatch({strict: false});

        return <CwgCatalogueSecondaryTabs
            additionalTabs={[
                {
                    label: <ViewAllTab />,
                    to: RouteViewAll.fullPath,
                    params: Object.assign({}, match.params),
                }
            ]}
            tagRoute={() => TagRoute.fullPath}
        />
    },
});
