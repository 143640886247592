import {Type} from "../api/api";
import {AxiosResponse} from "axios";
import {DiagnosticError, Diagnostics, Loc} from "../models/error";
import {getFixedT} from "./getFixedT";
import _ from "lodash";

export interface WithGRID {
    grid: string | undefined;
}

export interface KnownError extends WithGRID {
    __type: string;
}

export class NotFoundError implements KnownError {
    __type = "NotFoundError";
    message: string;
    cause: unknown
    grid: string | undefined;

    constructor(message: string = "Not found", grid?: string, cause?: unknown) {
        this.grid = grid;
        this.message = message;
        this.cause = cause;
    }
}

export class UserError implements KnownError {
    __type: string = "UserError";
    grid: string;
    message: string;

    constructor(message: string, grid?: string) {
        this.message = message;
        this.grid = grid ?? "";
    }
}

export class Forbidden extends UserError {
    __type: string = "Forbidden";

    constructor(message: string | null = null, grid?: string) {
        const t = getFixedT("layout");
        super(message ?? t("You don't have permission to access this page."), grid);
    }
}

export class ApiError<T = any> implements KnownError {
    status: number;
    statusText: string;
    grid: string;
    payload: T;
    remoteApp?: string;

    __type: string = "ApiError";

    constructor(status: number, statusText: string, grid: string, payload: T, remoteApp?: string) {
        this.status = status;
        this.statusText = statusText;
        this.grid = grid;
        this.payload = payload;
        this.remoteApp = remoteApp;
    }

    toString() {
        return (
            `Request failed with status ${this.status}: ${this.statusText}.\n` +
            `GRID: ${this.grid}\n` +
            JSON.stringify(this.payload, null, 2)
        );
    }

    static create<T>(error: any, fallbackGrid?: string, remoteApp?: string) {
        if (typeof(error.response) === "object") {
            const response: AxiosResponse = error.response;

            let ResponseClass: Type<ApiError> = ApiError<T>;

            switch (response.status) {
                case 422:
                    ResponseClass = ValidationError;
                    break;

                case 404:
                    return new NotFoundError("Not found", response.headers["x-grid"] || fallbackGrid || "", error);

                case 409:
                    ResponseClass = ConflictError;
            }

            return new ResponseClass(
                response.status,
                response.statusText,
                response.headers["x-grid"] || fallbackGrid || "",
                response.data,
                remoteApp
            );
        }

        return new ApiError(0, "Request failed", fallbackGrid ?? "", error, remoteApp);
    }
}

export class ValidationError extends ApiError<Diagnostics> {
    __type: string = "ValidationError";
    diagnostics: Diagnostics;

    static payloadToDiagnostic(payload: any): Diagnostics {
        if (!payload || !payload.detail) {
            return {
                detail: []
            }
        }

        if (!Array.isArray(payload.detail)) {
            payload.detail = [
                {
                    loc: [],
                    msg: payload.detail.toString(),
                    type: "error"
                } as DiagnosticError
            ];
        }

        return payload;
    }

    constructor(status: number, statusText: string, grid: string, payload: Diagnostics | {detail: string}, remoteApp?: string) {
        const diag = ValidationError.payloadToDiagnostic(payload);
        super(status, statusText, grid, diag, remoteApp);
        this.diagnostics = diag;
    }

    extractErrors(prefix: Loc): Diagnostics {
        const result: Diagnostics = {
            detail: []
        };

        for (const error of this.diagnostics.detail) {
            if (_.isEqual(_.take(error.loc, prefix.length), prefix)) {
                result.detail.push({
                    ...error,
                    loc: error.loc.slice(prefix.length)
                });
            }
        }

        return result;
    }
}

export class ConflictError extends ValidationError {
    __type: string = "ConflictError";
}

type SerializedApiError<P> = {
    status: number;
    statusText: string;
    grid: string;
    payload: P;
    remoteApp?: string;
}

function apiErrorFactory<T, P>(cls: { new (status: number, statusText: string, grid: string, payload: P, remoteApp?: string): T }): (err: object, fallbackGrid?: string) => T {
    return (err, fallbackGrid?: string, remoteApp?: string) => new cls(
        (err as SerializedApiError<P>).status,
        (err as SerializedApiError<P>).statusText,
        (err as SerializedApiError<P>).grid || fallbackGrid || "",
        (err as SerializedApiError<P>).payload,
        (err as SerializedApiError<P>).remoteApp || remoteApp
    );
}

const errors: Record<string, (error: object, fallbackGrid?: string, remoteApp?: string) => object> = {
    "ApiError": apiErrorFactory(ApiError),
    "ValidationError": apiErrorFactory(ValidationError),
    "ConflictError": apiErrorFactory(ConflictError),
    "NotFoundError": (err, fallbackGrid) => new NotFoundError("Not found", fallbackGrid, err),
    "UserError": (err: object, fallbackGrid) => new UserError((err as UserError).message, (err as UserError).grid ?? fallbackGrid),
    "Forbidden": (err: object, fallbackGrid) => new Forbidden((err as Forbidden).message, (err as Forbidden).grid ?? fallbackGrid),
}

type WithErrorType = {
    __type: string
};

type WithRemoteApp = {
    remoteApp?: string
};

export function errorFactory(error: object, fallbackGrid?: string, remoteApp?: string): object {
    if (typeof(error) !== "object" || error === null) {
        const err = new Error("Unknown error", {cause: error});
        (err as WithRemoteApp).remoteApp = remoteApp;
        return err;
    }

    if ((error as AggregateError).name === AggregateError.name) {
        error = (error as AggregateError).errors[(error as AggregateError).errors.length - 1];
        (error as WithRemoteApp).remoteApp = remoteApp;
    }

    // error is already an instance of some kind, let's just pass it by.
    /*if (Object.getPrototypeOf(error).constructor) {
        return error;
    }*/

    if ((error as WithErrorType).__type && errors[(error as WithErrorType).__type]) {
        return errors[(error as WithErrorType).__type](error, fallbackGrid, remoteApp);
    } else if (Object.prototype.hasOwnProperty.call(error, "response")) {
        return ApiError.create(error, fallbackGrid, remoteApp);
    } else {
        const err = new Error(error.toString(), {cause: error});
        (err as WithRemoteApp).remoteApp = remoteApp;
        return err;
    }
}
