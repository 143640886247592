import * as React from "react";
import {ReactElement} from "react";
import {Languages} from "../i18n";
import {useCurrentUser} from "../models/user";

import IconGlobe from "~icons/mdi/globe";
import {useTranslation} from "../utils/helpers";

import FlagCzech from "~icons/twemoji/flag-czechia";
import FlagEnglish from "~icons/twemoji/flag-united-states";
import FlagPolish from "~icons/twemoji/flag-poland";
import FlagSlovak from "~icons/twemoji/flag-slovakia";

import ChevronDownIcon from "~icons/mdi/chevron-down";

import {
    Box,
    BoxProps,
    Button,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    Tooltip,
    useTheme
} from "@chakra-ui/react";
import {useAPI} from "../api/api";
import {Link, useRouter} from "@tanstack/react-router";
import {TokenScope} from "../models/tokenScope";

import IconMessage from '~icons/wpf/message-outline';
import {ROUTES} from "../constants";

const Flags = {
    "cs": <FlagCzech />,
    "en": <FlagEnglish />,
    "pl": <FlagPolish />,
    "sk": <FlagSlovak />
};

function LanguageSelector() {
    const {t, i18n} = useTranslation("languages");
    const api = useAPI();
    const router = useRouter();

    return <Menu>
        <Tooltip label={t("Language switcher")}>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant={"ghost"}>
                <IconGlobe />
            </MenuButton>
        </Tooltip>
        <MenuList>
            {Object.keys(Languages).map(code => <MenuItem
                key={code}
                icon={((Flags as {[key: string]: ReactElement})[code])}
                onClick={async () => {
                    await new Promise((resolve) => i18n.changeLanguage(code, resolve));
                    await api.post("/cwg-main/language", {}, {
                        params: {
                            redirect: "false",
                            language: code
                        }
                    });
                    await router.invalidate();
                }}
            >
                {Languages[(code as keyof typeof Languages)].label} ({t(code)})
            </MenuItem>)}
        </MenuList>
    </Menu>;
}

function UserInfo() {
    const currentUser = useCurrentUser();
    const {t} = useTranslation("layout");

    if (currentUser) {
        return <>
            <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant={"ghost"}>
                    {currentUser.name}
                </MenuButton>
                <MenuList>
                    <MenuItem as={Link} to={ROUTES.USER_PUBLIC_PROFILE} params={{username: currentUser.name}}>
                        {t("My public profile")}
                    </MenuItem>
                    <MenuItem as={Link} to={ROUTES.COLLECTION_BROWSE}>
                        {t("My collection")}
                    </MenuItem>
                    <MenuItem as={Link} to={ROUTES.ACCOUNT_MESSAGES}>
                        {t("My account")}
                    </MenuItem>
                    {currentUser.has(TokenScope.ADMIN_ACCESS) && <MenuItem as={Link} to={ROUTES.ADMIN}>
                        {t("Administration", {ns: "admin"})}
                    </MenuItem>}
                    <MenuItem as={"a"} href={"/action-logout"}>
                        {t("Logout")}
                    </MenuItem>
                </MenuList>
            </Menu>
        </>;
    } else {
        return null;
    }
}

export type UnreadCount = {
    unreadCount: number;
};

export const UserBox = React.forwardRef<HTMLDivElement, BoxProps & UnreadCount>(function ({unreadCount, ...props}, ref) {
    const theme = useTheme();

    return <Box
        {...props}
        ref={ref}
        position={"absolute"}
        right={0}
        top={0}
        display={"flex"}
        gap={".5em"}
        alignItems={"center"}
        background={"shade"}
        borderBottomLeftRadius={"md"}
        borderBottomRightRadius={"md"}
    >
        {unreadCount > 0 && <Button as={Link} variant={"ghost"} to={ROUTES.ACCOUNT_MESSAGES}>
            <HStack>
                <IconMessage style={{color: theme.colors.red[500]}} />
                <Text color={"red.500"}>{unreadCount}</Text>
            </HStack>
        </Button>}
        <UserInfo />
        <LanguageSelector />
    </Box>
});
