import React from "react";
import {useTranslation} from "../../utils/helpers";
import numbro from "numbro";

// @ts-ignore
import languages from "numbro/dist/languages.min";

// @ts-ignore
Object.values(languages).forEach(l => numbro.registerLanguage(l));

const FormattedNumber = ({value, approximate}: {value: number, approximate?: boolean}) => {
    const {i18n} = useTranslation();

    if (value === null || value === undefined) {
        return null;
    }

    let lng = i18n.language;
    switch (lng) {
        case "cs": lng = "cs-CZ"; break;
        case "en": lng = "en-US"; break;
        case "sk": lng = "sk-SK"; break;
        case "pl": lng = "pl-PL"; break;
    }

    numbro.setLanguage(lng);
    return <span style={{whiteSpace: "nowrap"}}>
        {numbro(value).format({
            thousandSeparated: true,
        })}
        {approximate && <>&plusmn;</>}
    </span>
}

export {
    FormattedNumber
};
