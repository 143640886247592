import {createFileRoute, Link, useRouter} from '@tanstack/react-router'
import {useTranslation} from "../../../utils/helpers";
import {useCurrentUser} from "../../../models/user";
import {getAPI, useAPI} from "../../../api/api";
import {useFlash} from "../../../components/generic/flash";
import Account from "../../../components/account/account";
import React from "react";

import {CwgCategories} from "../../../components/cwg/categories";
import {EditButton, RemoveButton} from "../../../components/generic/buttons";
import {removeXwg} from "../../../api/removeXwg";
import {NotFoundError} from "../../../utils/errors";
import {listXwg} from "../../../api/listXwg";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    ButtonGroup,
    HStack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {PublishStatus, XwgWithId} from "../../../models/xwg";
import {PublicationBadge} from "../../../components/cwg/publicationBadge";
import {validateSearch} from "../../../utils/collectionListing/third";
import Pagination from "../../../components/generic/pagination";
import {XwgOrder} from "../../../models/xwgSearchQuery";
import {CwgImage} from "../../../components/cwg/image";
import {ListResponse} from "../../../models/response";
import {LightSelect} from "../../../components/generic/select";
import {ROUTES} from "../../../constants";
import {getFixedT} from "../../../utils/getFixedT";
import {combineTitle} from "../../../utils/combineTitle";
import {RouterContext} from "../../../models/routerContext";

type PublishStatusFilter = "all" | "new" | "published" | "archived";
type PublishStatusFilterSearch = {publishStatus: PublishStatusFilter};
type SearchParams = PublishStatusFilterSearch & ReturnType<ReturnType<typeof validateSearch<PublishStatusFilterSearch>>>;

export const Route = createFileRoute('/_site/cwg/own')({
    meta: ({matches, match}) => {
        const t = getFixedT("cwg_ops", match.context.request?.i18n);
        return combineTitle(matches, t("xWGs entered by me"));
    },
    component: ProfileMyXwgs,
    validateSearch: validateSearch<PublishStatusFilterSearch>((search: Record<string, unknown>) => {
        return {
            publishStatus: (search.publishStatus ?? "all") as PublishStatusFilter
        }
    }),
    loaderDeps: ({search}) => search,
    loader: async ({context, deps: search}: {context: RouterContext, deps: SearchParams}) => {
        if (!context.currentUser.currentUser) {
            throw new NotFoundError();
        }

        let publishStatus: null | PublishStatus[] = null;
        switch (search.publishStatus) {
            case "all":
                publishStatus = null;
                break;

            case "published":
                publishStatus = ["published"];
                break;

            case "new":
                publishStatus = ["new", "hold"];
                break;

            case "archived":
                publishStatus = ["archived"];
                break;
        }

        // Load xWGs created by current user
        const api = getAPI(context);
        return {
            xwgs: await listXwg({
                api,
                query: {
                    filter: {
                        created_by: [context.currentUser.currentUser.name],
                        publish_status: publishStatus
                    },
                    display: {
                        order: [`${search.order}:${search.direction}` as XwgOrder, "name:ASC", "version:ASC"],
                        attributes: ["id", "name", "version", "tags", "collected", "images"],
                        offset: search.offset,
                        limit: search.limit
                    }
                }
            })
        }
    }
})

function ProfileMyXwgs() {
    const {t} = useTranslation("cwg_ops");
    const {xwgs} = Route.useLoaderData() as {xwgs: ListResponse<XwgWithId>};
    const navigate = Route.useNavigate();
    const currentUser = useCurrentUser();
    const api = useAPI();
    const flash = useFlash();
    const router = useRouter();
    const search = Route.useSearch();

    xwgs.items = xwgs.items.map(item => item instanceof XwgWithId ? item : new XwgWithId(item));

    return (
        <Account title={t("xWGs entered by me")}>
            <HStack mb={4}>
                <Text fontWeight={"bold"}>{t("Filter by publish status:", {ns: "browse"})}</Text>
                <LightSelect
                    instanceId={"publishStatus"}
                    isSearchable={false}
                    isClearable={false}
                    options={[
                        {value: "all", label: t("All", {ns: "browse"})},
                        {value: "published", label: t("Published", {ns: "browse"})},
                        {value: "new", label: t("Unpublished", {ns: "browse"})},
                        {value: "archived", label: t("Archived", {ns: "browse"})},
                    ] as {value: PublishStatusFilter, label: string}[]}
                    value={search.publishStatus}
                    onChange={(value) => {
                        navigate({
                            to: Route.fullPath,
                            search: (prev) => {
                                prev.publishStatus = value as PublishStatusFilter;
                                return prev;
                            }
                        })
                    }}
                />
            </HStack>

            {xwgs.count > 0 && <>
                <Table mt={4} mb={4}>
                    <Thead>
                        <Tr>
                            <Th colSpan={2}>{t("Name", {ns: "cwg_index"})}</Th>
                            <Th>{t("Category", {ns: "cwg_index"})}</Th>
                            <Th>{t("Users", {ns: "browse"})}</Th>
                            <Th className={"td-shrink text-right"}></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {xwgs.items.map(xwg => <Tr key={xwg.id}>
                            <Td className={"td-shrink"}>
                                <CwgImage h={"6em"} images={xwg.images} overlay={xwg.overlay} />
                            </Td>
                            <Td>
                                <Link to={ROUTES.XWG_INDEX} params={{xwgId: xwg.id}}>
                                    {xwg.name}
                                </Link>
                                {" "}
                                {xwg.version != "1" && t("(version {{version}})", {version: xwg.version, ns: "cwg_index"})}
                                <PublicationBadge publishStatus={xwg.publish_status as PublishStatus} />
                            </Td>
                            <Td><CwgCategories xwg={xwg} /></Td>
                            <Td>
                                {t("{{usersCount}} ({{count}}{{pm}} pieces)", {
                                    ns: "browse",
                                    usersCount: xwg.collected?.length,
                                    count: xwg.collected?.reduce((acc, user) => acc + (user.pieces || 1), 0),
                                    pm: xwg.collected?.some(collected => collected.pieces === null) ? "±" : ""
                                })}
                            </Td>
                            <Td className={"td-shrink text-right"}>
                                <ButtonGroup isAttached size={"sm"}>
                                    {xwg.canModify(currentUser) && <EditButton
                                        className={"small"}
                                        onClick={() => {
                                            navigate({
                                                to: ROUTES.XWG_MODIFY,
                                                params: {
                                                    xwgId: xwg.id
                                                }
                                            });
                                        }}
                                    />}
                                    {xwg.canRemove(currentUser) && <RemoveButton
                                        className={"small"}
                                        confirm={t("Are you sure you want to remove this xWG? This action cannot be undone.")}
                                        onClick={async () => {
                                            await removeXwg({api, xwgId: xwg.id});
                                            flash.success({description: t("xWG has been removed.")});
                                            await router.invalidate();
                                        }}
                                    />}
                                </ButtonGroup>
                            </Td>
                        </Tr>)}
                    </Tbody>
                </Table>
                <Pagination
                    offset={search.offset}
                    limit={search.limit}
                    count={xwgs.count}
                    onOffsetChange={(newOffset) => {
                        navigate({
                            to: Route.fullPath,
                            search: (prev) => {
                                prev.offset = newOffset;
                                return prev;
                            }
                        })
                    }}
                />
            </>}
            {xwgs.count === 0 && <Alert>
                <AlertIcon />
                <AlertDescription>{t("There are no xWGs matching current filter settings.", {ns: "browse"})}</AlertDescription>
            </Alert>}
        </Account>
    );
}
