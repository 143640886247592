import { createFileRoute } from '@tanstack/react-router'
import {SendMessageForm} from "../../../components/messages/sendMessage";
import {Route as ThreadRoute} from "./$threadId";
import React from "react";

export const Route = createFileRoute('/_site/messages/new')({
  component: () => {
        const navigate = Route.useNavigate();


        return <>
            <SendMessageForm onMessageSent={async (data) => {
                await navigate({
                    to: ThreadRoute.fullPath,
                    params: {
                        threadId: data.threadId
                    }
                });
            }} />
        </>;
    }
});
