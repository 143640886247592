import {PublishStatus, XwgExtendedCollection, XwgStatus, XwgWithId} from "../../models/xwg";
import {useTranslation} from "../../utils/helpers";
import {EditButton, RemoveButton} from "../generic/buttons";
import React, {useEffect, useMemo, useState} from "react";
import {useAPI} from "../../api/api";
import {useAlert} from "../generic/alert";
import {FormattedDate} from "../generic/date";
import {removeCollection} from "../../api/removeCollection";
import {CollectionEditForm} from "./collectionEditForm";
import {
    Alert, AlertDescription, AlertIcon,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Table, Thead, Tr, Th, Tbody, Td,
    TableProps
} from "@chakra-ui/react";

type CollectionDetailProps = {
    xwg: XwgWithId;
    collection: XwgExtendedCollection[];
    onUpdateCollection?: (newCollection: XwgExtendedCollection[]) => void;
    status?: XwgStatus;
    publishStatus?: PublishStatus;
} & TableProps;

const CollectionDetail = ({ xwg, status, collection, onUpdateCollection, publishStatus, ...props }: CollectionDetailProps) => {
    const {t} = useTranslation("cwg_index");
    const [editedCollection, setEditedCollection] = useState<XwgExtendedCollection | null>(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {showConfirm} = useAlert();
    const resolvedPublishStatus = (publishStatus ?? xwg.publish_status) as PublishStatus;

    useEffect(() => {
        if (editedCollection) {
            onOpen();
        } else {
            onOpen();
        }
    }, [editedCollection]);

    const api = useAPI();

    const tbl = useMemo(() =>
        <>
            {resolvedPublishStatus && resolvedPublishStatus !== "published" && <Alert status={"warning"} mb={4}>
                <AlertIcon />
                <AlertDescription>
                    {t("Collection entry is not counted in your stats until the xWG is published. When the xWG gets archived, your collection entries will be permanently removed.")}
                </AlertDescription>
            </Alert>}
            {collection?.length
            ? <Table {...props}>
                <Thead>
                    <Tr>
                        <Th className={"td-shrink"}>{t("Year")}</Th>
                        <Th className={"td-shrink"}>{t("Pieces")}</Th>
                        <Th className={"td-shrink"}>{t("Collected")}</Th>
                        <Th>{t("Note")}</Th>
                        <Th className={"td-shrink"}></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {collection.map((collection, idx) => <Tr key={idx}>
                        <Td className={"td-shrink"}>{collection.year}</Td>
                        <Td className={"td-shrink"}>{collection.pieces}</Td>
                        <Td className={"td-shrink"}><FormattedDate date={collection.date} /></Td>
                        <Td>{collection.comment}</Td>
                        <Td className={"td-shrink"}>
                            <ButtonGroup size={"sm"} isAttached>
                                <EditButton onClick={() => {
                                    setEditedCollection(collection);
                                    onOpen();
                                }} />
                                <RemoveButton onClick={async () => {
                                    if (await showConfirm(
                                        t("Remove collection?"),
                                        t("Are you sure you want to remove this collection entry?")
                                    ) == "yes") {
                                        const newCollection = await removeCollection({
                                            api,
                                            xwgId: xwg.id,
                                            match: {year: collection.year, date: collection.date}
                                        });

                                        if (onUpdateCollection) {
                                            onUpdateCollection(newCollection);
                                        }
                                    }
                                }} />
                            </ButtonGroup>
                        </Td>
                    </Tr>)}
                </Tbody>
            </Table>
            : <Alert status={"info"}>
                <AlertIcon />
                <AlertDescription>
                    {status?.collected
                        ? t("You have this xWG in your collection, but more detailed information about your collection is not available.")
                        : t("You don't have this xWG in your collection.")
                    }
                </AlertDescription>
            </Alert>}
        </>, [collection, status, xwg])

    return <>
        {tbl}
        {editedCollection && <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={true} closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("Modify collection entry")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <CollectionEditForm
                        xwgId={xwg.id}
                        value={editedCollection}
                        onCancel={onClose}
                        onUpdateCollection={(collection: XwgExtendedCollection[]) => {
                            if (onUpdateCollection) {
                                onUpdateCollection(collection);
                            }
                            onClose();
                        }}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>}
    </>
};

export default CollectionDetail;
