import {createFileRoute} from '@tanstack/react-router'
import {useTranslation} from "../../../utils/helpers";
import React from "react";
import {getAPI} from "../../../api/api";
import {listAllUsers} from "../../../api/listUsers";
import {ProfileLink} from "../../../components/users/profileLink";
import {Table, Thead, Tr, Th, Tbody, Td, Alert, AlertIcon, AlertDescription} from "@chakra-ui/react";

export const Route = createFileRoute('/_site/users/stats')({
    component: TopCollectors,
    loader: async ({context}) => {
        const api = getAPI(context);
        const users = await listAllUsers({
            api,
            query: {
                filter: {
                    hide_from_top: false,
                    min_collected: 100
                },
                display: {
                    limit: 1000,
                    order: ["count:DESC", "name:ASC"],
                    attributes: ["id", "name", "total.unique"]
                }
            }
        });

        return {
            users
        };
    }
});

function TopCollectors() {
    const {t} = useTranslation("overview");
    const {users} = Route.useLoaderData();

    return <>
        <h2>{t("Statistics of xWG collectors")}</h2>

        <Alert>
            <AlertIcon />
            <AlertDescription>
                {t("This table shows number of unique xWGs, which user has in his collection.")}
            </AlertDescription>
        </Alert>

        <Table
            maxW={"30em"}
            mx={"auto"}
            mt={4}
        >
            <Thead>
                <Tr>
                    <Th className={"td-shrink"}>{t("#")}</Th>
                    <Th>{t("Name")}</Th>
                    <Th className={"td-shrink"}>{t("Pieces")}</Th>
                </Tr>
            </Thead>
            <Tbody>
                {users.map((user, idx) => <Tr key={user.id}>
                    <Td>{idx + 1}</Td>
                    <Td><ProfileLink user={user} /></Td>
                    <Td>{user.total.unique}</Td>
                </Tr>)}
            </Tbody>
        </Table>
    </>
}
