import {defineStyleConfig} from '@chakra-ui/styled-system';

export const dividerTheme = defineStyleConfig({
    baseStyle: ({colorScheme}) => ({
        mt: "3",
        mb: "3",
        borderColor: `${colorScheme}.500`,
    }),
    defaultProps: {
        colorScheme: "divider"
    }
});
