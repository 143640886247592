import {createFileRoute, Link, useNavigate} from '@tanstack/react-router'
import {getAPI, useAPI} from "../../../api/api";
import {APIApplication, ExistingAPIApplication} from "../../../models/api";
import {useTranslation} from "../../../utils/helpers";
import {getFixedT} from "../../../utils/getFixedT";
import {combineTitle} from "../../../utils/combineTitle";
import {BreadcrumbItem, BreadcrumbLink, useToast} from "@chakra-ui/react";
import {Route as ApplicationsRoute} from "./index";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../components/generic/breadcrumb";
import {ApplicationForm} from "../../../components/api/applicationForm";
import {diagToForm} from "../../../utils/diagToForm";
import {ValidationError} from "../../../utils/errors";

export const Route = createFileRoute('/_site/applications/$clientId')({
    meta: ({matches, match}) => {
        const t = getFixedT("layout", match.context.request?.i18n);
        return combineTitle(matches, t("Applications"))
    },
    component: EditApplicationComponent,
    loader: async ({context, params}) => {
        const api = getAPI(context);
        const resp = await api.get<ExistingAPIApplication>(`/api/v2/oauth/application/${encodeURIComponent(params.clientId)}`);

        return {
            application: resp.data
        }
    }
})

function EditApplicationComponent() {
    const {t} = useTranslation("api");
    const {application} = Route.useLoaderData() as {application: ExistingAPIApplication};
    const api = useAPI();
    const toast = useToast();
    const navigate = useNavigate();

    return <>
        <HeaderWithBreadcrumb>
            <h2>{t("Edit application {{applicationName}}", {applicationName: application.name})}</h2>

            <Breadcrumb>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={ApplicationsRoute.fullPath}>{t("Applications")}</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink as={Link} to={Route.fullPath} params={{clientId: application.id}}>{t("Edit application {{applicationName}}", {applicationName: application.name})}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </HeaderWithBreadcrumb>

        <ApplicationForm
            application={application}
            onSubmit={async (update: APIApplication, setError) => {
                if ((update as ExistingAPIApplication).id) {
                    // @ts-ignore
                    delete update.id;
                }

                try {
                    await api.patch(
                        `/api/v2/oauth/application/${encodeURIComponent(application.id)}`,
                        update,
                    );

                    toast({
                        title: t("Application has been updated."),
                        status: "success",
                    });
                    console.log("Navigating to ", ApplicationsRoute.fullPath);
                    await navigate({
                        to: ApplicationsRoute.fullPath,
                    });
                } catch (e) {
                    if (e instanceof ValidationError) {
                        diagToForm(e.diagnostics, setError, {stripPrefix: ["body"]});
                    } else {
                        throw e;
                    }
                }
            }}
        />
    </>;
}
