import SelectBase, {MultiValue, Props, SingleValue, GroupBase} from "react-select";
import {rgba} from "polished";
import React from "react";
import styled from "@emotion/styled";
import {cx} from "@emotion/css";
import {useTranslation} from "../../utils/helpers";

type SelectProps<
    V,
    O extends {value: V} = {value: V},
    I extends boolean = false,
    G extends GroupBase<O> = GroupBase<O>
> = Omit<Props<O, I, G>, "value" | "defaultValue" | "onChange"> & {
    value?: I extends true ? MultiValue<O["value"]> : SingleValue<O["value"]>;
    defaultValue?: I extends true ? MultiValue<O["value"]> : SingleValue<O["value"]>;
    onChange?: (value: (I extends true ? MultiValue<O["value"]> : SingleValue<O["value"]>) | null) => void;
}

const MySelect = styled(function <Value, Option extends {value: Value} = {value: Value}, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({ value, defaultValue, options, onChange, ...props}: SelectProps<Value, Option, IsMulti, Group>) {
    const {t} = useTranslation("forms");

    let finalValue: MultiValue<Option> | SingleValue<Option> | null | undefined = undefined;
    let finalDefaultValue: MultiValue<Option> | SingleValue<Option> | null | undefined = undefined;

    if (options && value) {
        finalValue = options[props.isMulti ? "filter" : "find"]((option) => {
            if (Object.prototype.hasOwnProperty.call(option, "options")) {
                // It is group
                return false;
            }

            const ov = (option as Option).value;
            return Array.isArray(value) ? value.indexOf(ov) >= 0 : ov === value;
        }) as SingleValue<Option> | MultiValue<Option>;
    }

    if (options && defaultValue) {
        finalDefaultValue = options[props.isMulti ? "filter" : "find"]((option) => {
            if (Object.prototype.hasOwnProperty.call(option, "options")) {
                // It is group
                return false;
            }

            return (option as Option).value === value;
        }) as SingleValue<Option> | MultiValue<Option>;
    }

    let finalOnChange = undefined;
    if (onChange) {
        finalOnChange = (newValue: (IsMulti extends true ? MultiValue<Option> : SingleValue<Option>) | null) => {
            if (newValue === null) {
                onChange(null);
            } else if (Array.isArray(newValue)) {
                onChange(newValue.map((value) => value.value));
            } else {
                onChange((newValue as Option).value);
            }
        }
    }

    return <SelectBase
        placeholder={t("Select...")}
        {...props}
        value={finalValue}
        defaultValue={finalDefaultValue}
        options={options}
        onChange={finalOnChange}
        classNames={{
            clearIndicator: (p) => cx("clear-indicator", props.classNames?.clearIndicator?.(p)),
            container: (p) => cx("container", props.classNames?.container?.(p)),
            control: (p) => cx("control", props.classNames?.control?.(p)),
            dropdownIndicator: (p) => cx("dropdown-indicator", props.classNames?.dropdownIndicator?.(p)),
            group: (p) => cx("group", props.classNames?.group?.(p)),
            groupHeading: (p) => cx("group-heading", props.classNames?.groupHeading?.(p)),
            indicatorsContainer: (p) => cx("indicators-container", props.classNames?.indicatorsContainer?.(p)),
            indicatorSeparator: (p) => cx("indicator-separator", props.classNames?.indicatorSeparator?.(p)),
            input: (p) => cx("input", props.classNames?.input?.(p)),
            loadingIndicator: (p) => cx("loading-indicator", props.classNames?.loadingIndicator?.(p)),
            loadingMessage: (p) => cx("loading-message", props.classNames?.loadingMessage?.(p)),
            menu: (p) => cx("menu", props.classNames?.menu?.(p)),
            menuList: (p) => cx("menu-list", props.classNames?.menuList?.(p)),
            menuPortal: (p) => cx("menu-portal", props.classNames?.menuPortal?.(p)),
            multiValue: (p) => cx("multi-value", props.classNames?.multiValue?.(p)),
            multiValueLabel: (p) => cx("multi-value-label", props.classNames?.multiValueLabel?.(p)),
            multiValueRemove: (p) => cx("multi-value-remove", props.classNames?.multiValueRemove?.(p)),
            noOptionsMessage: (p) => cx("no-options-message", props.classNames?.noOptionsMessage?.(p)),
            option: (p) => cx("option", props.classNames?.option?.(p)),
            placeholder: (p) => cx("placeholder", props.classNames?.placeholder?.(p)),
            singleValue: (p) => cx("single-value", props.classNames?.singleValue?.(p)),
            valueContainer: (p) => cx("value-container", props.classNames?.valueContainer?.(p)),
        }}
    />
})`
    .control {
        background: #572d04 linear-gradient(to bottom, #4c2603 0%, #663305 44%, #7f4006 100%);
        border: solid 1px ${props => props.theme.colors.text};
        border-radius: .2em;
        min-height: unset;
        padding: .2em;
    }

    .value-container {
        padding: .2em;
    }

    .single-value {
        color: #ece3d6;
        margin: 0;
        margin-right: .5em;
    }
    
    .menu {
        z-index: 10;
        width: max-content;
    }

    .indicator-separator {
        background: ${rgba("#ece3d6", .5)};
        margin-top: .3em;
        margin-bottom: .3em;
    }

    .dropdown-indicator {
        color: #ece3d6;
        padding: .2em .5em;

        svg {
            width: 1em;
            height: 1em;
        }
    }

    .placeholder {
        color: #ece3d6;
        margin: 0;
        font-weight: bold;
    }

    .input {
        margin: 0;
    }

    .multi-value {
        background: rgba(255, 255, 255, .2);
        color: #ece3d6;
    }

    .multi-value-label {
        color: #ece3d6;
    }

    .multi-value-remove {
        cursor: pointer;

        &:hover {
            background: unset;
        }
    }
`;

const LightSelect = styled(MySelect)`
    .control {
        background: none;
        border: solid 1px ${props => props.theme.colors.divider};
        border-radius: .2em;
        box-shadow: inset 2px 2px 15px -7px rgba(0, 0, 0, .3);
        background-color: #faf6ed;
        color: black
    }

    .single-value, .multi-value-label {
        color: black;
    }

    .placeholder {
        color: rgba(0, 0, 0, .5);
    }

    .indicator-separator {
        background: rgba(0, 0, 0, .5);
    }

    .dropdown-indicator {
        color: black;
    }
`;

export default MySelect;
export {
    LightSelect
};
