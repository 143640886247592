import {Controller, useForm} from "react-hook-form";
import {CreateNews, News} from "../../models/news";
import {Button, FormControl, FormLabel, Input, Textarea} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "../../utils/helpers";
import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles, CreateLink, diffSourcePlugin, DiffSourceToggleWrapper,
    headingsPlugin, InsertTable, InsertThematicBreak, linkDialogPlugin, linkPlugin,
    listsPlugin, ListsToggle,
    markdownShortcutPlugin,
    MDXEditor,
    quotePlugin, Separator, StrikeThroughSupSubToggles, tablePlugin,
    thematicBreakPlugin, toolbarPlugin
} from "@mdxeditor/editor";
import {ErrorMessage, FormButtons} from "../generic/form";

import '@mdxeditor/editor/style.css'

export type NewsFormProps = {
    news?: CreateNews | null;
    onSubmit?: (data: CreateNews) => void;
}

export function NewsForm(props: NewsFormProps) {
    const { register, handleSubmit, control, formState: { errors } } = useForm<CreateNews>({
        defaultValues: {
            title: props.news?.title ?? "",
            text: props.news?.text ?? "",
            date: props.news?.date ?? new Date().toISOString()
        }
    });

    const {t} = useTranslation("news");
    const {t: tf} = useTranslation("forms");

    const [showEditor, setShowEditor] = React.useState(false);

    React.useEffect(() => {
        setShowEditor(true);
    }, []);

    const {t: te} = useTranslation("editor");

    return <form onSubmit={handleSubmit(data => props.onSubmit?.(data))}>
        <FormControl>
            <FormLabel>{t("Title:")}</FormLabel>
            <Input {...register("title", {required: tf("Required.")})} />
            <ErrorMessage errors={errors} name={"title"} />
        </FormControl>

        <FormControl>
            <FormLabel>{t("Text:")}</FormLabel>
            {showEditor
                ? <Controller
                    name={"text"}
                    control={control}
                    render={({field}) => <MDXEditor
                        markdown={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        translation={(key, defaultValue, interpolations) => te(key, {nsSeparator: ".", defaultValue: defaultValue, ...interpolations})}
                        plugins={[
                            headingsPlugin({
                                allowedHeadingLevels: [3,4,5,6]
                            }),
                            listsPlugin(),
                            linkPlugin(),
                            quotePlugin(),
                            thematicBreakPlugin(),
                            markdownShortcutPlugin(),
                            linkDialogPlugin(),
                            tablePlugin(),
                            diffSourcePlugin(),
                            toolbarPlugin({
                                toolbarContents: () => <>
                                    <DiffSourceToggleWrapper options={["rich-text", "source"]}>
                                        <BlockTypeSelect />
                                        <BoldItalicUnderlineToggles />
                                        <StrikeThroughSupSubToggles />
                                        <Separator />
                                        <CreateLink />
                                        <InsertThematicBreak />
                                        <Separator />
                                        <InsertTable />
                                        <ListsToggle />
                                    </DiffSourceToggleWrapper>
                                </>
                            })
                        ]}
                    />}
                />
                : <Textarea {...register("text")} />
            }
            <ErrorMessage errors={errors} name={"text"} />
        </FormControl>

        <FormButtons>
            <Button type={"submit"}>{t((props.news as News)?.id ? "Modify" : "Create")}</Button>
        </FormButtons>
    </form>
}
