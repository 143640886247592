import React, {ReactNode} from "react";
import {Code, ToastProps, useToast} from "@chakra-ui/react";
import {CopyToClipboard} from "./copyToClipboard";
import {useTranslation} from "../../utils/helpers";
import {ApiError} from "../../utils/errors";

type FlashProps = ToastProps;

export function useFlash() {
    const toast = useToast();
    const {t} = useTranslation("default");

    return {
        hide: (id: ReturnType<typeof toast>) => {
            toast.close(id);
        },

        loading: (text: ReactNode | string, opts: Omit<FlashProps, "duration" | "status" | "description" | "isClosable"> = {}) => toast(Object.assign({
            duration: null,
            status: "loading",
            title: text,
            isClosable: false,
        } as FlashProps, opts)),

        success: (opts: Omit<FlashProps, "status">) => toast(Object.assign({
            status: "success",
        } as FlashProps, opts)),

        error: (opts: Omit<FlashProps, "status"> | ApiError | Error) => {
            if (opts instanceof ApiError) {
                opts = {
                    title: t("An error occurred while loading data."),
                    description: <>{t("Request ID:")}{" "}<Code colorScheme={"red"}>{opts.grid} <CopyToClipboard content={opts.grid} variant={"ghost"} /></Code></>,
                    isClosable: true
                }
            } else if ((opts as Error).stack) {
                opts = {
                    title: t("An error occurred while loading data."),
                    isClosable: true
                }
            }

            return toast({
                status: "error",
                ...opts
            });
        },

        info: (opts: Omit<FlashProps, "status">) => toast(Object.assign({
            status: "info",
        } as FlashProps, opts)),

        warning: (opts: Omit<FlashProps, "status">) => toast(Object.assign({
            status: "warning",
        } as FlashProps, opts)),
    };
}
