import React, {HTMLProps, useMemo, useState} from "react";
import {List as CwgList} from "./list";
import {Button} from "../generic/buttons";
import {XwgQueryResponse, XwgWithId} from "../../models/xwg";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Flex,
    FlexProps, FormLabel,
    Heading
} from "@chakra-ui/react";
import {CollectionForm} from "./collectionForm";
import {CollectionData} from "../../api/updateCollection";
import {FormattedDate} from "../generic/date";
import {useTranslation} from "../../utils/helpers";
import {Trans} from "react-i18next";
import {Switch} from "../generic/form";

type SearchPaginationProps = {
    count?: number,
    offset: number,
    limit: number,
    onOffsetChange?: (newOffset: number) => void
};

function SearchPagination({count, offset, limit, onOffsetChange, ...props}: SearchPaginationProps & FlexProps) {
    const {t: ts} = useTranslation("search");

    return <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        {...props}
    >
        <Button
            type="button"
            size={"sm"}
            onClick={(e) => {
                e.preventDefault();
                onOffsetChange?.(offset - limit)
            }}
            isDisabled={offset === 0}
        >
            {ts("< Previous")}
        </Button>

        {count && <p>{ts("{{offset}} - {{limit}} / {{count}}", {
            offset: offset + 1,
            limit: Math.min(offset + limit, count),
            count
        })}</p>}

        <Button
            type="button"
            size={"sm"}
            onClick={(e) => {
                e.preventDefault();
                onOffsetChange?.(offset + limit)
            }}
            isDisabled={count === undefined || offset + limit >= count}
        >
            {ts("Next >")}
        </Button>
    </Flex>;
}

type CwgSearchResultProps = {
    search?: XwgQueryResponse | null;
    offset?: number;
    limit?: number;
    onOffsetChange?: (newOffset: number) => void;
    warnIfNotFound?: boolean;
    actionable?: boolean;
    customActions?: (xwg: XwgWithId) => React.ReactNode;
};

export function CwgSearchResult({
        search, offset, limit, onOffsetChange, warnIfNotFound = true, actionable = true, customActions, ...props
}: CwgSearchResultProps & HTMLProps<HTMLDivElement>) {
    const {t: ts} = useTranslation("search");
    const actualOffset = offset ?? 0;
    const actualLimit = limit ?? 100;

    const [collectionData, setCollectionData] = useState<CollectionData>({
        comment: "",
        pieces: 1,
        date: new Date().toISOString().substring(0, 10),
        year: new Date().getFullYear()
    });

    const [actionsVisible, setActionsVisible] = useState<boolean>(false);

    const renderedResult = useMemo(() => <CwgList
        items={search?.items ?? []}
        quickCollect={actionable && actionsVisible ? collectionData : undefined}
        stateShifter={actionable && actionsVisible}
        customActions={customActions}
    />, [search?.items, collectionData, actionable, actionsVisible]);

    const memoizedForm = useMemo(() => <CollectionForm
        onChange={setCollectionData}
        showSubmitButton={false}
    />, [setCollectionData]);

    return <div {...props}>
        {search && search.count > 0 && <>
            <SearchPagination
                count={search.count}
                offset={actualOffset}
                limit={actualLimit}
                onOffsetChange={onOffsetChange}
                mb={4}
            />

            {actionable
                ? <FormLabel mb={4}><Switch value={actionsVisible} onChange={setActionsVisible} /> {ts("Show buttons to manage collection")}</FormLabel>
                : null}

            {actionable && actionsVisible && <Accordion allowMultiple mb={4}>
                <AccordionItem>
                    <Heading as={"h3"} m={0} size={"sm"}>
                        <AccordionButton>
                            <Box
                                as={"span"}
                                flex={"1"}
                                textAlign={"left"}
                            >
                                {collectionData.comment
                                    ? <Trans
                                        ns={"search"}
                                        i18nKey={"quick_collect_comment"}
                                        count={collectionData.pieces}
                                        values={{
                                            pieces: collectionData.pieces.toString(),
                                            year: collectionData.year.toString(),
                                            comment: collectionData.comment
                                        }}
                                        components={[
                                            <ins />,
                                            <ins><FormattedDate date={collectionData.date} /></ins>,
                                            <ins />,
                                            <ins />,
                                        ]}
                                    />
                                    : <Trans
                                        ns={"search"}
                                        i18nKey={"quick_collect"}
                                        count={collectionData.pieces}
                                        values={{
                                            pieces: collectionData.pieces.toString(),
                                            year: collectionData.year.toString()
                                        }}
                                        components={[
                                            <ins />,
                                            <ins><FormattedDate date={collectionData.date} /></ins>,
                                            <ins />,
                                        ]}
                                    />
                                }
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </Heading>
                    <AccordionPanel>
                        {memoizedForm}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>}

            {renderedResult}

            <SearchPagination
                mt={4}
                count={search.count}
                offset={actualOffset}
                limit={actualLimit}
                onOffsetChange={onOffsetChange}
            />
        </>}
        {search && search.count === 0 && warnIfNotFound && <Alert>
            <AlertIcon />
            <AlertDescription>{ts("Nothing was found for given search query. Try to enter only part of the name. If that does not help, try to hold until the xWG will be inserted into the catalogue.")}</AlertDescription>
        </Alert>}
    </div>;
}
