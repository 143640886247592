import {XwgWithId} from "../../models/xwg";
import React from "react";
import {CwgRow} from "./row";
import {ListItem, ListProps, UnorderedList} from "@chakra-ui/react";
import {CollectionData} from "../../api/updateCollection";

export function List({items, quickCollect, stateShifter, customActions, ...props}: ListProps & {
    items: XwgWithId[],
    quickCollect?: CollectionData,
    stateShifter?: boolean,
    customActions?: (xwg: XwgWithId) => React.ReactNode
}){
    return <UnorderedList {...props} variant={"divided"}>{items.map(xwg =>
        <ListItem key={xwg.id}>
            <CwgRow
                xwg={xwg}
                quickCollect={quickCollect}
                stateShifter={stateShifter}
                customActions={customActions}
            />
        </ListItem>
    )}</UnorderedList>;
}
