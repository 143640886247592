import {createFileRoute, Link} from '@tanstack/react-router'
import {getAPI} from "../../../api/api";
import {useTranslation} from "../../../utils/helpers";
import {ListResponse} from "../../../models/response";
import {News} from "../../../models/news";
import React from "react";
import {Table, Tbody, Td, Tfoot, Th, Thead, Tr} from "@chakra-ui/react";
import {FormattedDate} from "../../../components/generic/date";
import {ProfileLink} from "../../../components/users/profileLink";
import Pagination from "../../../components/generic/pagination";
import {ROUTES} from "../../../constants";

export const Route = createFileRoute('/_site/news/archive')({
    component: () => {
        const {t} = useTranslation("news");
        const {news, count} = Route.useLoaderData();
        const {offset, limit} = Route.useSearch();
        const nav = Route.useNavigate();

        return <>
            <h2>{t("News archive")}</h2>

            <Table>
                <Thead>
                    <Tr>
                        <Th className={"td-shrink"}>{t("Date")}</Th>
                        <Th>{t("Title")}</Th>
                        <Th className={"td-shrink"}>{t("Author")}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {news.map((news) => <Tr key={news.id}>
                        <Td className={"td-shrink"}><FormattedDate date={news.date} /></Td>
                        <Td><Link to={ROUTES.NEWS_DETAIL} params={{id: news.id}}>{news.title}</Link></Td>
                        <Td className={"td-shrink"}><ProfileLink user={news.author} /></Td>
                    </Tr>)}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Td colSpan={3}>
                            <Pagination
                                count={count}
                                limit={limit}
                                offset={offset}
                                onOffsetChange={async (newOffset) => {
                                    await nav({
                                        search: (prev) => {
                                            return {
                                                ...prev,
                                                offset: newOffset
                                            }
                                        }
                                    });
                                }}
                            />
                        </Td>
                    </Tr>
                </Tfoot>
            </Table>
        </>
    },
    validateSearch: (search: Record<string, string>) => {
        return {
            offset: parseInt(search.offset) || 0,
            limit: parseInt(search.limit) || 25
        }
    },
    loaderDeps: ({search}) => ({offset: search.offset, limit: search.limit}),
    loader: async ({ context, deps }) => {
        const api = getAPI(context);
        const resp = await api.get<ListResponse<News>>("/api/v2/news", {
            params: {
                offset: deps.offset,
                limit: deps.limit,
                attribute: ["id", "title", "date", "author"],
            }
        });

        return {
            news: resp.data.items,
            count: resp.data.count
        }
    }
});
