import {darken, lighten, rgba} from "polished";
import {cssVar, extendTheme} from "@chakra-ui/react";

import bg from "../assets/static/bg.jpg";
import {tableTheme} from "./components/table";
import {listTheme} from "./components/list";
import {tagTheme} from "./components/tag";
import {buttonTheme} from "./components/button";
import {inputTheme} from "./components/input";
import {tabsTheme} from "./components/tabs";
import {accordionTheme} from "./components/accordion";
import "@fontsource-variable/noto-sans";
import {dlTheme} from "./components/dl";
import {columnsTheme} from "./components/columns";
import {announcementTheme} from "./components/announcement";
import {alertTheme} from "./components/alert";
import {dividerTheme} from "./components/divider";
import {checkboxTheme} from "./components/checkbox";
import {textTheme} from "./components/text";

export const dividerColor = "#8a6832";

const $dividerBorderWidth = cssVar("dividerBorderWidth");

const cwgTheme = extendTheme({
    overlay: "circle(50% at 50% calc(50%))",
    pageWidth: "60rem",

    borderRadius: "1rem",
    blurRadius: ".2em",

    colors: {
        text: "#572d04",
        link: "black",
        divider: {
            100: '#1c150a',
            200: '#372a14',
            300: '#533f1e',
            400: '#6f5428',
            500: '#8a6832',
            600: '#ba8c43',
            700: '#cca971',
            800: '#ddc6a1',
            900: '#eee2d0',
        },
        primary: {
            100: '#140a01',
            200: '#291402',
            300: '#3d1e03',
            400: '#522804',
            500: '#663305',
            600: '#b35808',
            700: '#f47f18',
            800: '#f8aa65',
            900: '#fbd4b2'
        },
        secondary: {
            100: '#412d0d',
            200: '#825b1b',
            300: '#c48828',
            400: '#deac5c',
            500: '#ebcd9d',
            600: '#efd7b1',
            700: '#f3e1c5',
            800: '#f7ebd8',
            900: '#fbf5ec'
        },
        highlight: rgba(dividerColor, .5),
        shade: "rgba(255, 255, 255, .5)",
        popup: "#e5d3a5",
        textDanger: "#A12206",
        bgDanger: rgba("#fdeaeb", .6),
        textSuccess: "#0A6A0A",
        bgSuccess: rgba("#e6f5e6", .6),
    },

    breakpoints: {
          base: '0rem', // 0px
          sm: '30rem', // ~480px
          md: '48rem', // ~768px
          lg: '62rem', // ~992px
          xl: '80rem', // ~1280px
          '2xl': '96rem', // ~1536px
    },

    fonts: {
        heading: "Noto Sans Variable, sans-serif",
        body: "Noto Sans Variable, sans-serif",
        mono: "monospace",
    },

    fontSizes: {
        "4xs": ".5em",
        "3xs": ".6em",
        "2xs": ".8em",
        xs: ".85em",
        sm: ".9em",
        md: "1em",
        lg: "1.2em",
    },

    input: {
        padding: "space.2",
    },
    components: {
        Alert: alertTheme,
        Accordion: accordionTheme,
        Button: buttonTheme,
        Container: {
            variants: {
                "state-shifter": {
                    px: 0,

                    "button": {
                        px: 3,
                        py: 0,

                        "&:first-of-type": {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        },
                        "&:last-of-type": {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            ml: "-1px",
                        },
                        "&:not(:first-of-type):not(:last-of-type)": {
                            borderRadius: 0,
                            ml: "-1px",
                        }
                    },

                    "img, .Loader": {
                        maxHeight: "1.5em",
                    },

                    "img.inactive": {
                        filter: "grayscale(100%)",
                        opacity: ".5"
                    },

                    "button:hover img.inactive": {
                        filter: "unset",
                        opacity: "1",
                    },
                }
            }
        },
        Input: inputTheme,
        NumberInput: inputTheme,
        Tabs: tabsTheme,
        Text: textTheme,
        Textarea: {
            defaultProps: {
                variant: inputTheme.defaultProps?.variant,
            },
            variants: {
                filled: inputTheme.variants?.filled.field,
                monospace: Object.assign({}, inputTheme.variants?.filled.field, {
                    fontFamily: "monospace"
                })
            },
            sizes: {
                xs: inputTheme.sizes?.xs?.field ?? {},
                sm: inputTheme.sizes?.sm?.field ?? {},
                md: inputTheme.sizes?.md?.field ?? {},
                lg: inputTheme.sizes?.lg?.field ?? {},
            }
        },
        Modal: {
            baseStyle: {
                overlay: {
                    backdropFilter: "blur(.25em)",
                },
                dialog: {
                    bg: `linear-gradient(rgba(255, 255, 255, .5), rgba(255, 255, 255, .5)), url(${bg}) top center`,
                    overflow: "hidden",
                    border: "solid 1px",
                    borderColor: "divider.500",
                    boxShadow: "lg",
                },
                header: {
                    fontSize: "lg",
                    fontWeight: 800,
                    px: 2,
                    py: 1,
                    bg: `linear-gradient(to bottom, ${darken(.1, dividerColor)} 0%, ${lighten(.05, dividerColor)} 100%)`,
                    color: "white",
                    borderBottom: `1px solid`,
                    borderColor: "divider.500",
                    overflow: "hidden",
                },
                footer: {
                    px: 2,
                    py: 2,
                    borderTop: `1px solid`,
                    borderColor: "divider.500",
                    backdropFilter: "blur(.15em)",
                },
                body: {
                    px: 2,
                    py: 2,
                    backdropFilter: "blur(.15em)",
                },
                closeButton: {
                    color: "white",
                    p: 2,
                    w: "auto",
                    h: "auto",
                    top: ".35em",
                    right: 2,
                }
            }
        },
        Switch: {
            baseStyle: {
                track: {
                    bg: dividerColor
                }
            }
        },
        Form: {
            baseStyle: {
                container: {
                    mb: "3",
                },
                helperText: {
                    color: "text",
                    fontSize: "md",
                }
            }
        },
        FormLabel: {
            baseStyle: {
                fontWeight: "bold",
                mt: "2",
                mb: "1"
            }
        },
        Checkbox: checkboxTheme,
        Divider: dividerTheme,
        Table: tableTheme,
        List: listTheme,
        Tag: tagTheme,
        Dl: dlTheme,
        Columns: columnsTheme,
        Announcement: announcementTheme,
    },

    styles: {
        global: {
            "html, body": {
                width: "100%",
            },

            'body': {
                m: 0,
                p: 0,
                background: `url(${ bg }) top center`,
                fontFamily: "Noto Sans Variable, sans-serif",
                fontSize: ".8em",
                color: "text",
                [$dividerBorderWidth.variable]: "2px",
            },

            a: {
                "&:link, &:visited, &:active, &:hover": {
                    color: "link",
                    textDecoration: "underline"
                },

                "&:hover": {
                    textDecoration: "none",
                }
            },

            "*": {
                boxSizing: "border-box",
            },

            "h2, h3, h4, h5, h6": {
                color: "black",
                margin: "0 0 1em",
                fontWeight: "bold",

                "&:not(:first-of-type)": {
                    mt: "2em",
                },
            },

            "h2": {
                "fontSize": "1.5em"
            },

            "h3": {
                "fontSize": "1.2em",
            },

            "h4": {
                fontSize: "1em",
            },

            "h5": {
                fontSize: ".9em",
            },

            "h6": {
                fontSize: ".8em",
            },

            ".td-shrink": {
                width: "1px",
                whiteSpace: "nowrap",
            },

            ".text-center": {
                "&&": {
                    textAlign: "center",
                }
            },

            ".text-right": {
                "&&": {
                    textAlign: "right",
                }
            },

            ".text-left": {
                "&&": {
                    textAlign: "left",
                }
            },

            ".mdxeditor": {
                ...inputTheme.variants?.filled.field,
                w: "100%",
                px: 0,
            },
            ".mdxeditor-toolbar": {
                bg: "rgba(0, 0, 0, .05)",
                borderRadius: "0",
                borderBottom: `1px solid`,
                borderBottomColor: "divider.500",
                p: 0,
            },
            ".mdxeditor-root-contenteditable": {
                "h1": {
                    fontSize: "2em"
                },
                "h2": {
                    fontSize: "1.5em",
                },
                "h3": {
                    fontSize: "1.2em",
                },
                "h4": {
                    fontSize: "1em",
                },
                "h5": {
                    fontSize: ".9em",
                },
                "h6": {
                    fontSize: ".8em",
                }
            },
        }
    }
});

export default cwgTheme;
