import {createFileRoute, Link} from '@tanstack/react-router'
import React, {useCallback} from "react";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../components/generic/breadcrumb";
import {useTranslation} from "../../../../utils/helpers";
import {getAPI, useAPI} from "../../../../api/api";
import {ExistingGroup, GroupScopes, User} from "../../../../models/user";
import {ListResponse} from "../../../../models/response";
import {
    Badge,
    Box,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Stack,
    Text,
    useToast
} from "@chakra-ui/react";
import {Route as AdminIndexRoute} from "../index";
import {Route as AdminUsersRoute} from "./index";
import {Controller, useForm} from "react-hook-form";
import {FormButtons, Switch} from "../../../../components/generic/form";


export const Route = createFileRoute('/_site/admin/users/$userId/groups')({
    params: {
        parse: (rawParams) => {
            return {
                userId: parseInt(rawParams.userId)
            }
        },
        stringify: (params) => {
            return {
                userId: params.userId.toString()
            }
        }
    },
    validateSearch: (params) => {
        return AdminUsersRoute.options.validateSearch?.(params);
    },
    loader: async ({context, params}) => {
        const api = getAPI(context);

        const user = (await api.get<User>("/api/v2/users/{userId}", {
            pathParams: {
                userId: params.userId
            },
            params: {
                attribute: ["id", "name", "groups"]
            }
        })).data;

        const groups = (await api.get<ListResponse<ExistingGroup & GroupScopes>>("/api/v2/groups", {
            params: {
                attribute: ["id", "name", "scopes"]
            }
        })).data;

        return {
            user,
            groups: groups.items
        }
    },
    component: () => {
        const {t} = useTranslation("admin_users");
        const {user, groups} = Route.useLoaderData();
        const params = Route.useParams();

        type UserGroups = {
            group: boolean[]
        }

        const {control, handleSubmit} = useForm<UserGroups>({
            defaultValues: {
                group: Object.fromEntries(user.groups.map(group => [group.id, true])) as unknown as boolean[]
            },
        });

        const api = useAPI();
        const toast = useToast();
        const navigate = Route.useNavigate();
        const search = Route.useSearch();

        const saveGroups = useCallback(async (data: UserGroups) => {
            await api.put(
                "/api/v2/users/{userId}/groups",
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                Object.entries(data.group).filter(([_, value]) => value).map(([groupId]) => parseInt(groupId)),
                {
                    pathParams: {
                        userId: user.id
                    }
                }
            );

            toast({
                title: t("Groups saved"),
                status: "success"
            });

            await navigate({
                to: AdminUsersRoute.fullPath,
                search
            });
        }, [api, toast, navigate]);

        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("{{name}} - groups", { name: user.name })}</h2>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>{t("Administration", {ns: "admin"})}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminUsersRoute.fullPath} search={search}>{t("Users")}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink>{user.name}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath} params={{userId: params.userId}}>{t("Groups")}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>

            <form onSubmit={handleSubmit(saveGroups)}>
                {groups.map(group => {
                    return <FormControl key={group.id}>
                        <FormLabel _hover={{bgColor: "shade"}} p={4} cursor={"pointer"}>
                            <HStack>
                                <Controller
                                    control={control}
                                    name={`group.${group.id}`}
                                    render={({field}) => {
                                        return <Switch
                                            value={field.value}
                                            isDisabled={field.disabled}
                                            onChange={field.onChange}
                                        />
                                    }}
                                />
                                <Box>
                                    <Text fontSize={"lg"}>{group.name}</Text>
                                    <Stack direction={"row"} flexWrap={"wrap"} mt={2}>
                                        {group.scopes.allowed.map(scope => {
                                            return <Badge colorScheme={"gray"} fontSize={"sm"} key={scope}>{scope}</Badge>
                                        })}
                                    </Stack>
                                </Box>
                            </HStack>
                        </FormLabel>
                    </FormControl>
                })}

                <FormButtons>
                    <Button type={"submit"}>{t("Save")}</Button>
                </FormButtons>
            </form>
        </>
    }
})
