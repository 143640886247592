import {ErrorMessage, FormButtons} from "../generic/form";
import {useTranslation} from "../../utils/helpers";
import {Button} from "../generic/buttons";
import {useForm} from "react-hook-form";
import React, {useCallback} from "react";
import {useAPI} from "../../api/api";
import {diagToForm} from "../../utils/diagToForm";
import {HTTP} from "../../constants";
import {useFlash} from "../generic/flash";
import {chakra, FormControl, Input} from "@chakra-ui/react";
import {ValidationError} from "../../utils/errors";
import {useMatchData} from "../../utils/findInMatchesData";
import {ApplicationData} from "../../models/routerContext";

type UserRegistration = {
    name: string;
    password: string;
    password2: string;
    email: string;
}

export default function SignupForm() {
    const {t} = useTranslation("account");
    const {t: tf} = useTranslation("forms");

    const methods = useForm<UserRegistration>({
        criteriaMode: "all",
    });

    const {
        register,
        handleSubmit,
        watch,
        setError,
        reset,
        formState: { errors }
    } = methods;

    const api = useAPI();
    const flash = useFlash();
    const {r} = useMatchData<ApplicationData>("applicationData", true);

    const onSubmit = useCallback(async (data: UserRegistration) => {
        try {
            const resp = await api.post(`/${r}/action-register`, [{
                name: data.name,
                password: data.password,
                email: data.email
            }], {
                validateStatus: (status: number) => status === HTTP.OK || status === HTTP.UNPROCESSABLE_CONTENT || status == HTTP.CONFLICT
            });

            if (resp.status === HTTP.UNPROCESSABLE_CONTENT || resp.status === HTTP.CONFLICT) {
                const diag = ValidationError.payloadToDiagnostic(resp.data);
                diagToForm<UserRegistration>(diag, setError, {
                    stripPrefix: ["body", 0],
                    customErrors: {
                        "entity_already_exists": t("Selected user name already exists.")
                    }
                });
                return;
            } else {
                flash.success({
                    description: t("Registration has been completed successfully. You can now sign in.")
                });
                reset();
            }
        } catch (e) {
            if (e instanceof ValidationError) {
                diagToForm<UserRegistration>(e.diagnostics, setError);
                return;
            }
        }
    }, []);

    return <>
        <h2>{t("Sign up")}</h2>
        <chakra.form
            action={"/signup"}
            method={"post"}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"20em"}
            mx={"auto"}
        >
            <ErrorMessage errors={errors} name={"root"} />

            <FormControl>
                <Input
                    placeholder={t("User name")}
                    {...register("name", {
                        required: tf("Required.")
                    })}
                    autoComplete={"username"}
                />
                <ErrorMessage errors={errors} name={"name"} />
            </FormControl>
            <FormControl>
                <Input
                    placeholder={t("Password")}
                    {...register("password", {
                        required: tf("Required."),
                        validate: (val: string) => {
                            if (val.length < 8) {
                                return t("Password must be at least {{min_length}} characters long.", {min_length: 8});
                            }
                        }
                    })}
                    type={"password"}
                    autoComplete={"new-password"}
                />
                <ErrorMessage errors={errors} name={"password"} />
            </FormControl>
            <FormControl>
                <Input
                    placeholder={t("Retype password")}
                    {...register("password2", {
                        required: tf("Required."),
                        validate: (val: string) => {
                            if (watch('password') !== val) {
                                return t("Passwords did not match.");
                            }
                        }
                    })}
                    type={"password"}
                    autoComplete={"new-password"}
                />
                <ErrorMessage errors={errors} name={"password2"} />
            </FormControl>
            <FormControl>
                <Input
                    type={"email"}
                    placeholder={t("Email")}
                    {...register("email", {
                        required: tf("Required.")
                    })}
                    autoComplete={"email"}
                />
                <ErrorMessage errors={errors} name={"email"} />
            </FormControl>

            <FormButtons>
                <Button type={"submit"}>{t("Finish registration")}</Button>
            </FormButtons>
        </chakra.form>
    </>
}
