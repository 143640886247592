import {defineStyle, defineStyleConfig} from "@chakra-ui/styled-system";

const baseStyle = defineStyle((props) => {
    const { colorScheme: c } = props;

    return {
        borderBottom: "solid 1px",
        borderBottomColor: `${c}.800`,
        bg: `color-mix(in srgb, var(--chakra-colors-${c}-100), transparent 40%)`,
        color: `${c}.900`,
        fontWeight: 500,
        p: 3,
        fontSize: "lg",
        textAlign: "center",
    }
});

export const announcementTheme = defineStyleConfig({
    baseStyle
});
