import {createFileRoute, useRouter} from '@tanstack/react-router'
import {getAPI, useAPI} from "../../../api/api";
import {getUser} from "../../../api/listUsers";
import {useTranslation} from "../../../utils/helpers";
import {useForm} from "react-hook-form";
import {useCurrentUser} from "../../../models/user";
import {useFlash} from "../../../components/generic/flash";
import React, {useCallback} from "react";
import {createShortLivedAccessToken, Unauthorized} from "../../../api/shortLivedAccessToken";
import Account from "../../../components/account/account";
import {ErrorMessage, FormButtons} from "../../../components/generic/form";
import {Button} from "../../../components/generic/buttons";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Checkbox,
    Divider, Flex,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    useToast
} from "@chakra-ui/react";
import {EmailSettings} from "../../../components/account/emailSettings";
import {useAlert} from "../../../components/generic/alert";
import {Error} from "../../../components/error";
import {combineTitle} from "../../../utils/combineTitle";
import {getFixedT} from "../../../utils/getFixedT";
import {RouterContext} from "../../../models/routerContext";

export const Route = createFileRoute('/_site/users/profile')({
    meta: ({matches, match}) => {
        const t = getFixedT("settings", match.context.request?.i18n)
        return combineTitle(matches, t("Profile settings"));
    },
    component: ProfileSettings,
    loader: async ({context}: {context: RouterContext}) => {
        /*if (!context.currentUser.currentUser) {
            throw new NotFoundError();
        }*/

        const api = getAPI(context);
        const user = await getUser({
            id: context.currentUser.currentUser?.id ?? -1,
            api,
            attributes: ["profile", "private", "hide_from_top", "emails"]
        });

        return {
            user
        };
    }
});

type ProfileSettingsForm = {
    name: string;
    email: string;
    private: boolean;
    hide_from_top: boolean;
    profile: string;
    existingPassword: string;
};

function ProfileSettings() {
    const {t} = useTranslation("settings");
    const {t: to} = useTranslation("cwg_ops");

    const {user} = Route.useLoaderData() ?? {user: null};

    const {
        register,
        handleSubmit,
        setError,
        resetField,
        formState: {errors, dirtyFields}
    } = useForm<ProfileSettingsForm>({
        defaultValues: {
            name: user?.name,
            email: user?.email,
            private: user?.private,
            hide_from_top: user?.hide_from_top,
            profile: user?.profile,
            existingPassword: "",
        }
    });

    const api = useAPI();
    const currentUser = useCurrentUser();
    const flash = useFlash();
    const router = useRouter();
    const {showConfirm} = useAlert();
    const toast = useToast();

    const onSubmit = useCallback(async (data: ProfileSettingsForm) => {
        const headers: Record<string, string> = {};

        const profilePatch = {
            name: dirtyFields.name ? data.name : undefined,
            email: dirtyFields.email ? data.email : undefined,
            private: dirtyFields.private ? data.private : undefined,
            hide_from_top: dirtyFields.hide_from_top ? data.hide_from_top : undefined,
            profile: dirtyFields.profile ? data.profile : undefined
        };

        if (dirtyFields.name || dirtyFields.email) {
            // Need to retrieve new short-lived access token to perform these changes.
            try {
                const token = await createShortLivedAccessToken({
                    api,
                    currentUser,
                    password: data.existingPassword ?? ""
                });
                headers["Authorization"] = `Bearer ${token}`;
            } catch (e) {
                if (e instanceof Unauthorized) {
                    setError("existingPassword", {
                        type: "incorrect",
                        message: t("Invalid password")
                    });
                    return;
                }
            }
        }

        await api.patch(
            "/api/v2/users/me",
            profilePatch,
            {headers}
        );
        flash.success({description: t("Your profile has been successfully updated.")});
        resetField("existingPassword");

        await router.invalidate();
    }, [user, dirtyFields, currentUser]);

    if (!user) {
        return <Error>{t("You must log in to access profile settings.")}</Error>
    }

    return <Account title={t("Profile settings")}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <ErrorMessage errors={errors} name="name" />
            <FormControl>
                <FormLabel htmlFor="name">{t("User name")}:</FormLabel>
                <Input type="text" id="name" {...register("name")} />
                <ErrorMessage errors={errors} name="name" />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor={"existingPassword"}>{t("Password")}:</FormLabel>
                <Input type={"password"} id={"existingPassword"} {...register("existingPassword")} />
                <p className={"description"}>{t("Please confirm changing your user name by providing your current password.")}</p>
                <ErrorMessage errors={errors} name="existingPassword" />
            </FormControl>

            <Divider />

            <FormControl>
                <Checkbox {...register("private")}>
                    {t("Hide my profile")}
                </Checkbox>
                <p className={"description"}>{t("This will hide your user profile. Your statistics and collection will not be accessible to other users. Offers for xWG exchange will be displayed regardless of this option's settings.")}</p>
                <ErrorMessage errors={errors} name="private" />
            </FormControl>

            <FormControl>
                <Checkbox {...register("hide_from_top")}>
                    {t("Hide me from TOP table")}
                </Checkbox>
                <p className={"description"}>{t("This will hide your account from the Best collectors table. You won't affect overall rank of collectors.")}</p>
                <ErrorMessage errors={errors} name="hide_from_top" />
            </FormControl>

            <FormControl>
                <FormLabel htmlFor={"profile"}>{t("Profile:")}</FormLabel>
                <Textarea id={"profile"} {...register("profile")} style={{height: "20em"}} variant={"monospace"} />
                <p className={"description"}>{t("This text will be displayed in public part of your profile. You can put any information here that will be visible for other users. Text can contain HTML.")}</p>
                <ErrorMessage errors={errors} name="profile" />
            </FormControl>

            <FormButtons>
                <Button type={"submit"}>{t("Save")}</Button>
            </FormButtons>
        </form>

        <Divider />

        <h3>{t("Email settings")}</h3>

        <EmailSettings emails={user?.emails} />

        <Divider />

        <h3>{t("Housekeeping")}</h3>
        <Alert status={"error"}>
            <AlertIcon />
            <Box>
                <AlertTitle>{t("Remove collection")}</AlertTitle>
                <AlertDescription>
                    <p className={"description"}>{t("This will remove your entire collection, you would need to start over. This action is irreversible!")}</p>
                    <Flex justifyContent={"flex-end"}>
                        <Button
                            mt={4}
                            colorScheme={"red"}
                            variant={"solid"}
                            onClick={async () => {
                                if (await showConfirm(to("Are you sure?"), t("This will remove your entire collection, you would need to start over. This action is irreversible!")) === "yes") {
                                    await api.delete("/api/v2/users/me/collections");
                                    toast({
                                        title: t("You entire collection has been removed."),
                                        status: "success"
                                    });
                                }
                            }}
                        >
                            {t("Remove collection")}
                        </Button>
                    </Flex>
                </AlertDescription>
            </Box>
        </Alert>
        <Alert status={"error"} mt={4}>
            <AlertIcon />
            <Box>
                <AlertTitle>{t("Remove all offers")}</AlertTitle>
                <AlertDescription>
                    <p>{t("This will remove all your offers. This action is irreversible!")}</p>
                    <Button
                        mt={4}
                        colorScheme={"red"}
                        variant={"solid"}
                        onClick={async () => {
                            if (await showConfirm(to("Are you sure?"), t("This will remove all your offers. This action is irreversible!")) === "yes") {
                                await api.delete("/api/v2/users/me/offers");
                                toast({
                                    title: t("All your offers has been removed."),
                                    status: "success"
                                });
                            }
                        }}
                    >
                        {t("Remove offers")}
                    </Button>
                </AlertDescription>
            </Box>
        </Alert>
        <Alert status={"error"} mt={4}>
            <AlertIcon />
            <Box>
                <AlertTitle>{t("Remove all wants")}</AlertTitle>
                <AlertDescription>
                    <p>{t("This will remove all your wants. This action is irreversible!")}</p>
                    <Button
                        mt={4}
                        colorScheme={"red"}
                        variant={"solid"}
                        onClick={async () => {
                            if (await showConfirm(to("Are you sure?"), t("This will remove all your wants. This action is irreversible!")) === "yes") {
                                await api.delete("/api/v2/users/me/wants");
                                toast({
                                    title: t("All your wants has been removed."),
                                    status: "success"
                                });
                            }
                        }}
                    >
                        {t("Remove wants")}
                    </Button>
                </AlertDescription>
            </Box>
        </Alert>
        <Alert status={"error"} mt={4}>
            <AlertIcon />
            <Box>
                <AlertTitle>{t("Remove all not wants")}</AlertTitle>
                <AlertDescription>
                    <p>{t("This will remove all your not wants. This action is irreversible!")}</p>
                    <Button
                        mt={4}
                        colorScheme={"red"}
                        variant={"solid"}
                        onClick={async () => {
                            if (await showConfirm(to("Are you sure?"), t("This will remove all your not wants. This action is irreversible!")) === "yes") {
                                await api.delete("/api/v2/users/me/not-want");
                                toast({
                                    title: t("All your not wants has been removed."),
                                    status: "success"
                                });
                            }
                        }}
                    >
                        {t("Remove not wants")}
                    </Button>
                </AlertDescription>
            </Box>
        </Alert>
    </Account>
}
