import {AnySearchValidatorFn, createFileRoute, Link} from '@tanstack/react-router'
import React, {useCallback} from "react";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../components/generic/breadcrumb";
import {getAPI, useAPI} from "../../../../api/api";
import {ExistingGroup, GroupScopes, User} from "../../../../models/user";
import {ListResponse} from "../../../../models/response";
import {SplittedScopes, TokenScope} from "../../../../models/tokenScope";
import {useTranslation} from "../../../../utils/helpers";
import {
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Center,
    Checkbox,
    CheckboxProps,
    HStack,
    Radio,
    RadioGroup,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
} from "@chakra-ui/react";
import {CloseIcon} from "@chakra-ui/icons";
import {Route as AdminUsersRoute} from "./index";
import {Controller, useForm} from "react-hook-form";
import {FormButtons} from "../../../../components/generic/form";
import {RouterContext} from "../../../../models/routerContext";
import {ROUTES} from "../../../../constants";

type RouteParams = {
    userId: number;
};

export type UnsplittedScopes = {[scope: string]: boolean};
export type ScopeRadioValue = "-1" | "0" | "1";
export type FormScopes = {[scope: string]: ScopeRadioValue};

type UserScopesForm = {
    scopes: FormScopes;
}

export function unsplitScopes(scopes: SplittedScopes): UnsplittedScopes {
    const result: {[scope: string]: boolean} = {};

    for (const scope of scopes.allowed) {
        result[scope] = true;
    }

    for (const scope of scopes.denied) {
        result[scope] = false;
    }

    return result;
}

export function splitScopes(scopes: UnsplittedScopes): SplittedScopes {
    return Object.entries(scopes).reduce((acc, [scope, value]) => {
        if (value) {
            acc.allowed.push(scope);
        } else {
            acc.denied.push(scope);
        }

        return acc;
    }, {allowed: [], denied: []} as SplittedScopes);
}

export function scopesToForm(scopes: UnsplittedScopes): FormScopes {
    return Object.fromEntries(
        Object.entries(scopes)
        .map(([scope, value]) => [scope, value ? "1" : "0"])
    );
}

export function radioToBool(value: ScopeRadioValue): boolean | undefined {
    switch (value) {
        case "-1":
            return undefined;
        case "0":
            return false;
        case "1":
            return true;
    }
}

export function formToScopes(scopes: FormScopes): UnsplittedScopes {
    return Object.fromEntries(
        Object.entries(scopes)
        .map(([scope, value]) => [scope, radioToBool(value)])
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => value !== undefined)
    );
}


const ValueCheckbox = ({explicitValue, groupValue, defaultValue, ...props}: {
    explicitValue?: boolean | undefined,
    groupValue?: boolean | undefined,
    defaultValue?: boolean | undefined
} & Omit<CheckboxProps, "defaultValue">) => {
     const value = explicitValue !== undefined
         ? explicitValue
         : (groupValue !== undefined ? groupValue : defaultValue);
     return <Checkbox
         isChecked={value !== undefined}
         isIndeterminate={value === undefined}
         isReadOnly
         icon={value === false ? <CloseIcon /> : undefined}
         colorScheme={value ? "green" : (value === false ? "red" : "gray")}
         {...props}
     />
 }

export const Route = createFileRoute('/_site/admin/users/$userId/privileges')({
    params: {
        parse: (params): RouteParams => ({
            userId: parseInt(params.userId)
        }),
        serialize: (params: RouteParams) => ({
            userId: params.userId?.toString()
        })
    },

    validateSearch: (params) => {
        const vs = AdminUsersRoute.options.validateSearch;
        return (vs as AnySearchValidatorFn)(params);
    },

    loader: async ({context, params}: {context: RouterContext, params: RouteParams}) => {
        const api = getAPI(context);
        const user = (await api.get<User>(`/api/v2/users/${params.userId}?attribute=id&attribute=name&attribute=groups`)).data;
        const userGroupIds = user.groups.map(group => group.id);

        const userScopes = unsplitScopes((await api.get<SplittedScopes>(`/api/v2/users/${params.userId}/scopes?include_inherited=false`)).data);
        const groups = (await api.get<ListResponse<ExistingGroup & GroupScopes>>(`/api/v2/groups?attribute=id&attribute.name&attribute=scopes`)).data;
        const userGroups = groups.items.filter(group => userGroupIds.includes(group.id));

        const aggregatedGroupScopes = userGroups.reduce((acc, group) => {
            const scopes = unsplitScopes(group.scopes);

            for (const [scope, value] of Object.entries(scopes)) {
                acc[scope] = (acc[scope] ?? false) || value;
            }

            return acc;
        }, {} as {[scope: string]: boolean});

        const anonymousScopes = unsplitScopes((await api.get<SplittedScopes>(`/api/v2/users/0/scopes?include_inherited=false`)).data);
        const registeredScopes = unsplitScopes(groups.items.find(group => group.id === 0)?.scopes ?? {allowed: [], denied: []});

        const defaultScopes: {[scope: string]: boolean} = {};

        for (const scope of Object.values(TokenScope)) {
            defaultScopes[scope] = registeredScopes[scope] ?? anonymousScopes[scope] ?? false;
        }

        return {
            user, userScopes, groups, defaultScopes, groupScopes: aggregatedGroupScopes
        }
    },

    component: () => {
        const {t} = useTranslation("admin_users");
        const {user, defaultScopes, groupScopes, userScopes} = Route.useLoaderData();
        const search = Route.useSearch();

        const {handleSubmit, control, watch} = useForm<UserScopesForm>({
            defaultValues: {
                scopes: {
                    ...Object.fromEntries(Object.keys(defaultScopes).map((scope) => [scope, "-1"])),
                    ...scopesToForm(userScopes)
                }
            }
        });

        const api = useAPI();
        const navigate = Route.useNavigate();
        const toast = useToast();

        const saveScopes = useCallback(async (data: UserScopesForm) => {
            console.log(data);

            const splitted: SplittedScopes = splitScopes(formToScopes(data.scopes));
            await api.put(`/api/v2/users/${user.id}/scopes`, splitted);

            toast({
                title: t("Privileges saved."),
                status: "success"
            });

            await navigate({
                to: AdminUsersRoute.fullPath,
                search
            });
        }, [api, user]);

        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("{{name}} - privileges", {name: user.name})}</h2>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={ROUTES.ADMIN}>{t("Administration", {ns: "admin"})}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={".."} search={search}>{t("Users")}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink>{user.name}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={"."} params={{userId: user.id}}>{t("Privileges")}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>

            <form onSubmit={handleSubmit(saveScopes)}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>{t("Privilege")}</Th>
                            <Th textAlign={"center"}>{t("Default")}</Th>
                            <Th textAlign={"center"}>{t("Groups")}</Th>
                            <Th textAlign={"center"}>{t("Explicit")}</Th>
                            <Th textAlign={"center"}>{t("Result")}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.entries(defaultScopes).map(([scope, defaultValue]) => {
                           return <Tr key={scope}>
                               <Th>{scope}</Th>
                               <Td textAlign={"center"}>
                                   <ValueCheckbox
                                       defaultValue={!!defaultValue}
                                       opacity={.5}
                                   />
                               </Td>
                               <Td textAlign={"center"}>
                                   <ValueCheckbox
                                       groupValue={groupScopes[scope]}
                                       opacity={.5}
                                   />
                               </Td>
                               <Td textAlign={"center"}>
                                   <Center>
                                       <Controller
                                           control={control}
                                           name={`scopes.${scope}`}
                                           render={({field}) => (
                                               <RadioGroup
                                                   name={field.name}
                                                   value={field.value.toString()}
                                                   onChange={field.onChange}
                                               >
                                                   <HStack spacing={5}>
                                                       <Radio value={"-1"} colorScheme={"gray"}>{t("-")}</Radio>
                                                       <Radio value={"1"} colorScheme={"green"}>{t("Yes")}</Radio>
                                                       <Radio value={"0"} colorScheme={"red"}>{t("No")}</Radio>
                                                   </HStack>
                                               </RadioGroup>
                                           )}
                                       />
                                   </Center>
                               </Td>
                               <Td>
                                     <Center>
                                         <ValueCheckbox
                                            explicitValue={radioToBool(watch(`scopes.${scope}`))}
                                            groupValue={groupScopes[scope]}
                                            defaultValue={!!defaultValue}
                                         />
                                     </Center>
                               </Td>
                           </Tr>
                        })}
                    </Tbody>
                </Table>

                <FormButtons>
                    <Button type={"submit"}>{t("Save")}</Button>
                </FormButtons>
            </form>
        </>;
    }
});
