import {createFileRoute, Link} from '@tanstack/react-router'
import {useTranslation} from "../../../../utils/helpers";
import {getAPI, useAPI} from "../../../../api/api";
import {ExistingGroup, Group, GroupScopes} from "../../../../models/user";
import React, {useCallback} from "react";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../components/generic/breadcrumb";
import {
    BreadcrumbItem,
    BreadcrumbLink,
    FormControl,
    FormLabel, Input, Radio,
    RadioGroup,
    Table,
    Tbody, Td,
    Th,
    Thead,
    Tr, useToast
} from "@chakra-ui/react";
import {Route as AdminIndexRoute} from "../index";
import {Route as GroupsIndexRoute} from "./index";
import {TokenScope} from "../../../../models/tokenScope";
import {Controller, useForm} from "react-hook-form";
import {FormButtons} from "../../../../components/generic/form";
import {Button} from "../../../../components/generic/buttons";
import {formToScopes, ScopeRadioValue, scopesToForm, splitScopes, unsplitScopes} from "../users/$userId.privileges";

type GroupPatch = {
    name: string;
    scopes: {
        [scope: string]: ScopeRadioValue;
    }
}

export const Route = createFileRoute('/_site/admin/groups/$groupId/modify')({
    staleTime: 0,
    gcTime: 0,
    component: () => {
        const {t} = useTranslation("admin_users");
        const {group} = Route.useLoaderData();
        const {register, handleSubmit, control} = useForm<GroupPatch>({
            defaultValues: {
                name: group.name,
                scopes: {
                    ...Object.fromEntries(Object.values(TokenScope).map(scope => [scope, "-1"])),
                    ...scopesToForm(unsplitScopes(group.scopes)),
                }
            }
        });
        const api = useAPI();
        const toast = useToast();
        const navigate = Route.useNavigate();

        const modifyGroup = useCallback(async (data: GroupPatch) => {
            console.log(data.scopes);

            await api.patch<any, Group & GroupScopes>("/api/v2/groups/{groupId}", {
                name: data.name,
                scopes: splitScopes(formToScopes(data.scopes))
            }, {
                pathParams: {
                    groupId: group.id
                }
            });

            toast({
                title: t("Group has been modified."),
                status: "success",
                isClosable: true
            });

            await navigate({
                to: GroupsIndexRoute.fullPath
            });
        }, [api]);

        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("Modify group {{name}}", {name: group.name})}</h2>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>
                            {t("Administration", {ns: "admin"})}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={GroupsIndexRoute.fullPath}>
                            {t("Groups")}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink>
                            {t("{{name}}", {name: group.name})}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath}>
                            {t("Modify", {ns: "cwg_ops"})}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>

            <form onSubmit={handleSubmit(modifyGroup)}>
                <FormControl>
                    <FormLabel>{t("Name")}</FormLabel>
                    <Input {...register("name")} />
                </FormControl>
                <FormControl>
                    <FormLabel>{t("Privileges")}</FormLabel>

                    <Table>
                        <Thead>
                            <Tr>
                                <Th>{t("Privilege")}</Th>
                                <Th textAlign={"center"}>{t("Unset")}</Th>
                                <Th textAlign={"center"}>{t("Yes")}</Th>
                                <Th textAlign={"center"}>{t("No")}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {Object.values(TokenScope).map((scope, idx) =>
                                <Controller
                                    control={control}
                                    name={`scopes.${scope}`}
                                    render={({field}) => (
                                        <RadioGroup
                                            key={idx}
                                            as={Tr}
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                        >
                                            <Th>{scope}</Th>
                                            <Td textAlign={"center"}>
                                                <Radio
                                                    value={"-1"}
                                                    colorScheme={"gray"}
                                                    display={"block"}
                                                />
                                            </Td>
                                            <Td textAlign={"center"}>
                                                <Radio
                                                    value={"1"}
                                                    colorScheme={"green"}
                                                    display={"block"}
                                                />
                                            </Td>
                                            <Td textAlign={"center"}>
                                                <Radio
                                                    value={"0"}
                                                    colorScheme={"red"}
                                                    display={"block"}
                                                />
                                            </Td>
                                        </RadioGroup>
                                    )}
                                />
                            )}
                        </Tbody>
                    </Table>
                </FormControl>

                <FormButtons>
                    <Button type={"submit"}>{t("Modify", {ns: "cwg_ops"})}</Button>
                </FormButtons>
            </form>
        </>
    },
    params: {
        parse: (params) => {
            return {
                groupId: params.groupId
            }
        },
        stringify: (params) => {
            return {
                groupId: params.groupId
            }
        }
    },
    loader: async ({context, params}) => {
        const api = getAPI(context);
        const group = await api.get<ExistingGroup & GroupScopes>("/api/v2/groups/{groupId}", {
            pathParams: {
                groupId: params.groupId
            },
            params: {
                attribute: ["id", "name", "scopes"]
            }
        });

        return {
            group: group.data
        }
    }
})
