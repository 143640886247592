import {createFileRoute, Outlet} from '@tanstack/react-router'
import {getAPI} from "../../../../api/api";
import {getTagCategories} from "../../../../api/getTagCategories";
import {XwgFilter} from "../../../../models/xwgSearchQuery";
import {useTranslation} from "../../../../utils/helpers";
import React, {useMemo} from "react";
import {CwgSearchForm} from "../../../../components/cwg/cwgSearchForm";
import {ROUTES} from "../../../../constants";
import {UserSearchForm} from "../../../../components/users/searchForm";
import {UserSearch} from "../../../../models/user";
import Columns from "../../../../components/generic/columns";
import {getFixedT} from "../../../../utils/getFixedT";
import {combineTitle} from "../../../../utils/combineTitle";

export const Route = createFileRoute('/_site/cwg/search')({
    loader: async ({context}) => {
        const api = getAPI(context);

        const tagCategories = await getTagCategories({api});

        return {
            tagCategories,
        }
    },
    meta: ({matches, match}) => {
        const t = getFixedT("layout", match.context.request?.i18n);
        return combineTitle(matches, t("Search"))
    },
    validateSearch: (search: Record<string, unknown>) => {
        return {
            filter: (search.filter ?? {}) as XwgFilter
        }
    },
    component: () => {
        const {tagCategories} = Route.useLoaderData();
        const search = Route.useSearch();

        const {t: tb} = useTranslation("browse");
        const {t: ts} = useTranslation("search");

        const navigate = Route.useNavigate();

        const searchForm = useMemo(() => {
            return <CwgSearchForm
                tagCategories={tagCategories}
                filter={search.filter}
                onSearch={async ({names, ...filter}: XwgFilter) => {
                    if (!(names?.[0])) {
                        return;
                    }

                    await navigate({
                        to: ROUTES.XWG_SEARCH_WITH_QUERY,
                        params: {
                            query: names?.[0] as string
                        },
                        search: {
                            offset: 0,
                            limit: 100,
                            filter: filter
                        }
                    });
                }}
            />
        }, [tagCategories, search.filter]);

        return (
            <Columns>
                <>
                    <h3>{tb("Search the catalogue")}</h3>
                    {searchForm}

                    <h3>{ts("User search")}</h3>
                    <UserSearchForm
                        onSearch={async (search: UserSearch) => {
                            await navigate({
                                to: ROUTES.USER_SEARCH_WITH_QUERY,
                                params: {
                                    username: search.username
                                }
                            })
                        }}
                    />
                </>
                <><Outlet /></>
            </Columns>
        );
    }
});
