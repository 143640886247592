import {listAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";
import {scrollbarStyle} from "./scrollbar";

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(listAnatomy.keys);

const dividedStyle = definePartsStyle({
    container: {
        listStyleType: "none",
        m: "0 !important",
        p: 0,
    },

    item: {
        listStyleType: "none",
        borderTop: "solid 2px",
        borderBottom: "solid 2px",
        borderRadius: "xl",
        borderColor: "divider.500",
        mt: "-2px",
        mb: "-2px",
        p: 2,

        "&:first-of-type": {
            mt: "0",
        },

        "&:last-of-type": {
            mb: "0",
        },
    },
});

const mainMenuStyle = definePartsStyle({
    container: {
        listStyleType: "none",
        m: "0",
        p: "0",
        display: "flex",
        justifyContent: "stretch",
    },

    item: {
        flexGrow: 1,
        a: {
            display: "block",
            textAlign: "center",
            fontSize: "1.2em",
            fontWeight: "bold",
            letterSpacing: ".05em",
            lineHeight: "1.5em",
            padding: ".5em",
            color: "white",
            textShadow: ".05em .05em .15em black",
            textDecoration: "none",
            span: {
                display: "block",
            },
            _hover: {
                color: "#f8ebda",
            },
            _active: {
                color: "#f8ebda",
            },
        },
        "& .icon": {
            display: {
                base: "flex",
                md: "none"
            },
            justifyContent: "center",
            img: {
                width: "100%",
                maxWidth: "3em",
                aspectRatio: 1,
            },
        },
        "& .label": {
            display: {
                base: "none",
                md: "block"
            },
        },
        _hover: {
            ".icon": {
                filter: "sepia(1)",
            },
        },
        _active: {
            ".icon": {
                filter: "sepia(1)",
            }
        },
    },
});

const messageThreadsStyle = definePartsStyle({
    container: {
        listStyleType: "none",
        m: "0 !important",
        p: 0,
        ...scrollbarStyle,
    },
    item: {
        listStyleType: "none",
        borderTop: "solid 1px",
        borderColor: "divider.500",

        ">a": {
            display: "block",
            p: 2,
            textDecoration: "none",

            "h4": {
                m: 0,
            },

            _hover: {
                bg: "shade",
            }
        }
    }
});

const messagesStyle = definePartsStyle({
    container: {
        m: "0 !important",
        p: 0,
        ...scrollbarStyle,
    },

    item: {
        listStyleType: "none",
        borderTop: "solid 1px",
        borderColor: "divider.700",

        "&:first-of-type": {
            borderTop: "none",
        },

        "blockquote": {
            bg: "shade",
            border: "solid 1px",
            borderColor: "divider.500",
            borderRadius: "md",
            p: 2,
            my: 4,
        }
    }
});

export const listTheme = defineMultiStyleConfig({
    variants: {
        divided: dividedStyle,
        "main-menu": mainMenuStyle,
        "message-threads": messageThreadsStyle,
        "messages": messagesStyle
    }
});
