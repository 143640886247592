import {createFileRoute, useLoaderData, useNavigate} from '@tanstack/react-router'
import React from "react";
import {RISON} from "rison2";
import {AdvancedSearchForm} from "../../../../../components/cwg/advancedSearchForm";
import {Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading} from "@chakra-ui/react";
import {useTranslation} from "../../../../../utils/helpers";
import {XwgFilter} from "../../../../../models/xwgSearchQuery";
import {TFunction} from "i18next";
import {TagCategory} from "../../../../../models/tag";
import {loaderDeps, loadXwgs, QueryParams, validateSearch} from "../../../../../utils/collectionListing/third";
import {CwgCatalogue} from "../../../../../components/cwg/cwgCatalogueView";
import {useMatchData} from "../../../../../utils/findInMatchesData";
import {UpToZeroLevelContext} from "../../../../../utils/collectionListing/zero";
import {ROUTES} from "../../../../../constants";

export const Route = createFileRoute('/_site/cwg/browse/advanced/$filter')({
    params: {
        parse: (params: Record<string, string>) => {
            return {
                filter: params.filter
                    ? (
                        (typeof(params.filter) !== "object")
                            ? RISON.parse(params.filter)
                            : params.filter
                    ) : {}
            }
        },
        stringify: (params) => ({
            filter: typeof(params.filter) === "object" ? RISON.stringify(params.filter) : params.filter
        })
    },
    validateSearch: validateSearch<QueryParams>(),
    loaderDeps: loaderDeps,
    component: AdvancedBrowseResult,
    beforeLoad: ({params}) => {
        return {
            filter: params.filter,
        }
    },
    loader: ({context, deps}: {context: UpToZeroLevelContext, deps: QueryParams}) => {
        return loadXwgs({})({context, deps});
    }
});

function constructFilterName(filter: XwgFilter, t: TFunction, tagCategories: TagCategory[]): string {
    const conditions: string[] = [];

    const isCollectedByMe: boolean | undefined = typeof(filter.collected) === "boolean" ? filter.collected : (filter.collected as (string | boolean)[])?.find((v) => typeof(v) === "boolean");
    const isOfferedByMe: boolean | undefined = typeof(filter.offered) === "boolean" ? filter.offered : (filter.offered as (string | boolean)[])?.find((v) => typeof(v) === "boolean");
    const isWantedByMe: boolean | undefined = typeof(filter.wanted) === "boolean" ? filter.wanted : (filter.wanted as (string | boolean)[])?.find((v) => typeof(v) === "boolean");
    const isNotWantedByMe: boolean | undefined = typeof(filter.not_wanted) === "boolean" ? filter.not_wanted : (filter.not_wanted as (string | boolean)[])?.find((v) => typeof(v) === "boolean");

    if (isCollectedByMe === true) {
        conditions.push(t("my collection"));
    } else if (isCollectedByMe === false) {
        conditions.push(t("missing in my collection"));
    }

    if (isOfferedByMe !== undefined) {
        if (conditions.length) {
            conditions.push(t(isOfferedByMe ? "that I offers" : "that I don't offer"));
        } else {
            conditions.push(t(isOfferedByMe ? "my offers" : "not in my offers"));
        }
    }

    if (isWantedByMe !== undefined) {
        if (conditions.length) {
            conditions.push(t(isWantedByMe ? "that I want" : "that are missing in my wants"));
        } else {
            conditions.push(t(isWantedByMe ? "my wants" : "not in my wants"));
        }
    }

    if (isNotWantedByMe !== undefined) {
        if (conditions.length) {
            conditions.push(t(isNotWantedByMe ? "that I don't want" : "that are not in my not wants"));
        } else {
            conditions.push(t(isNotWantedByMe ? "my not wanted" : "not in my not wanted"));
        }
    }

    if (!conditions.length) {
        conditions.push(t("all"));
    }

    const categoriesTags = filter.tags?.filter((tag) => typeof(tag) === "object");
    const userTags = filter.tags?.filter((tag) => typeof(tag) === "string");

    const tagsByCategories: {[key: string]: string[]} = {};
    if (categoriesTags && categoriesTags.length) {
        categoriesTags.forEach((tag) => {
            if (!tagsByCategories[tag.category]) {
                tagsByCategories[tag.category] = [];
            }
            tagsByCategories[tag.category].push(tag.name);
        });
    }

    if (categoriesTags && categoriesTags.length) {
        conditions.push(
            t("in categories {{categories}}", {
                count: categoriesTags.length,
                categories: Object.entries(tagsByCategories).map(([category, tags]) => {
                    const tagCategory = tagCategories.find((categoryItem) => categoryItem.id === category);
                    const tagNames = tags.map((tagItem) => tagCategory?.tags.find((tagInCategory) => tagInCategory.id === tagItem)?.name);

                    return t("{{category}}: {{tags}}", {
                        category: tagCategory?.name,
                        tags: tagNames.join(t(" or "))
                    })
                }).join(t(" and "))
            })
        );
    }

    if (userTags && userTags.length) {
        conditions.push(t("with tags {{tags}}", {tags: userTags.join(t(" or "))}));
    }

    if (filter.names && filter.names.length) {
        conditions.push(t("with name containing {{names}}", {names: filter.names.join(t(" or "))}));
    }

    if (filter.ids && filter.ids.length) {
        conditions.push(t("with ID {{ids}}", {ids: filter.ids.join(t(" or "))}));
    }

    if (filter.cat_no && filter.cat_no.length) {
        conditions.push(t("with catalog number {{cat_nos}}", {cat_nos: filter.cat_no.join(t(" or "))}));
    }

    if (filter.created_by && filter.created_by.length) {
        conditions.push(t("created by {{users}}", {users: filter.created_by.join(t(" or ")), count: filter.created_by.length}));
    }

    const collectedOtherUsers = (filter.collected as (string | boolean)[])?.filter((v) => typeof(v) === "string");
    const offeredOtherUsers = (filter.offered as (string | boolean)[])?.filter((v) => typeof(v) === "string");
    const wantedOtherUsers = (filter.wanted as (string | boolean)[])?.filter((v) => typeof(v) === "string");
    const notWantedOtherUsers = (filter.not_wanted as (string | boolean)[])?.filter((v) => typeof(v) === "string");

    if (collectedOtherUsers && collectedOtherUsers.length) {
        conditions.push(t("collected by {{users}}", {users: collectedOtherUsers.join(t(" or ")), count: collectedOtherUsers.length}));
    }

    if (offeredOtherUsers && offeredOtherUsers.length) {
        conditions.push(t("offered by {{users}}", {users: offeredOtherUsers.join(t(" or ")), count: offeredOtherUsers.length}));
    }

    if (wantedOtherUsers && wantedOtherUsers.length) {
        conditions.push(t("wanted by {{users}}", {users: wantedOtherUsers.join(t(" or ")), count: wantedOtherUsers.length}));
    }

    if (notWantedOtherUsers && notWantedOtherUsers.length) {
        conditions.push(t("not wanted by {{users}}", {users: notWantedOtherUsers.join(t(" or ")), count: notWantedOtherUsers.length}));
    }

    return t("Showing {{conditions}}.", {conditions: conditions.join(" ")});
}

function AdvancedBrowseResult() {
    const {userTags} = useLoaderData({from: "/_site/cwg/browse/advanced"});
    const tagCategories = useMatchData<TagCategory[]>("tagCategories", true);

    const nav = useNavigate({from: ROUTES.BROWSE});
    const {t} = useTranslation("browse");
    const {filter} = Route.useParams();

    return <>
        <Accordion allowMultiple>
            <AccordionItem>
                <Heading as={"h3"} m={0} size={"sm"}>
                    <AccordionButton>
                        <Box as={"span"} flex={"1"} textAlign={"left"}>
                            {constructFilterName(filter, t, tagCategories)}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </Heading>
                <AccordionPanel>
                    <AdvancedSearchForm
                        tagCategories={tagCategories}
                        userTags={userTags}
                        filter={filter}
                        onFilter={async (newFilter) => {
                            await nav({
                                to: ROUTES.BROWSE_ADVANCED_WITH_FILTER,
                                params: {
                                    filter: newFilter
                                },
                                search: (prev: QueryParams) => ({
                                    ...prev,
                                    offset: 0
                                }),
                                replace: true,
                            });
                        }} />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
        <CwgCatalogue />
    </>;
}
