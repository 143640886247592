import {createFileRoute} from '@tanstack/react-router'
import {useTranslation} from "../../../utils/helpers";
import React, {useEffect, useState} from "react";
import {UserModel} from "../../../models/user";
import {getAPI, useAPI} from "../../../api/api";
import {listAllUsers} from "../../../api/listUsers";
import {Button} from "../../../components/generic/buttons";
import {ProfileLink} from "../../../components/users/profileLink";
import {Table, Thead, Tr, Th, Tbody, Td, Center, ButtonGroup} from "@chakra-ui/react";

export const Route = createFileRoute('/_site/users/stats_offers')({
  component: TopOffers,
  loader: async ({context}) => {
        const api = getAPI(context);
        const users = await listAllUsers({
            api,
            query: {
                filter: {
                    hide_from_top: false,
                    min_offered: 1,
                    offers_exclude_owned: false
                },
                display: {
                    limit: 1000,
                    order: ["offers:DESC", "name:ASC"],
                    attributes: ["id", "name", "offers.unique"]
                }
            }
        });

        return {
            users
        };
    }
});

function TopOffers() {
    const {t} = useTranslation("overview");

    const {users: loaderUsers} = Route.useLoaderData();
    const [excludeOwned, setExcludeOwned] = useState<boolean>(false);
    const [users, setUsers] = useState<UserModel[]>(loaderUsers);
    const api = useAPI();

    useEffect(() => {
        const reloadUsers = async () => {
            if (excludeOwned) {
                setUsers(await listAllUsers({
                    api,
                    query: {
                        filter: {
                            hide_from_top: false,
                            min_offered: 1,
                            offers_exclude_owned: excludeOwned
                        },
                        display: {
                            limit: 1000,
                            order: ["offers:DESC", "name:ASC"],
                            attributes: ["id", "name", "offers.unique"]
                        }
                    }
                }));
            } else {
                setUsers(loaderUsers);
            }
        };
        reloadUsers();
    }, [excludeOwned, loaderUsers]);

    return <>
        <h2>{t("Who offers how much?")}</h2>

        <Center mb={4}>
            <ButtonGroup variant={"secondary"} size={"sm"} isAttached>
                <Button
                    onClick={() => setExcludeOwned(false)}
                    isActive={!excludeOwned}
                >
                    {t("All", {ns: "browse"})}
                </Button>

                <Button
                    onClick={() => setExcludeOwned(true)}
                    isActive={excludeOwned}
                >

                    {t("Not collected by me only", {ns: "browse"})}
                </Button>
            </ButtonGroup>
        </Center>

        <Table
            maxW={"30em"}
            mx={"auto"}
        >
            <Thead>
                <Tr>
                    <Th className={"td-shrink"}>{t("#")}</Th>
                    <Th>{t("Name")}</Th>
                    <Th className={"td-shrink"}>{t("Pieces")}</Th>
                </Tr>
            </Thead>
            <Tbody>
            {users.map((user, idx) => <Tr key={user.id}>
                <Td>{idx + 1}</Td>
                <Td><ProfileLink user={user} /></Td>
                <Td>{user.offers.unique}</Td>
            </Tr>)}
            </Tbody>
        </Table>
    </>
}
