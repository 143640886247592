import {createFileRoute, useNavigate, useSearch} from '@tanstack/react-router'
import {thirdLevelIndexRoute} from "../../../../../../../../utils/collectionListing";
import React from "react";
import {CwgCatalogue} from "../../../../../../../../components/cwg/cwgCatalogueView";
import {useTranslation} from "../../../../../../../../utils/helpers";
import {XwgFilter} from "../../../../../../../../models/xwgSearchQuery";
import _ from "lodash";

export const Route = createFileRoute('/_site/users/_public_profile/wants/$username/$primarySorting/$primaryCategoryName/$secondaryCategoryName/')({
    ...thirdLevelIndexRoute({
        validateSearch: (search: Record<string, string>) => {
            return {
                filter: (search.filter ?? {}) as XwgFilter
            }
        },
        beforeLoad: async ({context: {filter, ...restOfContext}, search}) => {
            filter = _.mergeWith({}, filter, search.filter, (objValue, srcValue) => {
                if (Array.isArray(objValue) && Array.isArray(srcValue)) {
                    return [...objValue, ...srcValue];
                }
            });

            return {
                ...restOfContext,
                filter
            }
        },
        component: CollectionViewComponent
    })
});


export function CollectionViewComponent() {
    const {t} = useTranslation("browse");
    const navigate = useNavigate();
    const search = useSearch({strict: false});

    return <CwgCatalogue
        additionalFilters={[
            {
                name: t("All"),
                filter: {},
            },
            {
                name: t("Only what I have collected"),
                filter: {
                    collected: [true]
                }
            },
            {
                name: t("Only what I offer"),
                filter: {
                    offered: [true]
                }
            },
        ]}
        activeFilter={search.filter}
        onFilterChange={async (newFilter) => {
            await navigate({
                search: (prev) => ({
                    ...prev,
                    filter: newFilter
                })
            })
        }}
    />
}
