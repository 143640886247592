import {createFileRoute, Link} from '@tanstack/react-router'
import {getAPI, useAPI} from "../../../../api/api";
import {CreateNews, News} from "../../../../models/news";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../components/generic/breadcrumb";
import {useTranslation} from "../../../../utils/helpers";
import React from "react";
import {BreadcrumbItem, BreadcrumbLink, useToast} from "@chakra-ui/react";
import {Route as AdminIndexRoute} from "../index";
import {Route as NewsIndexRoute} from "./index";
import {NewsForm} from "../../../../components/news/form";

export const Route = createFileRoute('/_site/admin/news/modify/$id')({
    component: () => {
        const {t} = useTranslation("news");
        const {news} = Route.useLoaderData();
        const api = useAPI();
        const toast = useToast();

        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("Modify news {{title}}", {title: news.title})}</h2>

                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>{t("Administration", {ns: "admin"})}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={NewsIndexRoute.fullPath}>{t("News")}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath} params={{id: news.id}}>{t("Modify")}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>

            <NewsForm
                news={news}
                onSubmit={async (data: CreateNews) => {
                    await api.patch<News>(`/api/v2/news/${news.id}`, data);

                    toast({
                        title: t("News has been successfully modified."),
                        status: "success",
                        isClosable: true
                    });
                }}
            />
        </>
    },
    params: {
        parse: (rawParams: Record<string, string>) => ({
            id: parseInt(rawParams.id)
        }),
        stringify: (params) => ({
            id: params.id?.toString()
        })
    },
    loader: async ({context, params}) => {
        const api = getAPI(context);
        const resp = await api.get<News>(`/api/v2/news/${params.id}`);
        return {
            news: resp.data
        }
    }
})
