import {createFileRoute, redirect} from '@tanstack/react-router'
import {NotFoundError} from "../../../../utils/errors";
import {getAPI, useAPI} from "../../../../api/api";
import {useCurrentUser, User} from "../../../../models/user";
import {useTranslation} from "../../../../utils/helpers";
import {
    HistoryEntry,
    OfferData,
    PublishStatus,
    XwgExtendedCollection,
    XwgOffer,
    XwgStatus,
    XwgUserCollection,
    XwgWithId
} from "../../../../models/xwg";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Columns} from "../../../../components/generic/columns";
import {CwgTabs} from "../../../../components/cwg/cwgTabs";
import {CwgHeader} from "../../../../components/cwg/cwgHeader";
import {StateShifter} from "../../../../components/cwg/stateShifter";
import {CwgOps} from "../../../../components/cwg/cwgOps";
import {CwgInfo} from "../../../../components/cwg/cwgInfo";
import CollectionDetail from "../../../../components/cwg/collectionDetail";
import {CreateCollectionForm} from "../../../../components/cwg/collectionCreateForm";
import {OfferForm} from "../../../../components/cwg/offerForm";
import {ListResponse} from "../../../../models/response";
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Center, useToast} from "@chakra-ui/react";
import {getFixedT} from "../../../../utils/getFixedT";
import {combineTitle} from "../../../../utils/combineTitle";
import {ErrorComponent} from "../../../../components/error";
import {SendMessageForm} from "../../../../components/messages/sendMessage";
import {MessagesView} from "../../../../components/messages/messageView";
import Loader from "../../../../components/generic/loader";
import {MessageThreadDetail} from "../../../../models/messages";
import _ from "lodash";
import {XwgAttribute} from "../../../../models/xwgSearchQuery";

type XwgListingLoaderData = {
    xwg: XwgWithId,
    history: HistoryEntry[]
};

export const Route = createFileRoute('/_site/cwg/index/$xwgId/')({
    meta: ({matches, match, loaderData}) => {
        const t = getFixedT("cwg_index", match.context.request?.i18n);

        if (!loaderData) {
            return [];
        }

        const {xwg} = loaderData as {xwg: XwgWithId};

        return [
            ...combineTitle(matches, t("{{name}} (version {{version}})", {name: xwg.name, version: xwg.version})),
            ...[
                {
                    property: "og:title",
                    content: t("{{name}} (version {{version}})", {name: xwg.name, version: xwg.version})
                },
                {
                    property: "og:image",
                    content: xwg.images?.find(image => image.type == "primary")?.url
                }
            ]
        ];
    },
    component: CwgIndex,
    errorComponent: ErrorComponent,
    params: {
        parse: (params: Record<string, string>): {xwgId: number} => {
            if (!params.xwgId) {
                throw new NotFoundError();
            }

            const xwgId = Number.parseInt(params.xwgId);
            if (Number.isNaN(xwgId)) {
                throw new NotFoundError();
            }

            return {
                xwgId
            };
        },
        stringify: (params) => {
            return {
                xwgId: params.xwgId?.toString()
            };
        }
    },
    loader: async ({context, params: {xwgId}}): Promise<XwgListingLoaderData> => {
        const api = getAPI(context);

        const xwg = (await api.get<XwgWithId>("/api/v2/xwg/{xwgId}/", {
            pathParams: {
                xwgId
            }
        })).data;

        if (xwg.id !== xwgId) {
            throw redirect({
                to: Route.fullPath,
                params: {
                    xwgId: xwg.id
                }
            });
        }

        const history = (await api.get<ListResponse<HistoryEntry>>(
            `/api/v2/xwg/${xwg.id}/history`,
            {
                params: {
                    "precision": "minute"
                },
            }
        )).data.items;

        return {
            xwg,
            history
        }
    }
});

function CwgCommunication({xwg}: { xwg: XwgWithId }) {
    const {t} = useTranslation("cwg_ops");
    const [thread, setThread] = useState<MessageThreadDetail | null>(null);

    const api = useAPI();

    const reloadMessages = useCallback(async (signal?: AbortController) => {
        setThread((await api.get<MessageThreadDetail>("/api/v2/xwg/{xwgId}/messages", {
            pathParams: {
                xwgId: xwg.id
            },
            signal: signal?.signal
        })).data);
    }, [xwg]);

    useEffect(() => {
        const signal = new AbortController();
        reloadMessages(signal);
        return () => {
            signal.abort();
        }
    }, []);

    return <Box mb={8}>
        <h3>{t("Communication")}</h3>
        {thread
            ? <MessagesView thread={thread} />
            : <Center>
                <Loader w={16} h={16} />
            </Center>
        }
        <SendMessageForm xwgId={xwg.id} showSubject={false} mt={4} onMessageSent={() => reloadMessages()} />
    </Box>;
}

function CwgIndex() {
    const currentUser = useCurrentUser();
    const {t} = useTranslation("cwg_index");

    const {xwg, history} = Route.useLoaderData({
        select: (data: XwgListingLoaderData) => {
            const {xwg, history} = data;
            return {
                xwg: new XwgWithId(xwg),
                history,
            }
        }
    });

    const [collection, setCollection] = useState<XwgExtendedCollection[]>(
        (xwg?.collection)
            ? xwg.collection
            : []
    );

    const [status, setStatusInternal] = useState<XwgStatus>(xwg.status as XwgStatus);
    const [publishStatus, setPublishStatus] = useState<PublishStatus>(xwg.publish_status as PublishStatus);
    const [onHoldBy, setOnHoldBy] = useState<User | null>(xwg.hold ?? null);

    const [collected, setCollected] = useState<XwgUserCollection[] | undefined>(xwg.collected);
    const [offered, setOffered] = useState<XwgOffer[] | undefined>(xwg.offered);
    const [wanted, setWanted] = useState<User[] | undefined>(xwg.wanted);
    const [notWanted, setNotWanted] = useState<User[] | undefined>(xwg.not_wanted);

    const api = useAPI();

    const setStatus = useCallback(async (setter: ((prevState: XwgStatus) => XwgStatus) | XwgStatus) => {
        setStatusInternal(setter);
        const newStatus = typeof setter === "function" ? setter(_.assign({}, status) as XwgStatus) : setter;

        const attrs: XwgAttribute[] = [];

        if (newStatus.collected !== status?.collected) {
            attrs.push("collected");
        }

        if (newStatus.offered !== status?.offered) {
            attrs.push("offered");
        }

        if (newStatus.wanted !== status?.wanted) {
            attrs.push("wanted");
        }

        if (newStatus.not_wanted !== status?.not_wanted) {
            attrs.push("not_wanted");
        }

        if (attrs.length) {
            const newXwgData = (await api.get<XwgWithId>("/api/v2/xwg/{xwgId}", {
                pathParams: {
                    xwgId: xwg.id
                },
                params: {
                    "attribute": attrs
                }
            })).data;

            if (newXwgData.collected) {
                setCollected(newXwgData.collected);
            }

            if (newXwgData.offered) {
                setOffered(newXwgData.offered);
            }

            if (newXwgData.wanted) {
                setWanted(newXwgData.wanted);
            }

            if (newXwgData.not_wanted) {
                setNotWanted(newXwgData.not_wanted);
            }
        }
    }, [xwg])

    const setCollectionAndStatus = useCallback((newCollection: XwgExtendedCollection[]) => {
        setCollection(newCollection);
        setStatusInternal(status => {
            if (!status) {
                return status;
            }

            status.collected = newCollection.length > 0;
            return status;
        });

        const totalPieces = newCollection.reduce((acc, collection) => acc + (collection.pieces ?? 1), 0);
        const newestDate = newCollection.reduce((acc: Date | undefined, collection) => {
            const date = new Date(collection.date);
            return (!acc || date > acc) ? date : acc;
        }, undefined);

        if (totalPieces > 0) {
            const currentCollection = collected?.find(collection => collection.user.id === currentUser?.id);
            if (!currentCollection) {
                setCollected([...(collected ?? []), {
                    date: newestDate?.toISOString() as string,
                    pieces: totalPieces,
                    user: currentUser as User
                }])
            } else {
                setCollected(collected?.map(existingCollection => {
                    if (existingCollection.user.id !== currentUser?.id) {
                        return existingCollection;
                    }

                    return {
                        date: newestDate?.toISOString() as string,
                        pieces: totalPieces,
                        user: currentUser as User
                    };
                }));
            }
        } else {
            setCollected(collected?.filter(collection => collection.user.id !== currentUser?.id))
        }
    }, [currentUser]);

    const setOfferForUser = useCallback((newOffer: OfferData) => {
        if (newOffer.pieces === 0) {
            setOffered(offered?.filter(offer => offer.user.id !== currentUser?.id));
        } else {
            setOffered(offered => {
                const newXwgOffer: XwgOffer = {
                    ...newOffer,
                    user: currentUser as User
                };

                if (!offered) {
                    return [newXwgOffer]
                }

                const existingOffer = offered.find(offer => offer.user.id == currentUser?.id);
                if (!existingOffer) {
                    return [...offered, newXwgOffer]
                }

                return offered.map(existingOffer => {
                    if (existingOffer.user.id !== currentUser?.id) {
                        return existingOffer;
                    }

                    return newXwgOffer;
                });
            });
        }

        setStatusInternal(status => {
            if (!status) {
                return status;
            }

            status.offered = newOffer.pieces > 0;
            return status;
        });
    }, [currentUser])

    useEffect(() => {
        if (xwg.collection) {
            setCollection(xwg.collection);
        }

        setStatusInternal(xwg.status as XwgStatus);
        setCollected(xwg.collected);
        setOffered(xwg.offered);
        setWanted(xwg.wanted);
        setNotWanted(xwg.not_wanted);
    }, [xwg.collection, xwg.status, xwg.collected, xwg.offered, xwg.wanted, xwg.not_wanted]);

    const tabs = useMemo(() => {
        return <CwgTabs
            offered={!XwgWithId.isPublished(publishStatus) ? undefined : offered}
            collected={!XwgWithId.isPublished(publishStatus) ? undefined : collected}
            wanted={!XwgWithId.isPublished(publishStatus) ? undefined : wanted}
            notWanted={!XwgWithId.isPublished(publishStatus) ? undefined : notWanted}
            history={history}
        />
    }, [offered, collected, wanted, notWanted, publishStatus]);

    const comm = useMemo(() => {
        return !XwgWithId.isPublished(publishStatus)
            ? <CwgCommunication xwg={xwg} />
            : null;
    }, [publishStatus, xwg]);

    const toast = useToast();

    return <>
        <Columns>
            <div>
                <CwgHeader xwg={xwg} publishStatus={publishStatus}>
                    {status && <StateShifter xwg={xwg} status={status} onStatusChange={setStatus} w={"unset"} px={0} />}
                </CwgHeader>
                <main>
                    <CwgInfo
                        xwg={xwg}
                        publishStatus={publishStatus}
                        setPublishStatus={setPublishStatus}
                        onHoldBy={onHoldBy}
                        setOnHoldBy={setOnHoldBy}
                        mb={4}
                    />
                    <CwgOps xwg={xwg} />
                </main>
                {comm}
                {tabs}
            </div>
            <div>
                {publishStatus === "archived"
                    ? <Alert status={"error"}>
                        <AlertIcon />
                        <Box>
                            <AlertTitle>{t("Archived")}</AlertTitle>
                            <AlertDescription>{t("This xWG is archived and is available only for viewing purpose. You cannot do any action with it.")}</AlertDescription>
                        </Box>
                    </Alert>
                    : currentUser
                        ? <>
                            <h3>{t("Collection")}</h3>
                            <CollectionDetail
                                xwg={xwg}
                                publishStatus={publishStatus}
                                status={status}
                                collection={collection}
                                onUpdateCollection={setCollectionAndStatus}
                                className={"neg-margin"}
                            />

                            <h4 style={{marginTop: "1em"}}>{t("Put into your collection")}</h4>
                            <CreateCollectionForm onUpdateCollection={setCollectionAndStatus} xwgId={xwg.id} existingCollection={collection} />

                            <h4>{t("Offer for exchange")}</h4>
                            <OfferForm
                                xwgId={xwg.id}
                                value={offered?.find(offer => offer.user.id == currentUser.id)}
                                onOfferChange={offer => {
                                    setOfferForUser(offer);
                                    if (offer.pieces > 0) {
                                        toast({
                                            title: t("Offer has been updated.", {ns: "cwg_ops"}),
                                            status: "success"
                                        });
                                    } else {
                                        toast({
                                            title: t("Offer has been removed.", {ns: "cwg_ops"}),
                                            status: "success"
                                        });
                                    }
                                }}
                            />
                        </>
                        : <Alert status={"info"} mt={4}>
                            <AlertIcon />
                            <AlertDescription>
                                {t("You must first sign in to access the collection.")}
                            </AlertDescription>
                        </Alert>
                }
            </div>
        </Columns>
    </>
};

