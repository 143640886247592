import {createFileRoute} from '@tanstack/react-router'
import {thirdLevelLayoutRoute} from "../../../../../../../../utils/collectionListing";
import {
    ViewAllTab
} from "../../../../../../cwg-collection/browse/$primarySorting/$primaryCategoryName/$secondaryCategoryName/route";

export const Route = createFileRoute('/_site/users/_public_profile/offers/$username/$primarySorting/$primaryCategoryName/$secondaryCategoryName')({
    ...thirdLevelLayoutRoute({
        additionalTabs: [
            {
                label: <ViewAllTab />,
                to: "../all/"
            }
        ],
        tagRoute: () => Route.fullPath
    })
});
