import {useCurrentUser, User} from "../../models/user";
import {useTranslation} from "../../utils/helpers";
import React from "react";
import {CollapsibleTabList} from "../generic/tabs";
import {ContentHeader, ContentHeaderProps} from "../contentHeader";
import {Box, TabPanels, Tabs} from "@chakra-ui/react";
import {ROUTES} from "../../constants";


export function PublicProfile({title, toolbar, toolbarTitle, toolbarButtons, children, user, selected}: {children?: React.ReactNode, user: User, selected: string} & ContentHeaderProps) {
    const {t} = useTranslation("profile");
    const currentUser = useCurrentUser();

    if (!user) {
        return null;
    }

    const tabs = [
        {label: t("User profile"), to: ROUTES.USER_PUBLIC_PROFILE, params: {username: user.name}},
        {label: t("Collection"), to: ROUTES.USER_COLLECTION, params: {username: user.name}},
        {label: t("Offers"), to: ROUTES.USER_OFFERS, params: {username: user.name}},
        {label: t("Wants"), to: ROUTES.USER_WANTS, params: {username: user.name}},
        {label: t("Don't want"), to: ROUTES.USER_NOT_WANT, params: {username: user.name}},
        ...currentUser ? [{label: <>{t("Private messages", {ns: "messages"})}</>, to: ROUTES.USER_MESSAGES, params: {username: user.name}}] : [],
    ];

    return <>
        <ContentHeader
            title={title}
            toolbar={toolbar}
            toolbarTitle={toolbarTitle}
            toolbarButtons={toolbarButtons}
        />
        <Tabs index={tabs.findIndex(tab => selected == tab.to)}>
            <CollapsibleTabList tabs={tabs} />
            <TabPanels>
                <Box p={4}>
                    {user.private
                        ? <div>{t("User has decided to hide his public profile.")}</div>
                        : children
                    }
                </Box>
            </TabPanels>
        </Tabs>
    </>
}
