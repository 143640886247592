import { createFileRoute } from '@tanstack/react-router'
import React from "react";
import {Trans} from "react-i18next";
import {useTranslation} from "../../utils/helpers";

export const Route = createFileRoute('/_site/report')({
  component: () => {
      const {t} = useTranslation("problems");

      return <>
          <h2>{t("Report issue")}</h2>

          <p>
              <Trans ns={"problems"}>
                  To report an issue or suggest a new feature, send email to <EmailLink/> or
                  directly <a href={"https://gitlab.com/gcm-cz/cwg-collection/cwg-kolekce-eu/-/issues"} target={"_blank"}>here</a>.
              </Trans>
          </p>
          <p>
              <Trans ns={"problems"}>
                  You can browse existing issues reported by other users <a href={"https://gitlab.com/gcm-cz/cwg-collection/cwg-kolekce-eu/-/issues"} target={"_blank"}>here</a>.
              </Trans>
          </p>
      </>
  ;
  }
  });

  function EmailLink() {
      return <a href={"mailto:contact@cwg-kolekce.eu"}>contact@cwg-kolekce.eu</a>
  }
