import {NotFoundError} from "../errors";
import {UpToZeroLevelContext, zeroLevelIndexRedirect} from "./zero";
import {TagCategory} from "../../models/tag";
import {NavigateOptions, redirect} from "@tanstack/react-router";
import {RedirectWithParams} from "./index";
import {SecondLevelParams} from "./second";
import {ErrorComponent} from "../../components/error";
import {ThirdLevelParams} from "./third";

export type FirstLevelParams = {
    primarySorting: string;
};

export type FirstLevelContext = {
    primaryCategory: TagCategory;
};

export type UpToFirstLevelContext = FirstLevelContext & UpToZeroLevelContext;

export function getSecondLevelParams(firstLevelParams: FirstLevelParams, firstLevelContext: UpToFirstLevelContext): SecondLevelParams {
    return {
        ...firstLevelParams,
        primarySorting: `by-${firstLevelContext.primaryCategory.id}`,
        primaryCategoryName: firstLevelContext.primaryCategory.tags[0].id
    }
}

export async function firstLevelRedirect(to: NavigateOptions["to"], context: UpToFirstLevelContext): Promise<RedirectWithParams<ThirdLevelParams>> {
    const {params} = await zeroLevelIndexRedirect(to, context);
    const route = {
        to: to,
        params,
        replace: true,
    };

    console.log("Redirecting to...", route);

    return route;
}

export async function firstLevelBeforeLoad({context, params}: {context: UpToZeroLevelContext, params: FirstLevelParams}): Promise<FirstLevelContext> {
    let sort = params.primarySorting;
    if (sort.startsWith("by-")) {
        sort = sort.substring(3);
    }

    const primaryCategory = context.tagCategories.find(tagCategory => tagCategory.id === sort);

    if (!primaryCategory) {
        console.error("Primary category not found", params.primarySorting);
        throw new NotFoundError();
    }

    return {
        primaryCategory: primaryCategory
    };
}

export function firstLevelLayoutRoute() {
    return {
        errorComponent: ErrorComponent,
        beforeLoad: firstLevelBeforeLoad,
        loader: async ({context}: {context: UpToFirstLevelContext}) => ({
            primaryCategory: context.primaryCategory,
        }),
    }
}

export function firstLevelIndexRoute(route: NavigateOptions["to"]) {
    return {
        beforeLoad: async ({context}: {context: UpToFirstLevelContext}) => { throw redirect(await firstLevelRedirect(route, context)); },
    }
}
