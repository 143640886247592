import React from "react";
import * as Locales from "date-fns/locale";
import {Locale} from "date-fns/locale";
import {format} from "date-fns";
import defaulti18n from "i18next";
import {useTranslation} from "react-i18next";
import {I18next} from "i18next-http-middleware";

export const formatDate = (date: string | Date | null | undefined, i18n: I18next = defaulti18n) => {
    const locale = (Locales as {[key: string]: Locale})[i18n.language];

    if (!date) {
        return null;
    }

    return format(date, "P", {locale});
}

const formatDateTime = (date: string | Date | null | undefined, i18n: I18next = defaulti18n) => {
    const locale = (Locales as {[key: string]: Locale})[i18n.language];

    if (!date) {
        return null;
    }

    return format(date, "Pp", {locale});
}

const FormattedDate = ({date}: {date: string | Date | null | undefined}) => {
    if (!date) {
        return null;
    }

    const {i18n} = useTranslation();

    return <>{formatDate(date, i18n)}</>;
}

const FormattedDateTime = ({date}: {date: string | Date | null | undefined}) => {
    if (!date) {
        return null;
    }

    const {i18n} = useTranslation();

    return <>{formatDateTime(date, i18n)}</>;
}

export {
    FormattedDate,
    FormattedDateTime,
};
