import {User, UserAttribute, UserFilter, UserModel, UserRequest, UserResponse} from "../models/user";
import {ListResponse} from "../models/response";
import {NotFoundError} from "../utils/notFoundError";
import {WithAPI} from "./api";

type ListProps = {
    query: UserRequest,
} & WithAPI;

export async function listUsers({query, api}: ListProps): Promise<UserResponse> {
    const response = await api.post<ListResponse<User>>("/api/v2/users/query", query);
    const {items, ...rest} = response.data;

    return {
        grid: response.headers["x-grid"] || "",
        items: items.map(user => new UserModel(user)),
        ...rest
    };
}

type GetById = {
    id: number
};

type GetByName = {
    username: string
};

type GetUserProps = (GetByName | GetById) & WithAPI & {
    attributes?: UserAttribute[]
};

export async function getUser({attributes, api, ...props}: GetUserProps): Promise<UserModel> {
    if (!attributes) {
        attributes = [];
    }

    for (const attr of ["id", "name"] as UserAttribute[]) {
        if (!attributes.includes(attr)) {
            attributes.push(attr);
        }
    }

    const filter: UserFilter = {};

    if ((props as GetByName).username !== undefined) {
        filter.names = [(props as GetByName).username];
    }

    if ((props as GetById).id !== undefined) {
        filter.ids = [(props as GetById).id];
    }

    const response = await api.post(`/api/v2/users/query`, {
        filter: filter,
        display: {
            attributes: attributes
        }
    });

    if (response.data.items.length === 0) {
        throw new NotFoundError("Specified user was not found.", response.headers["x-grid"] || "");
    }

    return response.data.items[0];
}

export async function listAllUsers({query, api}: ListProps): Promise<UserModel[]> {
    let offset = 0;
    let response;

    const users: UserModel[] = [];

    do {
        if (!query.display) {
            query.display = {};
        }

        query.display.offset = offset;

        response = await api.post<ListResponse<User>>("/api/v2/users/query", query);
        offset += response.data.items.length;
        users.push(...response.data.items.map(user => new UserModel(user)));
    } while (offset < response.data.count && response.data.items.length > 0);

    return users;
}
