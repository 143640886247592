import { createFileRoute } from '@tanstack/react-router'
import {TokenScope} from "../../../models/tokenScope";
import {NotFoundError} from "../../../utils/notFoundError";
import {NotFound} from "../../../components/error";

export const Route = createFileRoute('/_site/admin')({
    beforeLoad: async ({context}) => {
        if (!context.currentUser.currentUser?.scope.includes(TokenScope.ADMIN_ACCESS)) {
            throw new NotFoundError();
        }
    },
    notFoundComponent: NotFound,
})
