import {createFileRoute, Link} from '@tanstack/react-router'
import * as React from 'react'
import {useEffect, useRef, useState} from 'react'
import {Trans} from "react-i18next";
import {Columns} from "../../components/generic/columns";
import LoginForm from "../../components/users/loginForm";
import SignupForm from "../../components/users/signupForm";
import {Statistics, useCurrentUser} from "../../models/user";
import {getAPI, useAPI} from "../../api/api";
import {listUsers} from "../../api/listUsers";
import {listXwg} from "../../api/listXwg";
import {Dd, Dl, Dt} from "../../components/generic/dl";
import {FormattedNumber} from "../../components/generic/number";
import {FormattedDate} from "../../components/generic/date";
import {ErrorComponent} from "../../components/error";
import {ListResponse} from "../../models/response";
import {News} from "../../models/news";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button, ButtonGroup,
    Center,
    Heading,
    HStack,
    List,
    ListItem,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Table,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
    UnorderedList,
} from "@chakra-ui/react";
import {ProfileLink} from "../../components/users/profileLink";
import {CopyToClipboard} from "../../components/generic/copyToClipboard";
import Markdown from "react-markdown";
import {useTranslation} from "../../utils/helpers";
import {CanceledError} from "axios";
import Loader from "../../components/generic/loader";
import {useMatchData} from "../../utils/findInMatchesData";
import {RequestData} from "../../models/requestData";
import {XwgSearchQuery} from "../../models/xwgSearchQuery";
import {XwgWithId} from "../../models/xwg";
import {ROUTES} from "../../constants";

export const Route = createFileRoute('/_site/')({
    pendingComponent: () => null,
    component: () => {
        const cu = useCurrentUser();
        if (cu) {
            return <Index />;
        } else {
            return <AnonymousIndex />;
        }
    },
    errorComponent: ErrorComponent,
    loader: async ({context}) => {
        const api = getAPI(context);

        const news = (await api.get<ListResponse<News>>("/api/v2/news", {
            params: {
                limit: 3,
                attribute: ["id", "date", "author", "title", "text"]
            }
        })).data;

        if (context.currentUser.currentUser) {
            const bestCollectors = await listUsers({
                api,
                query: {
                    filter: {
                        hide_from_top: false
                    },
                    display: {
                        limit: 10,
                        order: ["count:DESC", "name:ASC"],
                        attributes: ["id", "name", "total.unique"]
                    }
                }
            });

            const last = await listXwg({
                api,
                query: {
                    filter: {
                        "collected": true
                    },
                    display: {
                        limit: 50,
                        order: ["last_collected_date:DESC", "name:ASC", "version:ASC"],
                        attributes: ["id", "name", "version", "status"]
                    }
                }
            });

            return {
                bestCollectors: bestCollectors,
                last: last,
                news: news.items,
            }
        } else {
            return {
                bestCollectors: null,
                last: null,
                news: news.items,
            }
        }
    }
});

function NewsItem({item}: {item: News}) {
    const {t} = useTranslation("news");

    return <Box py={2}>
        <Heading size={"sm"} noOfLines={1}>
            <FormattedDate date={item.date} />
            {" "}&ndash;{" "}
            {item.title}
        </Heading>
        <Text variant={"article"}>
            <Markdown>{item.text}</Markdown>
        </Text>
        <HStack gap={4} justifyContent={"space-between"} mt={2}>
            <Link to={ROUTES.NEWS_DETAIL} params={{id: item.id}}>{t("Permanent link")}</Link>
            <span>{t("Published by:")} <ProfileLink user={item.author} /></span>
        </HStack>
    </Box>
}

function AnonymousIndex() {
    const request = useMatchData<RequestData>("request", true);
    const {news} = Route.useLoaderData();
    const {t} = useTranslation("layout");

    let error: string | undefined = undefined;

    const url = new URL(request.url);
    switch (url.searchParams.get("error")) {
        case null:
        case undefined:
            break;

        case "access_denied":
            error = t("Invalid username and/or password.");
            break;

        default:
            error = t("Login failed for unknown reason. Please, try again later.");
            break;
    }

    return <>
        <p>
            <Trans
                defaults={"Do you collect <0>xWG</0>? Do you want to introduce order to your collection? Do you offer <0>xWG</0> for exchange? Then you are at the right place. You don't know what <0>xWG</0> is? Take a look at <1>GeoWiki</1>."}
                components={[
                    <abbr title={t("(Czech) Wood Geocoin")} />,
                    <a href="http://wiki.geocaching.cz/wiki/Czech_Wood_Geocoin" />
                ]}
                ns={"layout"}
            />
        </p>
        <p>
            <Trans
                defaults={"To manage your collection, you must first sign in. Without registration you can look at the <0>xWG catalogue</0> and also at the <1>statistics</1> of biggest collectors."}
                components={[
                    <Link to={"/cwg/browse"} />,
                    <Link to={"/users/stats"} />
                ]}
                ns={"layout"}
            />
        </p>

        <Columns my={4}>
            <div>
                <LoginForm
                    client_id={request.clientId}
                    redirect_uri={new URL("/oauth2/login", request.url).toString()}
                    error={error}
                />

                <Center mt={4}>
                    <Button as={Link} to={"/recover-password"} size={"sm"}>{t("Recover lost password", {ns: "account"})}</Button>
                </Center>
            </div>
            <div>
                <SignupForm />
            </div>
        </Columns>

        <h3>{t("Latest updates", {ns: "news"})}</h3>
        <List variant={"divided"}>
            {news.map(item => <ListItem key={item.id}>
                <NewsItem item={item} />
            </ListItem>)}
        </List>
    </>
}

function Index() {
    const {t} = useTranslation("overview");
    const {t: tn} = useTranslation("news");
    const cu = useCurrentUser() as NonNullable<ReturnType<typeof useCurrentUser>>;

    const {bestCollectors, last, news} = Route.useLoaderData();
    const request = useMatchData<RequestData>("request", true);

    const iconCodeRef = useRef<HTMLPreElement>(null);

    const [stats, setStats] = useState<Statistics | null>(null);
    const [unpublishedXwgs, setUnpublishedXwgs] = useState<XwgWithId[] | null>(null);

    const api = useAPI();

    useEffect(() => {
        const signal = new AbortController();
        (async () => {
            try {
                const resp = await api.get("/api/v2/users/me", {
                    params: {
                        attribute: ["total.pieces", "total.unique"]
                    },
                    signal: signal.signal
                });

                if (resp.data) {
                    setStats(resp.data.total);
                }
            } catch (e) {
                if ((e as {cause: unknown}).cause instanceof CanceledError) {
                    return;
                }
            }
        })();

        return () => {
            signal.abort();
        }
    }, []);

    useEffect(() => {
        const signal = new AbortController();
        (async () => {
            try {
                const resp = await api.post<ListResponse<XwgWithId>, XwgSearchQuery>("/api/v2/xwg/query", {
                    filter: {
                        "publish_status": ["new", "hold"],
                        "created_by": [cu.name],
                    },
                    display: {
                        attributes: ["id", "name", "version"],
                        limit: 100
                    }
                });

                if (resp.data) {
                    setUnpublishedXwgs(resp.data.items);
                }
            } catch (e) {
                if ((e as {cause: unknown}).cause instanceof CanceledError) {
                    return;
                }
            }
        })();

        return () => {
            signal.abort();
        }
    }, [cu]);

    return <>
        <Columns>
            <div>
                <div>
                    {(unpublishedXwgs?.length ?? 0) > 0 && <>
                        <Alert mb={4} status={"warning"} variant={"left-accent"}>
                            <AlertIcon />
                            <Box>
                                <AlertTitle>{t("Your unpublished xWGs")}</AlertTitle>
                                <AlertDescription>
                                    {t("xWGs waiting to be published")}
                                    <UnorderedList ml={4}>
                                        {unpublishedXwgs?.map(xwg => <ListItem key={xwg.id}>
                                            <Link to={ROUTES.XWG_INDEX} params={{xwgId: xwg.id}}>
                                                {xwg.name} {xwg.version != "1" ? t("(version {{version}})", {ns: "cwg_index", version: xwg.version}) : null}
                                            </Link>
                                        </ListItem>)}
                                    </UnorderedList>
                                </AlertDescription>
                            </Box>
                        </Alert>
                    </>}

                    <h3>{t("Quick overview")}</h3>
                    <Dl variant={"border"}>
                        <Dt>{t("Total number of pieces:")}</Dt>
                        <Dd>{stats ? <FormattedNumber value={stats.pieces} approximate={stats.approximate ?? false} /> : <Loader w={4} h={4} />}</Dd>

                        <Dt>{t("Number of different xWGs:")}</Dt>
                        <Dd>{stats ? <FormattedNumber value={stats.unique ?? 0} /> : <Loader w={4} h={4} />}</Dd>
                    </Dl>

                    <div style={{textAlign: "center"}}>
                        <Link to={ROUTES.USER_PUBLIC_PROFILE} params={{username: cu.name}}>
                            {t("My public profile")}
                        </Link><br />
                        {t("You can find more statistics here.")}<br />

                        <Center mt={4}>
                            <Box>
                                <Link to={ROUTES.USER_PUBLIC_PROFILE} params={{username: cu.name}}>
                                    <img
                                        src={`/users/statbar/${encodeURIComponent(cu.name)}`}
                                        alt={t("CWG Collection - {{username}}", {username: cu.name})}
                                        title={t("CWG Collection - {{username}}", {username: cu.name})}
                                    />
                                </Link>
                            </Box>
                        </Center>

                        <Center mb={4}>
                            <Popover closeOnBlur={true} closeOnEsc={true} initialFocusRef={iconCodeRef}>
                                <PopoverTrigger>
                                    <Button variant={"ghost"} size={"xs"}>{t("Code for icon for usage in your profile")}</Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader textAlign={"left"}>
                                        <b>{t("Code for icon for usage in your profile")}</b>
                                        <CopyToClipboard content={iconCodeRef.current?.textContent} variant={"ghost"} />
                                    </PopoverHeader>
                                    <PopoverBody textAlign={"left"}>
                                        <pre style={{overflow: "auto"}} ref={iconCodeRef}>
                                            {`<a href="${(new URL(request.url)).origin}/users/index/${encodeURIComponent(cu.name)}">\n`}
                                            {`  <img src="${(new URL(request.url)).origin}/users/statbar/${encodeURIComponent(cu.name)}" alt="${t("CWG Collection - {{username}}", {username: cu.name})}" title="${t("CWG Collection - {{username}}", {username: cu.name})}" />\n`}
                                            {`</a>`}
                                        </pre>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </Center>
                    </div>
                </div>

                {bestCollectors && <>
                <h4>{t("Best collectors")}</h4>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>{t("#")}</Th>
                                <Th>{t("Name")}</Th>
                                <Th>{t("Pieces")}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {bestCollectors.items.map((user, idx) => <Tr key={user.id}>
                                <Td className={"td-shrink"}>{idx + 1}</Td>
                                <Td><ProfileLink user={user} /></Td>
                                <Td className={"td-shrink"}><FormattedNumber value={user.total.unique} /></Td>
                            </Tr>)}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Td colSpan={3} className={"text-center"}>
                                    <ButtonGroup size={"sm"} isAttached variant={"secondary"}>
                                        <Button as={Link} to={ROUTES.TOP_COLLECTORS}>{t("Whole table")}</Button>
                                        <Button as={Link} to={ROUTES.TOP_OFFERS}>{t("Who offers how much?")}</Button>
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        </Tfoot>
                    </Table>
                </>}

                {last && <>
                    <h4>{t("Last collected xWGs")}</h4>
                    {last.count > 0
                        ? <Table>
                            <Thead>
                                <Tr>
                                    <Th className={"td-shrink"}>{t("Date")}</Th>
                                    <Th>{t("xWG")}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {last.items.map((xwg, idx) => <Tr key={idx}>
                                    <Td className={"td-shrink"}><FormattedDate date={xwg.status?.last_collected_date} /></Td>
                                    <Td>
                                        <Link to={ROUTES.XWG_INDEX} params={{xwgId: xwg.id}}>
                                            {xwg.name}
                                        </Link>
                                        {" "}
                                        {xwg.version != "1" ? t("(version {{version}})", {ns: "cwg_index", version: xwg.version}) : null}
                                    </Td>
                                </Tr>)}
                            </Tbody>
                        </Table>
                        : <Alert mb={4}>
                            <AlertIcon />
                            <AlertDescription>
                                <Trans ns={"overview"}>
                                    You don't have any xWGs in your collection yet.
                                    To add some, use the <Link to={ROUTES.XWG_SEARCH}>Search</Link> or <Link to={ROUTES.BROWSE}>Browse</Link> functionality to find xWG that you
                                    physically have.
                                </Trans>
                            </AlertDescription>
                        </Alert>
                    }
                </>}
            </div>
            <div>
                <h3>{tn("News")}</h3>

                <List variant={"divided"}>
                    {news.map(item => <ListItem key={item.id}>
                        <NewsItem item={item} />
                    </ListItem>)}
                </List>

                <HStack justifyContent={"flex-end"} mt={4}>
                    <Link to={ROUTES.NEWS_ARCHIVE}>{tn("News archive")}</Link>
                </HStack>
            </div>
        </Columns>
    </>;
}
