import {AnyRouteMatch, useMatches} from "@tanstack/react-router";

export function findInMatchesData<T>(matches: AnyRouteMatch[], key: string): T | undefined {
    const match = matches.toReversed().find(match => match.loaderData?.[key]);
    return match ? match.loaderData[key] : undefined;
}

export function useMatchData<T>(key: string, throwIfNotFound: true): T;
export function useMatchData<T>(key: string, throwIfNotFound: false): T | undefined;
export function useMatchData<T>(key: string): T | undefined;

export function useMatchData<T>(key: string, throwIfNotFound: boolean = false): T | undefined {
    const matches = useMatches();
    const out = findInMatchesData<T>(matches, key);
    if (out === undefined && throwIfNotFound) {
        throw new Error(`Key ${key} not found in matched data.`);
    }
    return out;
}
