import {createFileRoute} from '@tanstack/react-router'
import {getAPI} from "../../../../api/api";
import {User, UserAttribute} from "../../../../models/user";
import {listUsers} from "../../../../api/listUsers";
import {NotFoundError} from "../../../../utils/errors";
import {RouterContext} from "../../../../models/routerContext";

export const Route = createFileRoute('/_site/users/_public_profile')({
    beforeLoad: async () => {
        return {
            attributes: ["id", "name", "private"] as UserAttribute[]
        }
    },
    params: {
        parse: (params: Record<string, string>) => {
            if (!params.username) {
                throw new Error("Missing path parameter username.");
            }

            return {username: params.username};
        },
        stringify: (params: {username: string}) => params
    }
});

export type UserLoaderResponse = {
    user: User,
    attributes: UserAttribute[]
};

export async function loadUser<AdditionalContext extends object>({context: {
    attributes,
    ...context
}, params}: {
    context: RouterContext & {attributes: UserAttribute[]} & AdditionalContext,
    params: {
        username: string
    },
}): Promise<UserLoaderResponse & AdditionalContext> {
    const api = getAPI(context);
    if (!attributes.includes("id")) {
        attributes.push("id");
    }

    if (!attributes.includes("name")) {
        attributes.push("name");
    }

    if (!attributes.includes("private")) {
        attributes.push("private");
    }

    const {username} = params;

    const user = await listUsers({
        api,
        query: {
            filter: {
                names: [username as string]
            },
            display: {
                attributes
            }
        }
    });

    if (!user.items[0]) {
        throw new NotFoundError(`User ${username} was not found!`);
    }

    return {
        attributes: attributes,
        user: user.items[0]
    } as UserLoaderResponse & AdditionalContext;
}
