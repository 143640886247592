import React, {useEffect, useState} from "react";
import {IconButton, IconButtonProps, Tooltip, useToast} from "@chakra-ui/react";
import IconCopy from "~icons/lucide/clipboard-copy";
import {useTranslation} from "../../utils/helpers";

export function CopyToClipboard({content, ...props}: {content: string | null | undefined} & Omit<IconButtonProps, "onClick" | "aria-label" | "icon" | "content">) {
    const [visible, setVisible] = useState<boolean>(false);
    const {t} = useTranslation("layout");

    useEffect(() => {
        if (navigator.clipboard && content) {
            setVisible(true);
        }
    }, [content]);

    const toast = useToast();

    return visible &&
        <Tooltip label={t("Copy to clipboard")}>
            <IconButton
                aria-label={t("Copy to clipboard")}
                icon={<IconCopy />}
                {...props}
                onClick={async () => {
                    if (navigator.clipboard) {
                        await navigator.clipboard.writeText(content as string);
                        toast({
                            title: t("Text has been copied to clipboard."),
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                        });
                    }
                }}
            />
        </Tooltip>;
}
