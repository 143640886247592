import {checkboxAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/styled-system";

import {filledVariant} from "./input";

const {defineMultiStyleConfig, definePartsStyle} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
    label: {
        fontWeight: "bold"
    },
    control: {
        bg: filledVariant.field.bg,
        boxShadow: filledVariant.field.boxShadow,
        border: filledVariant.field.border,
        borderColor: filledVariant.field.borderColor,
        borderRadius: filledVariant.field.borderRadius,

        _checked: {
            borderColor: filledVariant.field.borderColor,
        },

        _indeterminate: {
            borderColor: filledVariant.field.borderColor,
        },
    }
});

export const checkboxTheme = defineMultiStyleConfig({
    baseStyle: baseStyle,
});
