import {createRouter as createReactRouter, parseSearchWith, stringifySearchWith} from '@tanstack/react-router';
import {routeTree} from './routeTree.gen';
import {RISON} from "rison2";
import {RouterContext} from "./models/routerContext";
import pino from "pino";
import i18next from "i18next";
import {RootErrorComponent, RootNotFound} from "./components/error";

export const emptyContext: RouterContext = {
    request: {
        url: "",
        cookies: {},
        grid: "",
        clientId: "",
        language: "",
        log: pino(),
        i18n: i18next
    },
    currentUser: {
        currentUser: null,
        token: null,
        loaded: false,
        announcements: []
    },
    applicationData: {
        r: "",
        environment: "",
        version: "",
        announcements: []
    },
    scripts: [],
    links: []
}

export function createRouter() {
    return createReactRouter({
        routeTree,
        context: emptyContext,
        defaultPreload: false,
        parseSearch: parseSearchWith(RISON.parse),
        stringifySearch: stringifySearchWith(RISON.stringify),
        defaultErrorComponent: RootErrorComponent,
        defaultNotFoundComponent: RootNotFound,
    })
}

declare module '@tanstack/react-router' {
    interface Register {
        router: ReturnType<typeof createRouter>
    }
}
