import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import {useTranslation} from "../../utils/helpers";
import Select from "../generic/select";
import {TagCategory, UserTag} from "../../models/tag";
import {XwgFilter} from "../../models/xwgSearchQuery";
import {
    Button,
    ButtonGroup,
    Center,
    FormControl,
    FormLabel,
    Grid,
    HStack,
    Input,
    Stack,
    Tag,
    VStack
} from "@chakra-ui/react";
import {Controller, FieldSet, Switch, TransformProps} from "../generic/form";
import {useCurrentUser} from "../../models/user";

export type AdvancedSearchFormProps = {
    tagCategories: TagCategory[];
    userTags: UserTag[];
    filter?: XwgFilter,
    onFilter?: (filter: XwgFilter) => void;
}

export class Undefined {
    public undefined = true;
}

export type XwgAdvancedFilterFormData = {
    name?: string[],
    cat_no?: string[],
    id?: string[],

    categories?: Record<string, string[]>,
    userTags?: string[],

    collectedByMe: boolean | Undefined,
    offeredByMe: boolean | Undefined,
    wantedByMe: boolean | Undefined,
    notWantedByMe: boolean | Undefined,

    collectedByUser?: string[],
    offeredByUser?: string[],
    wantedByUser?: string[],
    notWantedByUser?: string[],

    created_by?: string[]
}

export function sanitizeFilter(filter: XwgAdvancedFilterFormData): XwgFilter {
    const out: XwgFilter = {
        names: filter.name?.filter(name => !!name),
        cat_no: filter.cat_no?.filter(cat_no => !!cat_no),
        ids: filter.id?.filter(id => !!id).map(id => parseInt(id)),
        tags: [
            ...Object.entries(filter.categories ?? {}).map(([category, names]) => (names ?? []).map(name => ({category, name}))).flat() ?? [],
            ...(filter.userTags ?? [])
        ],
        collected: [
            ...(typeof(filter.collectedByMe) === "boolean") ? [filter.collectedByMe] : [],
            ...(filter.collectedByUser?.filter(v => v.trim() !== "") ?? [])
        ],
        offered: [
            ...(typeof(filter.offeredByMe) === "boolean") ? [filter.offeredByMe] : [],
            ...(filter.offeredByUser?.filter(v => v.trim() !== "") ?? [])
        ],
        wanted: [
            ...(typeof(filter.wantedByMe) === "boolean") ? [filter.wantedByMe] : [],
            ...(filter.wantedByUser?.filter(v => v.trim() !== "") ?? [])
        ],
        not_wanted: [
            ...(typeof(filter.notWantedByMe) === "boolean") ? [filter.notWantedByMe] : [],
            ...(filter.notWantedByUser?.filter(v => v.trim() !== "") ?? [])
        ],
        created_by: filter.created_by?.filter(name => !!name)
    };

    if (out.names?.length === 0) {
        out.names = undefined;
    }

    if (out.cat_no?.length === 0) {
        out.cat_no = undefined;
    }

    if (out.ids?.length === 0) {
        out.ids = undefined;
    }

    if (out.tags?.length === 0) {
        out.tags = undefined;
    }

    if (Array.isArray(out.collected) && out.collected.length === 0) {
        out.collected = undefined;
    }

    if (Array.isArray(out.offered) && out.offered.length === 0) {
        out.offered = undefined;
    }

    if (Array.isArray(out.wanted) && out.wanted.length === 0) {
        out.wanted = undefined;
    }

    if (Array.isArray(out.not_wanted) && out.not_wanted.length === 0) {
        out.not_wanted = undefined;
    }

    if (out.created_by?.length === 0) {
        out.created_by = undefined;
    }

    return out;
}

export function prepareFilter(filter: XwgFilter): XwgAdvancedFilterFormData {
    const out: XwgAdvancedFilterFormData = {
        name: filter.names?.filter(name => typeof(name) === "string") ?? [],
        cat_no: filter.cat_no ?? [],
        id: filter.ids?.map(id => id.toString()) ?? [],

        categories: {},
        userTags: filter.tags?.filter(tag => typeof(tag) === "string") ?? [],

        collectedByMe: (
            (typeof(filter.collected) === "boolean")
                ? filter.collected
                : (filter.collected?.find(v => typeof(v) === "boolean") ?? new Undefined())
        ),
        offeredByMe: (
            (typeof(filter.offered) === "boolean")
                ? filter.offered
                : (filter.offered?.find(v => typeof(v) === "boolean") ?? new Undefined())
        ),
        wantedByMe: (
            (typeof(filter.wanted) === "boolean")
                ? filter.wanted
                : (filter.wanted?.find(v => typeof(v) === "boolean") ?? new Undefined())
        ),
        notWantedByMe: (
            (typeof(filter.not_wanted) === "boolean")
                ? filter.not_wanted
                : (filter.not_wanted?.find(v => typeof(v) === "boolean") ?? new Undefined())
        ),

        collectedByUser: Array.isArray(filter.collected) ? filter.collected.filter(v => typeof(v) === "string" && v.trim() !== "") as string[] : [],
        offeredByUser: Array.isArray(filter.offered) ? filter.offered.filter(v => typeof(v) === "string" && v.trim() !== "") as string[] : [],
        wantedByUser: Array.isArray(filter.wanted) ? filter.wanted.filter(v => typeof(v) === "string" && v.trim() !== "") as string[] : [],
        notWantedByUser: Array.isArray(filter.not_wanted) ? filter.not_wanted.filter(v => typeof(v) === "string" && v.trim() !== "") as string[] : [],
        created_by: Array.isArray(filter.created_by) ? filter.created_by.filter(v => v.trim() !== "") : [],
    };

    filter.tags?.filter(tag => typeof(tag) !== "string").forEach(tag => {
        if (!out.categories) {
            out.categories = {};
        }

        if (!out.categories[tag.category]) {
            out.categories[tag.category] = [];
        }

        out.categories[tag.category].push(tag.name);
    });

    return out;
}

function CheckableTagList({tags, value, onChange}: {tags: UserTag[], value: string[], onChange: (value: string[]) => void}) {
    return <Stack wrap={"wrap"} spacing={2} alignItems={"flex-start"} direction={"row"}>
        {tags?.map((tag) => <Tag
            variant={value.includes(tag.id) ? "tagActive" : "tag"}
            cursor={"pointer"}
            key={tag.id}
            onClick={() => {
                if (value.includes(tag.id)) {
                    onChange(value.filter(v => v !== tag.id));
                } else {
                    onChange([...value, tag.id]);
                }
            }}
        >
            {tag.name}
        </Tag>)}
    </Stack>
}

export const positiveTransformers: TransformProps = {
    toInputValue: (value: boolean | Undefined) => (value instanceof Undefined) ? false : value,
    fromInputValue: (value: boolean | undefined): boolean | Undefined => value === true ? true : new Undefined()
}

export const negativeTransformers: TransformProps = {
    toInputValue: (value: boolean | Undefined) => (value instanceof Undefined) ? false : !value,
    fromInputValue: (value: boolean | undefined): boolean | Undefined => value === true ? false : new Undefined()
}

export function AdvancedSearchForm({tagCategories, userTags, filter, onFilter}: AdvancedSearchFormProps) {
    const methods = useForm<XwgAdvancedFilterFormData>({
        defaultValues: prepareFilter(filter ?? {})
    })
    const { register, handleSubmit, control } = methods;
    const {t} = useTranslation("browse");
    const cu = useCurrentUser();

    return <form onSubmit={handleSubmit((filter: XwgAdvancedFilterFormData) => {
        const sanitized = sanitizeFilter(filter)
        onFilter?.(sanitized);
    })}>
        <FormProvider {...methods}>
            <HStack
                alignItems={"flex-start"}
                gap={8}
                wrap={{
                    base: "wrap",
                    md: "nowrap"
                }}
            >
                <FieldSet legend={t("Search")} style={{flex: "1 1 50%"}}>
                    <FormControl>
                        <FormLabel>{t("Name")}</FormLabel>
                        <Input {...register("name.0")} />
                    </FormControl>

                    <HStack>
                        <FormControl>
                            <FormLabel>{t("Catalogue number")}</FormLabel>
                            <Input {...register("cat_no.0")} />
                        </FormControl>

                        <FormControl>
                            <FormLabel>{t("ID")}</FormLabel>
                            <Input {...register("id.0")} />
                        </FormControl>
                    </HStack>
                </FieldSet>

                <VStack style={{flex: "1 1 50%"}} alignItems={"stretch"}>
                    <FieldSet legend={t("Categories")}>
                        <HStack>
                            {tagCategories.map((category) => (
                                <FormControl key={category.id}>
                                    <Controller
                                        control={control}
                                        name={`categories.${category.id}`}
                                        render={({field: {onChange, value, name}}) => {
                                            return <Select
                                                instanceId={`category-${category.id}`}
                                                isMulti={true}
                                                isClearable={false}
                                                placeholder={category.name}
                                                options={category.tags.map(tag => ({value: tag.id, label: tag.name}))}
                                                value={value}
                                                name={name}
                                                onChange={(value) => {
                                                    onChange(value);
                                                }}
                                            />
                                        }}
                                    />
                                </FormControl>
                            ))}
                        </HStack>
                    </FieldSet>

                    {cu &&
                        <FieldSet legend={t("Tags")}>
                            <Controller
                                name={"userTags"}
                                render={({field: {value, onChange}}) => <CheckableTagList tags={userTags} value={value} onChange={onChange} />}
                            />
                        </FieldSet>
                    }
                </VStack>
            </HStack>

            {cu && <FieldSet legend={t("Status")}>
                <HStack>
                    <Grid templateColumns={"1fr 1fr"} rowGap={"2"} columnGap={8}>
                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"collectedByMe"}
                                {...positiveTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I have collected")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"collectedByMe"}
                                {...negativeTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I haven't collected")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"offeredByMe"}
                                {...positiveTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I offer")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"offeredByMe"}
                                {...negativeTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I don't offer")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"wantedByMe"}
                                {...positiveTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I want")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"wantedByMe"}
                                {...negativeTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I don't have on my want list")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"notWantedByMe"}
                                {...positiveTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I don't want")}
                        </FormLabel>

                        <FormLabel mb={"0"} fontWeight={"normal"}>
                            <Controller
                                control={control}
                                name={"notWantedByMe"}
                                {...negativeTransformers}
                                render={({field}) => <Switch {...field} mr={2} />}
                            />
                            {t("Only what I don't have on my not wanted list")}
                        </FormLabel>
                    </Grid>
                </HStack>
            </FieldSet>}

            <FieldSet legend={t("Users")} style={{flex: "1 1 50%"}}>
                <FormControl>
                    <FormLabel>{t("Collected by")}</FormLabel>
                    <Input {...register("collectedByUser.0")} />
                </FormControl>

                <FormControl>
                    <FormLabel>{t("Offered by")}</FormLabel>
                    <Input {...register("offeredByUser.0")} />
                </FormControl>

                <FormControl>
                    <FormLabel>{t("Wanted by")}</FormLabel>
                    <Input {...register("wantedByUser.0")} />
                </FormControl>

                <FormControl>
                    <FormLabel>{t("Not wanted by")}</FormLabel>
                    <Input {...register("notWantedByUser.0")} />
                </FormControl>

                <FormControl>
                    <FormLabel>{t("Created by")}</FormLabel>
                    <Input {...register("created_by.0")} />
                </FormControl>
            </FieldSet>
        </FormProvider>
        <Center>
            <ButtonGroup>
                <Button type={"submit"}>{t("Filter")}</Button>
            </ButtonGroup>
        </Center>
    </form>
}
