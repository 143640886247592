import {createFileRoute, Link, Outlet} from '@tanstack/react-router'

import React from "react";
import {useTranslation} from "../../../../utils/helpers";
import {ContentHeader} from "../../../../components/contentHeader";
import {Button} from "@chakra-ui/react";

import {zeroLevelBeforeLoad} from "../../../../utils/collectionListing";
import {useMatchData} from "../../../../utils/findInMatchesData";
import {TagCategory} from "../../../../models/tag";
import {zeroLevelRouteLoader} from "../../../../utils/collectionListing/zero";
import {RouterContext} from "../../../../models/routerContext";
import {ROUTES} from "../../../../constants";
import {getFixedT} from "../../../../utils/getFixedT";
import {combineTitle} from "../../../../utils/combineTitle";
import {NotFoundError} from "../../../../utils/errors";

export const Route = createFileRoute('/_site/cwg-collection/browse')({
    meta: ({matches, match}) => {
        const t = getFixedT("browse", match.context.request?.i18n);
        return combineTitle(matches, t("My xWG collection"));
    },
    component: CwgBrowseHeader,
    beforeLoad: async ({context}: {context: RouterContext}) => {
        if (!context.currentUser?.currentUser) {
            throw new NotFoundError();
        }

        return {
            ...(await zeroLevelBeforeLoad(context, {collected: true})),
        }
    },
    loader: zeroLevelRouteLoader,
});

function CwgBrowseHeader() {
    const {t} = useTranslation("browse");
    const {tagCategories} = Route.useLoaderData();
    const primaryCategory = useMatchData<TagCategory>("primaryCategory");
    const isViewAll = useMatchData<boolean>("isViewAll") ?? false;

    return <>
        <ContentHeader
            title={t("My xWG collection")}
            toolbarTitle={t("Primary sorting:")}
            toolbarButtons={[
                ...tagCategories.map(category =>
                    <Button
                        size={"sm"}
                        key={category.id}
                        as={Link}
                        from={ROUTES.COLLECTION_BROWSE}
                        to={"./$primarySorting"}
                        params={{
                            primarySorting: `by-${category.id}`
                        }}
                        isActive={primaryCategory?.id === category.id}
                    >
                        {category.name}
                    </Button>
                ),
                <Button
                    size={"sm"}
                    as={Link}
                    from={ROUTES.COLLECTION_BROWSE}
                    to={"./all"}
                    isActive={isViewAll}
                    key={"all"}
                >
                    {t("All")}
                </Button>
            ]}
        />
        <Outlet />
    </>
}
