import { createFileRoute } from '@tanstack/react-router'
import {loadUser} from "./route";
import React, {useCallback, useEffect, useState} from "react";
import {PublicProfile} from "../../../../components/users/publicProfile";
import {useTranslation} from "../../../../utils/helpers";
import {MessagesView} from "../../../../components/messages/messageView";
import {getAPI, useAPI} from "../../../../api/api";
import {MessageThreadDetail} from "../../../../models/messages";
import {SendMessageForm} from "../../../../components/messages/sendMessage";
import {Container} from "@chakra-ui/react";

export const Route = createFileRoute('/_site/users/_public_profile/messages/$username')({
    component: () => {
        const {t} = useTranslation("profile");
        const {user, messages: initialThread} = Route.useLoaderData();

        const api = useAPI();
        const [thread, setThread] = useState<MessageThreadDetail>(initialThread);

        const reloadThread = useCallback(async () => {
            const thread = await api.get<MessageThreadDetail>("/api/v2/messages/{threadId}/", {
                pathParams: {
                    threadId: initialThread.id
                }
            });

            setThread(thread.data);
        }, [setThread, api, initialThread.id]);

        useEffect(() => {
            setThread(initialThread);
        }, [initialThread]);

        return <PublicProfile
            selected={Route.fullPath}
            title={t("{{user}} - private messages", {user: user.name})}
            user={user}
        >
            <Container maxW={"lg"}>
                <MessagesView thread={thread} minH={{
                    lg: "30em",
                    sm: "25em",
                }} maxH={{
                    lg: "calc(100vh - 28em)",
                    sm: "calc(100vh - 20em)",
                }} />
                <SendMessageForm threadId={thread.id} showSubject={false} onMessageSent={reloadThread} />
            </Container>
        </PublicProfile>;
    },
    loader: async ({context, params}) => {
        const userData = await loadUser({context, params});

        const api = getAPI(context);
        const messages = await api.get<MessageThreadDetail>("/api/v2/users/{userId}/messages", {
            pathParams: {
                userId: userData.user.id
            }
        });

        return {
            ...userData,
            messages: messages.data
        };
    },
});
