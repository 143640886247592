import { createFileRoute } from '@tanstack/react-router'
import React from "react";
import {UserSearchForm} from "../../../../components/users/searchForm";
import {useTranslation} from "../../../../utils/helpers";
import {ROUTES} from "../../../../constants";

export const Route = createFileRoute('/_site/users/search/')({
    component: () => {
        const {t} = useTranslation("search");
        const navigate = Route.useNavigate();

        return <>
            <h2>{t("User search")}</h2>
            <UserSearchForm
                onSearch={async (search) => {
                    await navigate({
                        to: ROUTES.USER_SEARCH_WITH_QUERY,
                        params: {
                            username: search.username
                        }
                    })
                }}
            />
        </>
    }
})
