import {defineStyleConfig, defineStyle} from '@chakra-ui/styled-system';

const articleTheme = defineStyle({
    "p": {
        my: 4
    },
    "ul, ol": {
        ml: 8,
        listStyleType: "disc",
    },
    "hr": {
        borderColor: "divider.600",
        borderWidth: 1,
    }
});

export const textTheme = defineStyleConfig({
    variants: {
        article: articleTheme
    }
});
