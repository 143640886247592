export const TokenScope = {
    CWG_CREATE: "cwg:create",
    CWG_EDIT: "cwg:edit",
    CWG_IMAGES: "cwg_images",
    ADMIN_ACCESS: "admin:access",
    ADMIN_JOIN: "admin:join",
    ADMIN_PUBLISH: "admin:publish",
    ADMIN_CATALOGUE: "admin:catalogue",
    ADMIN_USERS: "admin:users",
    ADMIN_GROUPS: "admin:groups",
    ADMIN_USER_SCOPES: "admin:users:scopes",
    ADMIN_GROUP_SCOPES: "admin:groups:scopes",
    ADMIN_NEWS: "admin:news",
    ADMIN_TAGS: "admin:tags",
}


export type TokenScopeModel = {
    id: string;
}

export type SplittedScopes = {
    allowed: string[];
    denied: string[];
}
