import {createFileRoute, Link} from '@tanstack/react-router'
import React, {useCallback, useRef, useState} from "react";
import Columns from "../../../../components/generic/columns";
import {Breadcrumb} from "../../../../components/generic/breadcrumb";
import {useTranslation} from "../../../../utils/helpers";
import {BreadcrumbItem, BreadcrumbLink, FormControl, FormLabel, HStack, Input, useToast} from "@chakra-ui/react";
import {getAPI, useAPI} from "../../../../api/api";
import {XwgWithId} from "../../../../models/xwg";
import {CwgHeader} from "../../../../components/cwg/cwgHeader";
import {List} from "../../../../components/cwg/list";
import {useForm} from "react-hook-form";
import {CwgSearchResult} from "../../../../components/cwg/cwgSearchResult";
import {ListResponse} from "../../../../models/response";
import {prepareFilter, sanitizeFilter, XwgAdvancedFilterFormData} from "../../../../components/cwg/advancedSearchForm";
import {FormButtons} from "../../../../components/generic/form";
import {Button} from "../../../../components/generic/buttons";
import {Forbidden} from "../../../../utils/errors";
import {TokenScope} from "../../../../models/tokenScope";
import {ROUTES} from "../../../../constants";

export const Route = createFileRoute('/_site/cwg/index/$xwgId/join')({
    params: {
        parse: (params) => (
            {
                xwgId: parseInt(params.xwgId?.toString() ?? "")
            }
        ),
        serialize: (params: {xwgId: number}) => (
            {
                xwgId: params.xwgId.toString()
            }
        )
    },
    loader: async ({context, params}) => {
        if (!context.currentUser.currentUser?.has(TokenScope.ADMIN_JOIN)) {
            throw new Forbidden();
        }

        const api = getAPI(context);
        const xwg = (await api.get<XwgWithId>("/api/v2/xwg/{xwgId}", {
            pathParams: {
                xwgId: params.xwgId
            },
            params: {
                attribute: ["id", "name", "version", "tags", "cat_no", "images", "note", "publish_status", "cat_no"]
            }
        })).data;
        return {xwg};
    },

    component: () => {
        const {t} = useTranslation("cwg_ops");
        const {xwg} = Route.useLoaderData({
            select: ({xwg}) => ({
                xwg: xwg instanceof XwgWithId ? xwg : new XwgWithId(xwg)
            })
        });

        const {handleSubmit, register, watch} = useForm<XwgAdvancedFilterFormData>({
            defaultValues: prepareFilter({})
        });
        const [candidates, setCandidates] = useState<ListResponse<XwgWithId>>({items: [], count: 0});

        const api = useAPI();

        const [offset, setOffset] = useState(0);
        const limit = 100;

        const searchCandidates = useCallback(async (data: XwgAdvancedFilterFormData, offset: number, limit: number) => {
            const filter = sanitizeFilter(data);
            const {items, count} = (await api.post<ListResponse<XwgWithId>>("/api/v2/xwg/query", {
                filter: filter,
                display: {
                    attributes: ["id", "name", "version", "tags", "cat_no", "images", "note", "link", "publish_status"],
                    offset,
                    limit
                }
            })).data;

            setCandidates({
                items: items.map(xwg => new XwgWithId(xwg)),
                count
            });
        }, [setCandidates]);

        const formRef = useRef<HTMLFormElement>(null);
        const timeoutRef = useRef<number | null>(null);

        const searchOnChange = useCallback(async () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = window.setTimeout(() => {
                formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
            }, 500);
        }, [timeoutRef, formRef.current]);

        const toast = useToast();
        const navigate = Route.useNavigate();

        const performJoin = useCallback(async (source: XwgWithId, target: XwgWithId) => {
            await api.post("/api/v2/xwg/join/{sourceId}/{targetId}", undefined, {
                pathParams: {
                    sourceId: source.id,
                    targetId: target.id
                }
            });

            toast({
                title: t("xWG {{name}} (version {{version}}) has been joined to xWG {{targetName}} (version {{targetVersion}}).", {
                    name: source.name,
                    version: source.version,
                    targetName: target.name,
                    targetVersion: target.version
                }),
                status: "success",
                isClosable: true
            });

            await navigate({
                to: ROUTES.XWG_INDEX,
                params: {
                    xwgId: target.id
                }
            });
        }, [api, toast])

        return <>
            <CwgHeader xwg={xwg} showVersion={true}>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            as={Link}
                            to={ROUTES.XWG_INDEX}
                            params={{xwgId: xwg.id}}
                        >
                            {t("{{name}}", {
                                name: xwg.name
                            })}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            as={Link}
                            to={ROUTES.XWG_JOIN}
                            params={{xwgId: xwg.id}}
                        >
                            {t("Join")}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </CwgHeader>
            <Columns>
                <>
                    <h3>{t("Join…")}</h3>
                    <List items={[xwg]} />

                    <h3>{t("Find join target")}</h3>
                    <form
                        onSubmit={handleSubmit(async (data) => {
                            setOffset(0);
                            await searchCandidates(data, 0, limit);
                        })}
                        onChange={searchOnChange} ref={formRef}
                    >
                        <FormControl>
                            <FormLabel>{t("Name", {ns: "browse"})}</FormLabel>
                            <Input {...register("name.0")} />
                        </FormControl>

                        <FormControl>
                            <FormLabel>{t("ID", {ns: "browse"})}</FormLabel>
                            <Input {...register("id.0")} />
                        </FormControl>

                        <FormButtons>
                            <Button type="submit">{t("Search", {ns: "search"})}</Button>
                        </FormButtons>
                    </form>
                </>
                <>
                    <h3>{t("…to")}</h3>
                    <CwgSearchResult
                        search={candidates}
                        actionable={false}
                        offset={offset}
                        limit={limit}
                        onOffsetChange={async (newOffset) => {
                            setOffset(newOffset);
                            await searchCandidates(watch(), newOffset, limit);
                        }}
                        customActions={(target: XwgWithId) => <>
                            <HStack
                                flexGrow={1}
                                justifyContent={"flex-end"}
                            >
                                <Button
                                    size={"sm"}
                                    variant={"secondary"}
                                    confirm={t("Do you really want to join xWG {{name}} (version {{version}}) to xWG {{targetName}} (version {{targetVersion}})?", {
                                        name: xwg.name,
                                        version: xwg.version,
                                        targetName: target.name,
                                        targetVersion: target.version
                                    })}
                                    onClick={() => {
                                        performJoin(xwg, target);
                                    }}
                                >
                                    {t("Join to this")}
                                </Button>
                            </HStack>
                        </>}
                    />
                </>
            </Columns>
        </>
    }
})
