import {
    chakra,
    forwardRef,
    HTMLChakraProps, omitThemingProps, SystemStyleObject,
    ThemingProps, useMultiStyleConfig,
} from "@chakra-ui/react";
import {createContext} from "@chakra-ui/utils";

const [DlStylesProvider, useDlStyles] = createContext<Record<string, SystemStyleObject>>({
    name: "DlStylesContext",
    errorMessage: "useDlStyles returned is 'undefined'. Seems you forgot to wrap the components in <Dl />"
})

export {
    useDlStyles
};

export interface DlProps extends HTMLChakraProps<"dl">, ThemingProps<"Dl"> {}

export const Dl = forwardRef<DlProps, "dl">(function(props, ref) {
    const styles = useMultiStyleConfig("Dl", props);
    const {
        children,
        ...rest
    } = omitThemingProps(props);

    return (
        <DlStylesProvider value={styles}>
            <chakra.dl
                ref={ref}
                __css={{ ...styles.container }}
                {...rest}
            >
                {children}
            </chakra.dl>
        </DlStylesProvider>
    )
});

Dl.displayName = "Dl";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface DtProps extends HTMLChakraProps<"dt"> {}

export const Dt = forwardRef<DtProps, "dt">(function(props, ref){
    const styles = useDlStyles();
    return <chakra.dt ref={ref} {...props} __css={styles.dt} />;
});

Dt.displayName = "Dt";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface DdProps extends HTMLChakraProps<"dd"> {}

export const Dd = forwardRef<DdProps, "dd">(function(props, ref){
    const styles = useDlStyles();
    return <chakra.dd ref={ref} {...props} __css={styles.dd} />;
});

Dd.displayName = "Dd";

import { anatomy } from "@chakra-ui/anatomy";

export const dlAnatomy = anatomy("dl")
    .parts("container", "dt", "dd");
