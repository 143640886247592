import ReactDOM from 'react-dom/client';
import React from 'react';

import {StartClient} from '@tanstack/start';
import {createRouter, emptyContext} from './router';
import i18next from "i18next";
import {I18nextProvider, initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {i18n} from "./i18n";

// @ts-ignore
import resources from 'virtual:i18next-loader';
import pino from "pino";
import {RouterContext} from "./models/routerContext";
import {CurrentUser} from "./models/user";
import {RouterProvider} from "@tanstack/react-router";
import Loader from "./components/generic/loader";
import {Box, Center} from "@chakra-ui/react";

async function hydrate() {
    await i18next
        .use(initReactI18next)
        .use(LanguageDetector)
        .init({
            ...i18n,
            ns: i18n.defaultNS,
            resources,
            detection: {
                order: ["htmlTag"],
                caches: [],
            },
        });

    const router = createRouter();

    const hydrateRouter = ({request, currentUser: {currentUser, ...restOfCurrentUser}, ...dehydrated}: Record<string, any>) => {
        // @ts-ignore
        globalThis.scripts = dehydrated.scripts;
        // @ts-ignore
        globalThis.links = dehydrated.links;

        router.update({
            context: {
                request: {
                    ...request,
                    log: pino({
                        mixin: () => ({
                            id: request.grid
                        })
                    }),
                    i18n: i18next
                },
                currentUser: {
                    currentUser: currentUser ? new CurrentUser(currentUser) : currentUser,
                    ...restOfCurrentUser
                },
                ...dehydrated
            } as RouterContext,
            defaultPendingComponent: () => <>
                <Center id={"apploader"} display={"flex"} alignItems={"center"}>
                    <Loader h={"1.2em"} borderWidth={2} />
                    <Box pl={8}>Loading application...</Box>
                </Center>
            </>
        });
    }

    if (window.__SERVER_CONTEXT__) {
        hydrateRouter(window.__SERVER_CONTEXT__);
    } else {
        router.update({
            context: emptyContext,
            hydrate: hydrateRouter,
        });
    }

    const root = document.getElementById("root");
    if (root) {
        if (window.__SERVER_CONTEXT__) {
            const jsx = <React.StrictMode>
                <I18nextProvider i18n={i18next}>
                    <RouterProvider router={router} />
                </I18nextProvider>
            </React.StrictMode>

            ReactDOM.createRoot(root).render(jsx);
        } else {
            const jsx = <React.StrictMode>
                <I18nextProvider i18n={i18next}>
                    <StartClient router={router}/>
                </I18nextProvider>
            </React.StrictMode>;

            ReactDOM.hydrateRoot(
                root,
                jsx
            );
        }
    } else {
        throw new Error("Unable to find root container for the application.");
    }
}

hydrate();
