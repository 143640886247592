import React from "react";
import {useTranslation} from "../../utils/helpers";
import {Box, ButtonGroup, HStack, ListItem, StackProps, UnorderedList, Wrap} from "@chakra-ui/react";
import {Button} from "./buttons";
import ChevronLeftIcon from "~icons/mdi/chevron-left";
import ChevronRightIcon from "~icons/mdi/chevron-right";
import ChevronLeftFirstIcon from "~icons/mdi/chevron-left-first";
import ChevronRightFirstIcon from "~icons/mdi/chevron-right-last";


function Pagination({ offset, limit, count, onOffsetChange, ...props }: {offset: number, limit: number, count: number, onOffsetChange?: (newOffset: number) => void} & StackProps) {
    const {t} = useTranslation("browse");

    /* Unique page offsets for first three pages, last three pages and current page +- three pages with null between non-consequential pages. */
    const radius = 2;
    const numberOfPages = Math.ceil(count / limit);
    const currentPage = Math.floor(offset / limit) + 1;

    const pages = [];

    let lastGeneratedPage: number | null = null;
    for (let i = 1; i <= numberOfPages; ++i) {
        if (
            // First <radius> pages
            i <= radius ||

            // First pages if
            (currentPage < (3 * radius) && i <= (3 * radius + 1)) ||

            // <radius> around current page + current page
            (i >= currentPage - radius && i <= currentPage + radius) ||

            // Last <radius> pages
            i > numberOfPages - radius
        ) {
            if (lastGeneratedPage !== null && lastGeneratedPage < i - 1) {
                pages.push(<li key={`sep-${i}`} className="separator">&hellip;</li>);
            }
            pages.push(<Button
                variant={"light"}
                key={i}
                as={ListItem}
                isActive={currentPage == i}
                onClick={() => onOffsetChange && onOffsetChange((i - 1) * limit)}
            >{i}</Button>);
            lastGeneratedPage = i;
        }
    }

    return <HStack {...props} justifyContent={"space-between"}>
        <ButtonGroup isAttached>
            <Button
                isDisabled={offset <= 0}
                onClick={() => onOffsetChange && onOffsetChange(0)}
                icon={ChevronLeftFirstIcon}
            >
                {t("First")}
            </Button>
            <Button
                isDisabled={offset <= 0}
                onClick={() => onOffsetChange && onOffsetChange(Math.max(offset - limit, 0))}
                icon={ChevronLeftIcon}
            >
                {t("Previous")}
            </Button>
        </ButtonGroup>

        <Wrap
            as={UnorderedList}
            listStyleType={"none"}
            m={0}
            p={0}
            alignItems={"baseline"}
            gap={".5em"}
            hideBelow={"md"}
        >
            {pages}
        </Wrap>
        <Box
            hideFrom={"md"}
        >
            {t("Page {{currentPage}} of {{numberOfPages}}", {currentPage, numberOfPages})}
        </Box>

        <ButtonGroup isAttached>
            <Button
                isDisabled={offset + limit >= count}
                onClick={() => onOffsetChange && onOffsetChange(offset + limit)}
                icon={ChevronRightIcon}
            >
                {t("Next")}
            </Button>
            <Button
                isDisabled={offset + limit >= count}
                onClick={() => onOffsetChange && onOffsetChange((numberOfPages - 1) * limit)}
                icon={ChevronRightFirstIcon}
            >
                {t("Last")}
            </Button>
        </ButtonGroup>
    </HStack>
};

export default Pagination;
