import React, {HTMLProps} from "react";
import styled from "@emotion/styled";

const Todo = styled(function({children, ...props}: Omit<HTMLProps<HTMLDivElement>, "children" | "title"> & {children?: string}){
    return <div title={children} {...props}>TODO</div>
})`
    display: inline-block;
    background: linear-gradient(180deg, rgba(245,232,21,1) 0%, rgba(251,245,146,1) 100%);
    color: #5F5A03;
    padding: .15rem .2rem;
    font-size: .6rem;
    font-weight: bold;
    border-radius: .2rem;
    border: solid 1px #7D7605;
    align-self: flex-start;
    line-height: .9em;
    flex-basis: 1rem;
`;

export {
    Todo
};
