import { createFileRoute } from '@tanstack/react-router'
import {getAPI} from "../../../../../api/api";
import {UserTag} from "../../../../../models/tag";
import {ErrorComponent} from "../../../../../components/error";

export const Route = createFileRoute('/_site/cwg/browse/advanced')({
    errorComponent: ErrorComponent,
    loader: async ({context}) => {
        const api = getAPI(context);

        return {
            userTags: context.currentUser.currentUser ? (await api.get<UserTag[]>("/api/v2/tags/user/me")).data : [],
            isAdvanced: true,
        }
    }
});
