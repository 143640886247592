import {TagCategory} from "../models/tag";
import {WithAPI} from "./api";

export enum Order {
    Name_ASC = "name:ASC",
    Name_DESC = "name:DESC",
    Count_ASC = "count:ASC",
    Count_DESC = "count:DESC",
}

export type TagCategoryProps = {
    order?: Order[];
} & WithAPI;

export async function getTagCategories(props: TagCategoryProps): Promise<TagCategory[]> {
    const defaultProps = {
        order: [Order.Name_ASC]
    };

    props = Object.assign({}, defaultProps, props);

    const response = await props.api.get<TagCategory[]>("/api/v2/tags/categories", {
        params: {
            order: props.order
        }
    });

    return response.data;
}
