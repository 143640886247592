import {AnyRouteMatch} from "@tanstack/react-router";
import {getFixedT} from "i18next";

export function combineTitle(matches: AnyRouteMatch[], title: string) {
    const t = getFixedT("layout");
    const stack = [t("CWG Collection"), title];

    return [
        {title: stack.reverse().join(" - ")}
    ];
}
