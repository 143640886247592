import {createFileRoute, Link} from '@tanstack/react-router'
import {useTranslation} from "react-i18next";
import React, {useCallback} from "react";
import {Button, FormControl, FormHelperText, FormLabel, Input, useToast} from "@chakra-ui/react";
import {FormButtons} from "../../components/generic/form";
import {useForm} from "react-hook-form";
import {useAPI} from "../../api/api";
import {useMatchData} from "../../utils/findInMatchesData";
import {ApplicationData} from "../../models/routerContext";
import {ValidationError} from "../../utils/errors";
import {diagToForm} from "../../utils/diagToForm";
import {
    ChangePasswordForm,
    ChangePasswordFormData,
    ChangePasswordFormMethods
} from "../../components/users/changePasswordForm";

type RecoverPasswordForm = {
    username: string;
}

function RequestPasswordRecovery() {
    const {t} = useTranslation("layout");
    const {register, handleSubmit, setError} = useForm<RecoverPasswordForm>();
    const api = useAPI();
    const {r} = useMatchData<ApplicationData>("applicationData", true);
    const toast = useToast();

    const recoverPassword = useCallback(async (data: RecoverPasswordForm) => {
        try {
            await api.post(`/${r}/action-recover-password`, {name: data.username});
            toast({
                title: t("Password recovery", {ns: "account"}),
                description: t("Password recovery email has been sent to your primary email address.", {ns: "account"}),
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                diagToForm(e.diagnostics, setError);
            }
        }
    }, []);

    return <>
        <h2>{t("Recover lost password", {ns: "account"})}</h2>

        <form onSubmit={handleSubmit(recoverPassword)}>
            <FormControl>
                <FormLabel>{t("User name", {ns: "account"})}</FormLabel>
                <Input type={"text"} {...register("username")} />
                <FormHelperText>{t("Enter the user name you want to recover password for.", {ns: "account"})}</FormHelperText>
            </FormControl>

            <FormButtons gap={4}>
                <Button type={"submit"}>{t("Recover", {ns: "account"})}</Button>
                <Button as={Link} to={"/"}>{t("Back to login")}</Button>
            </FormButtons>
        </form>
    </>
}

function SetPassword({token}: {token: string}) {
    const {t} = useTranslation("account");
    const api = useAPI();
    const toast = useToast();
    const navigate = Route.useNavigate();

    const setPassword = useCallback(async (data: ChangePasswordFormData, {setError}: ChangePasswordFormMethods) => {
        try {
            await api.post("/api/v2/users/me/reset-password", {
                password: data.newPassword,
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            toast({
                title: t("Password has been successfully set. You can now login."),
                status: "success",
                duration: 9000,
                isClosable: true,
            });

            await navigate({
                to: "/"
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                diagToForm(e.diagnostics, setError);
            } else {
                throw e;
            }
        }
    }, []);

    return <>
        <h2>{t("Recover lost password")}</h2>
        <ChangePasswordForm requireOldPassword={false} onSubmit={setPassword} />
    </>
}

export const Route = createFileRoute('/_site/recover-password')({
    validateSearch: (search: Record<string, unknown>): {token?: string} => {
        return {
            token: (search.token as string) ?? undefined
        }
    },
    component: () => {
        const search = Route.useSearch();
        if (search.token) {
            return <SetPassword token={search.token} />
        } else {
            return <RequestPasswordRecovery />
        }
    }
});
