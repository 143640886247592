import {createFileRoute, Link} from '@tanstack/react-router'
import {getAPI} from "../../api/api";
import {NotFoundError} from "../../utils/notFoundError";
import {useCurrentUser} from "../../models/user";
import {Route as IndexRoute} from "./index";
import React from "react";
import {useTranslation} from "../../utils/helpers";
import {Trans} from "react-i18next";
import {Alert, AlertDescription, AlertIcon} from "@chakra-ui/react";

export const Route = createFileRoute('/_site/verify-email')({
    component: () => {
        const { verified } = Route.useLoaderData();
        const cu = useCurrentUser();
        const {t} = useTranslation("settings");

        return <>
            <h2>{t("Verify Email")}</h2>
            {verified
                ? <>
                    <Alert status={"success"}>
                        <AlertIcon/>
                        <AlertDescription>
                            {t("Your email has been verified.")}

                            {!cu && <p><Trans ns={"settings"}>You can now <Link to={IndexRoute.fullPath}>Log in</Link>.</Trans></p>}
                        </AlertDescription>
                    </Alert>
                </>
                : <>
                    <Alert status={"error"}>
                        <AlertIcon />
                        <AlertDescription>
                            {t("Your email has not been verified. The verification link probably expired.")}
                        </AlertDescription>
                    </Alert>
                </>}
        </>
    },
    validateSearch: (search) => {
        if (!search.token) {
            throw new NotFoundError();
        }

        return search as {
            token: string;
        };
    },
    loaderDeps: ({search: {token}}) => ({token}),
    loader: async ({context, deps: { token }}) => {
        const api = getAPI(context);
        try {
            const response = await api.post(
                "/api/v2/users/me/emails/verify",
                {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    validateStatus: () => true
                }
            );

            if (~~(response.status / 100) === 2) {
                return {
                    verified: true
                }
            } else {
                return {
                    verified: false
                }
            }
        } catch {
            return {
                verified: false
            }
        }
    }
});
