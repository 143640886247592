import {Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {Button} from "../generic/buttons";
import PlusIcon from "~icons/fxemoji/heavyplussign";
import React, {HTMLProps} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "../../utils/helpers";

export function AddTagForm({onCreate, size, ...props}: {onCreate?: (tagName: string) => void, size?: string} & Omit<HTMLProps<HTMLFormElement>, "onSubmit" | "size">) {
    const {register, handleSubmit, reset} = useForm();
    const {t} = useTranslation("tags");

    const realSize = size ?? "xs";

    return <form
        {...props}
        onSubmit={handleSubmit((data) => {
            if (data.name) {
                onCreate?.(data.name);
            }

            reset();
        })}
    >
        <InputGroup size={realSize}>
            <Input placeholder={t("Add tag")} {...register("name", {required: true})} />
            <InputRightElement>
                <Button
                    variant={"ghost"}
                    size={realSize}
                    icon={PlusIcon}
                    type={"submit"}
                >
                    {t("Add tag")}
                </Button>
            </InputRightElement>
        </InputGroup>
    </form>
}
