import {createFileRoute, Link, Outlet, useMatches, useParams} from '@tanstack/react-router'
import {useTranslation} from "../../../utils/helpers";
import Account from "../../../components/account/account";
import Columns from "../../../components/generic/columns";
import React, {useEffect, useRef, useState} from "react";
import {getAPI, useAPI} from "../../../api/api";
import {Button, chakra, HStack, ListItem, UnorderedList, VStack} from "@chakra-ui/react";
import {ListResponse} from "../../../models/response";
import {ExistingMessage, MessageThread, MessageThreadWithUser, MessageThreadWithXwg} from "../../../models/messages";
import {Route as IndexRoute} from "./index";
import IconPlus from "~icons/mdi/plus";
import Loader from "../../../components/generic/loader";
import IconBack from "~icons/mdi/arrow-left-circle";
import {useCurrentUser} from "../../../models/user";
import {ROUTES} from "../../../constants";
import {getFixedT} from "../../../utils/getFixedT";
import {combineTitle} from "../../../utils/combineTitle";
import {RouterContext} from "../../../models/routerContext";
import {Truncate} from "@re-dev/react-truncate";

export const Route = createFileRoute('/_site/messages')({
    meta: ({matches, match}) => {
        const t = getFixedT("messages", match.context.request?.i18n);
        return combineTitle(matches, t("Private messages"))
    },
    component: ProfileMessages,
    loader: async ({context}: {context: RouterContext}) => {
        const api = getAPI(context);
        const threads = await api.get<ListResponse<MessageThread>>("/api/v2/messages");
        return {
            threads: threads.data
        }
    }
});

function MessageSnippet({message}: {message: ExistingMessage}) {
    const [text, setText] = React.useState<string>(message.subject || message.message);

    React.useEffect(() => {
        const container = document.createElement("div");
        container.innerHTML = message.message;

        if (message.subject) {
            container.insertBefore(document.createTextNode(message.subject + ": "), container.firstChild);
        }

        setText(container.textContent || "");
    }, [message]);

    return <Truncate lines={1} ellipsis={"…"}>{text}</Truncate>
}

function UnreadIndicator() {
    return <chakra.span color={"red"} mr={1}>●</chakra.span>;
}

function UserThreadHeader({thread}: {thread: MessageThreadWithUser}) {
    const cu = useCurrentUser();
    return <>
        <h4>{thread.unread > 0 && <UnreadIndicator />}{thread.users.filter(u => u.id !== cu?.id || thread.users.length === 1).map(u => u.name).join(", ")}</h4>
        <MessageSnippet message={thread.last_message} />
    </>
}

function XwgThreadHeader({thread}: {thread: MessageThreadWithXwg}) {
    return <>
        <h4>{thread.unread > 0 && <UnreadIndicator />}{thread.xwg.name}</h4>
        <MessageSnippet message={thread.last_message} />
    </>
}

function SystemThreadHeader({thread}: {thread: MessageThread}) {
    const {t} = useTranslation("messages");
    return <>
        <h4>{thread.unread > 0 && <UnreadIndicator />}{t("System messages")}</h4>
        <MessageSnippet message={thread.last_message} />
    </>;
}

function ProfileMessages() {
    const {t} = useTranslation("messages");
    const {threads: initialThreads} = Route.useLoaderData();

    const [threads, setThreads] = useState<MessageThread[]>(initialThreads.items);
    const [hasMore, setHasMore] = useState<boolean>(initialThreads.items.length < initialThreads.count);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const params = useParams({strict: false});
    const threadId = params.threadId ?? null;

    const threadListRef = useRef<HTMLUListElement>(null);
    const api = useAPI();

    useEffect(() => {
        if (!threadListRef.current || !hasMore) {
            return;
        }

        const handler = async () => {
            if (threadListRef.current) {
                if (threadListRef.current.scrollTop + threadListRef.current.clientHeight >= threadListRef.current.scrollHeight - 10 && !showLoader) {
                    try {
                        setShowLoader(true);
                        const additionalThreads = await api.get("/api/v2/messages", {
                            params: {
                                offset: threads.length
                            }
                        });

                        setHasMore(additionalThreads.data.items.length + threads.length < additionalThreads.data.count);
                        setThreads(threads.concat(additionalThreads.data.items));
                    } finally {
                        setShowLoader(false);
                    }
                }
            }
        }

        threadListRef.current.addEventListener("scroll", handler);

        return () => {
            threadListRef.current?.removeEventListener("scroll", handler);
        };
    }, [threadListRef.current, api, hasMore, setHasMore, setThreads]);

    const matches = useMatches();
    const match = matches[matches.length - 1];

    return <Account title={t("Private messages")}>
        <Columns
            variant={"fixed"}
            minH={{
                lg: "30em",
                base: "25em",
            }} maxH={{
                lg: "calc(100vh - 28em)",
                base: "calc(100vh - 12em)",
            }}
        >
            <>
                <VStack
                    spacing={0}
                    height={"100%"}
                    alignItems={"stretch"}
                    my={{
                        base: -4,
                        lg: 0
                    }}
                    hideBelow={match.id === IndexRoute.id ? undefined : "lg"}
                >
                    <Button as={Link} to={ROUTES.ACCOUNT_MESSAGES_NEW} variant={"ghost"}><IconPlus /> {t("New private message")}</Button>
                    <UnorderedList variant={"message-threads"} flexGrow={1} overflow={"auto"} ref={threadListRef}>
                        {threads.map((thread) => (
                            <ListItem key={thread.id} bg={threadId === thread.id ? "shade" : undefined}>
                                <Link to={`/messages/${thread.id}`}>
                                    {(thread as MessageThreadWithUser).users
                                        ? <UserThreadHeader thread={thread as MessageThreadWithUser} />
                                        : (thread as MessageThreadWithXwg).xwg
                                            ? <XwgThreadHeader thread={thread as MessageThreadWithXwg} />
                                            : <SystemThreadHeader thread={thread} />
                                    }
                                </Link>
                            </ListItem>
                        ))}
                        {showLoader && <ListItem>
                            <HStack justifyContent={"center"}>
                                <Loader />
                                {t("Loading additional conversations...")}
                            </HStack>
                        </ListItem>}
                    </UnorderedList>
                </VStack>
                <Button
                    display={{
                        base: match.id === IndexRoute.id ? "none" : "fleg",
                        lg: "none"
                    }}
                    width={"100%"}
                    as={Link}
                    to={ROUTES.ACCOUNT_MESSAGES}
                    variant={"ghost"}
                    my={-4}
                >
                        <IconBack />
                        {t("Back to threads list")}
                </Button>
            </>
            <Outlet />
        </Columns>
    </Account>;
}
