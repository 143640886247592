import {accordionAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";

const {defineMultiStyleConfig, definePartsStyle} = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
    container: {
        borderWidth: "2px",
        borderColor: "divider.500",
        borderLeft: "none",
        borderRight: "none",
        borderRadius: "2xl",
        overflow: "auto",
        _last: null
    }
})

export const accordionTheme = defineMultiStyleConfig({
    baseStyle
});
