import {useLocation} from "@tanstack/react-router";
import React from "react";
import {useTranslation} from "../../utils/helpers";
import {useCurrentUser} from "../../models/user";
import {NotFound} from "../error";

import {Box, TabPanels, Tabs} from "@chakra-ui/react";
import {CollapsibleTabList, TabSpec} from "../generic/tabs";
import {ROUTES} from "../../constants";

const Account = ({title, children}: {title?: string, children?: React.ReactNode}) => {
    const {t} = useTranslation(["settings", "messages", "cwg_ops", "browse", "tags", "import", "export", "profile"]);

    const currentUser = useCurrentUser();
    const location = useLocation();

    if (!currentUser) {
        return <NotFound />;
    }

    const tabs: TabSpec[] = [
        {
            to: ROUTES.ACCOUNT_MESSAGES,
            label: <>{t("Private messages", {ns: "messages"})}</>
        },
        {
            to: ROUTES.ACCOUNT_CHANGE_PASSWORD,
            label: t("Change password")
        },
        {
            to: ROUTES.ACCOUNT_PROFILE,
            label: t("Profile settings")
        },
        {
            to: ROUTES.XWG_OWN,
            label: t("xWGs entered by me", {ns: "cwg_ops"})
        },
        {
            to: ROUTES.COLLECTION_DUPLICATES,
            label: t("Duplicates", {ns: "browse"})
        },
        {
            to: ROUTES.COLLECTION_TAGS,
            label: t("Tags", {ns: "tags"})
        },
        /*{
            to: ImportRoute.fullPath,
            label: <>{t("Import", {ns: "import"})} <Todo /></>
        },
        {
            to: ExportRoute.fullPath,
            label: <>{t("Export", {ns: "export"})} <Todo /></>
        },*/
        {
            to: ROUTES.USER_PUBLIC_PROFILE,
            params: {username: currentUser.name},
            label: t("Public profile", {ns: "profile"})
        }
    ];

    return <>
        {title && <h2>{title}</h2>}
        <Tabs index={tabs.findIndex(tab => location.href.startsWith(tab.to ?? ""))}>
            <CollapsibleTabList tabs={tabs} />
            <TabPanels>
                <Box p={4}>
                    {children}
                </Box>
            </TabPanels>
        </Tabs>
    </>;
}

export default Account;
