import MdiInformationSlabCircle from "~icons/mdi/information-slab-circle";
import React from "react";
import {Tooltip, TooltipProps} from "@chakra-ui/react";

export const InfoBox = ({children, ...props}: Omit<TooltipProps, "label">) => {
    return <Tooltip {...props} label={children}>
        <span style={{verticalAlign: "middle", lineHeight: ".9em"}}>
            <MdiInformationSlabCircle />
        </span>
    </Tooltip>
}
