import {FormProvider, useForm} from "react-hook-form";
import {Controller, ErrorMessage, FormButtons} from "../generic/form";
import {Button} from "../generic/buttons";
import {isServer, useTranslation} from "../../utils/helpers";
import {CollectionData} from "../../api/updateCollection";
import React, {useEffect, useRef} from "react";
import {
    Textarea,
    Input,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    FormControl, FormLabel
} from "@chakra-ui/react";
import {diagToForm} from "../../utils/diagToForm";
import {Diagnostics} from "../../models/error";

type SetErrorMethod = ReturnType<typeof useForm<CollectionData>>["setError"];
type ResetMethod = ReturnType<typeof useForm<CollectionData>>["reset"];

type CollectFormProps = {
    onSubmit?: (data: CollectionData, setError: SetErrorMethod, reset: ResetMethod) => Promise<void>;
    value?: CollectionData;
    disabled?: boolean;
    errors?: Diagnostics;
    onCancel?: () => void;
    onChange?: (data: CollectionData) => void;
    showSubmitButton?: boolean;
    setGetCollectionData?: (callback: (() => CollectionData) | undefined) => void;
}

export const CollectionForm = (
    {
        onSubmit,
        value,
        disabled,
        errors: submitErrors,
        onCancel,
        onChange,
        showSubmitButton,
    }: CollectFormProps
) => {
    const defaultValue = {
        year: new Date().getFullYear(),
        pieces: 1,
        date: (new Date()).toISOString().split("T")[0]
    }
    const methods = useForm<CollectionData>({
        defaultValues: value ?? defaultValue,
        criteriaMode: "all",
        disabled: disabled ?? false,
        mode: onChange ? "onBlur" : "onSubmit",
    });
    const { register, handleSubmit, formState: { errors }, setError, getValues, reset } = methods;
    const { t } = useTranslation("cwg_index");

    const finalShowSubmitButton = showSubmitButton ?? true;

    useEffect(() => {
        if (submitErrors) {
            diagToForm(submitErrors, setError);
        }
    }, [submitErrors]);

    useEffect(() => {
        if (value) {
            reset(value);
        } else {
            reset(defaultValue);
        }
    }, [value]);

    const timeout = useRef<number | null>(null);

    return <form
        onSubmit={handleSubmit(async (data) => {
            await onSubmit?.(data, setError, reset);
        })}
        onChange={() => {
            if (!isServer) {
                if (timeout.current) {
                    window.clearTimeout(timeout.current);
                }

                timeout.current = window.setTimeout(() => {
                    onChange?.(getValues());
                }, 200);
            } else {
                onChange?.(getValues());
            }
        }}
        onBlur={() => onChange?.(getValues())}
    >
        <FormProvider {...methods}>
            <main>
                <FormControl>
                    <FormLabel>{t("Note:")}</FormLabel>
                    <Textarea
                        {...register("comment")}
                    />
                    <ErrorMessage errors={errors} name={"comment"} />
                </FormControl>

                <FormControl>
                    <FormLabel>{t("Number of pieces")}:</FormLabel>
                    <Controller
                        name={"pieces"}
                        fromInputValue={(value) => parseInt(value)}
                        render={({field}) => <NumberInput w={"6em"} {...field} min={1} mr={2}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>}
                        rules={{
                            min: {
                                value: 1,
                                message: t("You must collect at least one piece.")
                            },
                            required: t("You must collect at least one piece."),
                        }}
                    />
                    <ErrorMessage errors={errors} name={"pieces"} />
                </FormControl>

                <FormControl>
                    <FormLabel>{t("Date of collection")}:</FormLabel>
                    <Input
                        type="date"
                        {...register("date", {
                            required: t("Date is required."),
                        })}
                        style={{width: "10em"}}
                        mr={2}
                    />
                    <ErrorMessage errors={errors} name={"date"} />
                </FormControl>

                <FormControl>
                    <FormLabel>{t("Year")}:</FormLabel>
                    <Controller
                        name={"year"}
                        fromInputValue={(value) => {
                            const num = parseInt(value);
                            if (Number.isInteger(num)) {
                                return num;
                            }

                            return new Date().getFullYear();
                        }}
                        render={({field}) => <NumberInput w={"6em"} {...field} min={2005} max={new Date().getFullYear() + 1} mr={2}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>}
                        rules={{
                            min: {
                                value: 2005,
                                message: t("Year cannot be lower than {{minYear}}.", {minYear: 2005}),
                            },
                            max: {
                                value: new Date().getFullYear() + 1,
                                message: t("Year cannot be higher than {{maxYear}}.", {maxYear: new Date().getFullYear() + 1}),
                            },
                            required: t("Year is required.")
                        }}
                    />
                    <ErrorMessage errors={errors} name={"year"} />
                </FormControl>
            </main>

            {finalShowSubmitButton &&
                <FormButtons>
                    <Button type={"submit"}>{value ? t("Save") : t("Collect")}</Button>
                    {onCancel && <Button type={"button"} onClick={() => onCancel()}>{t("Cancel")}</Button>}
                </FormButtons>
            }
        </FormProvider>
    </form>
}
