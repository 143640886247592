import {tabsAnatomy} from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const {defineMultiStyleConfig, definePartsStyle} = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const primaryVariantTheme = definePartsStyle((props) => {
    const {colorScheme: c} = props;

    return {
        tab: {
            whiteSpace: "nowrap",
            border: "2px solid",
            borderColor: `${c}.500`,
            borderRadius: "2xl",
            borderBottom: "none",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            py: ".4em",
            px: "1em",
            pb: ".32em",
            mb: 0,
            mr: "-2px",
            color: "black",
            _selected: {
                borderColor: `${c}.500`,
                borderBottom: "none",
                bg: "shade",
                color: "black",
            },
            "&[data-status=active]": {
                borderColor: `${c}.500`,
                borderBottom: "none",
                bg: "shade",
                color: "black",
            },
            _hover: {
                bg: "shade",
            },
            "&:link, &:visited, &:active, &:hover": {
                textDecoration: "none"
            },
        },
        tablist: {
            border: "none",
            pl: 4,
            mb: 0,
        },
        tabpanels: {
            borderTop: "2px solid",
            borderColor: `${c}.500`,
            borderRadius: "2xl",
        }
    }
});

const secondaryVariantTheme = definePartsStyle((props) => {
    const {colorScheme: c} = props;

    return {
        tab: {
            whiteSpace: "nowrap",
            border: "2px solid",
            borderColor: `${c}.500`,
            borderRadius: "2xl",
            borderTop: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            py: ".4em",
            px: "1em",
            pt: ".32em",
            mb: 0,
            mr: "-2px",
            color: "black",
            _selected: {
                borderColor: `${c}.500`,
                borderTop: "none",
                bg: "shade",
                color: "black",
            },
            "&[data-status=active]": {
                borderColor: `${c}.500`,
                borderTop: "none",
                bg: "shade",
                color: "black",
            },
            _hover: {
                bg: "shade",
            },
            "&:link, &:visited, &:active, &:hover": {
                textDecoration: "none"
            },
        },
        tablist: {
            border: "none",
            pl: 4,
            mb: 0,
        },
    }
});

export const tabsTheme = defineMultiStyleConfig({
    variants: {
        primary: primaryVariantTheme,
        secondary: secondaryVariantTheme,
    },
    defaultProps: {
        variant: "primary",
        colorScheme: "divider"
    }
});
