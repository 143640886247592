import {inputAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(inputAnatomy.keys);

import {rgba} from "polished";

export const filledVariant = definePartsStyle({
    field: {
        border: `solid 1px`,
        borderColor: "divider.500",
        borderRadius: "sm",
        bg: "#faf6ed",
        color: "black",
        boxShadow: `inset 2px 2px 15px -7px ${rgba(0, 0, 0, .3)}`,
        px: 2,
        _focusVisible: {
            bg: null,
        },
    },
    group: {
        alignItems: "stretch",
        boxShadow: `inset 2px 2px 15px -7px ${rgba(0, 0, 0, .3)}`,
        bg: "#faf6ed",
        "input:has(+.chakra-input__right-addon),textarea:has(+.chakra-input__right-addon)": {
            boxShadow: "none",
            borderTopRightRadius: "none",
            borderBottomRightRadius: "none",
            borderRight: "none",
            background: "none",
        },
        ".chakra-input__left-addon+input,.chakra-input__left-addon+textarea": {
            boxShadow: "none",
            borderTopLeftRadius: "none",
            borderBottomLeftRadius: "none",
            borderLeft: "none",
            background: "none",
        }
    },
    addon: {
        height: "auto",
        bg: "rgba(0,0,0,.1)",
        borderRadius: "none",
        borderTopRightRadius: "sm",
        borderBottomRightRadius: "sm",
        border: "solid 1px",
        borderColor: "divider.500",
        borderLeft: "none",
        background: "none",
    }
});

export const inputTheme = defineMultiStyleConfig({
    defaultProps: {
        variant: "filled"
    },
    variants: {
        filled: filledVariant
    },
    sizes: {
        md: {
            field: {
                py: 1
            }
        }
    }
});
