import React, {Children} from "react";

import {
    Box,
    chakra,
    forwardRef,
    HTMLChakraProps,
    omitThemingProps,
    SystemStyleObject,
    ThemingProps,
    useMultiStyleConfig
} from "@chakra-ui/react";

import {createContext} from "@chakra-ui/utils";
import {anatomy} from "@chakra-ui/anatomy";

const [ColumnsStylesProvider, useColumnsStyles] = createContext<Record<string, SystemStyleObject>>({
    name: "ColumnsStylesContext",
    errorMessage: "useColumnsStyles returned is 'undefined'. Seems you forgot to wrap the components in <Columns />"
});

export {
    useColumnsStyles
}

export interface ColumnsProps extends HTMLChakraProps<"div">, ThemingProps<"Columns"> {}

export const Columns = forwardRef<ColumnsProps, "div">(function(props, ref) {
    const styles = useMultiStyleConfig("Columns", props);
    const {
        children,
        ...rest
    } = omitThemingProps(props);

    return (
        <ColumnsStylesProvider value={styles}>
            <chakra.div
                ref={ref}
                __css={{ ...styles.container }}
                {...rest}
            >
                {Children.toArray(children).map((child, index) =>
                    <Column key={index}>{child}</Column>
                )}
            </chakra.div>
        </ColumnsStylesProvider>
    );
});

Columns.displayName = "Columns";

export const Column = forwardRef<HTMLChakraProps<"div">, "div">(function(props, ref) {
    const styles = useColumnsStyles();

    return <Box
        ref={ref}
        {...props}
        __css={{ ...styles.column }}
    />
});

Column.displayName = "Column";

export default Columns;

export const columnsAnatomy = anatomy("div")
    .parts("container", "column");
