import {tagAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";
import _ from "lodash";

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(tagAnatomy.keys);

const variantTag = definePartsStyle({
    container: {
        background: "#EBCD9D linear-gradient(to bottom, #EBCD9D, #D9BD91)",
        border: "solid 1px #B39B77",
        borderRadius: 0,
        borderLeft: "none",
        borderTopRightRadius: ".2em",
        borderBottomRightRadius: ".2em",
        color: "black",
        textShadow: ".1em .1em .2em rgba(255, 255, 255, .5)",
        fontSize: "small",
        position: "relative",
        zIndex: "1",
        marginLeft: ".7em",
        "::before": {
            content: "''",
            display: "inline-block",
            width: "1.20em",
            height: "1.20em",
            position: "absolute",
            left: "-.65em",
            top: ".15em",
            transform: "rotate(-45deg)",
            background: "#EBCD9D linear-gradient(135deg, #EBCD9D, #D9BD91)",
            border: "solid 1px #B39B77",
            borderRadius: "1px",
            borderBottomLeftRadius: ".2em",
            zIndex: "-1",
            clipPath: "polygon(0 0, 1.4em 0, 0 1.4em)",
        },
        "::after": {
            content: "''",
            width: ".4em",
            height: ".4em",
            bg: "#FFF4D9",
            "borderRadius": ".4em",
            position: "absolute",
            left: "-.2em",
            top: "calc(50% - .2em - 1px)",
            zIndex: "2",
            border: "solid 1px #B39B77",
        },
    },
    label: {
        "a": {
            textDecoration: "none",
            display: "block",
            ml: "-1.2em",
            pl: "1.2em",
        },
        position: "relative",
        zIndex: "3",
        ml: "-1.2em",
        pl: "1.2em",
        lineHeight: "1.5",
    }
});

const variantTagActive = definePartsStyle(_.merge({}, variantTag, {
    container: {
        background: "#D9BD91 linear-gradient(135deg, #D9BD91, #EBCD9D)",
        "::before": {
            background: "#D9BD91 linear-gradient(135deg, #D9BD91, #EBCD9D)",
        },
        "::after": {
            bg: "divider.300"
        }
    }
}));

export const tagTheme = defineMultiStyleConfig({
    variants: {
        tag: variantTag,
        tagActive: variantTagActive
    },
});
