import {Badge} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "../../utils/helpers";
import {PublishStatus} from "../../models/xwg";

export function PublicationBadge({publishStatus}: {publishStatus: PublishStatus}) {
    const {t} = useTranslation("cwg_index");

    if (publishStatus === "new" || publishStatus === "hold") {
        return <Badge colorScheme={"yellow"} fontSize={"3xs"} ml={2}>{t("Not published")}</Badge>
    }

    if (publishStatus === "archived") {
        return <Badge colorScheme={"red"} fontSize={"3xs"} ml={2}>{t("Archived")}</Badge>
    }

    return null;
}
