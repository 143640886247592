import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {getAPI, useAPI} from "../../../api/api";
import {getTagCategories} from "../../../api/getTagCategories";
import {useTranslation} from "../../../utils/helpers";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useFlash} from "../../../components/generic/flash";
import {Columns} from "../../../components/generic/columns";
import {XwgEntry, XwgQueryResponse, XwgWithId} from "../../../models/xwg";
import {CwgSearchResult} from "../../../components/cwg/cwgSearchResult";
import CwgForm from "../../../components/cwg/cwgForm";
import {combineTitle} from "../../../utils/combineTitle";
import {getFixedT} from "../../../utils/getFixedT";
import {ErrorComponent} from "../../../components/error";
import {Alert, AlertDescription, AlertIcon} from "@chakra-ui/react";
import {XwgSearchQuery} from "../../../models/xwgSearchQuery";
import {TokenScope} from "../../../models/tokenScope";
import {UseFormSetError} from "react-hook-form";
import {Forbidden, ValidationError} from "../../../utils/errors";
import {diagToForm} from "../../../utils/diagToForm";
import {DiagnosticError} from "../../../models/error";
import {ROUTES} from "../../../constants";
import {RouterContext} from "../../../models/routerContext";

export const Route = createFileRoute('/_site/cwg/entry')({
    meta: ({matches, match}) => {
        const t = getFixedT("cwg_ops", match.context.request?.i18n);
        return combineTitle(matches, t("Insert new xWG"))
    },
    component: CwgEntry,
    errorComponent: ErrorComponent,
    loader: async ({context}: {context: RouterContext}) => {
        const api = getAPI(context);
        const tagCategories = await getTagCategories({api});

        if (!context.currentUser.currentUser?.has(TokenScope.CWG_CREATE)) {
            throw new Forbidden();
        }

        return {
            tagCategories
        };
    }
});

function CwgEntry() {
    const {t} = useTranslation("cwg_ops");

    const [query, setQuery] = useState<string>();
    const [searchResult, setSearchResult] = useState<XwgQueryResponse | null>(null);
    const [offset, setOffset] = useState<number>(0);
    const limit = 100;

    const {tagCategories} = Route.useLoaderData();
    const api = useAPI({flash: false});
    const apiWithFlash = useAPI();
    const flash = useFlash();
    const navigate = useNavigate();

    useEffect(() => {
        if (!query) {
            setSearchResult(null);
            return () => {};
        }

        const abort = new AbortController();

        const fetchData = async () => {
            const resp = await apiWithFlash.post<XwgQueryResponse, XwgSearchQuery>("/api/v2/xwg/query", {
                filter: {
                    names: [query]
                },
                display: {
                    offset,
                    limit,
                    attributes: ["id", "name", "version", "images", "tags", "cat_no", "status", "note", "link", "publish_status"],
                    order: ["name:ASC", "version:ASC"]
                }
            }, {
                signal: abort.signal,
            });
            setSearchResult({
                items: resp.data.items.map(item => new XwgWithId(item)),
                count: resp.data.count
            });
        }

        fetchData();

        return () => {
            abort.abort();
        }
    }, [query, offset, limit]);

    const imageFormatErrors = {
        "image_format_invalid": t("Image must be in JPEG format."),
        "image_dimension_invalid": (diag: DiagnosticError) => t("Image is too small. Expected size at least {{expectedDimension}} pixels.", {expectedDimension: diag.expected}),
    };

    const createXwgCallback = useCallback(async (xwg: XwgEntry, setError: UseFormSetError<XwgEntry>) => {
        const flashId = flash.loading(t("Creating xWG..."));
        try {
            const putResponse = await api.post<XwgWithId[], XwgEntry[]>("/api/v2/xwg", [{
                name: xwg.name,
                version: xwg.version,
                note: xwg.note ? xwg.note : null,
                link: xwg.link ? xwg.link : null,
                tags: xwg.tags,
            }]);

            const id = putResponse.data[0].id;

            if (xwg.frontside && xwg.frontside[0]) {
                try {
                    const fd = new FormData();
                    fd.set("type", "private");
                    fd.set("image", xwg.frontside[0]);

                    await api.post(`/api/v2/xwg/${id}/image`, fd);
                } catch (e) {
                    if (e instanceof ValidationError) {
                        diagToForm(
                            e.diagnostics,
                            setError,
                            {
                                stripPrefix: ["body", "image"],
                                prefix: ["frontside"],
                                customErrors: imageFormatErrors,
                            }
                        );
                    }
                    throw e;
                }
            }

            if (xwg.backside && xwg.backside[0]) {
                try {
                    const fd = new FormData();
                    fd.set("type", "private");
                    fd.set("image", xwg.backside[0]);

                    await api.post(`/api/v2/xwg/${id}/image`, fd);
                } catch (e) {
                    if (e instanceof ValidationError) {
                        diagToForm(
                            e.diagnostics,
                            setError,
                            {
                                stripPrefix: ["body", "image"],
                                prefix: ["backside"],
                                customErrors: imageFormatErrors
                            }
                        );
                    }
                    throw e;
                }
            }

            flash.success({description: t("xWG has been successfully created.")});
            await navigate({
                to: ROUTES.XWG_INDEX,
                params: {
                    xwgId: id
                }
            });
        } catch (e) {
            if (e instanceof ValidationError) {
                diagToForm(
                    e.diagnostics,
                    setError,
                    {
                        stripPrefix: ["body", 0],
                        customErrors: {
                            "entity_already_exists": t("xWG with this name, version and categories already exists.")
                        }
                    }
                );
                flash.error({
                    title: t("There was an error when creating xWG. Investigate the form for errors and try again after fixing them."),
                    isClosable: true,
                });
            } else {
                console.error(e);
                flash.error(e as Error);
            }
        } finally {
            flash.hide(flashId);
        }
    }, [flash, api, navigate, t]);

    const memoizedForm = useMemo(() => <CwgForm
        tagCategories={tagCategories}
        onSubmit={createXwgCallback}
        onSearch={(query: string | undefined) => {
            setQuery(query);
            setOffset(0);
        }}
    />, [tagCategories, createXwgCallback, setQuery]);

    return <>
        <h2>{t("Insert new xWG")}</h2>

        <Alert status={"info"}>
            <AlertIcon />
            <AlertDescription>
                <p dangerouslySetInnerHTML={{__html: t("This page can be used to enter xWG, that isn't (so far) present in the official catalogue. Please, take extreme caution of what you are entering. Mainly, please, pay attention to <strong>xWG NAME</strong>, which should contain exactly what is written <strong>AROUND THE xWG</strong>, not inside. Before you submit new xWG, please check, that xWG is not entered with some similar name (for example including \"&amp; team\", \"&amp; family\" and similar). You will spare administrator's time who will need to manually fix your error when it is found. When you enter incorrect xWG multiple times, it may lead to revocation of your privilege to insert new items.")}}/>
                <p dangerouslySetInnerHTML={{__html: t("Describe the picture on the xWG into the <strong>Note</strong> field.")}}/>
            </AlertDescription>
        </Alert>

        <Columns mt={4}>
            {memoizedForm}
            <>
                <h3>{t("Search result", {ns: "search"})}</h3>
                <CwgSearchResult
                    search={searchResult}
                    offset={offset}
                    limit={limit}
                    onOffsetChange={setOffset}
                    warnIfNotFound={false}
                    actionable={false}
                />
            </>
        </Columns>
    </>;
}
