import React, {forwardRef, useCallback, useMemo, useState} from "react";
import {XwgStatus, XwgWithId} from "../../models/xwg";
import {CwgImage} from "./image";
import {Dd, Dl, Dt} from "../generic/dl";
import {useTranslation} from "../../utils/helpers";
import MdiClose from "~icons/mdi/close";
import {CwgCategories} from "./categories";
import {Link} from "@tanstack/react-router";
import {Button} from "../generic/buttons";
import CwgOverlay from "./overlay";
import {Box, BoxProps, chakra, Flex, useToast} from "@chakra-ui/react";
import {useAPI} from "../../api/api";
import {CollectionData} from "../../api/updateCollection";
import {StateShifter} from "./stateShifter";
import {ROUTES} from "../../constants";

type CwgRowProps = {
    xwg: XwgWithId;
    onCloseRequest?: () => void;
    quickCollect?: CollectionData;
    stateShifter?: boolean;
    customActions?: (xwg: XwgWithId) => React.ReactNode;
}

export const CwgRow = forwardRef<HTMLDivElement, CwgRowProps & BoxProps>(function ({
    xwg,
    onCloseRequest,
    quickCollect,
    stateShifter,
    customActions,
    ...props
}, forwardedRef) {
    const {t} = useTranslation("cwg_index");
    const categories = xwg.categories;

    const [status, setStatus] = useState<XwgStatus>(xwg.status as XwgStatus);
    const toast = useToast();

    const api = useAPI();
    const doQuickCollect = useCallback(async () => {
        if (quickCollect) {
            await api.patch(`/api/v2/xwg/${xwg.id}/collection`, [quickCollect])
        } else {
            await api.patch(`/api/v2/xwg/${xwg.id}/collection`);
        }
        const updated = await api.get<XwgWithId>(`/api/v2/xwg/${xwg.id}?attribute=status`);

        if ((updated.data.status as XwgStatus).collected) {
            toast({
                title: t("xWG has been placed into your collection."),
                status: "success",
                isClosable: true,
            });
        }

        setStatus(updated.data.status as XwgStatus);
    }, [xwg, api, quickCollect]);

    const memoizedImage = useMemo(() => <Link to={ROUTES.XWG_INDEX} params={{xwgId: xwg.id}}>
        <CwgImage
            images={xwg.images ?? []}
            overlay={xwg.overlay}
            width={"8em"}
            position={"relative"}
        />
    </Link>, [xwg]);

    const memoizedRow = useMemo(() => <>
        <Box
            {...props}
            display={"flex"}
            gap={2}
            ref={forwardedRef}
        >
            {memoizedImage}
            <Box
                flexGrow={1}
            >
                <chakra.header
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    position={"relative"}
                >
                    <chakra.h4 m={0} flexGrow={"1"}>
                        <Link
                            to={ROUTES.XWG_INDEX}
                            params={{xwgId: xwg.id}}
                        >
                            {xwg.name}
                        </Link>
                    </chakra.h4>
                    <CwgOverlay status={status} unofficial={xwg.isUnofficial} position={"static"}/>
                    {onCloseRequest &&
                        <Button variant={"ghost"} type="button" size={"sm"} onClick={onCloseRequest}><MdiClose/></Button>}
                </chakra.header>
                <Dl>
                    {categories && <>
                        <Dt>{t("Category:")}</Dt>
                        <Dd><CwgCategories xwg={xwg}/></Dd>
                    </>}

                    <Dt>{t("Version:")}</Dt>
                    <Dd>{xwg.version}</Dd>

                    {xwg.note && <>
                        <Dt>{t("Note:")}</Dt>
                        <Dd>{xwg.note}</Dd>
                    </>}

                    {xwg.link && <>
                        <Dt>{t("Profile:")}</Dt>
                        <Dd><a href={xwg.link} target="_blank">{t("here")}</a></Dd>
                    </>}

                    <Dt>{t("Cat. no.:")}</Dt>
                    <Dd>{xwg.cat_no ?? t("Uncatalogized xWG")}</Dd>
                </Dl>
            </Box>
        </Box>
    </>, [xwg, status, onCloseRequest, categories, t]);

    return <>
        {memoizedRow}
        <Flex justifyContent={"space-between"}>
            {stateShifter && <StateShifter xwg={xwg} status={status} size={"xs"} variant={"secondary"} onStatusChange={setStatus} />}
            {quickCollect && <Button variant={"secondary"} size={"sm"} onClick={() => doQuickCollect()}>{t("Collect")}</Button>}
            {customActions?.(xwg)}
        </Flex>
    </>
});
