import {XwgQueryResponse, XwgWithId} from "../models/xwg";
import {XwgFilter, XwgSearchQuery} from "../models/xwgSearchQuery";
import {WithAPI} from "./api";

export type SearchParams = {
    offset: number;
    limit: number;
    filter?: XwgFilter;
} & WithAPI;

export async function searchXwg({offset, limit, api, filter}: SearchParams): Promise<XwgQueryResponse | null> {
    const searchQuery: XwgSearchQuery = {
        filter: filter,
        display: {
            offset: offset,
            limit: limit,
            order: ["name:ASC", "version:ASC"],
            attributes: ["id", "name", "version", "images", "tags", "cat_no", "status", "note", "publish_status"]
        }
    };

    const response = await api.post<XwgQueryResponse>("/api/v2/xwg/query", searchQuery);

    return {
        items: response.data.items.map(xwg => new XwgWithId(xwg)),
        count: response.data.count
    };
}
