import {alertAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {defineMultiStyleConfig, definePartsStyle} = createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
    container: {
        ">div": {
            flexGrow: "1",
        },
    },
});

export const alertTheme = defineMultiStyleConfig({
    baseStyle
});
