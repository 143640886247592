import {NavigateOptions} from "@tanstack/react-router";

const ROUTES: Record<string, NavigateOptions["to"]> = {
    XWG_INDEX: "/cwg/index/$xwgId",
    XWG_IMAGES: "/cwg/index/$xwgId/images",
    XWG_SEARCH: "/cwg/search",
    XWG_SEARCH_WITH_QUERY: "/cwg/search/$query",
    XWG_MODIFY: "/cwg/modify/$xwgId",
    XWG_JOIN: "/cwg/index/$xwgId/join",
    XWG_OWN: "/cwg/own",

    BROWSE: "/cwg/browse",
    BROWSE_ADVANCED_WITH_FILTER: "/cwg/browse/advanced/$filter",

    USER_PUBLIC_PROFILE: "/users/index/$username",
    USER_COLLECTION: "/users/collection/$username",
    USER_OFFERS: "/users/offers/$username",
    USER_WANTS: "/users/wants/$username",
    USER_NOT_WANT: "/users/notwant/$username",
    USER_MESSAGES: "/users/messages/$username",

    USER_SEARCH: "/users/search",
    USER_SEARCH_WITH_QUERY: "/users/search/$username",

    ACCOUNT_MESSAGES: "/messages",
    ACCOUNT_MESSAGES_NEW: "/messages/new",

    ACCOUNT_CHANGE_PASSWORD: "/change-password",
    ACCOUNT_PROFILE: "/users/profile",

    COLLECTION_BROWSE: "/cwg-collection/browse",
    COLLECTION_DUPLICATES: "/cwg-collection/duplicates",
    COLLECTION_TAGS: "/cwg-collection/tags",

    ADMIN: "/admin",

    NEWS_DETAIL: "/news/index/$id",
    NEWS_ARCHIVE: "/news/archive",

    TOP_COLLECTORS: "/users/stats",
    TOP_OFFERS: "/users/stats_offers",
};

const HTTP = {
    CONTINUE: 100,
    SWITCHING_PROTOCOLS: 101,
    PROCESSING: 102,
    EARLY_HINTS: 103,
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NON_AUTHORITATIVE_INFORMATION: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
    PARTIAL_CONTENT: 206,
    MULTIPLE_CHOICES: 300,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
    TEMPORARY_REDIRECT: 307,
    PERMANENT_REDIRECT: 308,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PROXY_AUTHENTICATION_REQUIRED: 407,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    GONE: 410,
    LENGTH_REQUIRED: 411,
    PRECONDITION_FAILED: 412,
    PAYLOAD_TOO_LARGE: 413,
    URI_TOO_LONG: 414,
    UNSUPPORTED_MEDIA_TYPE: 415,
    EXPECTATION_FAILED: 417,
    I_M_A_TEAPOT: 418,
    MISDIRECTED_REQUEST: 421,
    UNPROCESSABLE_CONTENT: 422,
    LOCKED: 423,
    FAILED_DEPENDENCY: 424,
    TOO_EARLY: 425,
    UPGRADE_REQUIRED: 426,
    PRECONDITION_REQUIRED: 428,
    TOO_MANY_REQUESTS: 429,
    REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
    UNAVAILABLE_FOR_LEGAL_REASONS: 451,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
};

export {
    ROUTES,
    HTTP
};
