const nonEnumerable: {
    (target: any, name: string): void;
    (target: any, name: string, desc: PropertyDescriptor): PropertyDescriptor;
} = (target: any, name: string, desc?: any) => {
    if(desc) {
        desc.enumerable = false;
        return desc;
    }
    Object.defineProperty(target, name,  {
        set(value) {
            Object.defineProperty(this, name, {
                value, writable: true, configurable: true, enumerable: false
            });
        },
        configurable: true,
        enumerable: false
    });
};

export class Model<M> {
    @nonEnumerable
    protected __model: M;

    constructor(model: M) {
        this.__model = model;
        for (const key in model) {
            if (!Object.hasOwnProperty.call(this, key) && key !== "__model") {
                Object.defineProperty(this, key, {
                    get: () => this.__model[key],
                    set: (value) => this.__model[key] = value,
                    enumerable: true
                });
            }
        }
    }
}
