export const scrollbarStyle = {
    "&::-webkit-scrollbar": {
        background: "transparent",
        w: 2,
        opacity: .5,
    },
    "&::-webkit-scrollbar-thumb": {
        bgColor: "divider.500",
        borderRadius: "full",
    },
};
