import {HTTP} from "../constants";
import { WithAPI } from "./api";

export type RemoveXwgProps = {
    xwgId: number
} & WithAPI;

export async function removeXwg({api, xwgId}: RemoveXwgProps): Promise<boolean> {
    const resp = await api.delete(`/api/v2/xwg/${xwgId}`);
    return resp.status === HTTP.NO_CONTENT || resp.status === HTTP.OK;
}
