import {dlAnatomy} from "../../components/generic/dl";
import {
    createMultiStyleConfigHelpers,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(dlAnatomy.keys);

const baseStyle = definePartsStyle({
    container: {
        display: "grid",
        gridTemplateColumns: "min-content auto",
        columnGap: "1em",
        rowGap: ".4em",

        "&>:not(dt):not(dd)": {
            gridColumn: "span 2",
        }
    },

    dt: {
        fontWeight: "bold",
        gridColumn: 1,
        whiteSpace: "nowrap",
    },

    dd: {
        m: 0,
        gridColumn: 2,
    },
});

const borderVariant = definePartsStyle({
    dt: {
        borderTop: "solid 1px",
        borderBottom: "solid 1px",
        borderColor: "divider.500",
        paddingTop: ".5em",
        paddingBottom: ".5em",
        paddingRight: "2em",
    },

    dd: {
        borderTop: "solid 1px",
        borderBottom: "solid 1px",
        borderColor: "divider.500",
        paddingTop: ".5em",
        paddingBottom: ".5em",
    },

    container: {
        marginBottom: "2em",
        columnGap: 0,
        rowGap: 0,

        "& dt+dd+dt, & dd+dt+dd": {
            borderTop: "none",
        }
    }
});

export const dlTheme = defineMultiStyleConfig({
    baseStyle,
    variants: {
        "border": borderVariant
    }
})
