import {createFileRoute, Link, useRouter} from '@tanstack/react-router'
import {getAPI, useAPI} from "../../../../api/api";
import {ListResponse} from "../../../../models/response";
import {ExistingGroup, GroupScopes} from "../../../../models/user";
import React from "react";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../components/generic/breadcrumb";
import {useTranslation} from "../../../../utils/helpers";
import {
    Badge,
    BreadcrumbItem,
    BreadcrumbLink,
    ButtonGroup,
    Stack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast
} from "@chakra-ui/react";
import {Route as AdminIndexRoute} from "../index";
import {Route as ModifyGroupRoute} from "./$groupId.modify";
import {Button, EditButton, RemoveButton} from "../../../../components/generic/buttons";
import {unsplitScopes} from "../users/$userId.privileges";

export const Route = createFileRoute('/_site/admin/groups/')({
    component: () => {
        const {t} = useTranslation("admin_users");
        const {groups} = Route.useLoaderData();
        const api = useAPI();
        const toast = useToast();
        const router = useRouter();

        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("Groups")}</h2>
                <Button as={Link} to={Route.fullPath + "create"}>{t("Create group")}</Button>

                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>
                            {t("Administration", {ns: "admin"})}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath}>
                            {t("Groups")}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>

            <Table>
                <Thead>
                    <Tr>
                        <Th>{t("Name")}</Th>
                        <Th>{t("Privileges")}</Th>
                        <Th className={"td-shrink"}></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {groups.items.map(group => (
                        <Tr key={group.id}>
                            <Th whiteSpace={"nowrap"}>{group.name}</Th>
                            <Td>
                                <Stack direction={"row"} flexWrap={"wrap"}>
                                    {
                                        Object.entries(unsplitScopes(group.scopes))
                                        .map(([scope, value]) =>
                                            <Badge colorScheme={value ? "green" : "red"}>{scope}</Badge>
                                        )
                                    }
                                </Stack>
                            </Td>
                            <Td>
                                <ButtonGroup isAttached variant={"secondary"} size={"sm"}>
                                    <EditButton
                                        as={Link}
                                        to={ModifyGroupRoute.fullPath}
                                        params={{
                                            groupId: group.id
                                        }}
                                    />
                                    <RemoveButton
                                        confirm={t("Do you really want to remove this group?")}
                                        onClick={async () => {
                                            await api.delete("/api/v2/groups/{groupId}", {
                                                pathParams: {
                                                    groupId: group.id
                                                }
                                            });

                                            toast({
                                                title: t("Group has been deleted."),
                                                status: "success"
                                            });

                                            await router.invalidate();
                                        }}
                                    />
                                </ButtonGroup>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    },
    loader: async ({context}) => {
        const api = getAPI(context);
        const groups = await api.get<ListResponse<ExistingGroup & GroupScopes>>("/api/v2/groups", {
            params: {
                attribute: ["id", "name", "scopes"]
            }
        });

        return {
            groups: groups.data
        };
    }
});
