import {HistoryEntry} from "../../models/xwg";
import {Box, Heading, HStack, List, ListItem, Stack, StackDivider} from "@chakra-ui/react";
import {FormattedDateTime} from "../generic/date";
import {ProfileLink} from "../users/profileLink";
import React, {ReactElement} from "react";
import {useTranslation} from "../../utils/helpers";
import {Trans} from "react-i18next";

function HistoryItem<
    I extends Exclude<keyof HistoryEntry, "date" | "user">,
    V = Exclude<Exclude<HistoryEntry[I], undefined>[0] | Exclude<HistoryEntry[I], undefined>[1], null | undefined>,
>({name, entry, item, valueFormatter}: {
    name: string,
    entry: HistoryEntry,
    item: I,
    valueFormatter?: (v: V) => string | ReactElement
}) {
    const {t} = useTranslation("cwg_index");

    if (!valueFormatter) {
        valueFormatter = (v: V) => v as string;
    }

    return <ListItem
        border={"solid 1px"}
        borderColor={"gray.400"}
        borderBottom={"none"}
        _last={{borderBottomLeftRadius: "md", borderBottomRightRadius: "md", borderBottom: "solid 1px", borderBottomColor: "gray.400"}}
        overflow={"hidden"}
    >
        <HStack spacing={"0"} align={"stretch"} overflow={"hidden"}>
            <Box
                p={1}
                bg={"gray.50"}
                color={"gray.900"}
                borderRight={"solid 1px"}
                borderColor={"gray.400"}
                flex={"1 1 50%"}
                fontWeight={"bold"}
                display={"flex"}
                alignItems={"center"}
            >
                {t(name)}
            </Box>
            {entry[item]?.[0] && <Box
                p={1}
                bg={"red.50"}
                color={"red.900"}
                flex={"1 1 50%"}
                display={"flex"}
                alignItems={"center"}
            >
                {valueFormatter(entry[item][0] as V)}
            </Box>}
            {entry[item]?.[0] && entry[item]?.[1] && <Box
                p={1}
                bg={"gray.50"}
                color={"gray.900"}
                borderX={"solid 1px"}
                borderColor={"gray.400"}
                display={"flex"}
                alignItems={"center"}
            >
                &raquo;
            </Box>}
            {entry[item]?.[1] && <Box
                p={1}
                bg={"green.50"}
                color={"green.900"}
                flex={"1 1 50%"}
                display={"flex"}
                alignItems={"center"}
            >
                {valueFormatter(entry[item][1] as V)}
            </Box>}
        </HStack>
    </ListItem>
}

export function XwgHistory({history}: {history: HistoryEntry[]}) {
    const {t} = useTranslation("cwg_index");

    if (!history.length) {
        return <>{t("There is no history entry for this xWG.")}</>
    }

    return <Stack spacing={"4"} divider={<StackDivider />}>
        {history?.map((entry, idx) => <Box key={idx}>
            <Heading
                size={"xs"}
                m={0}
                px={1}
                py={2}
                border={"solid 1px"}
                borderColor={"gray.400"}
                borderBottom={"none"}
                borderTopLeftRadius={"md"}
                borderTopRightRadius={"md"}
                bg={"gray.100"}
                color={"black"}
            >
                <Trans ns={"cwg_history"}>
                    <FormattedDateTime date={entry.date} /> by <ProfileLink user={entry.user} />
                </Trans>
            </Heading>
            <List>
                {entry.name && <HistoryItem
                    name={t("Name")}
                    entry={entry}
                    item={"name"}
                />}
                {entry.version && <HistoryItem
                    name={t("Version")}
                    entry={entry}
                    item={"version"}
                />}
                {entry.cat_no && <HistoryItem
                    name={t("Catalogue number")}
                    entry={entry}
                    item={"cat_no"}
                />}
                {entry.link && <HistoryItem
                    name={t("Link")}
                    entry={entry}
                    item={"link"}
                />}
                {entry.note && <HistoryItem
                    name={t("Note")}
                    entry={entry}
                    item={"note"}
                />}
                {entry.tags && <HistoryItem
                    name={t("Category")}
                    entry={entry}
                    item={"tags"}
                    valueFormatter={(tags) => tags.map(tag => tag.name).join(", ")}
                />}
                {entry.publish_status && <HistoryItem
                    name={t("Review status", {ns: "review"})}
                    entry={entry}
                    item={"publish_status"}
                    valueFormatter={(value) => t(value, {ns: "review"})}
                />}
                {entry.hold && <HistoryItem
                    name={t("Hold")}
                    entry={entry}
                    item={"hold"}
                    valueFormatter={(value) => <ProfileLink user={value} />}
                />}
                {entry.images && <HistoryItem
                    name={t("Images")}
                    entry={entry}
                    item={"images"}
                    valueFormatter={(value) => value.join(", ")}
                />}
            </List>
        </Box>)}
    </Stack>
}
