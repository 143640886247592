import {OfferData} from "../models/xwg";
import {WithAPI} from "./api";

type UpdateOfferProps = {
    xwgId: number;
    data: OfferData;
} & WithAPI;

export async function updateOffer({xwgId, api, data}: UpdateOfferProps): Promise<OfferData> {
    if (parseInt(data.pieces.toString()) === 0) {
        await api.delete(`/api/v2/xwg/${xwgId}/offer`);
        return {pieces: 0, comment: ""};
    } else {
        await api.put(`/api/v2/xwg/${xwgId}/offer`, data);
        return data;
    }
}
